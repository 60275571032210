import AsyncStorage from "@react-native-async-storage/async-storage";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Settings from "../../../config/Settings";
import getUserId from "../../../core/GetToken";

export const GetCompletedAppointFromAPI = createAsyncThunk(
    "getCompletedAppoint/Appointment",
    async (values, { dispatch, getState }) => {

        dispatch(getCompletedAppoint())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            //const queryString = '?Token=' + data + '&PatientID=17308&PageSize=' + pageSize + '&PageNumber=' + pageNumber

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = {
                PatientID: item.userid,
                PageSize: values.pageSize,
                PageNumber: values.pageNumber,
                AppointmentType: 3,
            }
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(raw)
            };


            // getPaitentAppointments(queryString, myHeaders)
            fetch(Settings.apiUrl + "Patient/Appointments", requestOptions)
                .then(data => data.json())
                .then(json => {
                    json[0].hasOwnProperty('entry') ? dispatch(getCompletedAppointSuccess(json[0].entry)) : dispatch(getCompletedAppointFinished())
                })
                .catch(err => dispatch(getCompletedAppointFailed(err)))
        })

    }

);

const GetCompletedAppointSlice = createSlice({
    name: "getCompletedAppoint",
    initialState: {
        CompletedAppointment: [],
        isGetting: false,
        error: false
    },
    reducers: {
        getCompletedAppoint: (state, action) => {
            state.CompletedAppointment = []
            state.isGetting = true
            state.error = false
        },
        getCompletedAppointSuccess: (state, action) => {
            state.CompletedAppointment = action.payload
            state.isGetting = false
            state.error = false

        },
        getCompletedAppointFailed: (state, action) => {
            state.CompletedAppointment = []
            state.isGetting = false
            state.error = true
        },
        getCompletedAppointFinished: (state, action) => {
            state.CompletedAppointment = []
            state.isGetting = false
            state.error = false
        },

    },
});




export const {
    getCompletedAppoint, getCompletedAppointFailed, getCompletedAppointSuccess, getCompletedAppointFinished
} = GetCompletedAppointSlice.actions;
export default GetCompletedAppointSlice.reducer;
