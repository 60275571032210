import React, { useEffect, useState } from "react";
import {
  Timeline,
  Modal,
  DatePicker,
  Space,
  Input,
  Select,
  Button, Spin
} from "antd";
import moment from "moment";
const { RangePicker } = DatePicker;
const { Option } = Select;

const TimelineFilterModal = ({
  isFilterModalOpen,
  handleFilterCancel,
  handleFilterOk,
  getTimelineDateTextSearch,
  lookupPersonsData,
}) => {
  const [filterStartDateMoment, setFilterStartDateMoment] = useState("");
  const [filterStartDateString, setFilterStartDateString] = useState(null);
  const [filterEndDateMoment, setFilterEndDateMoment] = useState("");
  const [filterEndDateString, setFilterEndDateString] = useState(null);
  const [filterText, setFilterText] = useState("");
  const [filterProviderId, setFilterProviderId] = useState("")

  const disabledDateForStart = (current) => {
    
    return current && current > moment().endOf("day") || current < moment("01-01-2000") || (filterEndDateMoment !== "" && current > filterEndDateMoment);
  };
  const disabledDateForEnd = (current) => {
    return (
      current &&
      current <= filterStartDateMoment || current > moment().endOf("day") || current < moment("01-01-2000")
    );
  };
  const handleFilterReset = () => {
    setFilterStartDateMoment("")
    setFilterStartDateString(null)
    setFilterEndDateMoment("")
    setFilterEndDateString(null)
    setFilterText("")
    setFilterProviderId("")

    getTimelineDateTextSearch(
      true
    );
    handleFilterCancel()
  }
  return (
    <>
      <Modal
        title="Timeline Search Filter"
        open={isFilterModalOpen}
        onCancel={handleFilterCancel}
        footer={[
          <Button
            key="close"
            className="close-btn"
            onClick={handleFilterCancel}
          >
            Close
          </Button>,
          <Button className="reset-btn" key="reset" onClick={handleFilterReset}>
            Reset
          </Button>,
          <Button
            key="Filter"
            className="btn btn-primary"
            onClick={() => {
              getTimelineDateTextSearch(
                true,
                filterStartDateString,
                filterEndDateString,
                filterText,
                filterProviderId == "" ? null : filterProviderId
              );
              handleFilterOk();
            }}
          >
            Filter
          </Button>,
        ]}
      >
        <Space direction="vertical" size={12}>
          <DatePicker
            autoComplete="off"
            placeholder={"Select start Date e.g. dd/mm/year"}
            format={"DD MMM YYYY"}
            value={filterStartDateMoment}
            disabledDate={disabledDateForStart}
            onChange={(date, dateString) => {
              setFilterStartDateMoment(date);
              setFilterStartDateString(dateString);
            }}
          />
          <DatePicker
            autoComplete="off"
            placeholder={"Select end Date e.g. dd/mm/year"}
            format={"DD MMM YYYY"}
            value={filterEndDateMoment}
            // disabled={filterStartDateMoment === null || filterStartDateMoment === ""}
            disabledDate={disabledDateForEnd}
            onChange={(date, dateString) => {
              setFilterEndDateMoment(date);
              setFilterEndDateString(dateString);
            }}
          />
          <Input
            autoComplete="off"
            placeholder="Type here to search.."
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
          />
          <Select
            showSearch
            placeholder="Select Provider"
            optionFilterProp="children"
            onChange={(e) => setFilterProviderId(e)}
            value={filterProviderId}
        
          >
            <Option value={""}>All</Option>
            {lookupPersonsData.length > 0 ? lookupPersonsData[0].entry?.map((data) => {
              return <Option value={data.resource.id}>{data.resource.title}</Option>
            }) : <Spin />}
          </Select>
        </Space>
      </Modal>
    </>
  );
};

export default TimelineFilterModal;
