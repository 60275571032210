import React, { useEffect, useState } from 'react'
import JpgFileComp from './JpgFileComp'
import PdfFileComp from './PdfFileComp'

const AttachmentsHandler = ({ selectedFiles, type="composed", handleRemoveFile=(index)=>{}} ) => {
    // const [fileDataInBase64, setFileDataInBase64] = useState([])
    // useEffect(() => {
    //     if (selectedFiles) {

    //         const images = [], fileReaders = [];
    //         let isCancel = false;

    //         selectedFiles.forEach((file) => {
    //             let data = {}
    //             const fileReader = new FileReader();
    //             fileReaders.push(fileReader);
    //             fileReader.onload = (e) => {
    //                 const { result } = e.target;
    //                 if (result) {
    //                     data = {
    //                         contentAttachment: {
    //                             contentType: result.split("/")[1].split(";base64")[0],
    //                             data: result.split("base64,")[1]

    //                         }
    //                     }
    //                     images.push(data)
    //                 }
    //                 if (images.length === selectedFiles.length && !isCancel) {
    //                     setFileDataInBase64((prev) => prev.concat(images));
    //                 }
    //             }
    //             fileReader.readAsDataURL(file);
    //         })
    //     }
    // }, [])
    const handleRemove = (index)=>{
        // if (selectedFiles.length > 0) {
        //     setFileDataInBase64(fileDataInBase64.filter((file, fileIndex) => fileIndex != index))
        // }
        handleRemoveFile(index)
    }
    return (
        <>
        <div className='d-flex flex-wrap'>

                {selectedFiles.map((fileData, index) => {
                    console.log(fileData)
                    if (fileData?.contentAttachment?.contentType?.toLowerCase() === "pdf") {
                    return <PdfFileComp attachNumber={index + 1} pdfData={fileData?.contentAttachment?.data} type ={type} handleRemove={handleRemove}/>
                } else {
                        return <JpgFileComp attachNumber={index + 1} jpgData={fileData?.contentAttachment?.data} type={type} handleRemove={handleRemoveFile} />
                }
            })}
            </div>
        </>
    )
}

export default AttachmentsHandler
