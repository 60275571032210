import AsyncStorage from "@react-native-async-storage/async-storage";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Settings from "../../../config/Settings";
import getUserId from "../../../core/GetToken";

export const ListDraftMessagesAPI = createAsyncThunk(
    "listDraftMessages/Messaging",
    async (values, { dispatch, getState }) => {

        dispatch(listingDraftMessages())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = {
                PatientID: item.userid,
                PageSize: values.pageSize,
                PageNumber: values.pageNumber,
                Type: values.type,
                MessagingType: values.messagingType,
            }
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(raw)
            };

            fetch(Settings.apiUrl + "Patient/Messaging", requestOptions)

                .then(data => data.json())
                .then(json => {
                    if (json == null) {
                        dispatch(listingDraftMessagesSuccess([{ total: 0 }]))

                    } else {

                        dispatch(listingDraftMessagesSuccess(json))
                    }
                })
                .catch(err => dispatch(listingDraftMessagesFailed(err)))

        })

    }

);

const ListingDraftMessagesSlice = createSlice({
    name: "listDraftMessages",
    initialState: {
        draftMessageListing: null,
        draftMessageListingSuccess: false,
        draftMessageListingFailed: false,
        draftMessageListingLoading: false,
        draftMessageListingError: null,
        draftMessageListingStatus: "idle",
    },
    reducers: {
        listingDraftMessages: (state, action) => {
            state.draftMessageListingSuccess = false
            state.draftMessageListingFailed = false
            state.draftMessageListingLoading = true
            state.draftMessageListingError = null
            state.draftMessageListingStatus = "loading"

        },
        listingDraftMessagesSuccess: (state, action) => {
            state.draftMessageListing = action.payload
            state.draftMessageListingSuccess = true
            state.draftMessageListingFailed = false
            state.draftMessageListingLoading = false
            state.draftMessageListingError = null
            state.draftMessageListingStatus = "success"


        },
        listingDraftMessagesFailed: (state, action) => {
            state.draftMessageListingSuccess = false
            state.draftMessageListingFailed = true
            state.draftMessageListingLoading = false
            state.draftMessageListingError = action.payload
            state.draftMessageListingStatus = "error"

        },

    },
});




export const {
    listingDraftMessages, listingDraftMessagesFailed, listingDraftMessagesSuccess
} = ListingDraftMessagesSlice.actions;
export default ListingDraftMessagesSlice.reducer;
