import AsyncStorage from "@react-native-async-storage/async-storage";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Settings from "../../../config/Settings";
import getUserId from "../../../core/GetToken";

export const GetAllMedicationsAPI = createAsyncThunk(
    "getAllMedications/Medication",
    async (values, { dispatch, getState }) => {

        dispatch(getMed())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = {
                PatientID: item.userid,
                PageSize: values.pageSize,
                PageNumber: values.pageNumber,
                TypeID: 1,
            }
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(raw)
            };



            fetch(Settings.apiUrl + "Patient/Medication", requestOptions)
                .then(data => data.json())
                .then(json => {
                    json[0].hasOwnProperty('entry') ? dispatch(getMedSuccess({ data: json[0].entry, totalData: json[0].total })) : dispatch(getMedFinished())
                })
                .catch(err => dispatch(getMedFailed(err)))
        })

    }

);

const GetAllMedicationsSlice = createSlice({
    name: "getAllMedications",
    initialState: {
        AllMedeications: [],
        isGetting: false,
        error: false
    },
    reducers: {
        getMed: (state, action) => {
            state.isGetting = true
            state.error = false

        },
        getMedSuccess: (state, action) => {
            state.AllMedeications = action.payload.data
            state.AllMedeicationsTotalData = action.payload.totalData
            state.isGetting = false
            state.error = false

        },
        getMedFailed: (state, action) => {
            state.AllMedeications = []
            state.AllMedeicationsTotalData = 0
            state.isGetting = false
            state.error = true
        },
        getMedFinished: (state, action) => {
            state.AllMedeications = []
            state.AllMedeicationsTotalData = 0
            state.isGetting = false
            state.error = false
        },

    },
});




export const {
    getMed, getMedFailed, getMedFinished, getMedSuccess
} = GetAllMedicationsSlice.actions;
export default GetAllMedicationsSlice.reducer;
