import Settings from "../config/Settings";
import { GET_PROVIDER_LOCATIONS, GET_PROVIDER_LOCATIONS_SUCCESS, GET_PROVIDER_LOCATIONS_FAILED, } from "../constants/Constant";

import getUserId from "../core/GetToken";
import getAllRoles from "../services/RolesService";

export const fetchProviderLocationFromApi = () => {
    return async (dispatch) => {

        dispatch(getProviderLocation())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = {
                PatientID: item.userid,
                TypeID: 11,
            }
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(raw)
            };
            fetch(Settings.apiUrl + "/IndiciLookup", requestOptions)
                .then(response => response.json())
                .then(json => {
                    let arrayofProviderLocation = []
                    let listofids=[]
                    let namesLocation= []
                    if (json[0].hasOwnProperty('entry'))
                        json[0].entry.map((item, index) => {
                            let ob = {
                                id: item.resource.extension[0].valueString,
                                title: item.resource.extension[1].valueString
                            }
                            let obj = {
                                id:item.resource.extension[0].valueString,
                                colorcode: getRandomColor(index),
                                name:item.resource.extension[1].valueString
                            }
                            listofids.push(item.resource.extension[0].valueString)
                            arrayofProviderLocation.push(obj)
                            namesLocation.push(item.resource.extension[0].valueString)
                        })

                    dispatch(getProviderLocationSuccess(json[0].entry,listofids,arrayofProviderLocation))

                })
                .catch(error => getProviderLocationFailed());

        })

    }
}


const getRandomColor = (index) => {
    // var colorList = ["#4A192C", "#8B8C7A", "#8A6642", "#4A192C", "#E63244", "#2F353B", "#FFA420", "#252850", "#F80000", "#721422"]
    // let colorInd = index
    // if (index > colorList.length - 1) {
    //     colorInd = colorInd % colorList.length
    // }
    // return colorList[colorInd];

    return index > 9 ? index % 10 : index
}

const getProviderLocation = () => {
    return {
        type: GET_PROVIDER_LOCATIONS
    }
}

//load data
const getProviderLocationSuccess = (data, locations,arrayofProviderLocation) => {
    return {
        type: GET_PROVIDER_LOCATIONS_SUCCESS,
        data,
        locations,
        arrayofProviderLocation
    }
}


//data failure
const getProviderLocationFailed = () => {
    return {
        type: GET_PROVIDER_LOCATIONS_FAILED,
    }
}




