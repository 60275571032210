import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Settings from "../../../config/Settings";
import getUserId from "../../../core/GetToken";

export const DeleteMessageAPI = createAsyncThunk(
    "deleteMessage/Messaging",
    async (values, { dispatch, getState }) => {

        dispatch(deleteMessage())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token);
            myHeaders.append("Content-Type", "application/json");

            var requestOptions = {
                method: 'DELETE',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify({
                    PatientID: item.userid,
                    MessageID: values.messageID
                })
            };
            fetch(Settings.apiUrl + "Patient/DeleteMessage", requestOptions)

                .then(data => data.json())
                .then(json => {
                    dispatch(deleteMessageSuccess(json))
                })
                .catch(err => dispatch(deleteMessageFailed(err)))

        })

    }

);
export const DeleteMessageAPIRefresher = createAsyncThunk(
    "deleteMessageRefreshData/Messaging",
    async (values, { dispatch, getState }) => {
        dispatch(deleteMessageRefreshData())
    }
)

const DeleteMessageSlice = createSlice({
    name: "deleteMessage",
    initialState: {
        deleteMessage: null,
        deleteMessageLoading: false,
        deleteMessageSuccess: false,
        deleteMessageFailed: false,
        deleteMessageError: null,
        deleteMessageStatus: "idle",
    },
    reducers: {
        deleteMessage: (state, action) => {
            state.deleteMessageLoading = true
            state.deleteMessageStatus = "loading"
            state.deleteMessage = null
            state.deleteMessageSuccess = false
            state.deleteMessageFailed = false
            state.deleteMessageError = null

        },
        deleteMessageSuccess: (state, action) => {
            state.deleteMessage = action.payload
            state.deleteMessageLoading = false
            state.deleteMessageSuccess = true
            state.deleteMessageFailed = false
            state.deleteMessageError = null
            state.deleteMessageStatus = "success"

        },
        deleteMessageRefreshData: (state, action) => {
            state.deleteMessage = null
            state.deleteMessageLoading = false
            state.deleteMessageSuccess = false
            state.deleteMessageFailed = false
            state.deleteMessageError = null
            state.deleteMessageStatus = "idle"

        },
        deleteMessageFailed: (state, action) => {
            state.deleteMessageLoading = false
            state.deleteMessageStatus = "failed"
            state.deleteMessageError = action.payload
            state.deleteMessageFailed = true
        },

    },
});




export const {
    deleteMessage, deleteMessageFailed, deleteMessageSuccess, deleteMessageRefreshData
} = DeleteMessageSlice.actions;
export default DeleteMessageSlice.reducer;
