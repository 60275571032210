import Settings from '../config/Settings';
//import { GETTING_PROFILE_IMAGES, GETTING_PROFILE_IMAGES_SUCCESS, GETTING_PROFILE_IMAGES_FAILED } from "../constants/Constant";
import getUserId from "../core/GetToken";
import getAllRoles from "../services/RolesService";
import { GETTING_PROFILE_IMAGES, GETTING_PROFILE_IMAGES_SUCCESS, GETTING_PROFILE_IMAGES_FAILED } from "../constants/Constant";

export const fetchProfileImageFromApi = (typeid) => {
    return async (dispatch) => {

        dispatch(getProfileImage())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = {
                PatientID: item.userid,
                DocumentKey: typeid
            }
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(raw)
            };
            fetch(Settings.apiUrl2 + "patient/LoadImage", requestOptions)
                .then(response => response.text())
                .then(result => {
                    dispatch(getProfileImageSuccess(result))
                })
                .catch(error => {
                    dispatch(getProfileImageFailed())
                    //{}
                });
            
        })


    }
}


const getProfileImage = () => {
    return {
        type: GETTING_PROFILE_IMAGES
    }
}

//load data
const getProfileImageSuccess = (data) => {
    return {
        type: GETTING_PROFILE_IMAGES_SUCCESS,
        data,
    }
}


//data failure
const getProfileImageFailed = () => {
    return {
        type: GETTING_PROFILE_IMAGES_FAILED,
    }
}




