import AsyncStorage from "@react-native-async-storage/async-storage";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Settings from "../../../config/Settings";
import getUserId from "../../../core/GetToken";

export const ListMessageRolesAPI = createAsyncThunk(
    "listMessageRoles/Messaging",
    async (values, { dispatch, getState }) => {

        dispatch(listingMessageRoles())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = {
                PatientID: item.userid,
                TypeID: 1,
            }
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(raw)
            };
            fetch(Settings.apiUrl + "Patient/LoadRoles", requestOptions)

                .then(data => data.json())
                .then(json => {
                    dispatch(listingMessageRolesSuccess(json))
                })
                .catch(err => dispatch(listingMessageRolesFailed(err)))

        })

    }

);

const ListingMessageRolesSlice = createSlice({
    name: "listMessageRoles",
    initialState: {
        rolesData: null,
        rolesDataLoading: null,
        rolesDataSuccess: null,
        rolesDataFailed: null,
        rolesDataError: null,
        rolesDataStatus: "idle",
    },
    reducers: {
        listingMessageRoles: (state, action) => {
            state.rolesData = null
            state.rolesDataSuccess = false
            state.rolesDataFailed = false
            state.rolesDataLoading = true
            state.rolesDataError = null
            state.rolesDataStatus = "loading"

        },
        listingMessageRolesSuccess: (state, action) => {
            state.rolesData = action.payload
            state.rolesDataSuccess = true
            state.rolesDataFailed = false
            state.rolesDataLoading = false
            state.rolesDataError = null
            state.rolesDataStatus = "success"


        },
        listingMessageRolesFailed: (state, action) => {
            state.rolesData = null
            state.rolesDataSuccess = false
            state.rolesDataFailed = true
            state.rolesDataLoading = false
            state.rolesDataError = action.payload
            state.rolesDataStatus = "error"

        },

    },
});




export const {
    listingMessageRoles, listingMessageRolesFailed, listingMessageRolesSuccess
} = ListingMessageRolesSlice.actions;
export default ListingMessageRolesSlice.reducer;
