import React, { useState, useEffect } from "react";
import { Select, Modal, Button, message, Upload } from "antd";
import { Input, notification, Spin } from "antd";
import { RadiusBottomleftOutlined } from "@ant-design/icons";
import { UploadOutlined } from "@ant-design/icons";
import { getMessageRecipientsAPI } from "../../../../shared/actions/MessagingActions/ListMessagesRecipients";
import { listMessageRolesAPI } from "../../../../shared/actions/MessagingActions/LoadMessagingRoles";
import { sendMessageAPI, sendMessageAPIRefresher } from "../../../../shared/actions/MessagingActions/SendingMessages";
import { connect, useDispatch, useSelector } from "react-redux";
import PdfFileComp from "../MessageAttachments/PdfFileComp";
import JpgFileComp from "../MessageAttachments/JpgFileComp";
import AttachmentsHandler from "../MessageAttachments/AttachmentsHandler";
import { deleteMessageAPI, deleteMessageAPIRefresher } from "../../../../shared/actions/MessagingActions/DeleteMessages";
import getUserId from "../../../../shared/core/GetToken";
import Settings from "../../../../shared/config/Settings";
import { listDraftMessageAPI } from "../../../../shared/actions/MessagingActions/ListDraftMesssages";
import { listInboxMessageAPI } from "../../../../shared/actions/MessagingActions/ListInboxMessages";
import { listSendMessageAPI } from "../../../../shared/actions/MessagingActions/ListSendMessages";
import renderHTML from 'react-render-html';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { actionAPI } from "../../../../shared/reduxToolkit/actionAPI";


const { Option } = Select;
const { TextArea } = Input;

const DraftEditMsgModal = (props) => {
  const { recipientsData, recipientsDataSuccess, recipientsDataLoading } = useSelector(state => state.ListMessageRecipients)
  const { rolesData, rolesDataSuccess } = useSelector(state => state.MessageRolesList)
  const [msgProviderLocaionId, setMsgProviderLocationId] = useState();

  const [msgRecipientId, setMsgRecipientId] = useState();
  const [msgRecipientName, setMsgRecipientName] = useState();
  const [msgSubject, setMsgSubject] = useState(props.msgContentSubject ? props.msgContentSubject : "");
  const [msgBody, setMsgBody] = useState(props.msgContentBody ? props.msgContentBody : "");
  const [msgRecipientErrMsg, setMsgRecipientErrMsg] = useState("");
  const [msgBodyErrMsg, setMsgBodyErrMsg] = useState("");
  const [msgSubErrMsg, setsetMsgSubErrMsg] = useState("");
  const [selectedFiles, setSelectedFiles] = useState(props.contentPayload ? props.contentPayload : [])
  const [draftLoadingState, setDraftLoadingState] = useState(false);
  const [sendLoadingState, setSendLoadingState] = useState(false);
  const [imageFiles, setImageFiles] = useState([])
  const [roleChangeValidationErr, setRoleChangeValidationErr] = useState("")
  const [roleCheckedOnce, setRoleCheckedOnce] = useState(false)
  const [filesState, setFilesState] = useState([])
  const [isLoadingAvailableProvidersData, setIsLoadingAvailableProvidersData] = useState(false)


  const apiDispatcher = useDispatch()

  const onRolesChange = (value, option) => {
    setMsgProviderLocationId(value);
    setMsgRecipientId(null);
    setMsgRecipientName(null);
    const values = {
      roleID: value,
    };
    apiDispatcher(actionAPI.ListMessageRecipientsAPI(values))
  };
  const onProviderChange = (value, option) => {
    if (option.key && option.key !== "") {
      setRoleChangeValidationErr("")
      setMsgRecipientErrMsg("")

    }
    setMsgRecipientId(option.key);
    setMsgRecipientName(option.children);
  };
  const checkProviderAvailable = (msgPayload, fromDraft) => {
    setIsLoadingAvailableProvidersData(true)

    getUserId().then((userInfo) => {
      const item = JSON.parse(userInfo);
      var myHeaders = new Headers();
      myHeaders.append("Token", item.token);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        ProviderID: msgRecipientId,
        PatientID: item.userid,
        FromCompose: 1,
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
        body: raw,

      };
      fetch(Settings.apiUrl + "/Patient/LoadOnlyAvaliableProviders", requestOptions)
        .then(data => data.json())
        .then(json => {
          console.log(json)
          if (json && json[0].IsLeave === false) {
            setIsLoadingAvailableProvidersData(false)

            sendMessage(msgPayload)

          }
          else {
            setIsLoadingAvailableProvidersData(false)

            openNotificationWithIcon("error", `As per practice policy, you cannot${fromDraft ? " draft " : " send "}the message as provider is on leave`)

            setSendLoadingState(false)
            setDraftLoadingState(false)
          }
        })
        .catch(err => setIsLoadingAvailableProvidersData(false))
    })
  }
  const checkValidations = () => {
    let caughtErr = false

    if (msgBody == "") {
      setMsgBodyErrMsg("Please type something in the body of the message")
      caughtErr = true
    }
    if (!msgRecipientId || msgRecipientId == "") {
      setMsgRecipientErrMsg("Please Select a Recipient")
      caughtErr = true
    }
    if (msgSubject == "") {
      setsetMsgSubErrMsg("Please type something in the subject of the message")
      caughtErr = true
    }
    return caughtErr
  }

  const sendMessage = (values) => {

    getUserId().then((userInfo) => {
      const item = JSON.parse(userInfo);


      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      myHeaders.append("Token", item.token);
      var raw = JSON.stringify({
        resourceType: "Communication",
        extension: [
          {
            url: "MessageBody",
            valueString: encodeURIComponent(values.message.body),
          },
          {
            url: "MessageType",
            valueString: values.messageType,
          },

        ],
        status: "completed",
        recipient: [
          {
            reference: `${values.practitioner.id}`,
            display: values.practitioner.name,
          },
        ],
        sender: {
          reference: `Patient/${item.userid}`,
          display: `${item.username}`,
        },
        payload: values.selectedFiles,
        note: [
          {
            text: values.message.subject,
          },
        ],
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };
      fetch(Settings.apiUrl + `Patient/Message?RoleID=${values.roleID}&CallType=3&IsWeb=true`, requestOptions)

        .then(data => data.json())
        .then(json => {

          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");

          myHeaders.append("Token", item.token);
          var requestOptions = {
            method: 'DELETE',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({
              PatientID: item.userid,
              MessageID: props.messageID,
            })
          };

          fetch(Settings.apiUrl + "Patient/DeleteMessage", requestOptions)

            .then(data => data.json())
            .then(json => {
              setSendLoadingState(false)
              setDraftLoadingState(false)
              openNotification("topRight", values.messageType == "Draft" ? "Message Saved to Draft" : "Message Sent Successfully", "success")
              props.setMsgsRefreshTrigger(props.msgsRefreshTrigger + 1)
              props.setIsMsgDeleted(true)
              props.setActiveMessage(null)
              props.handleCancel()
            })
            .catch(err => { })


        })
        .catch(err => { })

    })
  }
  const handleSendClick = () => {
    setSendLoadingState(true)
    let validateErr = checkValidations()
    if (validateErr) {
      setSendLoadingState(false)
      return
    } else {
      // if (filesState.length > 0) {

      //   try {
      //     PostTempFileData()
      //   } catch (error) {

      //   }
      // }
      const values = {

        practitioner: {
          id: "Practitioner/" + msgRecipientId,
          name: msgRecipientName,
        },
        message: {
          subject: msgSubject,
          body: msgBody,
        },
        selectedFiles: selectedFiles,
        messageType: "Send Mail",
        roleID: msgProviderLocaionId,
      };
      checkProviderAvailable(values, false)

    }
  };
  const handleDraftClick = () => {
    setDraftLoadingState(true)
    let validateErr = checkValidations()
    if (validateErr) {
      setDraftLoadingState(false)
      return
    }
    // if (filesState.length > 0) {

    //   try {
    //     PostTempFileData()
    //   } catch (error) {

    //   }
    // }
    const values = {

      practitioner: {
        id: "Practitioner/" + msgRecipientId,
        name: msgRecipientName,
      },

      message: {
        subject: msgSubject,
        body: msgBody,
      },
      selectedFiles: selectedFiles,
      messageType: "Draft",
      roleID: msgProviderLocaionId,

    };
    checkProviderAvailable(values, true)

  };
  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: 'Notification',
      description:
        message,
    });
  };
  const handleRemoveFile = (index) => {
    if (selectedFiles.length > 0) {
      setSelectedFiles(selectedFiles.filter((file, fileIndex) => fileIndex != index))
      // setFilesState(filesState.filter((file, fileIndex) => fileIndex != index))

    }
  }
  const PostTempFileData = async () => {
    const itemUserInfo = await getUserId().then((userInfo) => {
      return JSON.parse(userInfo);
    });

    const apiUrl = Settings.apiUrl + "Patient/PostTempMessageFiles";


    const formData = new FormData();


    for (let i = 0; i < filesState.length; i++) {
      formData.append('files', filesState[i]);
    }
    formData.append('patientid', itemUserInfo.userid);
    formData.append('messageid', 0);

    fetch(apiUrl, {
      method: 'POST',
      body: formData,
      processData: false,  // Set processData to false to prevent jQuery from serializing the data
      //contentType: false,  // Set contentType to false to let the browser set the Content-Type

      headers: {
        // Add this line to specify the content type

        'token': itemUserInfo.token
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.text(); // Assuming the server responds with a text message
        } else {
          //throw new Error('Error uploading files');
        }
      })
      .then((data) => {
        console.log(data); // Response from the server
      })
      .catch((error) => {
        console.error(error);
      });

  }
  const imageTypeRegex = /image\/(png|jpg|jpeg|heif|heic)/gm;
  const pdfTypeRegex = /application\/(pdf)/gm;
  const selectFilesHandler = (e) => {
    const { files } = e.target;

    const validImageFiles = [];
    if (selectedFiles.length + files.length > 4) {
      openNotificationWithIcon("error", "Only four files are allowed to be uploaded")
      return
    }
    let sizeLimitExceed = false
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.size > 6000000) {
        sizeLimitExceed = true
      }
      if (file.type.match(imageTypeRegex) || file.type.match(pdfTypeRegex) || (file.type === "" && (file.name.includes("heif") || file.name.includes("heic")))) {
        validImageFiles.push(file);
      }
    }
    if (sizeLimitExceed) {
      openNotificationWithIcon("error", "File size cannot be greater than 6MB")
      return
    }
    if (filesState.length + files.length < 5) {
      if (filesState.length > 0) {

        setFilesState([...filesState, e.target.files])
      } else {
        setFilesState(e.target.files)

      }
    }
    if (validImageFiles.length) {
      setImageFiles(validImageFiles);
      return;
    }
    openNotificationWithIcon("error", "Selected images are not of valid type!");
  };
  useEffect(() => {
    const images = [], fileReaders = [];
    let isCancel = false;
    if (imageFiles.length) {
      imageFiles.forEach((file) => {
        let data = {}
        const fileReader = new FileReader();
        fileReaders.push(fileReader);
        fileReader.onload = (e) => {
          const { result } = e.target;
          if (result) {
            data = {
              contentAttachment: {
                contentType: result.split("/")[1].split(";base64")[0],
                data: result.split("base64,")[1]

              }
            }
            images.push(data)
          }
          if (images.length === imageFiles.length && !isCancel) {
            setSelectedFiles((prev) => prev.concat(images));
          }
        }
        fileReader.readAsDataURL(file);
      })
    };
    return () => {
      isCancel = true;
      fileReaders.forEach(fileReader => {
        if (fileReader.readyState === 1) {
          fileReader.abort()
        }
      })
    }
  }, [imageFiles]);

  const openNotification = (placement, msg, type) => {

    notification[type]({
      message: msg,
      placement,
    });
  };
  useEffect(() => {
    apiDispatcher(actionAPI.ResetMessageRecipientsAPI())
    apiDispatcher(actionAPI.ListMessageRecipientsAPI({
      roleID: props.msgContentRecipientRoleID ? props.msgContentRecipientRoleID : 0
    }))
    debugger
    let x = rolesData[0].entry.findIndex(o => parseInt(o.resource.id) === props.msgContentRecipientRoleID)
    setMsgProviderLocationId(`${rolesData && rolesData.length > 0 && rolesData[0].entry.findIndex(o => parseInt(o.resource.id) === props.msgContentRecipientRoleID) >= 0 ? props.msgContentRecipientRoleID : ""}`)
  }, [])
  useEffect(() => {
    if (recipientsData && recipientsData?.length > 0 && props.msgContentRecipientID && !roleCheckedOnce) {
      debugger
      let recipientIdExist = recipientsData[0]?.entry?.find(recipient => recipient?.resource?.id === props.msgContentRecipientID.split("/")[1])?.resource?.id
      if (recipientIdExist && recipientIdExist !== "") {
        setRoleChangeValidationErr("")
        setMsgRecipientId(recipientIdExist)
      } else {
        setRoleChangeValidationErr(`Recipient ${props.msgContentRecipientName} is no longer associated with this role`)
        setMsgRecipientId(null)
      }
      // setRoleCheckedOnce(true)

    }
  }, [recipientsData])

  return (
    <>
      <Modal
        title="Draft Message"
        width={800}
        className="compose-msg-modal"
        open={props.isModalOpen}
        onCancel={props.handleCancel}
        maskClosable={false}
        afterClose={() => setIsLoadingAvailableProvidersData(false)}

        footer={[
          <Button
            key="cancel"
            onClick={props.handleCancel}
            className="cancel-msg-btn"
            disabled={draftLoadingState || sendLoadingState || isLoadingAvailableProvidersData}
          >
            Cancel
          </Button>,
          <Button
            key="draft"
            onClick={handleDraftClick}
            className="draft-msg-btn"
            loading={draftLoadingState}
            disabled={draftLoadingState || sendLoadingState || isLoadingAvailableProvidersData}
          >
            Draft
          </Button>,
          <Button
            key="send"
            disabled={roleChangeValidationErr !== "" || draftLoadingState || sendLoadingState || isLoadingAvailableProvidersData}
            onClick={handleSendClick}
            loading={sendLoadingState}
            className="send-msg-btn"
          >
            Send
          </Button>,
        ]}
      >
        {props.draftDisclaimerMsg && props.draftDisclaimerMsg != "" &&
          <div className="row alert alert-warning compose-msg-diclaimer">
            <h6 className="alert-heading">Disclaimer:</h6>
            {
              renderHTML(props.draftDisclaimerMsg)
            }

          </div>}
        {!rolesDataSuccess ? <Spin /> :
          <div className="row">
            <label className="col-3 compose-msg-h-text">To</label>
            <div className="m-0 p-0 col-8">

              <Select
                showSearch
                placeholder="To"
                className="w-100"
                optionFilterProp="children"
                compose-msg-select
                onChange={onRolesChange}
                value={
                  msgProviderLocaionId
                }
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                <Option
                  value={""}
                >
                  Select
                </Option>
                {rolesDataSuccess && rolesData !== null
                  ? rolesData[0].entry.map((data) => (
                    <Option
                      value={data.resource.id}
                      key={data.resource.id}
                    >
                      {data.resource.title}
                    </Option>
                  ))
                  : null}
              </Select>
              <small className="text-danger mt-1 mb-0 ml-0">{roleChangeValidationErr}</small>
            </div>

          </div>}

        <div className="row">

          <label className="col-6 col-sm-3 draft-msg-label-withMargin">Practitioner</label>
          <div className="m-0 p-0 col-8">

            <Select
              showSearch
              className=" w-100 draft-msg-select-withMargin"
              placeholder="Select Provider"
              optionFilterProp="children"
              value={
                msgRecipientId
              }
              onChange={onProviderChange}
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {recipientsDataSuccess && recipientsData !== null && recipientsData[0]?.entry
                ? recipientsData[0]?.entry?.map((data) => (
                  <Option
                    value={data.resource.extension[0].valueString}
                    key={data.resource.extension[0].valueString}
                  >
                    {data.resource.name[0].text}
                  </Option>
                ))
                : null}
            </Select>
            <p className="text-danger">{msgRecipientErrMsg}</p>
          </div>
        </div>

        <div className="row">
          <label className="col-3 draft-msg-label-withMargin">Subject</label>

          <Input
            autoComplete="off"
            className="col-8 m-top-15"
            placeholder="Subject"

            value={msgSubject}
            onChange={(e) => { setMsgSubject(e.target.value); setsetMsgSubErrMsg("") }}
          /></div>
        <p className="text-danger">{msgSubErrMsg}</p>
        {/* <TextArea
          // value={value}
          // onChange={(e) => setValue(e.target.value)}
          placeholder="Write your message here"
          autoSize={{ minRows: 10, maxRows: 10 }}
          className="m-top-15 w-100"
          value={msgBody}
          onChange={(e) => { setMsgBody(e.target.value); setMsgBodyErrMsg("") }}
          maxLength={800}
        /> */}
        <CKEditor
          editor={ClassicEditor}
          data={msgBody}
          config={{ toolbar: [], placeholder: "Write your message here." }}

          onChange={(event, editor) => {
            const data = editor.getData();
            setMsgBody(data);
            setMsgBodyErrMsg("")
          }}

        />
        <p className="text-danger">{msgBodyErrMsg}</p>
        <label htmlFor="file" className="p-2 text-primary fw-bold mt-3 px-3 choose-file">Choose Files
        </label>
        <input
          autoComplete="off"
          type="file"
          id="file"
          onChange={selectFilesHandler}
          accept="image/png, image/jpg, image/jpeg, image/heif, image/heic,application/pdf"
          multiple
        />
        {
          selectedFiles && selectedFiles.length > 0 ?
            <AttachmentsHandler selectedFiles={selectedFiles} type="composing" handleRemoveFile={handleRemoveFile} /> : null
        }
      </Modal>
    </>
  );
};


function mapStateToProps(state) {
  return {
    TokenValidator: state.TokenValidator,

    MessageRecipients: state.MessagesRecipientsReducer,
    SendingMessage: state.SendingMessagesReducer,
    MessagingRoles: state.LoadMessagingRolesReducer,
    DeleteMessage: state.DeleteMessagesReducer,

  }
}
function mapDispatchToProps(disptach) {
  return {

    getMsgRecipients: (values) => disptach(getMessageRecipientsAPI(values)),
    loadMsgRoles: () => disptach(listMessageRolesAPI()),
    listDraftMessages: (values) => disptach(listDraftMessageAPI(values)),
    listInboxMessages: (values) => disptach(listInboxMessageAPI(values)),
    listSendMessages: (values) => disptach(listSendMessageAPI(values)),

  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DraftEditMsgModal)
// export default
