import AsyncStorage from "@react-native-async-storage/async-storage";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Settings from "../../../config/Settings";
import getUserId from "../../../core/GetToken";

export const GetPatientRemindersFromAPI = createAsyncThunk(
    "getPatientReminders/Reminders",
    async (values, { dispatch, getState }) => {

        dispatch(getPatientReminders())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = {
                PatientID: item.userid,
                PageSize: values.pageSize,
                PageNumber: values.pageNumber,
            }
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(raw)
            };


            fetch(Settings.apiUrl + "Patient/PatientAlerts", requestOptions)
                .then(data => data.json())
                .then(json => {

                    json[0].hasOwnProperty('entry') ? dispatch(getPatientRemindersSuccess(json[0].entry)) : dispatch(getPatientRemindersFinished())
                })
                .catch((err) => {

                    dispatch(getPatientRemindersFailed(err))
                })


        })

    }

);

const GetPatientRemindersSlice = createSlice({
    name: "getPatientReminders",
    initialState: {
        Reminders: [],
        isGettingReminders: false,
        errorReminders: false
    },
    reducers: {
        getPatientReminders: (state, action) => {
            state.Reminders = []
            state.isGettingReminders = true
            state.errorReminders = false
        },
        getPatientRemindersSuccess: (state, action) => {
            state.Reminders = action.payload
            state.isGettingReminders = false
            state.errorReminders = false

        },
        getPatientRemindersFailed: (state, action) => {
            state.Reminders = []
            state.isGettingReminders = false
            state.errorReminders = true
        },
        getPatientRemindersFinished: (state, action) => {
            state.Reminders = []
            state.isGettingReminders = false
            state.errorReminders = false
        },

    },
});




export const {
    getPatientReminders, getPatientRemindersFailed, getPatientRemindersSuccess, getPatientRemindersFinished
} = GetPatientRemindersSlice.actions;
export default GetPatientRemindersSlice.reducer;
