import React from 'react';
import { Text, View } from 'react-native';
const AllergiesHandler = props => {
    return (
        <div className="col-lg-12 ">
            <div className="allergies-handler-card card shadow p-0 rounded ">
                <div className={props.styles}>
                    <div className="allergies-card-header">
                        <span numberOfLines={1} className="allergies-card-header-title m-0"><b>{props.algTitle}</b></span>
                        <span className='allergies-card-header-level'><b>{props.allergyLevel}</b></span>
                    </div>
                </div>
                <div className="card-body mb-0">
                    <h4 className="card-title mb-2">
                        <img src='assets/images/allergies-type.svg' className='mr-2 w-20px' />
                        <b>Type: </b>{props.algType}</h4>

                    <span className="card-text full-width mb-2">
                        <div className="allergies-card-body-reaction mr-2">
                          
                            <img src='assets/images/allergies-reaction.svg' className='allergies-card-body-reaction-svg' />
                        </div>
                        <div className='allergies-card-body-symptoms'>
                            <b>Symptoms: </b>{props.symptoms}
                        </div>
                    </span>
                    
                    <span className="card-text full-width">
                      
                        <img src='assets/images/allergies-comments.svg' className='mr-2 w-20px' />
                         <b>Comments: </b>{props.comment === "test" ? props.comment?.replace("test", "") : props.comment}</span>
                </div>
            </div>
        </div>
    );
}

export default AllergiesHandler