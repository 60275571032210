import { createStore, applyMiddleware, blacklist } from "redux";
import rootReducer from "./shared/reducers/index";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"

//cofigute store
const persistConfig = {
    key: "main-root",
    storage,
    blacklist: ['VitalReducer', 'Reminder', 'Counter', 'InboxMessagesReducer', 'SendMessagesReducer', 'DraftMessagesReducer', 'DeleteMessagesReducer', 'SendingMessagesReducer', 'SearchAppointment', 'Reports', 'PatientTimeline', 'PendingInvc', 'AllInvc', 'RecentMed', 'AllMedeications', 'Profile', "FutureAppointments", "AuthUser", "BookApp", "DueImmune", "OverdueImmune", "AdministeredImmune", "Immune"],

};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(persistedReducer, applyMiddleware(thunk));

const Persister = persistStore(store);


export default store;
export { Persister };