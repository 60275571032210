import AsyncStorage from "@react-native-async-storage/async-storage";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Settings from "../../../config/Settings";
import getUserId from "../../../core/GetToken";

export const ListMessagesAPI = createAsyncThunk(
    "listMessages/Messaging",
    async (values, { dispatch, getState }) => {

        dispatch(listingMessages())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = {
                PatientID: item.userid,
                PageSize: 100,
                PageNumber: 1,
                Type: values.type,
                MessagingType: values.messagingType,
            }
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(raw)
            };

            fetch(Settings.apiUrl + "Patient/Messaging", requestOptions)

                .then(data => data.json())
                .then(json => {
                    dispatch(listingMessagesSuccess(json))
                })
                .catch(err => dispatch(listingMessagesFailed(err)))

        })

    }

);

const ListingMessagesSlice = createSlice({
    name: "listMessages",
    initialState: {
        messageListing: null,
        messageListingSuccess: false,
        messageListingFailed: false,
        messageListingLoading: false,
        messageListingError: null,
        messageListingStatus: "idle",
    },
    reducers: {
        listingMessages: (state, action) => {
            state.messageListing = null
            state.messageListingSuccess = false
            state.messageListingFailed = false
            state.messageListingLoading = true
            state.messageListingError = null
            state.messageListingStatus = "loading"

        },
        listingMessagesSuccess: (state, action) => {
            state.messageListing = action.payload
            state.messageListingSuccess = true
            state.messageListingFailed = false
            state.messageListingLoading = false
            state.messageListingError = null
            state.messageListingStatus = "success"

        },
        listingMessagesFailed: (state, action) => {
            state.messageListing = null
            state.messageListingSuccess = false
            state.messageListingFailed = true
            state.messageListingLoading = false
            state.messageListingError = action.payload
            state.messageListingStatus = "error"

        },

    },
});




export const {
    listingMessages, listingMessagesFailed, listingMessagesSuccess
} = ListingMessagesSlice.actions;
export default ListingMessagesSlice.reducer;
