import AsyncStorage from "@react-native-async-storage/async-storage";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Settings from "../../../config/Settings";
import getUserId from "../../../core/GetToken";

export const GetAvailablePractitionersFromAPI = createAsyncThunk(
    "getAvailablePractitioners/Appointment",
    async (values, { dispatch, getState }) => {

        dispatch(getAvailablePractitioners())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = {
                PatientID: item.userid,
                AppointmentTypeID: values.appType,
                PracticeLocationID: values.locations?.toString(),
                RoleID: values.roleID,
                BookWithID: values.roleType,
                ReasonForVisitID: values.selectedReasonForVisit,
                IsLockDownProviderEnabled :true
            }
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(raw)
            };

            // alert('Path is alright---->>>>>: ' + JSON.stringify(queryString))
            fetch(Settings.apiUrl + "Patient/AvailablePractitioner", requestOptions)
                .then(data => data.json())
                .then(json => {
                    json[0].hasOwnProperty('entry') ? dispatch(getAvailablePractitionersSuccess(json[0].entry)) : dispatch(getAvailablePractitionersFailed())
                })
                .catch(err => dispatch(getAvailablePractitionersFailed(err)))
        })

    }

);

export const AvailablePractitionersDataCleanup = createAsyncThunk(
    "availablePractitionersDataCleanup/Appointment",
    async (values, { dispatch, getState }) => {
        dispatch(getAvailablePractitionersFinished())
    })
const GetAvailablePractitionersSlice = createSlice({
    name: "getAvailablePractitioners",
    initialState: {
        AvailablePractitioner: [],
        isGetting: false,
        error: false
    },
    reducers: {
        getAvailablePractitioners: (state, action) => {
            state.AvailablePractitioner = []
            state.isGetting = true
            state.error = false
        },
        getAvailablePractitionersSuccess: (state, action) => {
            state.AvailablePractitioner = action.payload
            state.isGetting = false
            state.error = false

        },
        getAvailablePractitionersFailed: (state, action) => {
            state.AvailablePractitioner = []
            state.isGetting = false
            state.error = true
        },
        getAvailablePractitionersFinished: (state, action) => {
            state.AvailablePractitioner = []
            state.isGetting = false
            state.error = false
        },

    },
});




export const {
    getAvailablePractitioners, getAvailablePractitionersFailed, getAvailablePractitionersSuccess, getAvailablePractitionersFinished
} = GetAvailablePractitionersSlice.actions;
export default GetAvailablePractitionersSlice.reducer;
