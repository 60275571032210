import React, { useCallback, useState, useEffect } from 'react';
import { View, Text, SafeAreaView, Dimensions, StyleSheet, FlatList, ActivityIndicator, TouchableOpacity } from 'react-native-web';
import { Link } from "react-router-dom";

import { connect, useDispatch, useSelector } from "react-redux";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";

import Svg, { Path, G } from "react-native-svg"
import { Spin, Timeline } from 'antd';

import 'antd/dist/antd.css';

import renderHTML from "react-render-html";

import { FormatAMPM, GetAppointmentDate, GetMonthName, GetYear } from '../../shared/core/datehelper'
import { fetchTimelineFromApi, fetchMoreTimelineFromApi, fetchTimelineSearchFromApi, fetchTimelineDatesTextFromApi, timelineResetDataFromRedux } from "../../shared/actions/TimelineActions";
import { checkToken } from '../../shared/actions/TokenValidActions';
import TimelineFilterModal from '../components/TimelineFilterModal';
import getUserId from '../../shared/core/GetToken';
import Settings from '../../shared/config/Settings';
import { flushSync } from 'react-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { actionAPI } from '../../shared/reduxToolkit/actionAPI';
const useNavigate = require("react-router-dom").useNavigate;

const TimelineHome = props => {
    const history = useNavigate()
    const { PatientTimeline, PatientTimelineTotalNum, isGetting } = useSelector(state => state.TimelineData);


    const navigate = useNavigate()
    const { ProxyMenu, isLoadingMenu } = useSelector(state => state.ProxyMenu);
    const [isloaded, setIsload] = React.useState(false);
    const [pageSize, setPageSize] = useState(100);           //state for the page number
    const [pageNumber, setPageNumber] = useState(1);
    const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
    const [filterfromDate, setFilterfromDate] = useState(null);
    const [filtertoDate, setFiltertoDate] = useState(null);
    const [filtertextSearch, setFiltertextSearch] = useState("");
    const [filterproviderID, setFilterproviderID] = useState(null);
    const [filterText, setFilterText] = useState("");
    const [filterActive, setFilterActive] = useState([false, false, false, true, false])
    const [lookupResult, setLookupResult] = useState([]);

    const [timelineRecordList, setTimelineRecordList] = useState([]);
    const [totalRecords, setTotalRecords] = useState(10)
    const apiDispatcher = useDispatch()
    let limit = 15
    let offset = 1

    const getTimelineDataFromApi = (fromStart = false, fromDate = null, toDate = null, textSearch = "", providerID = null) => {
        limit = 15;
        offset = fromStart ? 1 : Math.ceil(timelineRecordList.length / limit) + 1;
        if (offset == 1) {
            flushSync(() => {
                setTimelineRecordList([])
                setTotalRecords(10)
            })
        }
        if(fromStart)
        {
            setFilterfromDate(fromDate);
            setFiltertoDate(toDate);
            setFiltertextSearch(textSearch);
            setFilterproviderID(providerID);
        }
        apiDispatcher(actionAPI.GetTimelineDataFromAPI({
            pageNumber: offset,
            pageSize: limit,
            fromDate: fromDate,
            toDate: toDate,
            textSearch: textSearch,
            filterProviderId: providerID
        }))
       // props.getTimelineDateTextSearch(limit, offset, filterfromDate, filtertoDate, filtertextSearch, filterproviderID)
    };
    const fetchmoreTimeLineDataFromApi = (ignore = false) => {
        if (ignore || timelineRecordList.length < totalRecords) {
            getTimelineDataFromApi(false,filterfromDate,filtertoDate,filtertextSearch,filterproviderID);
        }
    }
    useEffect(() => {
        if (ProxyMenu != null && ProxyMenu != undefined) {
            let itemExists = false
            ProxyMenu.map((item, index) => {
                if (item.resource.extension[0].valueString == 'Timeline') {
                    itemExists = true
                }

            })
            if (!itemExists) {
                navigate("/")
            }
        }
    }, [ProxyMenu])
    useEffect(() => {
        getTimelineDataFromApi(true)
    }, [])
    useEffect(() => {
        if (PatientTimeline !== "undefined" && PatientTimeline?.length > 0) {
            setTotalRecords(PatientTimelineTotalNum)
            if (PatientTimeline && timelineRecordList != PatientTimeline) {
                const mergeData = [...timelineRecordList, ...PatientTimeline];
                setTimelineRecordList(mergeData);
                apiDispatcher(actionAPI.TimelineDataRefresher())
            }

        }
    }, [PatientTimeline])
    const showFilterModal = () => {
        setIsFilterModalOpen(true);
    };

    const handleFilterOk = () => {
        setIsFilterModalOpen(false);
    };

    const handleFilterCancel = () => {
        setFilterActive([false, false, false, true, false])
        setIsFilterModalOpen(false);

    };



    React.useEffect(() => {
        apiDispatcher(actionAPI.checkToken(history))
    }, [])

    const setDays = (date, days) => {
        var result = new Date(date);
        result.setDate(result.getDate() - days);
       
        getTimelineDataFromApi(
            true,
            GetAppointmentDate(new Date(result)) + " " + GetMonthName(new Date(result)) + " " + GetYear(new Date(result)),
            GetAppointmentDate(new Date()) + " " + GetMonthName(new Date()) + " " + GetYear(new Date()));
        //    props.getTimelineSEarch(new Date(), result);
    }

    function timeDifference(current, previous) {
        var msPerMinute = 60 * 1000;
        var msPerHour = msPerMinute * 60;
        var msPerDay = msPerHour * 24;
        var msPerMonth = msPerDay * 30;
        var msPerYear = msPerDay * 365;

        var elapsed = current - previous;

        if (elapsed < msPerMinute) {
            return Math.round(elapsed / 1000) + ' seconds ago';
        }

        else if (elapsed < msPerHour) {
            return Math.round(elapsed / msPerMinute) + ' minutes ago';
        }

        else if (elapsed < msPerDay) {
            return Math.round(elapsed / msPerHour) + ' hours ago';
        }

        else if (elapsed < msPerMonth) {
            return 'approximately ' + Math.round(elapsed / msPerDay) + ' days ago';
        }

        else if (elapsed < msPerYear) {
            return 'approximately ' + Math.round(elapsed / msPerMonth) + ' months ago';
        }

        else {
            return 'approximately ' + Math.round(elapsed / msPerYear) + ' years ago';
        }
    }
    const handleFilterClick = () => {
        setFilterActive([false, false, false, false, true])
        setIsFilterModalOpen(true)


    }

   
    useEffect(() => {
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = {
                PatientID: item.userid,
                TypeID: 14
            }
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(raw)
            };

            fetch(`${Settings.apiUrl}IndiciLookup`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLookupResult(result)
                })
                .catch(error => { });
        })
    }, [])
    const formatText = (text) => {
        let formattedStr = text
        if (text.includes("QuickConsult set to")) {
            let arrText = text.split("QuickConsult set to")
            formattedStr = arrText[arrText.length - 1]
        }
        return (renderHTML(formattedStr))
    }
    return (

        <div className="page-content-wrapper-inner">
            <div className="viewport-header">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb has-arrow">
                        <li className="breadcrumb-item">
                            <Link to="/">Dashboard</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">Timeline</li>
                    </ol>
                </nav>
            </div>
            <div className="content-viewport">
                <div className="row row-margin-zero h-100">
                    <div className="col-12 h-100">
                        <div className="grid h-100">
                            <div className="grid-body h-100 overflow-auto" >

                                <div className="item-wrapper timeline-container">


                                    <div className="row margin-bottom-twenty ">
                                        <div className="event-filters col-12">
                                            <div className='filter-timeline-container' >
                                                <span className="badge enent-filter-badge" >Event Filter: </span>
                                                <span onClick={() => { setDays(new Date(), 60); setFilterActive([true, false, false, false, false]) }} className={"badge badge-primary-timeline cursor-pointer font-size-15px margin-top-ten margin-left-fifteen " + (filterActive[0] ? " active-filter " : "")} >60D</span>
                                                <span onClick={() => { setDays(new Date(), 180); setFilterActive([false, true, false, false, false]) }} className={"badge badge-primary-timeline cursor-pointer font-size-15px margin-top-ten margin-left-fifteen " + (filterActive[1] ? " active-filter " : "")} >180D</span>
                                                <span onClick={() => { setDays(new Date(), 365); setFilterActive([false, false, true, false, false]) }} className={"badge badge-primary-timeline cursor-pointer font-size-15px margin-top-ten margin-left-fifteen " + (filterActive[2] ? " active-filter " : "")} >365D</span>
                                                <span onClick={() => { getTimelineDataFromApi(true); setFilterActive([false, false, false, true, false]) }} className={"badge badge-primary-timeline cursor-pointer font-size-15px margin-top-ten margin-left-fifteen " + (filterActive[3] ? " active-filter " : "")}>All</span>
                                                <span onClick={handleFilterClick} className={"filter-option badge badge-primary-timeline cursor-pointer font-size-15px" + (filterActive[4] ? " active-filter " : "")}>Filter</span>
                                            </div>
                                        </div>
                                    </div>

                                    <TimelineFilterModal
                                        isFilterModalOpen={isFilterModalOpen}
                                        handleFilterCancel={handleFilterCancel}
                                        handleFilterOk={handleFilterOk}
                                        getTimelineDateTextSearch={getTimelineDataFromApi}
                                        lookupPersonsData={lookupResult}

                                    />



                                    {
                                        isGetting && <div className='login-loader' ><ActivityIndicator size="small" className='loader-color' /></div>
                                    }
                                    {
                                        typeof (timelineRecordList) !== 'undefined' && timelineRecordList.length ? (
                                            <div className='timeline-internal-scroll' id="patientTimelineScrollableDiv">
                                                <Timeline>
                                                    <InfiniteScroll
                                                        dataLength={timelineRecordList.length}
                                                        next={fetchmoreTimeLineDataFromApi}
                                                        hasMore={timelineRecordList.length < totalRecords}
                                                        loader={<div className='d-flex justify-content-center my-2'><Spin /></div>}
                                                        scrollableTarget="patientTimelineScrollableDiv"
                                                    >
                                                        <FlatList
                                                            data={timelineRecordList}
                                                            className='flatlist-timeline'
                                                            renderItem={({ item }) => (

                                                                <Timeline.Item color="red">
                                                                    <div className="card gedf-card">
                                                                        <div className="card-header">
                                                                            <div className="d-flex justify-content-between align-items-center">
                                                                                <div className="d-flex justify-content-between align-items-center">
                                                                                    <div className="mr-2">
                                                                                        {/* <Svg
                                                                                            height={50}
                                                                                            viewBox="0 0 512 512"
                                                                                            width={50}
                                                                                            xmlns="http://www.w3.org/2000/svg">
                                                                                            <Path
                                                                                                d="M256.522 496.333l96.354-27.793c61.613-48.365 101.202-123.51 101.202-207.915 0-129.719-93.489-237.588-216.761-259.943C104.655 10.253 0 120.9 0 256c0 71.244 28.569 135.165 75.536 181.573l10.563 9.899z"
                                                                                                fill="#cbe2ff"
                                                                                            />
                                                                                            <Path
                                                                                                d="M256 0c-11.024 0-21.886.698-32.543 2.05C349.476 18.038 446.914 125.64 446.914 256c0 95.61-52.415 178.974-130.076 222.935l109.058-31.458C478.709 400.582 512 332.184 512 256 512 114.615 397.385 0 256 0z"
                                                                                                fill="#bed8fb"
                                                                                            />
                                                                                            <Path
                                                                                                d="M334.886 306.987a55.659 55.659 0 0027.497-48.027l-.028-87.988c0-58.738-47.616-106.354-106.354-106.354s-106.354 47.616-106.354 106.354l-.028 87.988a55.657 55.657 0 0027.497 48.027l11.997 7.036a132.221 132.221 0 00133.778 0z"
                                                                                                fill="#efc984"
                                                                                            />
                                                                                            <Path
                                                                                                d="M362.354 170.972c0-44.092-26.832-81.916-65.058-98.038v252.623a132.162 132.162 0 0025.592-11.534l11.997-7.036a55.659 55.659 0 0027.497-48.027l-.028-87.988z"
                                                                                                fill="#eabc6b"
                                                                                            />
                                                                                            <Path
                                                                                                d="M256 418.104l67.48-47.486-27.239-32.992v-75.207h-80.482v75.207l-27.239 32.992z"
                                                                                                fill="#ffddce"
                                                                                            />
                                                                                            <Path
                                                                                                d="M387.573 355.203l-74.522-14.342c-11.766 19.162-32.916 31.943-57.051 31.943s-45.285-12.781-57.051-31.943l-74.522 14.342c-28.38 5.462-48.89 30.296-48.89 59.196v23.174c39.91 39.667 92.827 66.253 151.788 72.824 4.422.493 8.897.222 13.242-.736l18.955-4.18a29.12 29.12 0 0113.624.26 29.176 29.176 0 0018.624-1.318c58.042-24.285 105.575-68.671 133.875-124.479-8.737-12.533-22.168-21.68-38.072-24.741z"
                                                                                                fill="#4a80aa"
                                                                                            />
                                                                                            <Path
                                                                                                d="M436.463 414.399c0-16.318-6.541-31.337-17.343-42.307-37.636 73.834-110.035 126.985-195.638 137.854a258.447 258.447 0 0031.666 2.052h.026c.333.001 1.179.002 1.65 0h.026c70.073-.228 133.516-28.61 179.612-74.425v-23.174z"
                                                                                                fill="#407093"
                                                                                            />
                                                                                            <Path
                                                                                                d="M261.178 505.117c9.536-27.607 29.332-90.05 34.041-145.019-11.02 7.989-24.567 12.706-39.219 12.706s-28.199-4.717-39.219-12.706c4.833 56.421 25.564 120.725 34.777 147.14z"
                                                                                                fill="#e28086"
                                                                                            />
                                                                                            <Path
                                                                                                d="M250.768 504.961a730.16 730.16 0 002.458 7.007c5.249.055 3.424.022 5.548 0a737.884 737.884 0 003.502-10.051 252.509 252.509 0 01-11.508 3.044z"
                                                                                                fill="#dd636e"
                                                                                            />
                                                                                            <Path
                                                                                                d="M327.324 163.855c-28.418-30.607-33.694-58.413-33.694-58.413-14.116 37.04-49.967 63.355-91.964 63.355-1.285 0-2.564-.025-3.837-.073-9.664-.371-17.69 7.375-17.69 17.046v31.07c.625 41.373 34.338 74.725 75.861 74.725 41.522 0 75.235-33.351 75.861-74.725v-41.405c0-4.292-1.617-8.435-4.537-11.58z"
                                                                                                fill="#ffddce"
                                                                                            />
                                                                                            <Path
                                                                                                d="M327.324 163.855c-17.072-18.387-25.786-35.754-30.028-46.698v96.257c-.575 38.004-29.074 69.218-65.892 74.053A75.713 75.713 0 00256 291.565c41.522 0 75.235-33.352 75.861-74.725v-41.405c0-4.292-1.617-8.435-4.537-11.58z"
                                                                                                fill="#ffcbbe"
                                                                                            />
                                                                                            <G fill="#365e7d">
                                                                                                <Path d="M171.879 343.452c-3.239 1.985-3.883 6.423-1.341 9.246l15.779 17.527a6.076 6.076 0 01-.48 8.608l-10.404 9.244c-2.185 1.941-3.265 4.873-2.75 7.749 5.813 32.476 57.771 94.503 76.568 116.09 1.325.032 2.652.055 3.982.069-9.093-25.471-37.475-110.116-37.475-174.36v-21.064zM340.121 343.452l-43.878-26.89v21.064c0 64.244-28.382 148.889-37.475 174.36 1.33-.013 2.657-.036 3.982-.069 18.797-21.587 70.755-83.614 76.568-116.09.515-2.877-.566-5.808-2.75-7.749l-10.404-9.244a6.076 6.076 0 01-.48-8.608l15.779-17.527c2.541-2.824 1.897-7.262-1.342-9.247z" />
                                                                                            </G>
                                                                                            <Path
                                                                                                d="M343.362 175.56H331.86v38h11.502c10.496 0 19.003-8.51 19-19.006-.003-10.49-8.509-18.994-19-18.994z"
                                                                                                fill="#ffddce"
                                                                                            />
                                                                                        </Svg> */}
                                                                                        <div className='timline-handler-picon'>
                                                                                            {item?.heading?.charAt(0)}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="ml-2">
                                                                                        <div className="h5 m-0">{item.heading}</div>
                                                                                        <div className="h7 text-muted">{GetAppointmentDate(item.date) + ' ' + GetMonthName(item.date) + ' ' + GetYear(item.date) + ' ' + FormatAMPM(item.date)}</div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="card-body">
                                                                            {item.alerts.map((value, key) => {
                                                                                return (
                                                                                    <>
                                                                                        <a className="card-link" href="#">

                                                                                            <h5 className="card-title">{value.display?.replace("AppointmentAdvise", "Patient Instructions")}</h5>
                                                                                        </a>
                                                                                        <p className="card-text">{formatText(value.text)}</p>
                                                                                    </>
                                                                                )
                                                                            })}

                                                                        </div>
                                                                    </div>
                                                                </Timeline.Item>

                                                            )}
                                                            keyExtractor={item => item.id}
                                                            initialNumToRender={10}
                                                            maxToRenderPerBatch={10}
                                                            windowSize={7}

                                                        />
                                                    </InfiniteScroll>
                                                </Timeline>
                                            </div>
                                        ) : !isGetting &&
                                            <div className='alert alert-danger p-2 text-center'>No Records Found</div>

                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}



function mapStateToProps(state) {
    return {
        PatientTimeline: state.PatientTimeline,
        ProxyMenu: state.ProxyMenu,
    }
}


function mapDispatchToProps(disptach) {
    return {
        getTimeline: (pageSize, pageNumber, fromDate, toDate) => disptach(fetchTimelineFromApi(pageSize, pageNumber, fromDate, toDate)),
        getTimelineDateTextSearch: (pageSize, pageNumber, fromdate, todate, textSearch, filterProviderId) => disptach(fetchTimelineDatesTextFromApi(pageSize, pageNumber, fromdate, todate, textSearch, filterProviderId)),
        getTimelineSEarch: (fromdate, todate) => disptach(fetchTimelineSearchFromApi(fromdate, todate)),

        //  getMoreTimeline: (pageSize, pageNumber,) => disptach(fetchMoreTimelineFromApi(pageSize, pageNumber))
        tokenVal: (history) => disptach(checkToken(history)),
        resetTimelineData: () => disptach(timelineResetDataFromRedux()
        )
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TimelineHome)
//export default TimelineHome
