import { Spin } from 'antd';
import React, { useEffect, useState } from 'react'
import MsgCard from '../MessageListingComp/MsgCard';
import { getMessageByIdAPI } from "../../../../shared/actions/MessagingActions/ListMessagesById";
import { connect, useDispatch, useSelector } from 'react-redux';
import { listSendMessageAPI } from '../../../../shared/actions/MessagingActions/ListSendMessages';
import InfiniteScroll from "react-infinite-scroll-component";
import { actionAPI } from '../../../../shared/reduxToolkit/actionAPI';

const SentMessages = (props) => {
    const [isComposeModalOpen, setIsComposeModalOpen] = useState(false);
    const [isReplyMessage, setIsReplyMessage] = useState(false);
  const [sentMessages, setSentMessages] = useState([]);

    const { sendMessageListing, sendMessageListingSuccess, sendMessageListingLoading } = useSelector(state => state.SendMessagesList)
    const { sendMessage, sendMessageSuccess, sendMessageLoading, sendMessageFailed } = useSelector(state => state.SendingMessage)

const [totalRecords, setTotalRecords] = useState(10)
    let limit  = 10
    let offset = 1
    const { deleteMessage, deleteMessageSuccess } = useSelector(state => state.DeleteMessage)
    const apiDispatcher = useDispatch()
    const showComposeModal = () => {
        setIsComposeModalOpen(true)
    }
    const getMsgProfileColor = (index)=>{
        let colorList = ["#ffa358","#f65747", "#47b2f6"]
        let colorIndex = index
        if(colorIndex > (colorList.length - 1)){
            colorIndex = index % colorList.length
        }
        return colorList[colorIndex]
    }
    const getSentMessagesAPI =  () => {
       
        limit = 25;
            offset = Math.ceil(sentMessages.length / limit)+1;
        if (offset == 1) {
            setSentMessages([])
        }
        apiDispatcher(actionAPI.ListSendMessagesAPI({
            type: 1, messagingType: 2, pageNumber: offset, pageSize: limit, readFilter: 0
        }))
  };
    const getSentMessagesFromStartAPI = () => {
        limit = 25;
            offset = 1;

        apiDispatcher(actionAPI.ListSendMessagesAPI({ type: 1, messagingType: 2, pageNumber: offset, pageSize: limit, readFilter: 0 }))
  };
    useEffect(()=>{
        getSentMessagesAPI()
    },[])
    useEffect(() => { 
        if (sendMessageListingSuccess && sendMessageListing) {
            setTotalRecords(sendMessageListing[0].total)
            if(sendMessageListing[0]?.entry){
                const mergeData= [...sentMessages,...sendMessageListing[0]?.entry];
                setSentMessages(mergeData);
             

            }
    
    }},[sendMessageListing])
    const fetchmoreSentData=(ignore = false)=>{
       
    if( ignore || sentMessages.length < totalRecords)
    {
      getSentMessagesAPI();
    }
}
useEffect(()=>{
        if(deleteMessageSuccess){
            // handleDeleteMessage()
            setSentMessages([])
            getSentMessagesFromStartAPI()
        }
    },[deleteMessageSuccess])
useEffect(()=>{
        if(sendMessageSuccess){
            setSentMessages([])
            getSentMessagesFromStartAPI()
        }
    },[sendMessageSuccess])
    useEffect(() => {
        if (props.refreshTrigger > 0 && sentMessages.length > 0) {

            setSentMessages([])
            getSentMessagesFromStartAPI()
        }

    }, [props.refreshTrigger])
    return (
        <>
            <div className="msgList">

                <div id="scrollableDiv" className='w-100 h-100 msgList-scrollable-div'>
           {sendMessageListing !=null && sentMessages.length == 0 && !sendMessageListingLoading && <div className="d-flex justify-content-center mt-3 text-secondary">Sent is empty</div>}
                <InfiniteScroll
          dataLength={sentMessages.length}
          next={fetchmoreSentData}
          hasMore={sentMessages.length < totalRecords}
          loader={<div className='d-flex justify-content-center my-2'><Spin/></div>}
          
          scrollableTarget="scrollableDiv"
        >

              
                
                {sentMessages.length > 0 ? sentMessages.map((message, index) => (
                    <MsgCard
                    handleCheckChanged = {()=>props.handleCheckChanged(message)}
                        msgChecked={props.selectAllMsgsChecked}
                        key={message.resource.id}
                        sender={message?.resource?.extension[3]?.valueString}
                        subject={message?.resource?.note ? message?.resource?.note[0]?.text : ""}
                        DMSID={
                            message?.resource?.extension[9]?.valueDateTime || message?.resource?.extension[9]?.valueDateTime !== ""
                        }
                        date={
                            message?.resource?.sent?.slice(8, 10) +
                            "-" +
                            message?.resource?.sent?.slice(5, 7) +
                            "-" +
                            message?.resource?.sent?.slice(0, 4)
                        }
                        imgSrc={null}
                        isRead={
                            true
                        }
                        onSelect={() => {
                            localStorage.setItem("activeMessage", JSON.stringify(message));
                            props.setActiveMessage(message);
                            apiDispatcher(actionAPI.GetMessageByIdAPI({
                                messageId: message.resource.id, messageNum: (index + 1)
                            }))
                        }}
                        isActive={
                            localStorage.getItem("activeMessage") &&
                                JSON.parse(localStorage.getItem("activeMessage")).resource.id ===
                                message.resource.id
                                ? true
                                : false
                        }
                        profileColor={getMsgProfileColor(index)}

                    />
                )) : ""}
                </InfiniteScroll>
                </div>
            </div>
            {/* <div
                className="composeMsgIcon d-flex align-items-center justify-content-center"
                onClick={showComposeModal}
            >
                <svg
                    id="Plus_-_Free_shapes_and_symbols_icons-2_1_"
                    props.data-name="Plus - Free shapes and symbols icons-2 (1)"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 39.615 39.615"
                >
                    <g id="Group_2985" props.data-name="Group 2985">
                        <path
                            id="Path_3744"
                            props.data-name="Path 3744"
                            d="M36.133,16.326H23.289V3.482a3.482,3.482,0,1,0-6.964,0V16.326H3.482a3.482,3.482,0,1,0,0,6.964H16.326V36.133a3.482,3.482,0,1,0,6.964,0V23.289H36.133a3.482,3.482,0,1,0,0-6.964Z"
                            fill="#fff"
                        />
                    </g>
                </svg>
            </div>
            {isComposeModalOpen && (
                <ComposeMsgModal
                    handleCancel={() => { setIsComposeModalOpen(false) }}
                    handleOk={() => { setIsComposeModalOpen(false) }}
                    isComposeModalOpen={isComposeModalOpen}
                />)} */}
        </>
    )
}

function mapStateToProps(state) {
    return {

        messageByID: state.MessageByIdReducer,
        SendMessages: state.SendMessagesReducer,
        DeleteMessage: state.DeleteMessagesReducer,
        SendingMessage: state.SendingMessagesReducer,

    }
}
function mapDispatchToProps(disptach) {
    return {
    listSendMessages: (values) => disptach(listSendMessageAPI(values)),

        getMsgByID: (values) => disptach(getMessageByIdAPI(values)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SentMessages)
// export default
