import Moment from "moment";

export function dateFormatWithOutTime(dt) {
    Moment.locale('en');
    // var dt = '2016-05-12T00:00:00';
    //var date = Moment(dt).format('DD-MM-YYYY HH:mm');
    var date = Moment.utc(dt).local().format('DD/MM/YYYY');
   
    return date
}


export const GetDayName = date => {
    var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    var d = new Date(date);
    var dayName = days[d.getDay()];
    return dayName;
};

// function getDayName(date) {
//     var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
//     var d = new Date(date);
//     var dayName = days[d.getDay()];
//     return dayName;
// }


export const GetAppointmentDate = date => {
    var d = new Date(date);
    var n = d.getDate();
    return n;
}

export const GetAppointmentDateMod= date => {
    var dayOfMonth = parseInt(date.substr(8, 2));
    return dayOfMonth;
}


export const GetMonthName = date => {
    var days = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var d = new Date(date);
    var monthName = days[d.getMonth()];
    return monthName;
}


export const GetMonthNumber = date => {
    var days = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
    var d = new Date(date);
    var monthName = days[d.getMonth()];
    return monthName;
}


export const GetYear = date => {
    var d = new Date(date);
    var n = d.getFullYear();
    return n;
}

// function getMonthName(date) {
//     var days = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oc', 'Nov', 'Dec'];
//     var d = new Date(date);
//     var monthName = days[d.getMonth()];
//     return monthName;
// }


export const ReturnTypeIcon = type => {
    // if (type === 'Telephonic') {
    //     return require('../../assets/telephone.png');
    // }
    // if (type === 'Video') {
    //     return require('../../assets/video.png');
    // }
    // if (type === 'F2F') {
    //     return require('../../assets/face.png');
    // }
    return 'a';
}


export const FomraatedDate = currentDate => {
    let date = new Date(currentDate);
    let weekdayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    let monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var dateString = weekdayNames[date.getDay()] + " " + date.getDate() + " " + monthNames[date.getMonth()] + " " + date.getFullYear()

    return dateString;
}
export const getformatedDate = currentDate => {
    var timedate = new Date(currentDate);
    var year = timedate.getFullYear();
    var month = timedate.getMonth() + 1 //getMonth is zero based;
    var day = timedate.getDate();
    if (month.toString().length < 2) month = '0' + month;
    if (day.toString().length < 2) day = '0' + day;

    var formatted = year + "-" + day + "-" + month;
    return formatted;
}
export const FormatAMPM = currentDate => {
    let date = new Date(currentDate);
    var hours = date.getHours().toString();
    var minutes = date.getMinutes();
    // var hours = date.getUTCHours();
    // var minutes = date.getUTCMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}

export const FormateUTCTime = currentDateTIme => {
    let date = new Date(currentDateTIme);
    var hours = date.getUTCHours();
    var minutes = date.getUTCMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    let strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}

export const displayTime = currentTime => {


    var now = new Date(currentTime);
    var utc = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
    return utc.toLocaleTimeString();
    // let d = new Date(Date.parse(currentTime));

    // // this logs for me 
    // // "Fri Jul 08 2005 13:22:33 GMT+0200 (Central European Summer Time)" 
    // // and something else for you

 


    // // this logs
    // // Fri, 08 Jul 2005 11:22:33 GMT
    // // for everyone

   

    // return d.toTimeString()

    // var stringDate = currentTime;
    // var dTimezone = new Date();
    // var offset = dTimezone.getTimezoneOffset() / 60;
    // var date = new Date(Date.parse(stringDate));
    // date.setHours(date.getHours() + offset);

    // return date.toISOString();
}
export const convertUTCToLocalTime = (dateString) => {
    // let datenew = dateString.split('+')[0]
    let date = new Date(dateString);
    const milliseconds = Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
    );
    const localTime = new Date(milliseconds);
    localTime.getDate() // local date
    localTime.getHours() // local hour
    return localTime.getHours();
};
export const convertLocalTimeToUTCTime = (dateString) => {
    let date = new Date(dateString);
    // if (Platform.OS === PLATFORM.IOS) {
    //   return date.toISOString();
    // }
    return date.toISOString();
    //     const str = new Date(
    //         date.getUTCFullYear(),
    //         date.getUTCMonth(),
    //         date.getUTCDate(),
    //         date.getUTCHours(),
    //         date.getUTCMinutes(),
    //         date.getUTCSeconds(),
    //     ).toISOString();
    //   //  alert(str)
    //     let str1=new Date(str)
    //     return str1.getHours() + ':' + str1.getMinutes();
};
// function returnTypeIcon(type) {
//     if (type === 'Telephonic') {
//         return require('../assets/telephone.png');
//     }
//     if (type === 'Video') {
//         return require('../assets/video.png');
//     }
//     if (type === 'F2F') {
//         return require('../assets/face.png');
//     }
// }




export const capitalize = word => {
    return word?.substring(0, 1).toUpperCase() + word?.slice(1).toLowerCase();
}

export const GetHeading = results => {
    if (results.resource.extension[10].valueBoolean == true) {
        return 'Was due on'
    }
    else if (results.resource.extension[11].valueBoolean == true) {
        return 'Due on'
    }
    else {
        return 'Adminstered on:'
    }
}


export const ShowDateImmune = results => {
    if (results.resource.extension[10].valueBoolean == true) {
        return ''
    }
    else if (results.resource.extension[11].valueBoolean == true) {
        return ''
    }
    else {
        return GetAppointmentDate(results.resource.extension[12].valueDateTime) + ' ' + GetMonthName(results.resource.extension[12].valueDateTime) + ' ' + GetYear(results.resource.extension[12].valueDateTime)
    }
}

export const GetHeadingDates = results => {
    if (results.resource.extension[10].valueBoolean == true) {
        return  GetAppointmentDate(results.resource.extension[7].valueDateTime) + ' ' + GetMonthName(results.resource.extension[7].valueDateTime) + ' ' + GetYear(results.resource.extension[7].valueDateTime)
    }
    else if (results.resource.extension[11].valueBoolean == true) {
        return  GetAppointmentDate(results.resource.extension[7].valueDateTime) + ' ' + GetMonthName(results.resource.extension[7].valueDateTime) + ' ' + GetYear(results.resource.extension[7].valueDateTime)
    }
    else {
        return  GetAppointmentDate(results.resource.extension[7].valueDateTime) + ' ' + GetMonthName(results.resource.extension[7].valueDateTime) + ' ' + GetYear(results.resource.extension[7].valueDateTime)
    }
}
