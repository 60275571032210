import AsyncStorage from "@react-native-async-storage/async-storage";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Settings from "../../../config/Settings";
import getUserId from "../../../core/GetToken";

export const SendingMessagesAPI = createAsyncThunk(
  "sendMessage/Messaging",
  async (values, { dispatch, getState }) => {

    dispatch(sendingMessage())
    getUserId().then((userInfo) => {
      const item = JSON.parse(userInfo);


      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      myHeaders.append("Token", item.token);
      var raw = JSON.stringify({
        resourceType: "Communication",
        extension: [
          {
            url: "MessageBody",
            valueString: encodeURIComponent(values.message.body),
          },
          {
            url: "MessageType",
            valueString: values.messageType,
          },

        ],
        status: "completed",
        recipient: [
          {
            reference: `${values.practitioner.id}`,
            display: values.practitioner.name,
          },
        ],
        sender: {
          reference: `Patient/${item.userid}`,
          display: `${item.username}`,
        },
        payload: values.selectedFiles,
        note: [
          {
            text: values.message.subject,
          },
        ],
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };
      const queryString = `?RoleID=${values.roleID}&CallType=3&IsWeb=true`
      fetch(Settings.apiUrl + "Patient/Message" + queryString, requestOptions)

        .then(data => data.json())
        .then(json => {
          dispatch(sendingMessageSuccess(json))
        })
        .catch(err => dispatch(sendingMessageFailed(err)))

    })

  }

);
export const SendingMessageAPIRefresher = createAsyncThunk(
  "sendingMessageRefreshData/Messaging",
  async (values, { dispatch, getState }) => {
    dispatch(sendMessageRefreshData())
  }
)

const SendingMessageSlice = createSlice({
  name: "sendMessage",
  initialState: {
    sendMessage: null,
    sendMessageLoading: false,
    sendMessageSuccess: false,
    sendMessageFailed: false,
    sendMessageError: null,
    sendMessageStatus: "idle",
  },
  reducers: {
    sendingMessage: (state, action) => {
      state.sendMessage = null
      state.sendMessageSuccess = false
      state.sendMessageFailed = false
      state.sendMessageLoading = true
      state.sendMessageError = null
      state.sendMessageStatus = "loading"

    },
    sendingMessageSuccess: (state, action) => {
      state.sendMessage = action.payload
      state.sendMessageSuccess = true
      state.sendMessageFailed = false
      state.sendMessageLoading = false
      state.sendMessageError = null
      state.sendMessageStatus = "success"


    },
    sendMessageRefreshData: (state, action) => {
      state.sendMessage = null
      state.sendMessageLoading = false
      state.sendMessageSuccess = false
      state.sendMessageFailed = false
      state.sendMessageError = null
      state.sendMessageStatus = "idle"

    },
    sendingMessageFailed: (state, action) => {
      state.sendMessage = null
      state.sendMessageSuccess = false
      state.sendMessageFailed = true
      state.sendMessageLoading = false
      state.sendMessageError = action.payload
      state.sendMessageStatus = "error"

    },

  },
});




export const {
  sendingMessage, sendingMessageFailed, sendingMessageSuccess, sendMessageRefreshData
} = SendingMessageSlice.actions;
export default SendingMessageSlice.reducer;
