import { configureStore } from "@reduxjs/toolkit";
import {
    AuthenticateUser,
    ProxyUsers,
    ValidateToken,
    AppSettings,
    RefreshCounter,
    PracticeOrganization,
    ProxyMenu,
    UserAccountBalance,
    UserProfile,
    DeleteMessage,
    DraftMessagesList,
    InboxMessagesList,
    GetMessageById,
    ListMessageRecipients,
    MessagesList,
    MessageRolesList,
    SendMessagesList,
    SendingMessage,
    AddPatientAppointment,
    AdministeredImmune,
    AllergyIntolerance,
    AllInvoices,
    AllMedications,
    AllDiagnosis,
    AppointmentSlots,
    LongTermDiagnosis,
    RecentDiagnosis,
    DueImmune,
    FutureAppointments,
    AppointmentTypes,
    AvailablePractitioners,
    Pharmacies,
    ProfileImage,
    ProviderLocations,
    ProviderRoles,
    AllImmuneData,
    ReasonsForVisit,
    OverdueImmune,
    PendingInvoices,
    RecentMedication,
    PatientReminders,
    RepeatedMedications,
    Resources,
    SearchAppointments,
    TimelineData,
    PatientVitals,
    CompletedAppointments,
    PatientResults,


} from "./actions";
// import rootReducer from "./shared/reducers/index";
import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import thunk from "redux-thunk";
import { combineReducers } from "redux";

//cofigute store
const persistConfig = {
    key: "main-root",
    storage,
    blacklist: ['PatientVitals', 'PatientReminders', 'RefreshCounter', 'InboxMessagesList', 'SendMessagesList', 'DraftMessagesList', 'DeleteMessage', 'SendingMessage', 'SearchAppointments', 'PatientResults', 'TimelineData', 'PendingInvoices', 'AllInvoices', 'RecentMedication', 'UserProfile', "FutureAppointments", "AuthenticateUser", "AllergyIntolerance", "DueImmune", "OverdueImmune", "AdministeredImmune", "AllImmuneData", "AllMedications", "UserAccountBalance", "ProviderLocations"],
};
const appReducers = combineReducers({
    AuthenticateUser,
    ProxyUsers,
    ValidateToken,
    AppSettings,
    RefreshCounter,
    PracticeOrganization,
    ProxyMenu,
    UserAccountBalance,
    UserProfile,
    DeleteMessage,
    DraftMessagesList,
    InboxMessagesList,
    GetMessageById,
    ListMessageRecipients,
    MessagesList,
    MessageRolesList,
    SendMessagesList,
    SendingMessage,
    AddPatientAppointment,
    AdministeredImmune,
    AllergyIntolerance,
    AllInvoices,
    AllMedications,
    AllDiagnosis,
    AppointmentSlots,
    LongTermDiagnosis,
    RecentDiagnosis,
    DueImmune,
    FutureAppointments,
    AppointmentTypes,
    AvailablePractitioners,
    Pharmacies,
    ProfileImage,
    ProviderLocations,
    ProviderRoles,
    AllImmuneData,
    ReasonsForVisit,
    OverdueImmune,
    PendingInvoices,
    RecentMedication,
    PatientReminders,
    RepeatedMedications,
    Resources,
    SearchAppointments,
    TimelineData,
    PatientVitals,
    CompletedAppointments,
    PatientResults,

})
const persistedReducer = persistReducer(persistConfig, appReducers);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: [thunk]
})

const Persister = persistStore(store);

export default store;
export { Persister };
