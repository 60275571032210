import { Button } from 'antd';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

const PaymentCancel = () => {
    const [isCallFromRpeatRX, setIsCallFromRpeatRX] = useState(false)
    const [isCallFromWeb, setisCallFromWeb] = useState(false);
    const navigate = useNavigate()
    useEffect(() => {
        // setTimeout(() => {
            function parseQuery(queryString) {
                const params = {};
                const queryStringWithoutQuestionMark = queryString.substring(1);
                const queryParams = queryStringWithoutQuestionMark.split("&");
                queryParams.forEach((param) => {
                    const [key, value] = param.split("=");
                    params[key] = decodeURIComponent(value);
                });
                return params;
            }


            const queryString = window.location.search;
            let { IsRxRequest, callfrom } = parseQuery(queryString);
            setIsCallFromRpeatRX(IsRxRequest === "True" ? true : false)
            if (callfrom == "mobile") {
                setisCallFromWeb(false)
                // alert(queryString)
              } else {
                setisCallFromWeb(true)
              }
            // if (IsRxRequest) {
            //     navigate("/medications")
            // } else {
            //     navigate("/accounts")

            // }


        // }, 2000)
    }, [])
    return (
      <>
        <div className="page-content-wrapper-inner">
          <div className="content-viewport">
            <div className="row row-margin-zero h-100 text-center d-flex justify-content-center">
              {isCallFromWeb ? (
                <div className="w-75 max-width-400 d-flex flex-column align-items-center payment-returnpage-main-div">
                  <img className="mb-5" src="assets/images/declined.svg" />
                  <h2 className="">Payment Cancelled</h2>
                  <p className="font-size-17">
                    Payment is cancelled by user. Please click go back button to
                    proceed further.
                  </p>
                  <button
                    className="payment-returnpage-btn mt-5"
                    onClick={() => {
                      isCallFromRpeatRX
                        ? navigate("/medications")
                        : navigate("/accounts");
                    }}
                  >
                    Go Back
                  </button>
                </div>
              ) : (
                <div className="w-75 max-width-400 d-flex flex-column align-items-center payment-returnpage-main-div">
                  <img className="mb-5" src="assets/images/declined.svg" />
                  <h2 className="">Payment Cancelled</h2>
                  <p className="font-size-17">
                      Payment is cancelled by user. Please tap on back arrow button to proceed further.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
}

export default PaymentCancel
