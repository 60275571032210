import React from 'react';
import { StyleSheet, Text, View, Image, Dimensions } from 'react-native';


const ProfileItem = props => {

    return (
        <form autoComplete="off" className="forms-sample profile-item-forms-sample">
            <div className="form-group row mb-0">

                {
                    props.title_B === "" ? (<div className="col-md-12">
                        <label htmlFor="validate-phone-field">{props.title_A}</label>
                        <div className="input-group">
                            <input autoComplete="off" id="mPassword" type="text" className="form-control" placeholder="Password" />

                        </div>
                    </div>) : <div className="col-md-6">
                        <label htmlFor="validate-phone-field">{props.title_A}</label>
                        <div className="input-group">
                                <input autoComplete="off" id="mPassword" type="text" className="form-control" placeholder="Password" />

                        </div>
                    </div>
                }
                {
                    props.title_B !== "" && <div className="col-md-6">
                        <label htmlFor="validate-internet-ip-field">{props.title_B}</label>
                        <div className="input-group">
                            <input autoComplete="off" id="mPassword" type="text" className="form-control" placeholder="Password" />

                        </div>
                    </div>
                }

            </div>
        </form>
    );
}

export default ProfileItem
