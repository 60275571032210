import AsyncStorage from "@react-native-async-storage/async-storage";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Settings from "../../../config/Settings";
import getUserId from "../../../core/GetToken";

export const GetPendingInvoicesFromAPI = createAsyncThunk(
    "getPendingInvoices/Invoice",
    async (values, { dispatch, getState }) => {

        dispatch(getPendingInvoices())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = {
                PatientID: item.userid,
                PageSize: values.pageSize,
                PageNumber: values.pageNumber,
                TransactionTypeID: 1,
            }
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(raw)
            };

            fetch(Settings.apiUrl + "Patient/PaymentNotice", requestOptions)
                .then(data => data.json())
                .then(json => {

                    json[0].hasOwnProperty('entry') ? dispatch(getPendingInvoicesSuccess(json)) : dispatch(getPendingInvoicesSuccess([]))
                })
                .catch(err => dispatch(getPendingInvoicesFailed(err)))
        })

    }

);

const GetPendingInvoicesSlice = createSlice({
    name: "getPendingInvoices",
    initialState: {
        PendingInvc: [],
        isGetting: false,
        error: false
    },
    reducers: {
        getPendingInvoices: (state, action) => {
            state.PendingInvc = []
            state.isGetting = true
            state.error = false
        },
        getPendingInvoicesSuccess: (state, action) => {
            state.PendingInvc = action.payload
            state.isGetting = false
            state.error = false

        },
        getPendingInvoicesFailed: (state, action) => {
            state.isGetting = false
            state.error = true
        },
        getPendingInvoicesFinished: (state, action) => {
            state.PendingInvc = []
            state.isGetting = false
            state.error = false
        },

    },
});




export const {
    getPendingInvoices, getPendingInvoicesFailed, getPendingInvoicesSuccess, getPendingInvoicesFinished
} = GetPendingInvoicesSlice.actions;
export default GetPendingInvoicesSlice.reducer;
