import React, { memo, useCallback, useEffect, useState } from 'react';
import { View, Text, Button, StyleSheet, StatusBar, Dimensions, ActivityIndicator, FlatList } from 'react-native-web';
import { connect, useDispatch, useSelector } from "react-redux";


import { fetchAllMedsFromApi } from "../../../shared/actions/AllMedicationActions";
import MedicationHandler from "../../components/MedicationHandler";
import { GetAppointmentDate, GetMonthName, GetYear } from '../../../shared/core/datehelper'
import { fetchRecentMedsFromApi } from '../../../shared/actions/RecentMedAction';
import { flushSync } from 'react-dom';
import { Spin, Table } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { actionAPI } from '../../../shared/reduxToolkit/actionAPI';
const AllMedications = props => {

    const { AllRecentMedeications, isGettingRecent } = useSelector(state => state.RecentMedication);
    //state for the page number
    const [medsDataList, setMedsDataList] = useState([]);
    const [totalRecords, setTotalRecords] = useState(10)
    const [pageNumber, setPageNumber] = useState(1)
    const apiDispatcher = useDispatch()
    let limit = 15
    let offset = 1

    const getAllMedsDataFromApi = (fromStart = false) => {
        limit = 15;
        offset = fromStart ? 1 : Math.ceil(medsDataList.length / limit) + 1;
        setPageNumber(offset)
        if (offset == 1) {
            flushSync(() => {
                setMedsDataList([])
                setTotalRecords(0)
            })
        }
        // getTimelineDateTextSearch: (pageSize, pageNumber, fromdate, todate, textSearch, filterProviderId)

        apiDispatcher(actionAPI.GetRecentMedsFromAPI({ pageSize: limit, pageNumber: offset }))


    };
    const fetchMoreMedsDataFromAPI = (ignore = false) => {
        if (ignore || medsDataList.length < totalRecords) {
            getAllMedsDataFromApi();
        }
    }

    useEffect(() => {
        getAllMedsDataFromApi(true)
    }, [props.allMedsClickChange])
    useEffect(() => {
        if (AllRecentMedeications !== "undefined" && AllRecentMedeications?.length > 0) {
            setTotalRecords(AllRecentMedeications[0].total)
            if (AllRecentMedeications && medsDataList != AllRecentMedeications[0].entry) {
                const mergeData = [...medsDataList, ...AllRecentMedeications[0].entry];
                setMedsDataList(mergeData);


            }

        }
    }, [AllRecentMedeications])
    function capitalize(word) {
        return word[0].toUpperCase() + word.slice(1).toLowerCase();
    }
    const gridColumns = [
        {
            title: 'DATE',
            dataIndex: "resource",
            width: '200px',
            render: (item) => <p>{GetAppointmentDate(item?.hasOwnProperty('authoredOn') ? item.authoredOn : null) + ' ' + GetMonthName(item.hasOwnProperty('authoredOn') ? item.authoredOn : null) + ' ' + GetYear(item.hasOwnProperty('authoredOn') ? item.authoredOn : null)}</p>
        },
        {
            title: 'MEDICINE NAME',
            dataIndex: "resource",

            render: (item) => <p>{item?.hasOwnProperty('medicationCodeableConcept') ? capitalize(item?.medicationCodeableConcept?.coding[0]?.display) : 'N/A'}</p>

        },
        {
            title: 'DIRECTIONS',
            dataIndex: "resource",

            render: (item) => <p>{item?.hasOwnProperty('dosageInstruction') ? item.dosageInstruction[0].text : 'No'}</p>

        },


    ];
    return (
        <div className='d-flex flex-column h-100'>
            <div className='padding-left-ten padding-right-ten'>
                {/* <div className="reminder-legends card-body">
                    <div className="medication-shortterm-legend">
                        <div className="custom-legends shortterm-legend-box color-white"> History </div>
                    </div>
                    <div className="medication-longterm-legend">
                        <div className="custom-legends lognterm-legend-box color-white"> Long Term</div>
                    </div>

                </div> */}
                </div>
            {
                isGettingRecent && pageNumber === 1 && <div className='d-flex text-center justify-content-center mb-1 mt-2'><ActivityIndicator size="small" color="#00A1DE" /></div>
            }
            {
                typeof (AllRecentMedeications) !== 'undefined' && medsDataList.length > 0 ? (
                    <div id="allMedsScrollableDiv" className='long-term-medication-inner-div'>
                        <InfiniteScroll
                            dataLength={medsDataList.length}
                            next={fetchMoreMedsDataFromAPI}
                            hasMore={medsDataList.length < totalRecords}
                            loader={<div className='d-flex justify-content-center my-2'><Spin /></div>}
                            scrollableTarget="allMedsScrollableDiv"
                        >
                            {/* <FlatList
                            className="all-medications-view-flatlist"
                                data={medsDataList}
                            renderItem={({ item }) => (


                                // <MedicationHandler
                                //     medcategory={item.resource.extension[0].valueBoolean ? 'longterm' : 'history'}
                                //     cardStyle={'card-header text-white bg-primary'}
                                //     medDate={GetAppointmentDate(item.resource.hasOwnProperty('authoredOn') ? item.resource.authoredOn : null) + ' ' + GetMonthName(item.resource.hasOwnProperty('authoredOn') ? item.resource.authoredOn : null) + ' ' + GetYear(item.resource.hasOwnProperty('authoredOn') ? item.resource.authoredOn : null)}
                                //     //medMonth={GetYear(item.resource.hasOwnProperty('authoredOn') ? item.resource.authoredOn : null)}
                                //     medicationDetails={item.resource.hasOwnProperty('medicationCodeableConcept') ? capitalize(item.resource.medicationCodeableConcept.coding[0].display) : 'N/A'} //
                                //     directions={item.resource.hasOwnProperty('dosageInstruction') ? item.resource.dosageInstruction[0].text : 'No'} // item.resource.dosageInstruction[0].text
                                //     checking={item.resource.extension[1].valueString}
                                //     quantity={item.resource.dispenseRequest.quantity.value + " " + (item.resource.extension[5] != undefined && item.resource.extension[5].valueString != undefined ? item.resource.extension[5].valueString : '')}
                                //     endDate={item.resource.extension[4] != undefined ? (GetAppointmentDate(item.resource.extension[4].valueDateTime) + ' ' + GetMonthName(item.resource.extension[4].valueDateTime) + ' ' + GetYear(item.resource.extension[4].valueDateTime)) : "-"}
                                //     startDate={GetAppointmentDate(item.resource.hasOwnProperty('authoredOn') ? item.resource.authoredOn : null) + ' ' + GetMonthName(item.resource.hasOwnProperty('authoredOn') ? item.resource.authoredOn : null) + ' ' + GetYear(item.resource.hasOwnProperty('authoredOn') ? item.resource.authoredOn : null)}
                                // />

                                // <MedicationWebHandler
                                //     medDate={GetAppointmentDate(item.resource.hasOwnProperty('authoredOn') ? item.resource.authoredOn : null) + ' ' + GetMonthName(item.resource.hasOwnProperty('authoredOn') ? item.resource.authoredOn : null)}
                                //     medMonth={GetYear(item.resource.hasOwnProperty('authoredOn') ? item.resource.authoredOn : null)}
                                //     medicationDetails={capitalize(item.resource.medicationCodeableConcept.coding[0].display)}
                                //     directions={item.resource.dosageInstruction[0].text}
                                //     checking={'Period: ' + item.resource.extension[1].valueString}
                                //     quantity={'Quantity: ' + item.resource.dispenseRequest.quantity.value + " " + (item.resource.extension[5] != undefined && item.resource.extension[5].valueString != undefined ? item.resource.extension[5].valueString : '')}
                                //     presby={item.resource.extension[4] != undefined ? (GetAppointmentDate(item.resource.extension[4].valueDateTime) + ' ' + GetMonthName(item.resource.extension[4].valueDateTime) + ' ' + GetYear(item.resource.extension[4].valueDateTime)) : "-"}
                                //     pratname={GetAppointmentDate(item.resource.hasOwnProperty('authoredOn') ? item.resource.authoredOn : null) + ' ' + GetMonthName(item.resource.hasOwnProperty('authoredOn') ? item.resource.authoredOn : null) + ' ' + GetYear(item.resource.hasOwnProperty('authoredOn') ? item.resource.authoredOn : null)}
                                // />
                            )}
                                keyExtractor={item => item?.resource?.id}
                            initialNumToRender={10}
                            maxToRenderPerBatch={10}
                            windowSize={7}

                        /> */}

                            <Table
                                columns={gridColumns}

                                dataSource={medsDataList}
                                pagination={false}
                                className='longterm-table'
                            />
                        </InfiniteScroll>
                    </div>
                ) : !isGettingRecent &&
                    <div className='alert alert-danger p-2 text-center'>No Records Found</div>


            }
        </div>
    );
}


function mapStateToProps(state) {
    return {
        RecentMed: state.RecentMed
    }
}

function mapDispatchToProps(disptach) {
    return {
        getMed: (pageSize, pageNumber) => disptach(fetchRecentMedsFromApi(pageSize, pageNumber)),

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllMedications);

// export default AllMedications
