import {  GET_ALL_INVOICES , GET_ALL_INVOICES_SUCCESS , GET_ALL_INVOICES_MORE_SUCCESS, GET_ALL_INVOICES_FAIL  } from "../constants/Constant";

//inital state
const initalState = {
    AllInvc: [],
    isGetting: false,
    error: false
}

//export default state
export default function allInvRedcuer(state = initalState, action) {
    switch (action.type) {
        case GET_ALL_INVOICES:
            return {
                ...state,
                isGetting: true
            }

        case GET_ALL_INVOICES_SUCCESS:
            return {
                ...state,
                isGetting: false,
                AllInvc: action.data
            }

        case GET_ALL_INVOICES_MORE_SUCCESS:
            return {
                ...state,
                isGetting: false,
                AllInvc: [...state.AllInvc, ...action.data]
              
            }

        case GET_ALL_INVOICES_FAIL:
            return {
                ...state,
                isGetting: false,
                error: true
            }
        default:
            return state
    }
}