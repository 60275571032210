import AsyncStorage from "@react-native-async-storage/async-storage";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Settings from "../../../config/Settings";
import getUserId from "../../../core/GetToken";

export const ListMessageRecipientsAPI = createAsyncThunk(
    "listMessageRecipients/Messaging",
    async (values, { dispatch, getState }) => {

        dispatch(gettingMessageRecipients())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = {
                PatientID: item.userid,
                RoleID: values.roleID,
                CallType: 0,
            }
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(raw)
            };
            fetch(Settings.apiUrl + "Patient/MessagingRecipients", requestOptions)

                .then(data => data.json())
                .then(json => {
                    dispatch(gettingMessageRecipientsSuccess(json))
                })
                .catch(err => dispatch(gettingMessageRecipientsFailed(err)))

        })

    }

);


export const ResetMessageRecipientsAPI = createAsyncThunk(
    "resetMessageRecipients/Messaging",
    async (values, { dispatch, getState }) => {
        dispatch(gettingMessageRecipientsReset())
    })
const ListMessageRecipientsSlice = createSlice({
    name: "listMessageRecipients",
    initialState: {
        recipientsData: null,
        recipientsDataLoading: false,
        recipientsDataSuccess: false,
        recipientsDataFailed: false,
        recipientsDataError: null,
        recipientsDataStatus: "idle",
    },
    reducers: {
        gettingMessageRecipients: (state, action) => {
            state.recipientsData = null
            state.recipientsDataSuccess = false
            state.recipientsDataFailed = false
            state.recipientsDataLoading = true
            state.recipientsDataError = null
            state.recipientsDataStatus = "loading"

        },
        gettingMessageRecipientsSuccess: (state, action) => {
            state.recipientsData = action.payload
            state.recipientsDataSuccess = true
            state.recipientsDataFailed = false
            state.recipientsDataLoading = false
            state.recipientsDataError = null
            state.recipientsDataStatus = "success"


        },
        gettingMessageRecipientsFailed: (state, action) => {
            state.recipientsData = null
            state.recipientsDataSuccess = false
            state.recipientsDataFailed = true
            state.recipientsDataLoading = false
            state.recipientsDataError = action.payload
            state.recipientsDataStatus = "error"

        },
        gettingMessageRecipientsReset: (state, action) => {
            state.recipientsData = null
            state.recipientsDataSuccess = false
            state.recipientsDataFailed = false
            state.recipientsDataLoading = false
            state.recipientsDataError = null
            state.recipientsDataStatus = "idle"

        },
    },
});




export const {
    gettingMessageRecipients, gettingMessageRecipientsFailed, gettingMessageRecipientsSuccess, gettingMessageRecipientsReset
} = ListMessageRecipientsSlice.actions;
export default ListMessageRecipientsSlice.reducer;
