import AsyncStorage from "@react-native-async-storage/async-storage";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Settings from "../../../config/Settings";
import getUserId from "../../../core/GetToken";

export const fetchAdministeredImmunefromApi = createAsyncThunk(
    "fetchAdministeredImmunefromApi/Immune",
    async (values, { dispatch, getState }) => {

        dispatch(getImmune())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = {
                PatientID: item.userid,
                PageSize: values.pageSize,
                PageNumber: values.pageNumber,
                TypeID: 1,
            }
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(raw)
            };



            fetch(Settings.apiUrl + "Patient/Immunization", requestOptions)
                .then(data => data.json())
                .then(json => {
                    if (json) {

                        json[0].hasOwnProperty('entry') ? dispatch(getImmuneSuccess(json[0].entry)) : dispatch(getImmuneFinished())
                    } else {
                        dispatch(getImmuneFinished())
                    }
                })
                .catch(err => dispatch(getImmuneFailed(err)))


        })

    }

);


const AdministeredImmuneSlice = createSlice({
    name: "fetchAdministeredImmunefromApi",
    initialState: {
        AddImmune: [],
        isGettingImmune: false,
        AddimmuneError: false
    },
    reducers: {
        getImmune: (state, action) => {
            state.AddImmune = []
            state.isGettingImmune = true
            state.AddimmuneError = false

        },
        getImmuneSuccess: (state, action) => {
            state.AddImmune = action.payload
            state.isGettingImmune = false
            state.AddimmuneError = false

        },
        getImmuneFailed: (state, action) => {
            state.AddImmune = []
            state.isGettingImmune = false
            state.AddimmuneError = true

        },
        getImmuneFinished: (state, action) => {
            state.AddImmune = []
            state.isGettingImmune = false
            state.AddimmuneError = false

        },
    },
});




export const {
    getImmune, getImmuneFailed, getImmuneSuccess, getImmuneFinished, getImmuneMoreSuccess
} = AdministeredImmuneSlice.actions;
export default AdministeredImmuneSlice.reducer;
