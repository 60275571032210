import React from "react";
import { connect, useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";

const PrivateRoute = (props) => {
    const { TokenValidator, isChecking } = useSelector(state => state.ValidateToken);
    console.log("Private routeeee", TokenValidator)
    // return (
    //     <Route {...rest} render={props => (
    //         authed ? (<Component {...props} />
    //         ) : (
    //                 <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    //             )
    //     )} />
    // )
    const location = useLocation();

    return (
        TokenValidator ? (
            <div className="main-wrapper">
                {!isChecking && TokenValidator ? <Header /> : null}
                <div id="myphonemenu" className={!isChecking && TokenValidator ? "page-body" : null}>
                    {!isChecking && TokenValidator ? <Sidebar /> : null}
                    <div className={!isChecking && TokenValidator ? "page-content-wrapper" : null}>

                        <Outlet />

                        {
                            !isChecking && <footer className="footer-ignore">
                                <small>Version 2.0.0.0<br />© Copyrights . All rights Reserved</small>
                            </footer>
                        }
                    </div >

                </div >

            </div >


        ) : (
                <Navigate to="/login" state={{ from: location }} replace />
        )
    )

}


function mapStateToProps(state) {
    return {
        TokenValidator: state.TokenValidator,

    }
}
function mapDispatchToProps(disptach) {
    return {

    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PrivateRoute)