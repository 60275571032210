import React from 'react';
import { StyleSheet, Text, View, Image, Dimensions, TouchableOpacity } from 'react-native';


const MedicationHandler = props => {
    return (
        <>
            

            <div className="col-lg-12 ">
                <div className='medicaiton-history-container' >
                    <div className={`medication-history-left-border ${props.medcategory == "longterm" ? ' long-term-medication-bg' : 'normal-term-medication-bg'}` } >
                    </div>

                    <div className="item-wrapper medication-history-content" >
                        <p className='mb-2 text-black text-primary-14'>Prescribed On: {props.medDate}</p>
                        <p className="card-title mb-2 text-primary-14 medication-gray-color">{props.medicationDetails}</p>
                        <p className='mb-0 text-primary-14 medication-gray-color'>{props.directions}</p>
                    </div>

                </div>
             
            </div>
        </>
    );
}

export default MedicationHandler
