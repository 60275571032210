import AsyncStorage from "@react-native-async-storage/async-storage";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Settings from "../../../config/Settings";
import getUserId from "../../../core/GetToken";

export const GetPatientAppointmentsFromAPI = createAsyncThunk(
    "getPatientAppointments/Diagnosis",
    async (values, { dispatch, getState }) => {

        dispatch(getPatientAppointments())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);

            let appointType = 0
            if (values.appointmentType === "Completed") {
                appointType = 11
            }
            else if (values.appointmentType === "Missed") {
                appointType = 12
            }
            else if (values.appointmentType === "Cancelled") {
                appointType = 13
            }
            else {
                appointType = 0
            }
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = {
                PatientID: item.userid,
                PageSize: values.pageSize ? values.pageSize : 200,
                PageNumber: values.pageNumber ? values.pageNumber : 1,
                AppointmentType: appointType,
                FromDate: values.fromDate,
                ToDate: values.toDate
            }
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(raw)
            };
            // AppointmentType =>0 for All,  11 for completed, 12 for missed, 13 for cancelled

            //  alert(queryString)
            //const queryString = '?PatientID=' + item.userid + '&PageSize=999&PageNumber=1&AppointmentType=0'

            fetch(Settings.apiUrl + "Patient/Appointments", requestOptions)
                .then(data => data.json())
                .then(json => {

                    json[0].hasOwnProperty('entry') ? dispatch(getPatientAppointmentsSuccess(json)) : dispatch(getPatientAppointmentsFinished())
                })
                .catch(err => dispatch(getPatientAppointmentsFailed(err)))
        })

    }

);

const GetPatientAppointmentsSlice = createSlice({
    name: "getPatientAppointments",
    initialState: {
        SearchAppointment: [],
        isGettingSearch: false,
        errorSearch: false
    },
    reducers: {
        getPatientAppointments: (state, action) => {
            state.isGettingSearch = true
            state.errorSearch = false
        },
        getPatientAppointmentsSuccess: (state, action) => {
            state.SearchAppointment = action.payload
            state.isGettingSearch = false
            state.errorSearch = false

        },
        getPatientAppointmentsFailed: (state, action) => {
            state.isGettingSearch = false
            state.errorSearch = true
        },
        getPatientAppointmentsFinished: (state, action) => {
            state.SearchAppointment = []
            state.isGettingSearch = false
            state.errorSearch = false
        },

    },
});




export const {
    getPatientAppointments, getPatientAppointmentsFailed, getPatientAppointmentsSuccess, getPatientAppointmentsFinished
} = GetPatientAppointmentsSlice.actions;
export default GetPatientAppointmentsSlice.reducer;
