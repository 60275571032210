import React from 'react'

const PosFailMobileComp = () => {
    return (
        <div className='d-flex flex-column align-items-center mt-5'>
            <svg xmlns="http://www.w3.org/2000/svg" width="156.188" height="134.348" viewBox="0 0 156.188 134.348">
                <g id="error" transform="translate(-9.5 -43.969)">
                    <g id="Group_6849" data-name="Group 6849" transform="translate(9.5 43.969)">
                        <path id="Path_33882" data-name="Path 33882" d="M92.282,46.619l72.36,120.752a7.218,7.218,0,0,1-6.2,10.945H16.746a7.218,7.218,0,0,1-6.2-10.945L82.9,46.619a5.473,5.473,0,0,1,9.378,0Z" transform="translate(-9.5 -43.969)" fill="#e03" fill-rule="evenodd" />
                        <path id="Path_33883" data-name="Path 33883" d="M115.027,91.076,49.654,200.169H180.4Z" transform="translate(-36.933 -76.152)" fill="#eff4f7" fill-rule="evenodd" />
                        <g id="Group_6848" data-name="Group 6848" transform="translate(70.533 40.64)">
                            <path id="Path_33884" data-name="Path 33884" d="M244.045,222.1c0,5.988-9.114,5.987-9.114,0l-2.8-42.607c0-9.664,14.708-9.665,14.708,0Zm-4.556,22.425a5.864,5.864,0,1,0-5.864-5.864A5.876,5.876,0,0,0,239.488,244.527Z" transform="translate(-232.134 -172.246)" fill="#e03" fill-rule="evenodd" />
                        </g>
                    </g>
                </g>
            </svg>

            <div className='pos-tick-shadow'>

            </div>
            <p className='text-bolder mt-2'>Payment Failed</p>
        </div>
    )
}

export default PosFailMobileComp
