import {
    START_SEARCH_APPOINTMENTN,
    START_SEARCH_APPOINTMENTN_SUCCESS,
    START_SEARCH_APPOINTMENTN_FAILURE,
    START_SEARCH_APPOINTMENTN_FINISHED,

} from "../constants/Constant";
import getPaitentAppointments from "../services/AppointmentServices";
import getUserId from "../core/GetToken";


export const searchAppointmentFromApi = (fromDate, toDate) => {
    return (dispatch) => {
        dispatch(searchAppointment())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token);

            const queryString = '?PatientID=' + item.userid + '&PageSize=200&PageNumber=1&FromDate=' + fromDate + '&ToDate=' + toDate
          //  alert(queryString)
           //const queryString = '?PatientID=' + item.userid + '&PageSize=999&PageNumber=1&AppointmentType=0'
            
            getPaitentAppointments(queryString, myHeaders)
                .then(data => data.json())
                .then(json => {
                   
                    json[0].hasOwnProperty('entry') ? dispatch(searchAppointmentSucess(json[0].entry)) : dispatch(searchAppointmentFinished())
                })
                .catch(err => dispatch(searchAppointmentFail(err)))
        })
    }
}
export const showALlAppointmentFromApi = (pageSize = 200, pageNumber = 1, fromDate = null, toDate = null, appointmentType = "All") => {
    return (dispatch) => {
        dispatch(searchAppointment())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);

            let appointType = 0
            if (appointmentType == "Completed") {
                appointType = 11
            }
            else if (appointmentType == "Missed") {
                appointType = 12
            }
            else if (appointmentType == "Cancelled") {
                appointType = 13
            }
            else {
                appointType = 0
            }
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = {
                PatientID: item.userid,
                PageSize: pageSize,
                PageNumber: pageNumber,
                AppointmentType: appointType,
                FromDate: fromDate,
                ToDate: toDate
            }
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(raw)
            };
            // AppointmentType =>0 for All,  11 for completed, 12 for missed, 13 for cancelled

            //  alert(queryString)
            //const queryString = '?PatientID=' + item.userid + '&PageSize=999&PageNumber=1&AppointmentType=0'
           
            getPaitentAppointments("", requestOptions)
                .then(data => data.json())
                .then(json => {
                 
                    json[0].hasOwnProperty('entry') ? dispatch(searchAppointmentSucess(json)) : dispatch(searchAppointmentFinished())
                })
                .catch(err => dispatch(searchAppointmentFail(err)))
        })
    }
}
export const searchFilteredAppointmentFromApi = (appointType) => {
    return (dispatch) => {
        dispatch(searchAppointment())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token);

            const queryString = '?PatientID=' + item.userid + '&PageSize=200&PageNumber=1&AppointmentType=' + appointType
            //  alert(queryString)
            //const queryString = '?PatientID=' + item.userid + '&PageSize=999&PageNumber=1&AppointmentType=0'
          
            getPaitentAppointments(queryString, myHeaders)
                .then(data => data.json())
                .then(json => {
                    
                    json[0].hasOwnProperty('entry') ? dispatch(searchAppointmentSucess(json[0].entry)) : dispatch(searchAppointmentFinished())
                })
                .catch(err => dispatch(searchAppointmentFail(err)))
        })
    }
}

const searchAppointment = () => {
    return {
        type: START_SEARCH_APPOINTMENTN
    }
}

//data success
const searchAppointmentSucess = (data) => {
    return {
        type: START_SEARCH_APPOINTMENTN_SUCCESS,
        data,
    }
}

//data failed
const searchAppointmentFail = () => {
    return {
        type: START_SEARCH_APPOINTMENTN_FAILURE
    }
}
const searchAppointmentFinished = () => {
    return {
        type: START_SEARCH_APPOINTMENTN_FINISHED
    }
}