import { async } from "q";
import React, { useEffect, useState } from "react";
import { View, Text, Alert } from "react-native";
import getUserId from "../../shared/core/GetToken";

const PosStripe = () => {
  const [paymentData, setPaymentData] = useState(null);
  const [sendingMedicationRepeatRequest, setSendingMedicationRepeatRequest] =
    useState(false);

    console.log('abccc')

    useEffect(async () => {
        function parseQuery(queryString) {
          const params = {};
          const queryStringWithoutQuestionMark = queryString.substring(1);
          const queryParams = queryStringWithoutQuestionMark.split("&");
          queryParams.forEach((param) => {
            const [key, value] = param.split("=");
            params[key] = decodeURIComponent(value);
          });
          return params;
        }
    
        // alert(window.location.search);
    
        const queryString = window.location.search;

        alert(queryString)

        return
        // alert(queryString);
        let { pid, patienttoken, token } = parseQuery(queryString);
    
        console.log("pid:", pid);
        console.log("patienttoken:", patienttoken);
        console.log("token:", token);
        // parseQuery()
        //   const RepeatPoliTransaction = async () => {
    
        let repeatPayload;
    
        //   let Transcationtoken = window?.location?.search;
    
        //   if (Transcationtoken != null) {
        //     Transcationtoken = Transcationtoken.substring(7, Transcationtoken.length);
        //   }else{
        //       alert('Payment unsuccessful!')
        //       return false
        //   }
    
        const myHeaders = new Headers();
        myHeaders.append("patienttoken", patienttoken);
        myHeaders.append("Content-Type", "application/json");
    
        // const item = await getUserId().then((userInfo) => {
    
        //     return JSON.parse(userInfo);
        // });
    
        // alert(item.userid)
    
        const raw = JSON.stringify({
          transactionId: token,
          patientId: pid,
          ApiUrl: `https://devdhbconnect.vitonta.com`,
        });
    
        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };
        console.log("raw----", raw);
    
        const resultPoliTransaction = await fetch(
          "https://australia-southeast1-myindici-nz.cloudfunctions.net/indiciportalpaymentsaus/patient/production/GetTransaction",
          requestOptions
        );
        const finalresultPoliTransaction = await resultPoliTransaction.json();
        setPaymentData(finalresultPoliTransaction);
        if(finalresultPoliTransaction?.MerchantData?.indexOf("repeat") == -1){
            //SavePaymentCall
            if (finalresultPoliTransaction.TransactionStatus == "Completed") {
              if (repeatJsonTID && parseInt(repeatJsonTID) > 0) {
                const payloadqueryStringFinal = `?PatientID=${pid}&electronicPaymentID=${repeatJsonTID}`;
                const responsePaymentFinal = await fetch(
                  `https://devdhbconnect.vitonta.com/api/patient/SavePayment${payloadqueryStringFinal}`,
                  {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                      Token: patienttoken,
                    },
                    redirect: "follow",
                  }
                );
              }
            }
            alert("Accountssss")
            return;
        }
        const startIndex =
          finalresultPoliTransaction?.MerchantData?.indexOf("repeat=") + 7;
        const endIndex = finalresultPoliTransaction?.MerchantData?.lastIndexOf("}");
        const repeatJson = finalresultPoliTransaction?.MerchantData?.substring(
          startIndex,
          endIndex + 1
        );
        repeatPayload = JSON.parse(repeatJson);
    
        console.log("repeattttttt", repeatPayload);
    
        const startIndexTID =
          finalresultPoliTransaction?.MerchantData?.indexOf("temppaymentid=") + 14;
        const endIndexTID =
          finalresultPoliTransaction?.MerchantData?.lastIndexOf("*");
        const repeatJsonTID = finalresultPoliTransaction?.MerchantData?.substring(
          startIndexTID,
          endIndexTID
        );
        alert("Repeatttttt")
        if (finalresultPoliTransaction.TransactionStatus == "Completed") {
          if (repeatJsonTID && parseInt(repeatJsonTID) > 0) {
            const payloadqueryStringFinal = `?PatientID=${pid}&electronicPaymentID=${repeatJsonTID}`;
            const responsePaymentFinal = await fetch(
              `https://devdhbconnect.vitonta.com/api/patient/SavePayment${payloadqueryStringFinal}`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Token: patienttoken,
                },
                redirect: "follow",
              }
            );
    
            var myHeaders2 = new Headers();
            myHeaders2.append("Token", patienttoken);
            myHeaders2.append("Content-Type", "application/json");
            const finalpaymentResult = await responsePaymentFinal.json();
            if (finalpaymentResult) {
              setSendingMedicationRepeatRequest(true);
              const requestOptions = {
                method: "POST",
                headers: myHeaders2,
                body: JSON.stringify(repeatPayload),
                redirect: "follow",
              };
    
              let statusMed;
              fetch(
                "https://devdhbconnect.vitonta.com/api/Patient/SendRepeatMedicationRequest",
                requestOptions
              )
                .then((response) => {
                  statusMed = response.status;
                  alert(response.status);
                  return response.json();
                })
                .then((result) => {
                  setSendingMedicationRepeatRequest(false);
                });
            }
          }
        }
        //   // repeatPayload = JSON.parse(repeatJson);
        // };
        // alert(window.location.search);
        // RepeatPoliTransaction()
      }, []);

  return (
  <View>
    <Text>
        Successful
    </Text>
  </View>
  );
};


export default PosStripe;
