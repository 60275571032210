import Settings from '../config/Settings';
import { GET_REMINDER, GET_REMINDER_SUCCESS, GET_REMINDER_MORE_SUCCESS, GET_REMINDER_FAILED, GET_REMINDER_FINISHIED } from '../constants/Constant'
import getUserId from "../core/GetToken";
import getAllReminders from '../services/ReminderServices';


export const fetchReminderFromApi = (pageSize, pageNumber) => {
    return (dispatch) => {
        dispatch(getReminder())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = {
                PatientID: item.userid,
                PageSize: pageSize,
                PageNumber: pageNumber,
            }
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(raw)
            };


            fetch(Settings.apiUrl + "Patient/PatientAlerts", requestOptions)
                .then(data => data.json())
                .then(json => {
                    
                    json[0].hasOwnProperty('entry') ? dispatch(getReminderSuccess(json[0].entry)) : dispatch(getReminderFinshed())
                })
                .catch((err) => {
                    
                    dispatch(getReminderFailed(err))
                })




            // getAllReminders(queryString)
            //     .then(data => data.json())
            //     .then(json => {
           
            //         json[0].hasOwnProperty('entry') ? dispatch(getReminderSuccess(json[0].entry)) : dispatch(getReminderFinshed())
            //     })
            //     .catch((err) => {
           
            //         dispatch(getReminderFailed(err))
            //     })
        })
    }
}


// export const fetchMoreReminderFromApi = (pageSize, pageNumber) => {
//     return (dispatch) => {
//         dispatch(getReminder())
//         getUserId().then((userInfo) => {
//             const item = JSON.parse(userInfo);
//             const queryString = '?Token=' + item.token + '&PatientID=' + item.userid + '&PageSize=' + pageSize + '&PageNumber=' + pageNumber;

//             getAllLetters(queryString)
//                 .then(data => data.json())
//                 .then(json => {

//                     json[0].hasOwnProperty('entry') ? dispatch(getReminderSuccessMore(json[0].entry)) : dispatch(getReminderFinshed())
//                 })
//                 .catch((err) => {

//                     dispatch(getReminderFailed(err))
//                 })
//         })
//     }
// }
//call api
const getReminder = () => {
    return {
        type: GET_REMINDER
    }
}

//load data
const getReminderSuccess = (data) => {
    return {
        type: GET_REMINDER_SUCCESS,
        data,
    }
}

//more
const getReminderSuccessMore = (data) => {
    return {
        type: GET_REMINDER_MORE_SUCCESS,
        data
    }
}

//data failure
const getReminderFailed = () => {
    return {
        type: GET_REMINDER_FAILED,
    }
}

//finishing
const getReminderFinshed = () => {
    return {
        type: GET_REMINDER_FINISHIED
    }
}


