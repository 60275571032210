import {
    GETTING_MEDS,
    GETTING_MEDS_SUCCESS,
    GETTING_MEDS_MORE_SUCCESS,
    GETTING_MEDS_FAILED,
    GETTING_RECENT_MEDS,
    GETTING_MEDS_RECENT_SUCESSS,
    GETTING_RECENT_MEDS_FAILED,
} from "../constants/Constant";
import getAllMedications from "../services/MedicationService";
import getUserId from "../core/GetToken";



export const fetchRecentMedsFromApi = (pageSize, pageNumber) => {
    return (dispatch) => {
        dispatch(getMed())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify(
                {
                    "PatientID": item.userid,
                    "PageSize": pageSize,
                    "PageNumber": pageNumber,
                    "TypeID": 2,
                }
            );

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };


            getAllMedications("", requestOptions)
                .then(data => data.json())
                .then(json => {
                   
                    json[0].hasOwnProperty('entry') ? dispatch(getMedSuccess(json)) : dispatch(getMedFinished())
                })
                .catch(err => dispatch(getMedFailed(err)))
        })
    }
}


//start getting data
const getMed = () => {
    return {
        type: GETTING_RECENT_MEDS
    }
}

//data is success
const getMedSuccess = (data) => {
    return {
        type: GETTING_MEDS_RECENT_SUCESSS,
        data
    }
}
//data failed
const getMedFailed = () => {
    return {
        type: GETTING_RECENT_MEDS_FAILED
    }
}

const getMedFinished = () => {
    return null;
}