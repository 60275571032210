import React from 'react'
import Avatar from 'antd/lib/avatar/avatar';
import { UserOutlined, HeatMapOutlined } from '@ant-design/icons';
const PrintOrganizationHeader = (props) => {
    return (
        <>
        {props?.organizationData &&  props?.organizationData?.length > 0 && props?.patientProfile?.length > 0 ?
            <div className={"" + (props.isPrintActive ? "d-flex" : "d-none")}>
                <div className="print-header lab-test-print-header-container">

                        {/* <div className="print-header-left lab-test-column">

                            <h4>{props.organizationData[0]?.resource?.name}</h4>
                            <span>{props.organizationData[0]?.resource?.address[0]?.text}</span>

                    </div> */}
                    <div className="print-header-center lab-test-column padding-left-right-ten">
                            <h5>{props.patientProfile.length > 0 ? props.patientProfile[0]?.resource?.name[0].text : null}</h5>
                            <span><span></span> {props.patientProfile.length > 0 ? props.patientProfile[0].resource?.extension[3].valueString : null}</span>
                            <span><span></span> {props.patientProfile.length > 0 ? props.patientProfile[0].resource?.telecom?.map(item => {
                            if (item?.use === "home" && item?.system === "phone") {
                                return item.value
                            }
                        }) : null}</span>
                            <span><span></span> {props.patientProfile.length > 0 ? props.patientProfile[0].resource?.telecom?.map(item => {
                            if (item?.system === "email") {
                                return item.value
                            }
                        }) : null}</span>

                        </div>
                        <div className="print-header-right lab-test-column padding-left-right-ten">
                            <h4>{props.organizationData[0]?.resource?.name}</h4>
                            <span>{props.organizationData[0]?.resource?.address[0]?.text}</span>
                            {props.rptDate && <span className='text-bolder'>Date Last Rx: {props.rptDate}</span>}

                    </div>
                </div>

                <hr />
            </div>:null}
        </>
    )
}

export default PrintOrganizationHeader
