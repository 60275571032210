import { GET_AVAILABLE_PRACTITIONER, GET_AVAILABLE_PRACTITIONER_SUCCESS, GET_AVAILABLE_PRACTITIONER_FAILED, GET_AVAILABLE_PRACTITIONER_CLEAN } from "../constants/Constant";

//inital state
const initalState = {
    AvailablePractitioner: [],
    isGetting: false,
    error: false
}

export default function allPractitionerReducer(state = initalState, action) {
    switch (action.type) {
        case GET_AVAILABLE_PRACTITIONER:
            return {
                ...state,
                isGetting: true
            }

        case GET_AVAILABLE_PRACTITIONER_SUCCESS:
            return {
                ...state,
                isGetting: false,
                AvailablePractitioner: action.data  // [...state.AllMedeications, ...action.data]

            }

        case GET_AVAILABLE_PRACTITIONER_FAILED:
            return {
                ...state,
                isGetting: false,
                AvailablePractitioner: [],
                error: true
            }
        case GET_AVAILABLE_PRACTITIONER_CLEAN:
            return {
                ...state,
                isGetting: false,
                AvailablePractitioner: [],
                error: false

            }

        default:
            return state
    }
}

