import AsyncStorage from "@react-native-async-storage/async-storage";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Settings from "../../../config/Settings";
import getUserId from "../../../core/GetToken";

export const GetLongTermDiagnosisFromAPI = createAsyncThunk(
    "getLongTermDiagnosis/Diagnosis",
    async (values, { dispatch, getState }) => {

        dispatch(getLongTermDiagnosis())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = {
                PatientID: item.userid,
                PageSize: values.pageSize,
                PageNumber: values.pageNumber,
                TypeID: 2,
            }
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(raw)
            };

            fetch(Settings.apiUrl + "Patient/Condition", requestOptions)

                .then(data => data.json())
                .then(json => {
                    json[0].hasOwnProperty('entry') ? dispatch(getLongTermDiagnosisSuccess(json[0].entry)) : dispatch(getLongTermDiagnosisFinished())
                })
                .catch(err => dispatch(getLongTermDiagnosisFailed(err)))

        })

    }

);

const GetLongTermDiagnosisSlice = createSlice({
    name: "getLongTermDiagnosis",
    initialState: {
        LongDiag: [],
        isFetching: false,
        error: false
    },
    reducers: {
        getLongTermDiagnosis: (state, action) => {
            state.LongDiag = []
            state.isFetching = true
            state.error = false
        },
        getLongTermDiagnosisSuccess: (state, action) => {
            state.LongDiag = action.payload
            state.isFetching = false
            state.error = false

        },
        getLongTermDiagnosisFailed: (state, action) => {
            state.LongDiag = []
            state.isFetching = false
            state.error = true
        },
        getLongTermDiagnosisFinished: (state, action) => {
            state.LongDiag = []
            state.isFetching = false
            state.error = false
        },

    },
});




export const {
    getLongTermDiagnosis, getLongTermDiagnosisFailed, getLongTermDiagnosisSuccess, getLongTermDiagnosisFinished
} = GetLongTermDiagnosisSlice.actions;
export default GetLongTermDiagnosisSlice.reducer;
