import React from 'react';
import { StyleSheet, Text, View, Image, Dimensions } from 'react-native';
import renderHTML from "react-render-html";

const DiagnosisHandler = props => {
    return (
        <div className="col-lg-12 ">
            <div className="card shadow p-0 mb-3 rounded appointment-card">
                <div className={props.mystyles}>
                    <div className='d-flex justify-content-between'>

                        <span className='diagnosis-handler-date'>{props.date}</span>
                    </div>
                </div>
                <div className="card-body column mb-0">


                    {/* <br /> */}
                    {/* <HTML source={{ html: props.comments.split('<br>')[1] }} imagesMaxWidth={Dimensions.get('window').width} /> */}
                    {/* {props.comments.split('<br>')[1] ? renderHTML(props.comments.split('<br>')[1]) : ""} */}
                    {props.comments ? renderHTML(props.comments) : ""}
                </div>
            </div>
        </div>
    );
}

export default DiagnosisHandler
