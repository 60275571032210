import React, { useEffect, useState } from 'react'
import { Modal, Input, notification } from "antd";
import { DatePicker } from "antd";



import ReCAPTCHA from 'react-google-recaptcha';
import dayjs from 'dayjs';
import Settings from '../../shared/config/Settings';


const { TextArea } = Input
const LoginHelpModal = ({ open, setVisible, handleCancel }) => {
    const [value, setValue] = useState()
    const [email, setEmail] = useState("")
    const [NHI, setNHI] = useState("")
    const [fName, setFName] = useState("")
    const [famName, setFamName] = useState("")
    const [DOB, setDOB] = useState("")
    const [help, setHelp] = useState("")
    const [mainMsg, setMainMsg] = useState("")

    const [emaErrMsg, setEmaErrMsg] = useState("")
    const [NHIErrMsg, setNHIErrMsg] = useState("")
    const [fNameErrMsg, setFNameErrMsg] = useState("")
    const [famNameErrMsg, setFamNameErrMsg] = useState("")
    const [DOBErrMsg, setDOBErrMsg] = useState("")
    const [helpErrMsg, setHelpErrMsg] = useState("")
    const [mMsgErrMsg, setmMsgErrMsg] = useState("")
    const [valiErrorCaught, setValiErrorCaught] = useState(false)
    const [isValidated, setIsValidated] = useState(false)

    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: 'Notification',
            description:
                message,
        });
    };
    const validateForm = () => {
        let result = false
        if (NHI?.trim() === "" && fName?.trim() === "") {
            // setEmaErrMsg("Either Email or NHI Number is Required")
            // setEmaErrMsg("Either Email or NHI Number is Required")
            setFNameErrMsg("Either of the above fields is required")
            result = true
        }
        if (email?.trim() === "") {
            setEmaErrMsg("Email is required")
            result = true
        }
        // if (fName === "") {
        //     setFNameErrMsg("Either of the above fields is required")
        //     result = true

        // }
        if (famName?.trim() === "") {
            setFamNameErrMsg("Family Name is Required")
            result = true

        }
        if (DOB === "") {
            setDOBErrMsg("Date of Birth is required")
            result = true

        }
        if (help?.trim() === "") {
            setHelpErrMsg("Subject is Required")
            result = true

        }
        if (mainMsg?.trim() === "") {
            setmMsgErrMsg("Message body is Required")
            result = true

        }
        return result
    }
    const getApiURL = () => {

        var myHeaders = new Headers();

        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify(
            {
                "Email": email
            }
        );

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_API_AUTH_IDENTITY_URL.replace("Authentication2","")}GetApiUrl`, requestOptions)
            .then(res => res.json())
            .then(data => {
                if (data?.APIURL !== "") {
                    sendHelpMessage(data?.APIURL, data?.PatientID)

                } else {
                    openNotificationWithIcon("error", "Sorry, we are currently unable to identify your account with the details provided. Please double-check the information for accuracy. If the issue persists, kindly contact your practice directly for further assistance.")

                }
            })
            .catch(err => {
                openNotificationWithIcon("error", "Some error occured")


            })
    }
    const handleOk = () => {
        let errCaught = validateForm()

        if (errCaught) {
            setValiErrorCaught(!valiErrorCaught);
        }
        else {
            getApiURL()

        }
    }
    const sendHelpMessage = (apiUrl, patientID) => {
        let raw = {
            "PatientID": patientID,        //send this empty
            "EmailAddress": email,   //email address
            "UID": NHI,       //NHI number
            "FirstName": fName,     //first name
            "FamilyName": famName,  //family name
            "DOB": dayjs(DOB).format("YYYY-MM-DD"),       //date of birth
            "MessageSubject": help,   //Message Subject
            "MessageBody": mainMsg    //body goes here
        }
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: 'POST',
            body: JSON.stringify(raw),
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${apiUrl}SendHelpMessage`, requestOptions)
            .then(response => response.json())
            .then(result => {

                openNotificationWithIcon(result.includes("Unable to send message") ? "error" : "success", result)

                handleCancel()
                onClose()
            }
            )

            .catch(error => openNotificationWithIcon("error", error));
    }

    function onRecaptchaChange(value) {
        setIsValidated(false)
    }

    const onClose = () => {
        setEmail("")
        setNHI("")
        setFName("")
        setFamName("")
        setDOB("")
        setHelp("")
        setMainMsg("")

    }
    function onRecaptchaExpired(value) {
        setIsValidated(true)
    }
   
    return (
        <>
       
            <Modal
            onClick={e => e.stopPropagation()}
            backdrop="static"
                open={open}
                title="Login Help"

                onOk={handleOk}
                onCancel={() => {
                    handleCancel()
                    onClose()
                }}
                okText="Ask For Help"
                centered
                footer={[
                    <button className="btn btn-outline" onClick={() => { handleCancel(); onClose() }}>Close</button>,
                    <button className="btn btn-primary" disabled={isValidated} onClick={handleOk}>OK</button>,
                ]}
                okButtonProps={{ disabled: !isValidated }}
            >
                <div className="modalBody">

                    <Input autoComplete="off" className="my-2" placeholder="Please Enter Your Email" value={email} onChange={(e) => {
                        setEmail(e.target.value);
                        setEmaErrMsg("");
                        setFNameErrMsg("");
                    }} />
                    <p className="errorMsg text-danger m-0">{emaErrMsg}</p>


                    <Input autoComplete="off" className="my-2" placeholder="Please Enter Your NHI Number" value={NHI} onChange={(e) => { setNHI(e.target.value); setNHIErrMsg(""); setFNameErrMsg("") }} />
                    <p className="errorMsg text-danger m-0">{NHIErrMsg}</p>
                    <div className="text-center m-0">

                        <span className="help-OR-text">OR</span>
                    </div>

                    <Input autoComplete="off" className="my-2" placeholder="First Name" value={fName} onChange={(e) => { setFName(e.target.value); setFNameErrMsg("") }} />
                    <p className="errorMsg text-danger m-0">{fNameErrMsg}</p>

                    <Input autoComplete="off" className="my-2" placeholder="Family Name" value={famName} onChange={(e) => { setFamName(e.target.value); setFamNameErrMsg("") }} />
                    <p className="errorMsg text-danger m-0">{famNameErrMsg}</p>

                    <DatePicker
                        autoComplete="off"
                        inputReadOnly
                        style={{ background: "white" }}
                    filterDate={d => {
                        return new Date() > d;
                      }}
                    isClearable
                        className="ant-input my-2 login-help-modal-date-picker"
                    onChange={(e) => { setDOB(e); setDOBErrMsg("") }}
                    selected={DOB}
                        placeholder="Date of Birth"
                        format="DD-MM-YYYY"
                        value={DOB}

                    />

                    {/* <DatePicker className="my-2 bg-light" placeholder="Date of Birth" value={DOB} onChange={(e) => { setDOB(e); setDOBErrMsg("") }} /> */}
                    <p className="errorMsg text-danger m-0">{DOBErrMsg}</p>

                    <Input autoComplete="off" className="my-2" placeholder="Subject" value={help} onChange={(e) => { setHelp(e.target.value); setHelpErrMsg("") }} />
                    <p className="errorMsg text-danger m-0">{helpErrMsg}</p>

                    <TextArea
                        autoComplete="off"
                        className="my-2"
                        value={mainMsg}
                        onChange={(e) => { setMainMsg(e.target.value); setmMsgErrMsg("") }}
                        placeholder="Type Message"
                        autoSize={{
                            minRows: 3,
                            maxRows: 5,
                        }}
                    />
                    <p className="errorMsg text-danger m-0">{mMsgErrMsg}</p>
                    <ReCAPTCHA
                        className='d-inline-block'
                        sitekey={`${process.env.REACT_APP_CAPTCHA_SITEKEY}`}
                        onChange={onRecaptchaChange}
                        onExpired={onRecaptchaExpired}
                        badge={'bottomleft'}
                    />

                </div>

            </Modal>
        </>
    )
}

export default LoginHelpModal
