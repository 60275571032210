


import React from 'react';
import { StyleSheet, Text, View, Image, Dimensions } from 'react-native';
const ImmuneHandler = props => {
    return (
        <div className="ind-med col-lg-12">
            <div className="card shadow p-0 mb-3 rounded immunisation-container-margin">
                <div className={props.styles}>
                    <div className='immunisation-heading-container' >

                        <div className="med-heading med-heading-main" ><b>{props.heading}</b></div>
                        <p className="card-text">
                            {/* <i className="mdi mdi-24px mdi-clock-fast" /> */}
                            {/* <b>{props.datedue}</b> */}
                            </p>
                        {/* <div className="med-date med-date-right" ><b>{props.date}</b></div> */}
                    </div>
                </div>
                <div className="card-body mb-0">
                <p className="card-title mb-2"><span className="text-bolder"> <b>Due On: </b>{props.datedue}</span></p>
                    <p className="card-title mb-2"><span className="text-bolder"><b>Indication:</b></span> {props.indication}</p>
                    <p className="card-title mb-2"><span className=""><b>Vaccinator:</b></span> {props.vacc}</p>
                    <p className="card-title"><span className=""><b>Description:</b></span> {props.vaccineName}</p>
                    {props.immuneType == "administered" && <p className="card-title  mt-2"> 
                    {/* <i className="mdi mdi-24px mdi-clock-fast" /> */}
                        <b>Administered on: </b>
                        {props.dateAdministered}</p>}
                    {props.vaccOutcome != "" && <p className="card-title mt-2"><span className=""><b> Outcome:</b> </span>{props.vaccOutcome}</p>}
                </div>
            </div>
        </div>
    );
}

export default ImmuneHandler