import React, { useState, useEffect } from 'react';
import { Link, useLocation, useParams } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { clearUserState, fetchUserInformation, fetchUserInformationError, LogoutUser } from "../../shared/actions/AuthActions";
import { AuthContext } from "../../shared/core/context";
import getUserId from "../../shared/core/GetToken";
//import FlatList from 'flatlist-react';

import { View, TouchableOpacity, FlatList, ActivityIndicator, Text } from "react-native-web";
import { emailValidator, getInitials, passwordValidator } from '../../shared/core/utils';
import ProxyAccountWebHandler from "../components/ProxyAccountWebHandler";
import { Redirect, useNavigate } from "react-router-dom";

import AsyncStorage from "@react-native-async-storage/async-storage";

import { Modal, Button } from "react-bootstrap";
import ForgetPassword from './ForgetPassword';
import changePasswrodService from '../../shared/services/ChangePasswrod';
import Settings from '../../shared/config/Settings';
import indicilogo from '../assets/indicilogo.png'
import covidlogo from '../assets/covidlogo.png'
import LoginHelpModal from './LoginHelpModal';

import ReCAPTCHA from "react-google-recaptcha";
import { fetchApplicationSettingFromApi, RefreshApplicationSettings } from '../../shared/actions/GetApplicationSettingAction';
import { fetchIdentityServerApiUrl, resetIdentityServerData } from '../../shared/actions/IdentityServerActions';
import { refreshTokenValidatorData, validatePatientTokenFromApi } from '../../shared/actions/TokenValidActions';
import { refreshProxyListData } from '../../shared/actions/ProxyActions';
import { Checkbox } from 'antd';
import { isAndroid, isIOS, isMobile } from 'react-device-detect';
import { AuthenticateUserAPI } from '../../shared/reduxToolkit/actions/AuthenticateActions/AuthenticateUser';
import { actionAPI } from '../../shared/reduxToolkit/actionAPI';
//export default function Login() {
const Login = props => {
  const linkHistory = useNavigate();

  useEffect(() => {


    //$("#email2").bind("paste", function () { return false; });
    // $("#email2").bind("copy", function () { return false; });

    //document.getElementById("mPassword").bind("paste", function () { return false; });
    //document.getElementById("mPassword").bind("copy", function () { return false; });

    //const myInput = document.getElementById('mPassword');
    //myInput.onpaste = e => e.preventDefault();
    //myInput.oncopy = e => e.preventDefault();

    // props.getProxy();
  }, []);

  const people = [
    { firstName: 'Elson', lastName: 'Correia', info: { age: 24 } },
    { firstName: 'John', lastName: 'Doe', info: { age: 18 } },
    { firstName: 'Jane', lastName: 'Doe', info: { age: 34 } },
    { firstName: 'Maria', lastName: 'Carvalho', info: { age: 22 } },
    { firstName: 'Kelly', lastName: 'Correia', info: { age: 23 } },
    { firstName: 'Don', lastName: 'Quichote', info: { age: 39 } },
    { firstName: 'Marcus', lastName: 'Correia', info: { age: 0 } },
    { firstName: 'Bruno', lastName: 'Gonzales', info: { age: 25 } },
    { firstName: 'Alonzo', lastName: 'Correia', info: { age: 44 } }
  ]


  const renderPerson = (person, idx) => {
    return (

      <li onClick={() => listOnClick(person.resource.id)} key={idx}>

        <b>{person.resource.id} </b> (<span>Testing</span>)

      </li >

    );
  }


  const listOnClick = (id) => {
    alert(id);
  }

  const [isRedirected, setIsRedirect] = useState(false)
  const { isLoggedIn, isLoggin, AuthUser, error, passworderror, loginFailed, userPasswordStatus } = useSelector(state => state.AuthenticateUser);
  const { isGettlistPrpxy } = useSelector(state => state.ProxyUsers)
  const { isAvailable, isLoadingAppsetting } = useSelector(state => state.AppSettings)
  const { signIn, signOut } = React.useContext(AuthContext);
  const [Proxy, setProxy] = useState('');
  const [userAgrement, setUserAgrement] = useState(false)
  const [privacyStatementCheck, setPrivacyStatemCheck] = useState(false)
  const [firstState, setFirstState] = useState(false);
  const [isCaptchaVisible, setIsCaptchaVisible] = useState(false)
  const [helpModalOpen, setHelpModalOpen] = useState(false)
  const [wrongAttemptCount, setWrongAttemptCount] = useState(0)
  const [passwordChangeErrMsg, setPasswordChangeErrMsg] = useState("")

  const [username, setUsername] = useState("")
  const [userPassword, setUserPassword] = useState("")
  const [isLoginLoading, setIsLoginLoading] = useState(false)
  const [isLoginIdentityLoading, setIsLoginIdentityLoading] = useState(false)
  const [loginPasswordErr, setLoginPasswordErr] = useState("")

  const apiDispatcher = useDispatch()
  useEffect(() => {
    setLoginPasswordErr(passworderror)

    // if (loginPasswordErr == "" || passworderror.indexOf("no access") > -1) {
    // }
  }, [passworderror])
  useEffect(() => {

    setLoginPasswordErr("")
  }, [])
  const identityServercall = (useName, password) => {
    setIsLoginIdentityLoading(true)
    // setLoginPasswordErr("")

    var myHeaders = new Headers();

    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(
      {
        "Username": useName,
        "Password": password
      }
    );

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_API_AUTH_IDENTITY_URL}`, requestOptions)
      .then(res => res.json())
      .then(data => {
        if (data?.APIURL !== "") {
          setIsLoginIdentityLoading(false)

          Settings.apiUrl = data?.APIURL
          localStorage.setItem('ApiBaseUrl', data?.APIURL);



        }
        if (data?.Status === "User not authenticated.") {
          setIsLoginIdentityLoading(false)
          // setLoginPasswordErr("Username or Password is incorrect")
          apiDispatcher(actionAPI.fetchUserInformationError("Username or Password is incorrect"))
        } else {
          apiDispatcher(actionAPI.AuthenticateUserAPI({ username: useName, password: password }))
        }
      })
      .catch(err => {
        setIsLoginIdentityLoading(false)
        setLoginPasswordErr("Some error occured, Please contact practice for further assistance")

      })
  }


  const processLogin = async (e) => {
    setIsLoginLoading(true)
    let useName = document.getElementById("mUserName").value;
    let password = document.getElementById("mPassword").value;


    const emailError = emailValidator(useName?.trim());
    const pawrdEror = passwordValidator(password?.trim());

    if (emailError || pawrdEror) {
      document.getElementById("errorMessage").innerHTML = "You have entered an invalid email address.";
      setIsLoginLoading(false)
      return;
    }
    document.getElementById("errorMessage").innerHTML = "";
    setUsername(useName)
    setUserPassword(password)
    // var myHeaders = new Headers();
    // myHeaders.append("Content-Type", "application/json");
    // myHeaders.append("Access-Control-Allow-Origin", "*");

    // var raw = JSON.stringify({
    //   "UserName": useName,
    //   "Password": password
    // });

    // var requestOptions = {
    //   method: 'POST',
    //   headers: myHeaders,
    //   body: raw,
    //   redirect: 'follow',
    //   mode: 'cors',

    // };

    // const IdentityCall = await fetch("https://stagingidentityserver.vitonta.com/api/Authentication", requestOptions);
    // const IdentityCallResult = await IdentityCall.json();
    // if (IdentityCallResult && IdentityCallResult.ACCAPIURL) {

    //   sessionStorage.setItem("ACCAPIURL", IdentityCallResult.ACCAPIURL);
    //     Settings.apiUrl = IdentityCallResult.ACCAPIURL;
    //     props.getUserToken(useName, password)
    // }
    // else
    // {
    //   // set
    //   // loginFailed && <p id="userName_pass_Incorrect">{passworderror}</p>
    // }



    // fetch("https://stagingidentityserver.vitonta.com/api/Authentication", requestOptions
    // )
    //   .then(response => response.json())
    //   .then(result => {
    //     // setShow(true)  
    //     if (result && result.APIURL) {
    //       Settings.apiUrl = result.APIURL
    //       props.getUserToken(useName, password)
    //     }

    //   })
    //   .catch(error => props.getUserToken(useName, password));

    // .catch(error => {});
    if (process.env.REACT_APP_API_AUTH_IDENTITY_URL?.indexOf("devidentityserver") >= 0) {

      Settings.apiUrl = process.env.REACT_APP_API_URL
      localStorage.setItem('ApiBaseUrl', process.env.REACT_APP_API_URL);

      apiDispatcher(actionAPI.AuthenticateUserAPI({ username: useName, password: password }))

    } else {
      identityServercall(useName, password)
    }

    //for normal login without IS
    //  props.getUserToken(useName, password)
    //props.getIdentityUrl(useName, password)
  }

  // useEffect(() => {
  //   if (identityApiDataSuccess && identityApiData) {
  //     props.getUserToken(username, userPassword)

  //   }
  // }, [identityApiDataSuccess])

  const [showReset, setShowReset] = useState(false);
  const [show, setShow] = useState(false);

  const [isResetPassword, setIsResetPassword] = useState(false)
  const [termnConditonVisible, setTermnConditonVisible] = useState(false)


  const handleClose = () => setShow(false);
  // this method is used for to show the handleclose terma and conditon modal 
  // this is not used now because we add the check box in the reset passwrod modal.
  const handleCloseTemnCond = () => {
    // props.clearResetState()
    apiDispatcher(actionAPI.clearUserState())
    setShowReset(false)
    setTermnConditonVisible(false)
    getProxyUsersApiCall(AuthUser[0].extension[0].valueString, AuthUser[0].id)

  };
  const handleCloseReset = () => {
    // props.clearResetState()
    apiDispatcher(actionAPI.clearUserState())
    setShowReset(false)
    // use for to show the term and condition modal
    // setTermnConditonVisible(true)
    getProxyUsersApiCall(AuthUser[0].extension[0].valueString, AuthUser[0].id)

  };

  const handleShow = () => setShow(true);




  useEffect(() => {

  }, [showReset])

  const getProxyUsersApiCall = (userToken, userId) => {
    // alert("Proxy Check Called==>" + userToken + ",,," + userId);

    // var formdata = new FormData();
    var myHeaders = new Headers();
    myHeaders.append("Token", userToken)
    myHeaders.append("Content-Type", "application/json")
    var raw = JSON.stringify({
      PatientID: userId,
    })
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow',
      body: raw
    };

    fetch(Settings.apiUrl + "Patient/ProxyUsers?", requestOptions)
      .then(response => response.json())
      .then(result => {

        if (result[0].total === 1) {

          getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);

            //if the account self is patient
            if (item.userid === result[0].entry[0].resource.id) {
              //save single patient information
              // _saveData(item.token, item.userid, item.email, "No", item.username, item.parentid, result[0].entry[0].resource.extension[0].valueString, item.parentname)
              _saveData(item.token, item.userid, item.email, "No", item.username, item.parentid, result[0].entry[0].resource.extension[0].valueString, item.parentname)


            }
            else {
              _saveDataSingleProxy(item.token, result[0].entry[0].resource.id, item.email, "Yes", result[0].entry[0].resource.name[0].text, item.parentid, result[0].entry[0].resource.extension[0].valueString, item.parentname)


            }
          })
        }
        else {
          setProxy(result[0].entry)
          setShow(true)
          setFirstState(true);
        }
      })
      .catch(error => { })
      .finally(setIsLoginLoading(false));
  }


  const _setDataState = async (proxyUserId, proxyUserName, proxyid) => {
    let jsonValue = '';
    debugger
    getUserId().then((userInfo) => {
      const item = JSON.parse(userInfo);

      _saveData(item.token, proxyUserId, item.email, "Yes", proxyUserName, item.parentid, proxyid, item.parentname)

    })
  };


  const _saveDataSingleProxy = async (token, proxyUserId, pemail, isProxy, proxyUserName, parentid, proxyid, parentName) => {

    // alert(isProxy)
    await AsyncStorage.removeItem('@userToken');
    try {
      const jsonValue = JSON.stringify({ "token": token, "userid": proxyUserId, "email": pemail, "proxy": "Yes", "username": proxyUserName, "parentid": parentid, "proxyid": proxyid, "parentname": parentName });
      await AsyncStorage.setItem('@userToken', jsonValue)
      loadNext();
    } catch (error) {
      // Error saving data
    }
  }

  const _saveData = async (token, proxyUserId, pemail, isProxy, proxyUserName, parentid, proxyid, parentName) => {

    // alert(isProxy)
    await AsyncStorage.removeItem('@userToken');
    try {
      const jsonValue = JSON.stringify({ "token": token, "userid": proxyUserId, "email": pemail, "proxy": isProxy, "username": proxyUserName, "parentid": parentid, "proxyid": proxyid, "parentname": parentName });
      await AsyncStorage.setItem('@userToken', jsonValue)
      loadNext();
    } catch (error) {
      // Error saving data
    }
  }
  //************************************** End Proxey account **************************************/

  const loadReset = () => {
    // return (<Redirect to={"/resetpassword"} />)
    setShowReset(!showReset)
  }
  const closeResetModal = () => {
    // setShowReset(() => {
    //     return !showReset
    // })

    apiDispatcher(actionAPI.clearUserState())

    apiDispatcher(actionAPI.LogoutUser())
    setShowReset(false)
    setShowReset((state) => {
      return state
    })

    signOut();
    // window.location.reload();




  }
  // let history = useNavigate()
  const loadNext = () => {

    setIsRedirect(true);
    setIsRedirect((state) => {
      return state;
    });
    signIn('baseuser');
    // linkHisto  linkHistory.push("/")
    // window.location.reload()
    apiDispatcher(actionAPI.ValidateTokenAPI(linkHistory))

    // return (<Redirect to={"/"} />)
  }


  const resetPasswordFunc = async () => {
    //    alert("ok")

    let password = document.getElementById("password").value;
    let confirmPassword = document.getElementById("confirmPassword").value;

    const passwordError = passwordValidator(password);
    const cnfrmPaswrd = passwordValidator(confirmPassword);
    if (passwordError) {
      setPasswordChangeErrMsg("Password is Required ");
      return;
    }
    if (cnfrmPaswrd) {
      setPasswordChangeErrMsg("Confirm Password is Required ");
      return;
    }
    if (password !== confirmPassword) {
      setPasswordChangeErrMsg("Password and Confirm Password does not match");
      return;
    }

    //check for lower case
    if (!password.match(/[a-z]/)) {
      setPasswordChangeErrMsg("Password must contain at least one lower case letter.");
      // passForm.passInput.focus();
      return;
    }

    //Validating length
    if (password.length < 8) {
      setPasswordChangeErrMsg("Your password has less than 8 characters.");
      return;
    }

    //check for upper ase
    if (!password.match(/[A-Z]/)) {
      setPasswordChangeErrMsg("Password must contain at least one upper case letter.");
      // passForm.passInput.focus();
      return;
    }
    var patt = new RegExp("^(?=(.*[a-zA-Z]){1,})(?=(.*[0-9]){2,}).{8,}$");
    //check for number
    if (!patt.test(password)) {
      setPasswordChangeErrMsg("Password must contain at least two number.");
      // passForm.passInput.focus();
      return;
    }

    if (!userAgrement) {
      setPasswordChangeErrMsg("You need to mark that you agree before you can proceed.");
      return;
    }
    document.getElementById("errorMessageReset").innerHTML = "";
    var userInfo = (await AsyncStorage.getItem("@userPassordReset")) || "none";
    // check remining confirm and passweordk
    if (userInfo === "none") {
      return
    }
    setIsResetPassword(true);
    const item = JSON.parse(userInfo);
    var myHeaders = new Headers();
    myHeaders.append("Token", item.token);
    myHeaders.append("Content-Type", "application/json");
    const queryString = "?Token=" + item.token;



    var jsonPa = JSON.stringify({ PatientID: item.userid, Password: password });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: jsonPa,
      redirect: 'follow'
    };

    fetch(Settings.apiUrl + "Patient/ChangePassword", requestOptions)
      .then((data) => data.json())
      .then((json) => {
        // {"StatusCode":1,"StatusMessage":"Password updated successfully."}
        if (json.StatusCode == "1") {
          // props.clearResetState();
          apiDispatcher(actionAPI.clearUserState())

          setShowReset(false);
          // for activity indicatorr
          setIsResetPassword(false);
          document.getElementById("errorMessageReset").innerHTML = "";
          handleCloseReset();
          // handleCloseReset()
        }
        // {"StatusCode":3,"StatusMessage":"three time password error try to some differect passsword"}
        if (json.StatusCode == "3") {
          setIsResetPassword(false);
          document.getElementById("errorMessageReset").innerHTML =
            json.StatusMessage;
        }
      })
      .catch((err) => {
        setIsResetPassword(false);
        handleCloseReset();
        document.getElementById("errorMessageReset").innerHTML =
          "Some thing went wrong please try agian.";

      });


  };



  const clearCache = async () => {
    try {
      await AsyncStorage.clear()
    } catch (e) {
      // clear error
    }

  }



  const [showincon, setshowicon] = useState('mdi mdi-eye')
  const [showReseticon, setshowReseticon] = useState('mdi mdi-eye')
  const [showconfirmResticcon, setshowconfirmResticcon] = useState('mdi mdi-eye')
  const showPassword = () => {
    var x = document.getElementById("mPassword");
    if (x.type === "password") {
      setshowicon('mdi mdi-eye-off')
      x.type = "text";
    } else {
      setshowicon('mdi mdi-eye')
      x.type = "password";
    }
  }
  const resetPasswordShow = (elementId) => {
    var x = document.getElementById(elementId);
    if (x.type === "password") {

      setshowReseticon('mdi mdi-eye-off')
      x.type = "text";
    } else {
      setshowReseticon('mdi mdi-eye')
      x.type = "password";
    }
  }
  const resetConfirmPasswordShow = (elementId) => {
    var x = document.getElementById(elementId);
    if (x.type === "password") {

      setshowconfirmResticcon('mdi mdi-eye-off')
      x.type = "text";
    } else {
      setshowconfirmResticcon('mdi mdi-eye')
      x.type = "password";
    }
  }
  // if (isRedirected === true) {
  //     return <Redirect to={'/'} />
  // }
  const handleCheckTermandCondition = () => {
    setUserAgrement(!userAgrement)
  }
  const handlePrivacyStatement = () => {
    setPrivacyStatemCheck(!privacyStatementCheck)
  }
  const loginOnEnter = e => {
    if (e.keyCode === 13) {

      e.preventDefault();
      processLogin(e)
      //alert("Enter Key is done")
      // handleSubmit();
    }
  };
  const handleHelpOk = () => {
    setHelpModalOpen(false)
  }
  const handleHelpCancel = () => {
    setHelpModalOpen(false)
  }
  useEffect(() => {
    if (loginFailed) {
      setWrongAttemptCount(wrongAttemptCount + 1)
      setIsLoginLoading(false)
    }
    if (isLoggedIn) {
      setWrongAttemptCount(0)

    }
  }, [loginFailed])
  useEffect(() => {
    if (isLoggedIn) {
      apiDispatcher(actionAPI.GetAppSettingAPI())
    }
  }, [isLoggedIn])
  useEffect(() => {
    if (!userPasswordStatus && !show && isLoggedIn && isAvailable) {
      getProxyUsersApiCall(AuthUser[0].extension[0].valueString, AuthUser[0].id)
    }

  }, [userPasswordStatus, show, isLoggedIn, isAvailable])
  const search = useLocation().search
  const navigate = useNavigate()
  useEffect(() => {
    const q = new URLSearchParams(search).get('q');
    if (q) {
      navigate(`/loginSwitch/${q}`)
    }
  }, [])
  useEffect(() => {
    apiDispatcher(actionAPI.clearUserState())

    apiDispatcher(actionAPI.refreshProxyListData())
    apiDispatcher(actionAPI.RefreshAppSettingData())
    // props.resetIdentityData()
    apiDispatcher(actionAPI.refreshTokenValidatorData())
    localStorage.clear()
  }, [])
  return (
    <div className="Login-Page justify-content-center align-items-center">
    <div className="login-content d-flex align-items-center justify-content-center flex-col h-100">
        <div className=" d-flex bg-color-login  position-relative">
            <div className="alert d-flex position-absolute align-items-center  justify-content-between flex-row-reverse fw-bold " id="alertpopup">
                <span className="closebtn close-custom-btn pointer">&times;</span>
                Quality Improvement of Health Services Through Kaizen Approach JFY 2024 From 4 July to 2 jan In Japan
              </div>
            <div className="d-flex align-items-center justify-content-center login-image-side ">
                <img src="assets/images/login-side-image.svg" className="login-sideimg" alt="Login"/>
            </div>
            <div className="login-form-side">
                <div className="login-fields-width">
                    <div className="d-flex flex-column align-items-center mb-5 justify-content-center">
                    <img src="assets/images/indicilogo.svg" className="login-sideimg" alt="Login"/>
                    </div>
                    <div className="mt-3 w-100">
                        <div className="d-flex flex-column gap20 login-fields">
                            <div className="text-left position-relative">
                                <div>
                                    <input placeholder="User Name" type="Text" className="login-input" />
                                </div>
                                <span className="img-fields-icon pointer">
                                    <img src="assets/images/userblue.svg" className="w-18px" alt=""/>
                                </span>
                            </div>
                            <div className="text-left position-relative">
                                <div>
                                    <input placeholder="Password" type="password" className="login-input" />
                                </div>
                                <span className="img-fields-icon pointer">
                                    <img src="./assets/images/lockblue.svg" className="w-18px"s alt=""/>
                                </span>
                            </div>
                            <div className=" forgetpassword w-100">
                                <span className="ms-auto">Forget Password?</span>
                            </div>
                            <button  type="button"
                                className="d-flex align-items-center justify-content-center btn loginbtn text-white btn-lg btn-block mt-3"><span>LOGIN</span></button>
                        </div>
                    </div>
                    <h2 className="or-text mt-5">
                        <div className="d-flex gap5 align-items-center"><span className="w-50 border"></span> OR <span
                                className="border w-50"></span>
                             </div>
                    </h2>
                    <div className="mt-5 thumbprintsections d-flex align-items-center justify-content-center w-100">
                        <div className="d-flex flex-column gap20 login-fields">


                            <span className=" pointer">
                                <img src="assets/images/thumbprint.svg" className="w-60" alt=""/>
                            </span>


                        </div>
                    </div>

                    <div className="login-footer mt-5 text-center  w-100">
                        V2.0.22 - Powered By Valentia Technologies NZ Limited

                    </div>
                </div>
            </div>

        </div>
    </div>

</div>
  );
}



function mapStateToProps(state) {
  return {
    AuthUser: state.AuthUser,
    ApplicationSettings: state.ApplicationSettings,
    Proxy: state.Proxy,
    IdentityServerApiData: state.IdentityServerReducer,
  }
}

function mapDispatchToProps(disptach) {
  return {
    getUserToken: (email, password, q = "") => disptach(fetchUserInformation(email, password, q)),
    getUserTokenErr: (msg) => disptach(fetchUserInformationError(msg)),
    clearResetState: () => disptach(clearUserState()),
    endUserSession: () => disptach(LogoutUser()),
    getAppSetting: () => disptach(fetchApplicationSettingFromApi()),
    getIdentityUrl: (email, password) => disptach(fetchIdentityServerApiUrl(email, password)),
    validateToken: (history) => disptach(validatePatientTokenFromApi(history)),

    resetTokenData: () => disptach(refreshTokenValidatorData()),
    resetProxyData: () => disptach(refreshProxyListData()),
    resetAppSettings: () => disptach(RefreshApplicationSettings()),
    resetIdentityData: () => disptach(resetIdentityServerData()),
    resetAuthUserData: () => disptach(clearUserState()),
  }
}


//export default Login;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login)