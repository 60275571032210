import AsyncStorage from "@react-native-async-storage/async-storage";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Settings from "../../../config/Settings";
import getUserId from "../../../core/GetToken";

export const GetTestResultsFromAPI = createAsyncThunk(
    "getTestResults/Reports",
    async (values, { dispatch, getState }) => {

        dispatch(getTestResults())
        let activeFilter = 2
        if (values.status == "Read") {
            activeFilter = 1
        } else if (values.status == "Unread") {
            activeFilter = 0
        } else {

            activeFilter = 2
        }
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = {
                PatientID: item.userid,
                PageSize: values.pageSize,
                PageNumber: values.pageNumber,
                searchText: values.searchtext,
                fromdate: values.searchFromDate,
                todate: values.searchToDate,
                readfromportal: activeFilter,
            }
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(raw)
            };





            fetch(Settings.apiUrl + "Patient/LabResults", requestOptions)
                .then(data => data.json())
                .then(json => {

                    dispatch(getTestResultsSuccess(json ? json : []))
                })
                .catch(err => dispatch(getTestResultsFailed(err)))

        })

    }

);

const GetTestResultsSlice = createSlice({
    name: "getTestResults",
    initialState: {
        Reports: null,
        isFetching: false,
        error: false
    },
    reducers: {
        getTestResults: (state, action) => {
            state.isFetching = true
            state.error = false
        },
        getTestResultsSuccess: (state, action) => {
            state.Reports = action.payload
            state.isFetching = false
            state.error = false

        },
        getTestResultsFailed: (state, action) => {
            state.isFetching = false
            state.error = true
        },
        getTestResultsFinished: (state, action) => {
            state.Reports = null
            state.isFetching = false
            state.error = false
        },

    },
});




export const {
    getTestResults, getTestResultsFailed, getTestResultsSuccess, getTestResultsFinished
} = GetTestResultsSlice.actions;
export default GetTestResultsSlice.reducer;
