import AsyncStorage from "@react-native-async-storage/async-storage";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Settings from "../../../config/Settings";
import getUserId from "../../../core/GetToken";

export const GetPharmaciesDataFromAPI = createAsyncThunk(
    "getPharmaciesData/Medication",
    async (values, { dispatch, getState }) => {

        dispatch(getPharmaciesData())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = {
                PatientID: item.userid,
                PageSize: 1965,
                PageNumber: 1,
            }
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(raw)
            };

            fetch(Settings.apiUrl + "Patient/Pharmacies", requestOptions)
                .then(response => response.json())
                .then(result => {
                    dispatch(getPharmaciesDataSuccess(result[0]?.entry))
                })
                .catch(error => {
                    dispatch(getPharmaciesDataFailed())

                });

        })


    }

);

const GetPharmaciesDataSlice = createSlice({
    name: "getPharmaciesData",
    initialState: {
        Pharmacy: [],
        isFetching: false,
        error: false
    },
    reducers: {
        getPharmaciesData: (state, action) => {
            state.Pharmacy = []

            state.isFetching = true
            state.error = false
        },
        getPharmaciesDataSuccess: (state, action) => {
            state.Pharmacy = action.payload
            state.isFetching = false
            state.error = false

        },
        getPharmaciesDataFailed: (state, action) => {
            state.Pharmacy = []
            state.isFetching = false
            state.error = true
        },
        getPharmaciesDataFinished: (state, action) => {
            state.Pharmacy = []
            state.isFetching = false
            state.error = false
        },

    },
});




export const {
    getPharmaciesData, getPharmaciesDataFailed, getPharmaciesDataSuccess, getPharmaciesDataFinished
} = GetPharmaciesDataSlice.actions;
export default GetPharmaciesDataSlice.reducer;
