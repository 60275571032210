import AsyncStorage from "@react-native-async-storage/async-storage";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Settings from "../../../config/Settings";
import getUserId from "../../../core/GetToken";

export const GetAppointmentTypesFromAPI = createAsyncThunk(
    "getAppointmentTypes/Appointment",
    async (values, { dispatch, getState }) => {

        dispatch(getAppointmentTypes())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = {
                PatientID: item.userid,
                TypeID: 12,
            }
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(raw)
            };


            fetch(Settings.apiUrl + "IndiciLookup", requestOptions)
                .then(response => response.json())
                .then(json => {
                    json[0].hasOwnProperty('entry') ? dispatch(getAppointmentTypesSuccess(json[0].entry)) : dispatch(getAppointmentTypesFailed())
                })
                .catch(err => dispatch(getAppointmentTypesFailed(err)))

        })

    }

);

const GetAppointmentTypesSlice = createSlice({
    name: "getAppointmentTypes",
    initialState: {
        isLoadingAPPType: false,
        AppointmentTypes: [],
        error: false,
        isAvailable: false
    },
    reducers: {
        getAppointmentTypes: (state, action) => {
            state.isLoadingAPPType = true
            state.AppointmentTypes = []
            state.error = false
            state.isAvailable = false
        },
        getAppointmentTypesSuccess: (state, action) => {
            state.isLoadingAPPType = false
            state.AppointmentTypes = action.payload
            state.error = false
            state.isAvailable = true
        },
        getAppointmentTypesFailed: (state, action) => {
            state.isLoadingAPPType = false
            state.AppointmentTypes = []
            state.error = true
            state.isAvailable = false
        },
        getAppointmentTypesFinished: (state, action) => {
            state.isLoadingAPPType = false
            state.AppointmentTypes = []
            state.error = false
            state.isAvailable = false
        },

    },
});




export const {
    getAppointmentTypes, getAppointmentTypesFailed, getAppointmentTypesSuccess, getAppointmentTypesFinished
} = GetAppointmentTypesSlice.actions;
export default GetAppointmentTypesSlice.reducer;
