import { AuthenticateUserAPI, LogoutUser, clearUserState, fetchUserInformationError } from "./actions/AuthenticateActions/AuthenticateUser"
import { GetProxyUsersAPI, refreshProxyListData } from "./actions/AuthenticateActions/GetProxyUsers"
import { LogoutUserFunction } from "./actions/AuthenticateActions/Logout"
import { ValidateTokenAPI, checkToken, refreshTokenValidatorData } from "./actions/TokenValidateActions/ValidateToken";
import { GetAppSettingAPI, RefreshAppSettingData } from "./actions/ApplicationSettingsActions/GetAppSettings";
import { RefreshCounterAPI } from "./actions/PortalCounterActions/RefreshCounter";
import { GetPracticeOrganizationDataAPI } from "./actions/PracticeOrganizationActions/GetPracticeOrganizationData";
import { GetProxyMenuAPI } from "./actions/ProxyMenuActions/GetProxyMenu";
import { GetUserProfileAPI } from "./actions/UserActions/GetUserProfile";
import { GetAccountBalanceAPI } from "./actions/UserAccountActions/GetAccountBalance";
import { DeleteMessageAPI, DeleteMessageAPIRefresher } from "./actions/MessagingActions/DeleteMessages";
import { ListDraftMessagesAPI } from "./actions/MessagingActions/ListDraftMesssages";
import { ListInboxMessagesAPI } from "./actions/MessagingActions/ListInboxMessages";
import { ListMessagesAPI } from "./actions/MessagingActions/ListMessages";
import { GetMessageByIdAPI, RefreshMessageByIdData } from "./actions/MessagingActions/ListMessagesById";
import { ListMessageRecipientsAPI, ResetMessageRecipientsAPI } from "./actions/MessagingActions/ListMessagesRecipients";
import { ListSendMessagesAPI } from "./actions/MessagingActions/ListSendMessages";
import { ListMessageRolesAPI } from "./actions/MessagingActions/LoadMessagingRoles";
import { SendingMessagesAPI, SendingMessageAPIRefresher } from "./actions/MessagingActions/SendingMessages";
import { AddPatientAppoinmenttoAPI, AddPatientAppointmentRefreshData } from "./actions/AppointmentActions/AddApointmentActions";
import { fetchAdministeredImmunefromApi } from "./actions/ImmunizationActions/AdministeredImmuneActions";
import { fetchAllergyFromApi } from "./actions/AllergiesActions/AllergiesActions";
import { GetAllInvoicesFromAPI } from "./actions/InvoicesActions/GetAllInvoices";
import { GetAllMedicationsAPI } from "./actions/MedicationActions/GetAllMedications";
import { GetAppointmentSlotsFromAPI, AppointmentSlotsDataCleanup } from "./actions/AppointmentActions/GetAppointmentSlots";
import { GetAllDiagnosisFromAPI } from "./actions/DiagnosisActions/AllDiagnosis";
import { GetLongTermDiagnosisFromAPI } from "./actions/DiagnosisActions/LongTermDiagnosis";
import { GetRecentDiagnosisFromAPI } from "./actions/DiagnosisActions/RecentDiagnosis";
import { GetDueImmuneFromAPI } from "./actions/ImmunizationActions/DueImmuneActions";
import { GetFutureAppointFromAPI } from "./actions/AppointmentActions/GetFutureAppointments";
import { GetAppointmentTypesFromAPI } from "./actions/AppointmentActions/GetAppointmentTypes";
import { GetAvailablePractitionersFromAPI, AvailablePractitionersDataCleanup } from "./actions/AppointmentActions/GetAvailablePractitioners";
import { GetPharmaciesDataFromAPI } from "./actions/MedicationActions/GetPharmacies";
import { GetProfileImageFromAPI } from "./actions/UserActions/GetProfileImage";
import { GetProviderLocationsFromAPI } from "./actions/AppointmentActions/GetProviderLocations";
import { GetRolesFromAPI } from "./actions/AppointmentActions/GetProviderRoles";
import { GetAllImmuneFromAPI } from "./actions/ImmunizationActions/AllImmuneActions";
import { GetReasonsForVisitFromAPI } from "./actions/AppointmentActions/GetReasonsForVisit";
import { GetOverdueImmuneFromAPI } from "./actions/ImmunizationActions/OverdueImmuneActions";
import { GetPendingInvoicesFromAPI } from "./actions/InvoicesActions/GetPendingInvoices";
import { GetRecentMedsFromAPI } from "./actions/MedicationActions/GetRecentMedications";
import { GetPatientRemindersFromAPI } from "./actions/RemindersActions/GetReminders";
import { GetRepeatedMedicationsFromAPI } from "./actions/MedicationActions/GetRepeatedMedications";
import { GetResourcesFromAPI } from "./actions/ResourcesActions/GetResources";
import { GetPatientAppointmentsFromAPI } from "./actions/AppointmentActions/SearchSavedAppointments";
import { GetTimelineDataFromAPI, TimelineDataRefresher } from "./actions/TimelineActions/GetTimelineData";
import { GetPatientVitalsFromAPI } from "./actions/VitalsActions/GetPatientVitals";
import { GetCompletedAppointFromAPI } from "./actions/AppointmentActions/GetCompletedAppointments";
import { GetTestResultsFromAPI } from "./actions/ReportsActions/GetTestResults";



export const actionAPI = {
    AvailablePractitionersDataCleanup,
    GetTestResultsFromAPI,
    GetCompletedAppointFromAPI,
    GetPatientVitalsFromAPI,
    TimelineDataRefresher,
    GetTimelineDataFromAPI,
    GetPatientAppointmentsFromAPI,
    GetResourcesFromAPI,
    GetRepeatedMedicationsFromAPI,
    GetPatientRemindersFromAPI,
    GetRecentMedsFromAPI,
    GetPendingInvoicesFromAPI,
    GetOverdueImmuneFromAPI,
    GetReasonsForVisitFromAPI,
    GetAllImmuneFromAPI,


    AuthenticateUserAPI,
    fetchUserInformationError,
    LogoutUser,
    clearUserState,
    GetProxyUsersAPI,
    refreshProxyListData,
    LogoutUserFunction,
    ValidateTokenAPI,
    refreshTokenValidatorData,
    checkToken,
    GetAppSettingAPI,
    RefreshAppSettingData,
    RefreshCounterAPI,
    GetPracticeOrganizationDataAPI,
    GetProxyMenuAPI,
    GetUserProfileAPI,
    GetAccountBalanceAPI,
    DeleteMessageAPI,
    DeleteMessageAPIRefresher,
    ListDraftMessagesAPI,
    ListInboxMessagesAPI,
    ListMessagesAPI,
    GetMessageByIdAPI,
    RefreshMessageByIdData,
    ListMessageRecipientsAPI,
    ResetMessageRecipientsAPI,
    ListSendMessagesAPI,
    ListMessageRolesAPI,
    SendingMessagesAPI,
    SendingMessageAPIRefresher,
    AddPatientAppoinmenttoAPI,
    AddPatientAppointmentRefreshData,
    fetchAdministeredImmunefromApi,
    fetchAllergyFromApi,
    GetAllInvoicesFromAPI,
    GetAllMedicationsAPI,
    GetAppointmentSlotsFromAPI,
    AppointmentSlotsDataCleanup,
    GetAllDiagnosisFromAPI,
    GetRecentDiagnosisFromAPI,
    GetLongTermDiagnosisFromAPI,
    GetDueImmuneFromAPI,
    GetFutureAppointFromAPI,
    GetAppointmentTypesFromAPI,
    GetAvailablePractitionersFromAPI,
    GetPharmaciesDataFromAPI,
    GetProfileImageFromAPI,
    GetProviderLocationsFromAPI,
    GetRolesFromAPI
}