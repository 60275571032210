import { GET_ALL_INVOICES, GET_ALL_INVOICES_SUCCESS, GET_ALL_INVOICES_MORE_SUCCESS, GET_ALL_INVOICES_FAIL } from "../constants/Constant";
import getUserInvoice from "../services/InvoiceService";
import getUserId from "../core/GetToken";



export const fetchAllInvoiceFromApi = (pageSize, pageNumber) => {
    return async (dispatch) => {
        // const networkcheck = await isNetworkAvilable();
        // if (networkcheck) {
        dispatch(getAllInvoice())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = {
                PatientID: item.userid,
                PageSize: pageSize,
                PageNumber: pageNumber,
                TransactionTypeID: 0,

            }
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(raw)
            };

            getUserInvoice("", requestOptions)
                .then(data => data.json())
                .then(json => {
                    json[0].hasOwnProperty('entry') ? dispatch(getAllInvoiceSuccess(json)) : dispatch(getAllInvoiceFinsihed())
                })
                .catch(err => dispatch(getAllInvoiceFail(err)))
        })
       

    }
}


export const fetchMoreAllInvoiceFromApi = (pageSize, pageNumber) => {
    return (dispatch) => {
        dispatch(getAllInvoice())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            //fetch('https://stagingindiciconnect.itsmyhealth.nz/api/?Tozken=c72bf947-4549-4d0d-b7de-141617e52b87&PatientID=2255013&PageSize=10&PageNumber=1', requestOptions)
            const queryString = '?Token=' + item.token + '&PatientID=' + item.userid + '&PageSize=' + pageSize + '&PageNumber=' + pageNumber + '&Type=1&MessagingType=1'
            // getPaitentMsg(queryString)
            //     .then(data => data.json())
            //     .then(json => {
            //         json[0].hasOwnProperty('entry') ? dispatch(getAllInvoiceMoreSuccess(json[0].entry)) : dispatch(getAllInvoiceFinsihed())
            //     })
            //     .catch(err => dispatch(getAllInvoiceFail(err)))
        })
    }
}




const getAllInvoice = () => {
    return {
        type: GET_ALL_INVOICES
    }
}

//data success
const getAllInvoiceSuccess = (data) => {
    return {
        type: GET_ALL_INVOICES_SUCCESS,
        data,
    }
}

const getAllInvoiceMoreSuccess = (data) => {
    return {
        type: GET_ALL_INVOICES_MORE_SUCCESS,
        data,
    }
}

//data failed
const getAllInvoiceFail = () => {
    return {
        type: GET_ALL_INVOICES_FAIL
    }
}

const getAllInvoiceFinsihed = () => {
    return null;
}


