import fetchIntercept from 'fetch-intercept';
import store from "./configureStore"
import Settings from "./shared/config/Settings";

const unregister = fetchIntercept.register({
    request: function (url, config) {
        // Modify the url or config here
        if (url.indexOf("create-payment-intent") != -1) {
            return [url, config]
        }
        const state = store.getState().IdentityServerReducer.identityApiData
        if (state && state.ACCAPIURL) {
            url = state.ACCAPIURL + url.split("/api/")[1]
            Settings.apiUrl = state.ACCAPIURL
        }

        return [url, config];
    },


});