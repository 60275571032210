import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import { View, Text } from "react-native-web";

import { Tabs } from "antd";
import "antd/dist/antd.css";
import DueTab from "./ImmuneTabs/DueTab";
import OverdueTab from "./ImmuneTabs/OverdueTab";
import AdministeredTab from "./ImmuneTabs/AdministeredTab";
import AllTabs from "./ImmuneTabs/AllTabs";
import { fetchProfileFromApi } from "../../shared/actions/ProfileActions";
import { connect, useSelector } from "react-redux";
import { ActivityIndicator } from "react-native";
import PendingInvoice from "./Accounts/PendingInvoice";
import AllInvoice from "./Accounts/AllInvoice";
import { useLayoutEffect } from "react";
import GetAppSettings from "../../shared/core/GetAppSettings";
import PendingInvoiceUpdated from "./Accounts/PendingInvoiceUpdated";

const { TabPane } = Tabs;

const Accounts = (props) => {

  const navigate = useNavigate()
  const { ProxyMenu, isLoadingMenu } = useSelector(state => state.ProxyMenu);
  let reloadCount;


  // useLayoutEffect(() => {
  //   GetAppSettings().then((info) => {
  //     const set = JSON.parse(info);
  //     if (window.location.pathname.indexOf("account") > -1) { //stripe case
  //       reloadCount = sessionStorage.getItem('reloadCount');
  //       if (reloadCount < 1) {
  //         sessionStorage.setItem('reloadCount', String(reloadCount + 1))
  //         window.location.reload();
  //       }
  //       else {
  //         sessionStorage.removeItem('reloadCount');
  //       }
  //     }
  //   })
  // }, []);
  useEffect(() => {
    if (ProxyMenu != null && ProxyMenu != undefined) {
      let itemExists = false
      ProxyMenu.map((item, index) => {
        if (item.resource.extension[0].valueString == 'Account') {
          itemExists = true
        }

      })
      if (!itemExists) {
        navigate("/")
      }
    }
  }, [ProxyMenu])

  return (
    <div className="page-content-wrapper-inner">
      <div className="viewport-header">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb has-arrow">
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Accounts
            </li>
          </ol>
        </nav>
      </div>
      <div className="content-viewport">
        <div className="row row-margin-zero h-100">
          <div className="h-100 accounts-outer-container">

            {/* ----- */}
            <div className="row row-margin-zero mb-2">
              <div className="col-md-12 mx-auto">
                <div className="alert alert-warning accounts-alert-warning" role="alert" >

                  <h6 className="alert-heading">Disclaimer:</h6>
                  <span>Never give indici or myindici account details, passwords, or confidential information out to anyone and be careful what information you share on social media. If you get a suspicious call, text, or email, or if you notice any suspicious activity, contact your medical practice immediately.</span>

                  <span className="mb-0"><strong> Remember:</strong> Team indici will never ask you for your bank details or personal information.</span>


                </div>
              </div>
            </div>
            {/* -----  */}


            {/* <h2 className="grid-title">Accounts</h2> */}
            <div className="item-wrapper accounts-container">
              <Tabs defaultActiveKey="1" centered items={[
                {
                  label: "Pending Invoices",
                  key: "1",
                  children: <>
                    <div className="pending-inv-tabpane">
                      <PendingInvoiceUpdated />

                    </div>
                  </>
                },
                {
                  label: "All Invoices",
                  key: "2",
                  children: <>
                    <div className="pending-inv-tabpane">
                      <AllInvoice />

                    </div>
                  </>
                }
              ]}>

              </Tabs>
            </div>
          </div>
        </div>

      </div>
    </div>


  );
};

//export default connect(mapStateToProps, mapDispatchToProps)(ImmuneHome);
function mapStateToProps(state) {
  return {
    ProxyMenu: state.ProxyMenu,
  }
}


function mapDispatchToProps(disptach) {
  return {
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Accounts)
