import { async } from "q";
import React, { useEffect, useState } from "react";
import { View, Text, Alert } from "react-native";
import getUserId from "../../shared/core/GetToken";

const PosMobileComp = () => {
  const [paymentData, setPaymentData] = useState(null);
  const [sendingMedicationRepeatRequest, setSendingMedicationRepeatRequest] =
    useState(false);

  useEffect(async () => {
    function parseQuery(queryString) {
      const params = {};
      const queryStringWithoutQuestionMark = queryString.substring(1);
      const queryParams = queryStringWithoutQuestionMark.split("&");
      queryParams.forEach((param) => {
        const [key, value] = param.split("=");
        params[key] = decodeURIComponent(value);
      });
      return params;
    }

    // alert(window.location.search);

    const queryString = window.location.search;
    // alert(queryString);
    let { pid, patienttoken, token } = parseQuery(queryString);

    console.log("pid:", pid);
    console.log("patienttoken:", patienttoken);
    console.log("token:", token);
    // parseQuery()
    //   const RepeatPoliTransaction = async () => {

    let repeatPayload;

    //   let Transcationtoken = window?.location?.search;

    //   if (Transcationtoken != null) {
    //     Transcationtoken = Transcationtoken.substring(7, Transcationtoken.length);
    //   }else{
    //       alert('Payment unsuccessful!')
    //       return false
    //   }

    const myHeaders = new Headers();
    myHeaders.append("patienttoken", patienttoken);
    myHeaders.append("Content-Type", "application/json");

    // const item = await getUserId().then((userInfo) => {

    //     return JSON.parse(userInfo);
    // });

    // alert(item.userid)

    const raw = JSON.stringify({
      transactionId: token,
      patientId: pid,
      ApiUrl: `https://devdhbconnect.vitonta.com`,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    console.log("raw----", raw);

    const resultPoliTransaction = await fetch(
      "https://australia-southeast1-myindici-nz.cloudfunctions.net/indiciportalpaymentsaus/patient/production/GetTransaction",
      requestOptions
    );
    const finalresultPoliTransaction = await resultPoliTransaction.json();
    setPaymentData(finalresultPoliTransaction);
    if(finalresultPoliTransaction?.MerchantData?.indexOf("repeat") == -1){
        //SavePaymentCall
        if (finalresultPoliTransaction.TransactionStatus == "Completed") {
          if (repeatJsonTID && parseInt(repeatJsonTID) > 0) {
            const payloadqueryStringFinal = `?PatientID=${pid}&electronicPaymentID=${repeatJsonTID}`;
            const responsePaymentFinal = await fetch(
              `https://devdhbconnect.vitonta.com/api/patient/SavePayment${payloadqueryStringFinal}`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Token: patienttoken,
                },
                redirect: "follow",
              }
            );
          }
        }
        alert("Accountssss")
        return;
    }
    const startIndex =
      finalresultPoliTransaction?.MerchantData?.indexOf("repeat=") + 7;
    const endIndex = finalresultPoliTransaction?.MerchantData?.lastIndexOf("}");
    const repeatJson = finalresultPoliTransaction?.MerchantData?.substring(
      startIndex,
      endIndex + 1
    );
    repeatPayload = JSON.parse(repeatJson);

    console.log("repeattttttt", repeatPayload);

    const startIndexTID =
      finalresultPoliTransaction?.MerchantData?.indexOf("temppaymentid=") + 14;
    const endIndexTID =
      finalresultPoliTransaction?.MerchantData?.lastIndexOf("*");
    const repeatJsonTID = finalresultPoliTransaction?.MerchantData?.substring(
      startIndexTID,
      endIndexTID
    );
    alert("Repeatttttt")
    if (finalresultPoliTransaction.TransactionStatus == "Completed") {
      if (repeatJsonTID && parseInt(repeatJsonTID) > 0) {
        const payloadqueryStringFinal = `?PatientID=${pid}&electronicPaymentID=${repeatJsonTID}`;
        const responsePaymentFinal = await fetch(
          `https://devdhbconnect.vitonta.com/api/patient/SavePayment${payloadqueryStringFinal}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Token: patienttoken,
            },
            redirect: "follow",
          }
        );

        var myHeaders2 = new Headers();
        myHeaders2.append("Token", patienttoken);
        myHeaders2.append("Content-Type", "application/json");
        const finalpaymentResult = await responsePaymentFinal.json();
        if (finalpaymentResult) {
          setSendingMedicationRepeatRequest(true);
          const requestOptions = {
            method: "POST",
            headers: myHeaders2,
            body: JSON.stringify(repeatPayload),
            redirect: "follow",
          };

          let statusMed;
          fetch(
            "https://devdhbconnect.vitonta.com/api/Patient/SendRepeatMedicationRequest",
            requestOptions
          )
            .then((response) => {
              statusMed = response.status;
              alert(response.status);
              return response.json();
            })
            .then((result) => {
              setSendingMedicationRepeatRequest(false);
            });
        }
      }
    }
    //   // repeatPayload = JSON.parse(repeatJson);
    // };
    // alert(window.location.search);
    // RepeatPoliTransaction()
  }, []);

  return (
    <div className="page-content-wrapper-inner">

            <div className="content-viewport">
                <div className="row row-margin-zero h-100 text-center d-flex justify-content-center">
                    <h1 className='mt-5'>{!paymentData ? "Processing Payment........." : sendingMedicationRepeatRequest ? "Repeating Prescription.........." : paymentData?.TransactionStatus?.toLowerCase() == "completed" ? <div className='d-flex flex-column align-items-center mt-5'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="154.313" height="156.045" viewBox="0 0 154.313 156.045">
                            <g id="checkexact" transform="translate(-17371.427 -18803.621)">
                                <path id="Path_33868" data-name="Path 33868" d="M17080.219,18800.408l-11.062,12.85s-41.58-21.949-74.236,19.273c.357.178-26.945,34.441,1.785,74.592.178,0,43.008,50.146,94.58,0,0-.357,17.844-20.521,12.49-49.43.355.178,14.1-11.242,14.1-11.242s12.314,40.152-14.1,69.773c0,.357-46.039,57.105-107.07,12.67-.359,0-66.742-54.785-1.785-120.633C16995.1,18808.262,17029.182,18775.426,17080.219,18800.408Z" transform="translate(405 13.786)" fill="#49c701" />
                                <path id="Path_33867" data-name="Path 33867" d="M16991.383,18861l33.906-23.336s1.783-1.371,4.393,2.746,15.1,22.648,15.1,22.648,3.295,4.395,5.078,0,34.867-62.045,61.5-73.439c.961,14.689-1.373,25.121,6.59,47.221.137-.137-30.613,14-67.4,75.637-.137,0-2.191,5.264-4.836,0S17017.689,18871.092,16991.383,18861Z" transform="translate(401.203 14)" fill="#49c701" />
                            </g>
                        </svg>
                        <div className='pos-tick-shadow'>

                        </div>
                        <p className='text-bolder mt-2'>Request Successful</p>
                    </div> : <div className='d-flex flex-column align-items-center mt-5'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="156.188" height="134.348" viewBox="0 0 156.188 134.348">
                            <g id="error" transform="translate(-9.5 -43.969)">
                                <g id="Group_6849" data-name="Group 6849" transform="translate(9.5 43.969)">
                                    <path id="Path_33882" data-name="Path 33882" d="M92.282,46.619l72.36,120.752a7.218,7.218,0,0,1-6.2,10.945H16.746a7.218,7.218,0,0,1-6.2-10.945L82.9,46.619a5.473,5.473,0,0,1,9.378,0Z" transform="translate(-9.5 -43.969)" fill="#e03" fill-rule="evenodd" />
                                    <path id="Path_33883" data-name="Path 33883" d="M115.027,91.076,49.654,200.169H180.4Z" transform="translate(-36.933 -76.152)" fill="#eff4f7" fill-rule="evenodd" />
                                    <g id="Group_6848" data-name="Group 6848" transform="translate(70.533 40.64)">
                                        <path id="Path_33884" data-name="Path 33884" d="M244.045,222.1c0,5.988-9.114,5.987-9.114,0l-2.8-42.607c0-9.664,14.708-9.665,14.708,0Zm-4.556,22.425a5.864,5.864,0,1,0-5.864-5.864A5.876,5.876,0,0,0,239.488,244.527Z" transform="translate(-232.134 -172.246)" fill="#e03" fill-rule="evenodd" />
                                    </g>
                                </g>
                            </g>
                        </svg>

                        <div className='pos-tick-shadow'>

                        </div>
                        <p className='text-bolder mt-2'>Payment Failed</p>
                    </div>}</h1>
                </div>
            </div>
        </div>
  );
};

//const PosMobileComp = () => {
//     const [paymentData, setPaymentData] = useState(null);
//     const [sendingMedicationRepeatRequest, setSendingMedicationRepeatRequest] = useState(false);

//     // const search = useLocation().search
//     // const pid = new URLSearchParams(search).get('pid');
//     // const token = new URLSearchParams(search).get('token');

//     alert(pid);

//     Alert.alert(pid)

//     useEffect(() => {
//         async function ftPaymentCall() {
//             try {
//                 // const { pid, token } = route.params; // Access route parameters from props

//                 // // Your fetch calls here...

//                 // Alert.alert(`pid:${pid},  token:${token}`)

//                 var payload11 = `PID: ${pid}, token: ${token}, PatientInfo: ${JSON.stringify(item)}`;

//                 Alert.alert(`payload11:${payload11}`)

//             fetch('https://devdhbconnect.vitonta.com/api/Patient/WriteToFile?data='+payload11+'', {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json'
//                 }
//             })
//             .then(response => {
//                 if (!response.ok) {
//                     throw new Error('Network response was not ok');
//                 }
//                 return response.json();
//             })
//             .then(data => {
//                 console.log(data);
//                 // Do something with the retrieved data
//             })
//             .catch(error => {
//                 console.error('There was a problem with the fetch operation:', error);
//             });

//                 let repeatPayload;
//                 const item = JSON.parse(await getUserId());

//                 const myHeaders = new Headers();
//                 myHeaders.append("patienttoken", item.token);
//                 myHeaders.append("Content-Type", "application/json");

//                 const raw = JSON.stringify({
//                     "transactionId": token,
//                     "patientId": item.userid,
//                     ApiUrl:'https://devdhbconnect.vitonta.com'
//                 });

//                 const requestOptions = {
//                     method: 'POST',
//                     headers: myHeaders,
//                     body: raw,
//                     redirect: 'follow'
//                 };

//                 const resultPoliTransaction = await fetch('YOUR_POLI_TRANURL', requestOptions);
//                 const finalresultPoliTransaction = await resultPoliTransaction.json();
//                 setPaymentData(finalresultPoliTransaction);
//                 const startIndex = finalresultPoliTransaction?.MerchantData?.indexOf('repeat=') + 7;
//                 const endIndex = finalresultPoliTransaction?.MerchantData?.lastIndexOf('}');
//                 const repeatJson = finalresultPoliTransaction?.MerchantData?.substring(startIndex, endIndex + 1);

//                 const startIndexTID = finalresultPoliTransaction?.MerchantData?.indexOf('temppaymentid=') + 14;
//                 const endIndexTID = finalresultPoliTransaction?.MerchantData?.lastIndexOf('*');
//                 const repeatJsonTID = finalresultPoliTransaction?.MerchantData?.substring(startIndexTID, endIndexTID);

//                 repeatPayload = JSON.parse(repeatJson);

//                 if (finalresultPoliTransaction.TransactionStatus == "Completed") {
//                     if (repeatJsonTID && parseInt(repeatJsonTID) > 0) {
//                         const payloadqueryStringFinal = `?PatientID=${item.userid}&electronicPaymentID=${repeatJsonTID}`;
//                         const responsePaymentFinal = await fetch(`https://devdhbconnect.vitonta.com/api/patient/SavePayment${payloadqueryStringFinal}`, {
//                             method: 'POST',
//                             headers: {
//                                 "Content-Type": "application/json",
//                                 "Token": item.token
//                             },
//                             redirect: 'follow'
//                         });

//                         const finalpaymentResult = await responsePaymentFinal.json();

//                         if (finalpaymentResult) {
//                             setSendingMedicationRepeatRequest(true);
//                             const requestOptions = {
//                                 method: 'POST',
//                                 headers: myHeaders,
//                                 body: JSON.stringify(repeatPayload),
//                                 redirect: 'follow'
//                             };

//                             let statusMed;
//                             fetch("https://devdhbconnect.vitonta.com/api/Patient/SendRepeatMedicationRequest", requestOptions)
//                                 .then(response => {
//                                     statusMed = response.status;
//                                     return response.json();
//                                 })
//                                 .then((result) => {
//                                     setSendingMedicationRepeatRequest(false);
//                                 });
//                         }
//                     }
//                 }
//             } catch (error) {
//                 console.error('Error:', error);
//             }
//         }

//         ftPaymentCall();
//     }, []); // Make sure to include route.params as a dependency

//     return (
//         <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
//             <Text>{!paymentData ? "Processing Payment........." : sendingMedicationRepeatRequest ? "Repeating Prescription.........." : paymentData?.TransactionStatus?.toLowerCase() == "completed" ? "Request Successful" : "Payment Failed"}</Text>
//         </View>
//     );
// };

export default PosMobileComp;
