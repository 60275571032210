import {
    GETTING_MEDS,
    GETTING_MEDS_SUCCESS,
    GETTING_MEDS_MORE_SUCCESS,
    GETTING_MEDS_FAILED,
} from "../constants/Constant";
import getAllMedications from "../services/MedicationService";
import getUserId from "../core/GetToken";



export const fetchAllMedsFromApi = (pageSize, pageNumber) => {
    return (dispatch) => {
        dispatch(getMed())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = {
                PatientID: item.userid,
                PageSize: 100,
                PageNumber: 1,
                TypeID: 1,
            }
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(raw)
            };



            getAllMedications("", requestOptions)
                .then(data => data.json())
                .then(json => {
                    json[0].hasOwnProperty('entry') ? dispatch(getMedSuccess(json[0].entry)) : dispatch(getMedFinished())
                })
                .catch(err => dispatch(getMedFailed(err)))
        })
    }
}


//start getting data
const getMed = () => {
    return {
        type: GETTING_MEDS
    }
}

//data is success
const getMedSuccess = (data) => {
    return {
        type: GETTING_MEDS_SUCCESS,
        data
    }
}
//data failed
const getMedFailed = () => {
    return {
        type: GETTING_MEDS_FAILED
    }
}

const getMedFinished = () => {
    return null;
}