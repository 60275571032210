import AsyncStorage from "@react-native-async-storage/async-storage";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Settings from "../../../config/Settings";
import getUserId from "../../../core/GetToken";

export const GetRepeatedMedicationsFromAPI = createAsyncThunk(
    "getRepeatedMedications/Medication",
    async (values, { dispatch, getState }) => {

        dispatch(getRepeatedMedications())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = {
                PatientID: item.userid,
                PageSize: 10,
                PageNumber: 1,
                TypeID: 3,
            }
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(raw)
            };
            fetch(Settings.apiUrl + "Patient/Medication", requestOptions)
                .then(response => response.json())
                .then(result => {

                    if (result !== null) {

                        result[0].hasOwnProperty('entry') ? dispatch(getRepeatedMedicationsSuccess(result[0].entry)) : dispatch(getRepeatedMedicationsFinished())
                    } else {
                        dispatch(getRepeatedMedicationsFailed())
                    }
                })
                .catch(error => {
                    getRepeatedMedicationsFailed(error)

                });

        })

    }

);

const GetRepeatedMedicationsSlice = createSlice({
    name: "getRepeatedMedications",
    initialState: {
        AllRepeatedMedeications: [],
        isGettingRepeated: false,
        errorRepeated: false
    },
    reducers: {
        getRepeatedMedications: (state, action) => {
            state.AllRepeatedMedeications = []
            state.isGettingRepeated = true
            state.errorRepeated = false
        },
        getRepeatedMedicationsSuccess: (state, action) => {
            state.AllRepeatedMedeications = action.payload
            state.isGettingRepeated = false
            state.errorRepeated = false

        },
        getRepeatedMedicationsFailed: (state, action) => {
            state.AllRepeatedMedeications = []
            state.isGettingRepeated = false
            state.errorRepeated = true
        },
        getRepeatedMedicationsFinished: (state, action) => {
            state.AllRepeatedMedeications = []
            state.isGettingRepeated = false
            state.errorRepeated = false
        },

    },
});




export const {
    getRepeatedMedications, getRepeatedMedicationsFailed, getRepeatedMedicationsSuccess, getRepeatedMedicationsFinished
} = GetRepeatedMedicationsSlice.actions;
export default GetRepeatedMedicationsSlice.reducer;
