import React from 'react'
import Avatar from 'antd/lib/avatar/avatar';
import { UserOutlined, HeatMapOutlined } from '@ant-design/icons';
const PrintAppointmentHeader = (props) => {
    return (
        <>
            {props?.organizationData?.length > 0 && props?.patientProfile?.length > 0 ?
            <div className={"" + (props.isPrintActive ? "d-flex" : "d-none")}>
                <div className="print-header appointment-print-container">

                    <div className="print-header-left d-flex flex-column appointment-print-coumns">
                            <h4>{props.organizationData[0]?.resource?.name}</h4>
                            <span>{props.organizationData[0]?.resource?.address[0]?.text}</span>
                        
                    </div>
                    <div className="print-header-center d-flex flex-column appointment-print-coumns padding-left-right-ten" >
                        <span>{props.patientProfile[0].resource?.name[0].text}</span>
                        <span><span>NHI:</span> {props.patientProfile[0].resource?.extension[3].valueString}</span>

                    </div>
                    <div className="print-header-right d-flex flex-column appointment-print-coumns padding-left-right-ten" >

                        <span><span>Phone:</span> {props.patientProfile[0].resource?.telecom?.map(item => {
                            if (item?.use === "home" && item?.system === "phone") {
                                return item.value
                            }
                        })}</span>
                        <span><span>Email:</span> {props.patientProfile[0].resource?.telecom?.map(item => {
                            if (item?.system === "email") {
                                return item.value
                            }
                        })}</span>
                    </div>
                </div>

                <hr />
            </div>
                : null}
       </>
    )
}

export default PrintAppointmentHeader
