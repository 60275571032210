import React, { useCallback, useState, useEffect, useRef } from 'react';
import { View, Text, Dimensions, StyleSheet, FlatList, ActivityIndicator, TouchableOpacity } from 'react-native-web';
import { Link } from "react-router-dom";

import { connect, useDispatch, useSelector } from "react-redux";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";

import { capitalize, GetAppointmentDate, GetAppointmentDateMod, GetMonthName, GetYear } from '../../shared/core/datehelper'
import { fetchReportsFromApi, fetchMoreReportsFromApi } from "../../shared/actions/ReportsActions";
import ReportsHandler from "../components/ReportsHandler";
import { Table, Tag, Space, DatePicker, Input } from 'antd';
import { Modal, Button } from 'antd';
import 'antd/dist/antd.css';
import ReportViewHandler from '../components/ReportViewHandler';
import getUserId from "../../shared/core/GetToken";
import { Spin, Switch, Alert } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';


import renderHTML from "react-render-html";
import { useReactToPrint } from 'react-to-print';
import Settings from '../../shared/config/Settings';
import { checkToken } from '../../shared/actions/TokenValidActions';
import moment from 'moment';
import { flushSync } from 'react-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { actionAPI } from '../../shared/reduxToolkit/actionAPI';
const useNavigate = require("react-router-dom").useNavigate;


const { Column, ColumnGroup } = Table;
const data = [
    {
        key: '1',
        firstName: 'John',
        lastName: 'Brown',
        age: 32,
        address: 'New York No. 1 Lake Park',
        tags: ['nice', 'developer'],
    },
    {
        key: '2',
        firstName: 'Jim',
        lastName: 'Green',
        age: 42,
        address: 'London No. 1 Lake Park',
        tags: ['loser'],
    },
    {
        key: '3',
        firstName: 'Joe',
        lastName: 'Black',
        age: 32,
        address: 'Sidney No. 1 Lake Park',
        tags: ['cool', 'teacher'],
    },
];
let tabledata = []
const PatientResults = props => {
    const { RangePicker } = DatePicker;

    const antIcon = <LoadingOutlined className='reports-antd-loading-fs' spin />;

    const history = useNavigate()
    const [isloaded, setIsload] = React.useState(false);
    const { Reports, isFetching } = useSelector(state => state.PatientResults);
    const [pageSize, setPageSize] = useState(100);           //state for the page number
    const [pageNumber, setPageNumber] = useState(1);
    const [sloading, setSLoading] = useState(false);

    const [visible, setVisible] = useState(false);  //for report pdf view 
    const [tableModalVisible, setTableModalVisible] = useState(false); //for
    //for report virws
    const [authorName, setAuthorName] = useState('');
    const [labrotatyName, setlabrotatyName] = useState('');
    const [InsertedDate, setInsertedDate] = useState('');
    const [Observationtext, setObservationtext] = useState('');
    const [AbnormalFlagDesc, setAbnormalFlagDesc] = useState('');
    const [pdfViewerLoading, setPdfViewerLoading] = useState(false);
    const { Profile } = useSelector(state => state.UserProfile)
    const { Practise } = useSelector(state => state.PracticeOrganization)
    const [isReady, setIsReady] = useState(true);
    const [activeStatus, setActiveStatus] = useState("All");
    const [searchtext, setSearchtext] = useState("");
    const [searchRangeValue, setSearchRangeValue] = useState([
        moment(new Date()).subtract(1, "year"),
        moment(new Date())
    ]);
    const [searchFromDate, setSearchFromDate] = useState(moment(new Date()).subtract(1, "year").format("YYYY-MM-DD"));
    const [searchToDate, setSearchToDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
    
    const [ReportsDisplayed, setReportsDisplayed] = useState([])

    const [patientResultsList, setPatientResultsList] = useState([]);
    const [totalRecords, setTotalRecords] = useState(10)

    const navigate = useNavigate()
    const { ProxyMenu, isLoadingMenu } = useSelector(state => state.ProxyMenu);
    const apiDispatcher = useDispatch()
    useEffect(() => {
        if (ProxyMenu != null && ProxyMenu != undefined) {
            let itemExists = false
            ProxyMenu.map((item, index) => {
                if (item.resource.extension[0].valueString == "Test Results") {
                    itemExists = true
                }

            })
            if (!itemExists) {
                navigate("/")
            }
        }
    }, [ProxyMenu])
    let limit = 15
    let offset = 1

    const getPatientResultsApi = (fromSearchButton = false, status = activeStatus, fromReset = false) => {
        limit = 15;
        offset = fromSearchButton || fromReset ? 1 : Math.ceil(patientResultsList.length / limit) + 1;

        if (offset == 1) {
            flushSync(() => {
                setPatientResultsList([])
                setTotalRecords(0)
            })
        }
        apiDispatcher(actionAPI.GetTestResultsFromAPI({
            pageSize: limit,
            pageNumber: offset,
            searchtext: searchtext,
            searchFromDate: fromReset ? moment(new Date()).subtract(1, "year").format("YYYY-MM-DD") : searchFromDate,
            searchToDate: fromReset ? moment(new Date()).format("YYYY-MM-DD") : searchToDate,
            status: status
        }))

    };
    const getPatientResultsFromStartAPI = () => {

        limit = 15;
        offset = 1;
        flushSync(() => {
            setPatientResultsList([])
        })

        apiDispatcher(actionAPI.GetTestResultsFromAPI({
            pageSize: limit,
            pageNumber: offset,
            searchtext: searchtext,
            searchFromDate: searchFromDate,
            searchToDate: searchToDate,

        }))


    };
    const fetchmorePatientResultsData = (ignore = false) => {
        if (ignore || ReportsDisplayed.length < totalRecords) {
            getPatientResultsApi();
        }
    }

    useEffect(() => {
        getPatientResultsApi(true, activeStatus)
    }, [activeStatus])
    useEffect(() => {
        if (Reports !== "undefined" && Reports?.length && Reports[0].entry) {
            setTotalRecords(Reports[0].total)
            if (Reports && patientResultsList[0]?.resource?.id != Reports[0]?.entry[0]?.resource?.id) {
                const mergeData = [...patientResultsList, ...Reports[0]?.entry];
                setPatientResultsList(mergeData);


            }

        }
    }, [Reports])
    useEffect(() => {
        setReportsDisplayed(patientResultsList)
    }, [patientResultsList])


    const resetResultFilters = () => {
        setSearchtext("");
        setSearchRangeValue([
            moment(new Date()).subtract(1, "year"),
            moment(new Date())
        ])
        setSearchFromDate(moment(new Date()).subtract(1, "year").format("YYYY-MM-DD"));
        setSearchToDate(moment(new Date()).format("YYYY-MM-DD"));
        getPatientResultsApi(false, activeStatus, true)
    }



    React.useEffect(() => {
        apiDispatcher(actionAPI.checkToken(history))
    }, [])


    const loadReport = (reportId) => {
        // setVisible(true)


        getUserId().then((userInfo) => {
            setPdfViewerLoading(true)
            //    

            const item = JSON.parse(userInfo);

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = JSON.stringify({
                PatientID: item.userid,
                ResultID: reportId
            })
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: raw
            };
            fetch(Settings.apiUrl + "Patient/LabResultByID", requestOptions)
                .then(data => data.json())
                .then(json => {
                    setSLoading(false);
                    if (json[0].entry[0].resource.content[0].attachment.contentType == "PDF" || json[0].entry[0].resource.content[0].attachment.contentType == "pdf") {
                        setVisible(true)
                        setTimeout(() => {

                        }, 3000);
                        document.getElementById("showPDF").innerHTML = ""
                        //    alert("pdf here")
                        var b64 = json[0].entry[0].resource.content[0].attachment.data;
                        var obj = document.createElement('iframe');
                        obj.style.width = '100%';
                        obj.style.height = '550px';
                        obj.type = 'application/pdf';
                        obj.src = 'data:application/pdf;base64,' + b64;
                        //document.body.appendChild(obj);
                        document.getElementById("showPDF").appendChild(obj)
                        setPdfViewerLoading(false)
                    }
                    else {
                        //showTable

                        let ObservationText = '';
                        let ObservationValue = "";
                        let AbnormalFlagDesc = ""
                        json[0].entry.map((item, index) => {
                            item.resource.extension.map((value, key) => {
                                if (item.resource.extension[key].url == "ObservationText") {
                                    ObservationText = capitalize(item.resource.extension[key].valueString)
                                } else if (item.resource.extension[key].url == "ObservationValue") {
                                    ObservationValue = capitalize(item.resource.extension[key].valueString)
                                }
                                else if (item.resource.extension[key].url == "AbnormalFlagDesc") {
                                    AbnormalFlagDesc = capitalize(item.resource.extension[key].valueString)
                                }
                            })
                            let ob = {
                                "ObservationText": ObservationText,
                                "ObservationValue": ObservationValue,
                                "AbnormalFlagDesc": AbnormalFlagDesc //capitalize(item.resource.extension[3].valueString) // ObservationValue,
                              
                            }
                            tabledata.push(ob)


                        })
                        setTableModalVisible(true)
                        setIsReady(!isReady)
                        setTimeout(() => {

                        }, 3000);
                      
                    }
                  




                })
                .catch(error => {
                    // setIsLoadingPdf(false)
                    setPdfViewerLoading(false)
                })
                .finally(() => {
                    // setCheckFile(false)
                    // setIsLoadingPdf(false)
                    setPdfViewerLoading(false)


                });
        });
    }



    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });


    const columnsLetter = [
        {
            title: "Observation Text",
            dataIndex: "ObservationText",
            key: "ObservationText",
        },
        {
            title: "Observation Value",
            dataIndex: "ObservationValue",
            key: "ObservationValue",
            render: (text, record, index) => (
                <span>
                    Hello
                </span>
            ),
        },
        {
            title: "Abnormal Flag Desc",
            dataIndex: "AbnormalFlagDesc",
            key: "AbnormalFlagDesc",
        },
    ];
    const filterReadResults = () => {
        setActiveStatus("Read")
        // getPatientResultsApi(true, 1)

        // if (Reports && Reports.length) {
        //     setReportsDisplayed(Reports[0].entry.filter(item => item?.resource?.extension[5]?.valueString == "True"))
        // }
    }
    const filterUnreadResults = () => {
        setActiveStatus("Unread")
        // getPatientResultsApi(true, 0)


        // if (Reports && Reports.length) {
        //     setReportsDisplayed(Reports[0].entry.filter(item => item?.resource?.extension[5]?.valueString !== "True"))
        // }
    }
    const filterAllResults = () => {
        setActiveStatus("All")
        // getPatientResultsApi(true, 2)

        // if (Reports && Reports.length) {
        //     setReportsDisplayed(Reports[0].entry)
        // }

    }
    useEffect(() => {
        if (Reports && Reports.length) {
            if (activeStatus == "Read") {
                filterReadResults()
            } else if (activeStatus == "Unread") {
                filterUnreadResults()
            } else {
                setReportsDisplayed(Reports[0].entry)
            }
        }
    }, [Reports])
    return (

        <div className="page-content-wrapper-inner">
            <div className="viewport-header">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb has-arrow">
                        <li className="breadcrumb-item">
                            <Link to="/">Dashboard</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">Test Results</li>
                    </ol>
                </nav>
            </div>
            <div className="content-viewport">
                <div className="row row-margin-zero h-100">
                    <div className="col-12 h-100">
                        <div className="grid h-100">
                            <div className="grid-body h-100">
                                <div className="item-wrapper report-container h-100" >
                                    {/* <Spin indicator={antIcon} tip="Loading..." spinning={sloading}> */}
                                    <div className="row row-margin-zero mb-2">
                                        <div className="col-12 col-padding-zero">
                                            <div className='align-items-center mb-3'>

                                                <span className={"appoint-status-badge unread-badge badge badge-success " + (activeStatus === "Unread" ? " active " : "")} onClick={filterUnreadResults}>Unread</span>
                                                <span className={"appoint-status-badge read-badge badge badge-warning" + (activeStatus === "Read" ? " active " : "")} onClick={filterReadResults}>Read</span>
                                                <span className={"appoint-status-badge all-badge badge  badge-primary" + (activeStatus === "All" ? " active " : "")} onClick={filterAllResults}>All </span>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='row row-margin-zero'>


                                       
                                        <div className="grid-body card-body full-width-charts pt-3" >
                                            <div className='row row-margin-zero position-relative result-btuns-filter'>
                                            
                                                <div className='col-md-6 col-sm-6'>
                                                    <div className='form-group'>
                                                        <RangePicker
                                                            className="reports-filter-datepicker w-100"
                                                            format={"DD-MM-YYYY"}

                                                            getPopupContainer={(trigger) =>
                                                                trigger.parentNode
                                                            }
                                                            value={searchRangeValue}

                                                            onChange={(e) => {
                                                                setSearchRangeValue(e)
                                                                setSearchFromDate(e ? moment(e[0]._d).format("YYYY-MM-DD") : null)
                                                                setSearchToDate(e ? moment(e[1]._d).format("YYYY-MM-DD") : null)
                                                            }}
                                                            inputReadOnly
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-md-6 col-sm-6 '>
                                                    <div className='form-group search-object'>
                                                        <Input
                                                            autoComplete='off'
                                                            placeholder='Search by Subject' value={searchtext} onChange={(e) => setSearchtext(e.target.value)} allowClear />
                                                    </div>
                                                </div>
                                                <div className='results-buttons-container'>
                                                    <div className='form-group pull-right'>
                                                        <Button className='btn btn-primary mr-2' onClick={() => getPatientResultsApi(true, activeStatus)} type='primary'>Search</Button>
                                                        <Button className='btn btn-primary ' onClick={() => resetResultFilters()} type='primary'>Reset</Button>
                                                   
                                                    </div>
                                                </div>
                                               
                                            </div>
                                        </div>



                                    </div>
                                    {
                                        isFetching && !Reports && <div className='m-top-15'><ActivityIndicator size="small" color="#00A1DE" /></div>
                                    }

                                    {
                                        Reports && (Reports?.length > 0 || ReportsDisplayed?.length > 0) ? (
                                            <div id="patientReportsScrollableDiv" className='appointment-history-internal-scroll h-100' >
                                                <InfiniteScroll
                                                    dataLength={patientResultsList.length}
                                                    next={fetchmorePatientResultsData}
                                                    hasMore={patientResultsList.length < totalRecords}
                                                    loader={<div className='d-flex justify-content-center my-2'><Spin /></div>}
                                                    // onScroll={() => {}}
                                                    scrollableTarget="patientReportsScrollableDiv"
                                                >
                                            <FlatList
                                                data={ReportsDisplayed}

                                                        className="reports-displayed-flatlist"
                                                renderItem={({ item }) => (

                                                    <ReportsHandler
                                                        refreshReports={() => {}}
                                                        labid={item.resource.id}
                                                        // indicatorHandler={() => ActivityIndicatorHanlder()}
                                                        rptDate={GetAppointmentDateMod(item.resource.extension[6].valueDateTime) + ' ' + GetMonthName(item.resource.extension[6].valueDateTime) + ' ' + GetYear(item.resource.extension[6].valueDateTime)}
                                                        testName={item.resource.description}
                                                        author={'Provider: ' + item.resource.author[0].display}
                                                        labName={item.resource.extension[0].valueString ? item.resource.extension[0].valueString : 'N/A'}
                                                        folderName={item.resource.extension[4].valueString ? item.resource.extension[4].valueString : 'N/A'}

                                                        comments={item.resource.extension[2].valueString ? item.resource.extension[2].valueString : 'N/A'}
                                                        patientProfile={Profile}
                                                        organizationData={Practise}
                                                        isRead={item.resource.extension[5].valueString == "True"}
                                                    />
                                                )}
                                                keyExtractor={item => item.resource.id}
                                                initialNumToRender={10}
                                                maxToRenderPerBatch={10}
                                                windowSize={7}
                                            />
                                                </InfiniteScroll>
                                            </div>
                                        ) : !isFetching &&
                                            <div className='alert alert-danger p-2 text-center'>No Records Found</div>


                                    }

                                  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                title="PDF File"
                centered
                open={visible}
                onOk={() => setVisible(false)}
                onCancel={() => setVisible(false)}
                afterClose={() => document.getElementById("showPDF").innerHTML = ""}
                className="reports-pdf-modal"
                width={1000}
            >
                <div className="PDF" id="showPDF">

                </div>
                <p id="hee">
                    {pdfViewerLoading && <div className='d-flex justify-content-center align-items-center' ><ActivityIndicator size="small" color="#00A1DE" /><Text>Loading. Please wait...</Text></div>}
                </p>
            </Modal>
            <Modal
                title="Lab Result dhdskjhdsksjdhkdsjhs"
                centered
                open={tableModalVisible}
                onOk={() => setTableModalVisible(false)}
                onCancel={() => setTableModalVisible(false)}
                afterClose={() => {
                    tabledata = []
                }}
                className="reports-pdf-modal"
                width={1000}
            >
                <div className="Table" id="showTable">

                    {tabledata && tabledata.length ?
                        <>
                           
                        </>
                        : <span>no data is available</span>
                    }
                 
                   
                </div>
               
            </Modal>
           
        </div>





    )
}


function mapStateToProps(state) {
    return {
        Reports: state.Reports,
        Profile: state.Profile,
        Practise: state.Practise,
        ProxyMenu: state.ProxyMenu,
    }
}

function mapDispatchToProps(disptach) {
    return {
        getReports: (pageSize, pageNumber, searchtext = "", searchFromDate = null, searchToDate = null, status = 'All') => disptach(fetchReportsFromApi(pageSize, pageNumber, searchtext, searchFromDate, searchToDate, status)),
        tokenVal: (history) => disptach(checkToken(history))
        //  getMoreReports: (token, pageSize, pageNumber) => disptach(fetchMoreReportsFromApi(token, pageSize, pageNumber))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PatientResults)
// export default PatientResults
