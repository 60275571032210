import React, { useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Normaize } from "../../shared/core/Normaize";

const getRandomColor = () => {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

const ProxyAccountWebHandler = props => {
    const [iconColor, setIconColor] = useState(getRandomColor());

    return (
        <div className="proxy-accoutlist-items">
            <div className={"proxy-accoutlist-circle-column accountheader"}  >
                <span className="proxy-accoutlist-circle-column-item-date">{props.reInitails}</span>
            </div>
            <div className="proxy-accoutlist-details">
                <div className='d-flex flex-column'>
                    <div className='d-flex justify-content-between'>
                        <p className='proxy-accoutlist-alg-title m-0'>{props.reReceiverName}</p>
                        <p>{props.sentDate}</p>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <p className='proxy-accoutlist-retype'>{props.reType}</p>
                    </div>


                </div>
            </div>
        </div>
    );
};



export default ProxyAccountWebHandler;