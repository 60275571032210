import { Checkbox, Dropdown, Modal, Space, Spin, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import MsgCard from '../MessageListingComp/MsgCard';
import { getMessageByIdAPI } from "../../../../shared/actions/MessagingActions/ListMessagesById";
import { connect, useDispatch, useSelector } from 'react-redux';
import { DownOutlined } from '@ant-design/icons';
import { deleteMessageAPI } from '../../../../shared/actions/MessagingActions/DeleteMessages';
import { listInboxMessageAPI } from '../../../../shared/actions/MessagingActions/ListInboxMessages';
import InfiniteScroll from "react-infinite-scroll-component";
import { flushSync } from 'react-dom';
import { actionAPI } from '../../../../shared/reduxToolkit/actionAPI';

const InboxMessages = (props) => {

    const [isComposeModalOpen, setIsComposeModalOpen] = useState(false);
    const { inboxMessageListing, inboxMessageListingSuccess, inboxMessageListingLoading } = useSelector(state => state.InboxMessagesList)
    const { sendMessage, sendMessageSuccess, sendMessageFailed } = useSelector(state => state.SendingMessage)

    const [inboxMessages, setInboxMessages] = useState([]);
    const [totalRecords, setTotalRecords] = useState(10)
    let limit = 25
    let offset = 1
    const { deleteMessage, deleteMessageSuccess } = useSelector(state => state.DeleteMessage)
    const apiDispatcher = useDispatch()
    //   let selectMsgsArr = []
    // let totalRecords = 100
    const showComposeModal = () => {
        setIsComposeModalOpen(true)
    }
    const getMsgProfileColor = (index) => {
        let colorList = ["#ffa358", "#f65747", "#47b2f6"]
        let colorIndex = index
        if (colorIndex > (colorList.length - 1)) {
            colorIndex = index % colorList.length
        }
        return colorList[colorIndex]
    }
    const getInboxMessagesAPI = () => {

        limit = 25;
        offset = Math.ceil(inboxMessages.length / limit) + 1;
        if (offset == 1) {
            setInboxMessages([])
        }
        apiDispatcher(actionAPI.ListInboxMessagesAPI({
            type: 1, messagingType: 1, pageNumber: offset, pageSize: limit, readFilter: (props.currentActiveFilter == "Read" ? 2 : props.currentActiveFilter == "Unread" ? 1 : 0)
        }))
    };
    const getInboxMessagesFromStartAPI = () => {
        limit = 25;
        offset = 1;

        apiDispatcher(actionAPI.ListInboxMessagesAPI({
            type: 1, messagingType: 1, pageNumber: offset, pageSize: limit, readFilter: (props.currentActiveFilter == "Read" ? 2 : props.currentActiveFilter == "Unread" ? 1 : 0)
        }))
    };

    // useEffect(()=>{
    //     getInboxMessagesAPI()
    // },[])


    useEffect(() => {

        if (inboxMessageListingSuccess && inboxMessageListing) {

            setTotalRecords(inboxMessageListing[0].total)
            if (inboxMessageListing[0]?.entry) {
                if (inboxMessages.length > 0 && inboxMessageListing[0].entry[0].resource.id == inboxMessages[0].resource.id) {
                    setInboxMessages(inboxMessageListing[0].entry);

                } else {

                    const mergeData = [...inboxMessages, ...inboxMessageListing[0]?.entry];

                    setInboxMessages(mergeData);
                }

            }

        }

    }, [inboxMessageListing])

    const fetchmoreInboxData = (ignore = false) => {
        if (ignore || inboxMessages.length < totalRecords) {
            getInboxMessagesAPI();
        }
    }
    useEffect(() => {
        if (deleteMessageSuccess) {
            // handleDeleteMessage()
            setInboxMessages([])
            getInboxMessagesFromStartAPI()
        }
    }, [deleteMessageSuccess])
    useEffect(() => {
        if (sendMessageSuccess) {
            // handleDeleteMessage()
            setInboxMessages([])
            getInboxMessagesFromStartAPI()
        }
    }, [sendMessageSuccess])
    useEffect(() => {
        if (props.refreshTrigger > 0) {
            setInboxMessages([])
            getInboxMessagesFromStartAPI()
            props.setRefreshTrigger(0)
        }
    }, [props.refreshTrigger])
    useEffect(() => {

        flushSync(() => {
            setInboxMessages([])
            setTotalRecords(10)

        })
        getInboxMessagesFromStartAPI(true)
    }, [props.currentActiveFilter])
    return (
        <>

            <div className="msgList">
                <div id="scrollableSentDiv" className='w-100 h-100 msgList-scrollable-div'>

                    {inboxMessageListing != null && inboxMessages.length == 0 && !inboxMessageListingLoading && <div className="d-flex justify-content-center mt-3 text-secondary">{props.currentActiveFilter == "All" ? "Inbox is empty" : props.currentActiveFilter == "Read" ? "No Read Messages" : "No Unread Messages"}</div>}

                    <InfiniteScroll
                        dataLength={inboxMessages.length}
                        next={fetchmoreInboxData}
                        hasMore={inboxMessages.length < totalRecords}
                        loader={<div className='d-flex justify-content-center my-2 mt-3'><Spin /></div>}

                        scrollableTarget="scrollableSentDiv"
                    >
                        {inboxMessages.length > 0 ? inboxMessages.map((message, index) => (
                            <MsgCard
                                handleCheckChanged={() => props.handleCheckChanged(message)}
                                msgChecked={props.selectAllMsgsChecked}
                                key={message.resource.id}
                                sender={message?.resource?.extension[2]?.valueString?.replace("Super Admin USER", "System")}
                                subject={message?.resource?.note ? message?.resource?.note[0]?.text : ""}
                                DMSID={
                                    message?.resource?.extension[9]?.valueDateTime || message?.resource?.extension[9]?.valueDateTime !== ""
                                }
                                date={
                                    message?.resource?.sent?.slice(8, 10) +
                                    "-" +
                                    message?.resource?.sent?.slice(5, 7) +
                                    "-" +
                                    message?.resource?.sent?.slice(0, 4)
                                }
                                imgSrc={null}
                                isRead={
                                    message?.resource?.extension[5]?.valueString == "2"
                                }
                                onSelect={() => {
                                    localStorage.setItem("activeMessage", JSON.stringify(message));
                                    props.setActiveMessage(message);
                                    apiDispatcher(actionAPI.GetMessageByIdAPI({
                                        messageId: message.resource.id, messageNum: (index + 1)
                                    }))
                                }}
                                isActive={
                                    localStorage.getItem("activeMessage") &&
                                        JSON.parse(localStorage.getItem("activeMessage")).resource.id ===
                                        message.resource.id
                                        ? true
                                        : false
                                }
                                profileColor={getMsgProfileColor(index)}
                            />
                        )) : ""}
                    </InfiniteScroll>
                </div>

            </div>
            {/* <div
              className="composeMsgIcon d-flex align-items-center justify-content-center"
              onClick={showComposeModal}
          >
              <svg
                  id="Plus_-_Free_shapes_and_symbols_icons-2_1_"
                  data-name="Plus - Free shapes and symbols icons-2 (1)"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 39.615 39.615"
              >
                  <g id="Group_2985" data-name="Group 2985">
                      <path
                          id="Path_3744"
                          data-name="Path 3744"
                          d="M36.133,16.326H23.289V3.482a3.482,3.482,0,1,0-6.964,0V16.326H3.482a3.482,3.482,0,1,0,0,6.964H16.326V36.133a3.482,3.482,0,1,0,6.964,0V23.289H36.133a3.482,3.482,0,1,0,0-6.964Z"
                          fill="#fff"
                      />
                  </g>
              </svg>
          </div>
          {isComposeModalOpen && (
              <ComposeMsgModal
                  handleCancel={()=>{setIsComposeModalOpen(false)}}
                  handleOk={() => { setIsComposeModalOpen(false) }}
                  isComposeModalOpen={isComposeModalOpen}
              />)} */}

        </>
    )
}
function mapStateToProps(state) {
    return {
        InboxMessages: state.InboxMessagesReducer,
        messageByID: state.MessageByIdReducer,
        DeleteMessage: state.DeleteMessagesReducer,
        SendingMessage: state.SendingMessagesReducer,

    }
}
function mapDispatchToProps(disptach) {
    return {
        deleteMessage: (values) => disptach(deleteMessageAPI(values)),
        listInboxMessages: (values) => disptach(listInboxMessageAPI(values)),
        getMsgByID: (values) => disptach(getMessageByIdAPI(values)),

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InboxMessages)
// export default
