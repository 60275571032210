import { START_VALIDATION, START_VALIDATION_SUCCESS, START_VALIDATION_FAILED, START_VALIDATION_REFRESH_DATA } from "../constants/Constant";
import checkPatientToken from "../services/TokenValidationService";
import getUserId from "../core/GetToken";
import Settings from "../config/Settings";

import { Modal, Button, Space, Alert } from 'antd';
import { refreshProxyListData } from "./ProxyActions";
import { RefreshApplicationSettings } from "./GetApplicationSettingAction";
import { clearUserState } from "./AuthActions";
import { resetIdentityServerData } from "./IdentityServerActions";



export const validatePatientTokenFromApi = (history) => {

    return (dispatch) => {
        dispatch(validateToken())
        getUserId().then((userInfo) => {

            const item = JSON.parse(userInfo);
            const queryString = '?Token=' + item.token;
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token);
            //  myHeaders.append("Cookie", ".Stackify.Rum=462da2cc-f704-4f17-a355-2b2660c6762f");

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            fetch(Settings.apiUrl + "ValidateToken", requestOptions)
                .then(data => data.json())
                .then(json => {
                   
                    if (!json) {
                        resetIdentityServerData()
                        clearUserState()
                        refreshProxyListData()
                        RefreshApplicationSettings()
                        dispatch(validateTokenRefreshData())
                        history('/login')
                        return
                    }
                    // history.replace('/')

                    dispatch(validateTokenSuccess(json))
                })
                .catch(err => {
                   

                    dispatch(validateTokenFailed(err))
                    history('/login')
                })


            // checkPatientToken(queryString)
            // .then(data => data.json())
            // .then(json => {
           
            //     dispatch(validateTokenSuccess(json))
            // })
            //     .catch(err => dispatch(validateTokenFailed(err)))
        })
    }
}
export const refreshTokenValidatorData = () => {
    return (dispatch) => {
        dispatch(validateTokenRefreshData())
    }
}
export const checkToken = (history) => {
    return (dispatch) => {
        // dispatch(validateToken())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            // const queryString = '?Token=' + item.token;



            var myHeaders = new Headers();
            myHeaders.append("Token", item.token);
            //  myHeaders.append("Cookie", ".Stackify.Rum=462da2cc-f704-4f17-a355-2b2660c6762f");

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            fetch(Settings.apiUrl + "ValidateToken", requestOptions)
                .then(data => data.json())
                .then(json => {
                   
                    if (!json) {
                       // alert("out")
                        history('/', { replace: true })
                    }
                    // history.replace('/')
                    // dispatch(validateTokenFailed(json))
                })
                .catch(err => {
                   
                    history('/login')
                    dispatch(validateTokenFailed(false))
                })



        }).catch(error => {
          
            // Modal.warning({
            //     title: 'This is a warning message',
            //     content: 'some messages...some messages...',
            // });
            // alert("hello")
            window.location.reload();
            // dispatch(validateTokenFailed(false))
            // dispatch(showModal(false))

            history('/login')

        })
    }
}


const validateToken = () => {
    return {
        type: START_VALIDATION
    }
}

const validateTokenRefreshData = () => {
    return {
        type: START_VALIDATION_REFRESH_DATA
    }
}


const validateTokenSuccess = (data) => {
    return {
        type: START_VALIDATION_SUCCESS,
        data
    }
}

const validateTokenFailed = () => {
    return {
        type: START_VALIDATION_FAILED
    }
}
const showModal = () => {
    return {
        type: "show_Modal"
    }
}