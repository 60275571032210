import Settings from '../config/Settings'


const defaultHeaders = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
}

//expor the dulft get fetch api call

export const get = (endpoint, requestOptions) => (
    fetch(`${Settings.apiUrl}${endpoint}`, requestOptions)
);

export const getPatientById = () => { }