import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from "react-redux";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import { View, Text, ActivityIndicator, Switch } from "react-native-web";
import ProfileItem from '../components/ProfileItem';
import { fetchProfileFromApi } from "../../shared/actions/ProfileActions";
import { fetchPractisefromAPI } from "../../shared/actions/PractiseAction";
import { GetAppointmentDate, GetMonthName, GetYear, capitalize } from '../../shared/core/datehelper'
import ProfileItemB from '../components/ProfileItemB';
import ProfileItemSingle from '../components/ProfileItemSingle';
import getUserId from '../../shared/core/GetToken';
import { TouchableOpacity } from 'react-native';
import { Modal, Button } from "react-bootstrap";
import changePasswrodService from '../../shared/services/ChangePasswrod';
import { passwordValidator } from '../../shared/core/utils';
import AsyncStorage from "@react-native-async-storage/async-storage";
import getUserInfo from '../../shared/core/GetTokenized';
import Settings from '../../shared/config/Settings';

// import "../components/css/profile.css"

import { checkToken } from '../../shared/actions/TokenValidActions';
import { actionAPI } from '../../shared/reduxToolkit/actionAPI';

const useNavigate = require("react-router-dom").useNavigate;

const UserProfile = props => {
    const history = useNavigate()
    const [showReset, setShowReset] = useState(false);
    const [isResetPassword, setIsResetPassword] = useState(false)
    const [passwordStartMessage, setPasswordStartMessage] = useState({
        error: false,
        message: ''
    })

    const [isloading, setIsLoading] = useState(false);
    const [isloadingNot, setIsLoadingNot] = useState(false);
    const [isEnabled, setIsEnabled] = useState(false);
    const apiDispatcher = useDispatch()
    const toggleSwitch = (emailVal, pushVal, tokenVal) => {
       
        setIsLoading(true);
        emailVal
            ? callApi(false, pushVal, tokenVal)
            : callApi(true, pushVal, tokenVal);
    };



    const toggleSwitchPushNotes = (emailVal, pushVal, tokenVal) => {
        setIsLoadingNot(true);
        pushVal
            ? callApi(emailVal, false, tokenVal)
            : callApi(emailVal, true, tokenVal);
    };



    const handleCloseReset = () => {
        setShowReset(false)
        // testFunction(AuthUser[0].extension[0].valueString, AuthUser[0].id)

    };
    const resetPasswordFunc = async () => {

        let password = document.getElementById("password").value;
        let confirmPassword = document.getElementById("confirmPassword").value;

        const passwordError = passwordValidator(password);
        const cnfrmPaswrd = passwordValidator(confirmPassword);
        if (passwordError) {
            document.getElementById("errorMessageReset").innerHTML = "Password cannot be empty "
            return;
        }
        if (cnfrmPaswrd) {
            document.getElementById("errorMessageReset").innerHTML = "Confirm Password cannot be empty "
            return;
        }
        if (password !== confirmPassword) {
            document.getElementById("errorMessageReset").innerHTML = "Passwords did not matched. Please try again"
            return
        }
        //check for lower case
        if (!password.match(/[a-z]/)) {
            alert("Password must contain at least one lower case letter.");
            // passForm.passInput.focus();
            return;
        }

        //Validating length
        if (password.length < 8) {
            alert("Your password has less than 8 characters.");
            return;
        }

        //check for upper ase
        if (!password.match(/[A-Z]/)) {
            alert("Password must contain at least one upper case letter.");
            // passForm.passInput.focus();
            return;
        }
        var patt = new RegExp("^(?=(.*[a-zA-Z]){1,})(?=(.*[0-9]){2,}).{8,}$");
        //check for number
        if (!patt.test(password)) {
            alert("Password must contain at least two number.");
            // passForm.passInput.focus();
            return;
        }
        setIsResetPassword(true)
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            const queryString = '?Token=' + item.token;
           
            var jsonPa = JSON.stringify({ "PatientID": item.parentid, "Password": password })
            changePasswrodService(queryString, jsonPa).then(data => data.json())
                .then(json => {
                  
                    if (json.StatusCode == "1") {
                        // for activity indicatorr
                        setIsResetPassword(false)
                        setPasswordStartMessage(prevState => ({ ...prevState, error: false, message: json.StatusMessage }))
                        alert(json.StatusMessage)
                        handleCloseReset()

                    
                    }
                    // {"StatusCode":3,"StatusMessage":"three time password error try to some differect passsword"}
                    if (json.StatusCode == "3") {
                        setPasswordStartMessage(prevState => ({ ...prevState, error: true, message: json.StatusMessage }))
                        setIsResetPassword(false)
                       
                    }
                })
                .catch(err => {
                    setPasswordStartMessage(prevState => ({ ...prevState, error: true, message: "Some thing went wrong" }))

                })
        })
        // check remining confirm and passweordk

    }
    const callApi = (mail, push, tok) => {

        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            var raw = JSON.stringify({
                resourceType: "Patient",
                id: item.userid,
                extension: [
                    {
                        url: "PatientDeviceToken",
                        valueString: tok,
                    },
                    {
                        url: "EnableEmail",
                        valueBoolean: mail,
                    },
                    {
                        url: "EnableNotification",
                        valueBoolean: push,
                    },
                ],
            });

            // alert(isEnabled)

            var requestOptions = {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: raw,
                redirect: "follow",
            };
          
            //alert(raw)
            fetch(
                Settings.apiUrl + "Patient?Token=" + item.token + "",
                requestOptions
            )
                .then((response) => response.json())
                .then((result) => {
                    setIsLoading(false);
                    setIsLoadingNot(false);

                    apiDispatcher(actionAPI.GetUserProfileAPI())
                })
                .catch((error) => {
                    setIsLoading(false);
                    setIsLoadingNot(false);
                });

        });
    };


    const addressNodes = ['Address Line 1', 'Address Line 2', 'Address Line 3', 'City/County', 'Country', 'Eircode']

    const { Profile, isFetching } = useSelector(state => state.UserProfile);
    const { Practise } = useSelector(state => state.PracticeOrganization);
    const [isloaded, setIsload] = React.useState(false);



    useEffect(() => {
        apiDispatcher(actionAPI.GetUserProfileAPI())

        apiDispatcher(actionAPI.GetPracticeOrganizationDataAPI())


    }, []);
    React.useEffect(() => {
        apiDispatcher(actionAPI.checkToken())

    }, [])

    const getByTitle = item => {

        if (item.system === "email" && item.use === "home") {
            return "Primary Email"
        }
        else if (item.system === "email" && item.use === "temp") {
            return "Secondary Email"
        }
        else {
            return capitalize(item.use)
        }
        //  item.system === "email" && item.use ==="home" ?   capitalize(item.use)
    }


    const [showincon, setshowicon] = useState('mdi mdi-eye')
    const showPassword = () => {
        var x = document.getElementById("password");
        if (x.type === "password") {
            setshowicon('mdi mdi-eye-off')
            x.type = "text";
        } else {
            setshowicon('mdi mdi-eye')
            x.type = "password";
        }
    }


    const showPasswordConfirm = () => {
        var x = document.getElementById("confirmPassword");
        if (x.type === "password") {
            setshowicon('mdi mdi-eye-off')
            x.type = "text";
        } else {
            setshowicon('mdi mdi-eye')
            x.type = "password";
        }
    }

    return (

        <div className="page-content-wrapper-inner">
            <div className="viewport-header">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb has-arrow">
                        <li className="breadcrumb-item">
                            <a href="#">Dashboard</a>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">Profile</li>
                    </ol>
                </nav>
            </div>
            <div className="content-viewport">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="row mb-2">
                            <div className="col-md-8 mx-auto">
                                <div className="alert alert-warning" role="alert">
                                    <h6 className="alert-heading">Please Note:</h6>

                                    <span>
                                        {isFetching && <ActivityIndicator color='blue' size='small' />}
                                        {
                                            typeof Profile !== "undefined" && Profile.length ? (
                                                <span>{Profile[0].resource.extension[7].valueString}</span>
                                            ) : null
                                        }
                                    </span>

                                </div>
                            </div>
                        </div>
                        <div className="grid">
                            <div className='flex-column justify-content-space-between'>
                                <p className="grid-header">Personal Information</p>

                            </div>


                            <div className="grid-body">
                                <div className="item-wrapper">

                                    <div className="row">
                                        <div className="col-md-8 mx-auto">
                                            <div className='d-flex h-30px' >
                                                {
                                                    isFetching && <ActivityIndicator size="small" />
                                                }
                                            </div>
                                            {
                                                typeof (Profile) !== 'undefined' && Profile.length ? (
                                                    <ProfileItem
                                                        title_A="Title"
                                                        value_A={typeof (Profile[0].resource.name[0].prefix) !== 'undefined' ? Profile[0].resource.name[0].prefix[0] : null}
                                                        title_B="Name"
                                                        value_B={typeof (Profile[0].resource.name) !== 'undefined' ? Profile[0].resource.name[0].given[0] : null}
                                                    />


                                                ) : null
                                            }


                                            {
                                                typeof (Profile) !== 'undefined' && Profile.length ? (
                                                    <ProfileItem
                                                        title_A="Family Name"
                                                        value_A={typeof (Profile[0].resource.name[0].family) !== 'undefined' ? Profile[0].resource.name[0].family : null}
                                                        title_B="Preferred  Name"
                                                        value_B=""
                                                    />


                                                ) : null
                                            }


                                            {
                                                typeof (Profile) !== 'undefined' && Profile.length ? (
                                                    <ProfileItem
                                                        title_A="Gender"
                                                        value_A={typeof (Profile[0].resource.gender) !== 'undefined' ? capitalize(Profile[0].resource.gender) : null}
                                                        title_B="DOB"
                                                        value_B={typeof (Profile[0].resource.birthDate) !== 'undefined' ? GetAppointmentDate(Profile[0].resource.birthDate) + '-' + GetMonthName(Profile[0].resource.birthDate) + '-' + GetYear(Profile[0].resource.birthDate) : null}
                                                    />


                                                ) : null
                                            }







                                            {
                                                typeof (Profile) !== 'undefined' && Profile.length && Profile[0].resource.extension.map((value, index) => {
                                                    if (value.url == "Ethnicity") {
                                                        return < ProfileItem
                                                            title_A="Ethnicity"
                                                            value_A={value.valueString}
                                                            title_B=""
                                                            value_B=""
                                                        />
                                                    }

                                                })
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12">
                        <div className="grid">
                            <p className="grid-header">Address Information</p>
                            <div className="grid-body">
                                <div className="item-wrapper">
                                    <div className="row">
                                        <div className="col-md-12 mx-auto">
                                            {/* {
                                                            isFetching && <ActivityIndicator size="small" />
                                                        } */}

                                            {/* {
                                                            typeof (Profile) !== 'undefined' && Profile.length ? (
                                                                <ProfileItemB title="Primary Address" address={Profile[0].resource.address[0].text + ', ' + Profile[0].resource.address[0].country} />

                                                            ) : null
                                                        } */}

                                            {
                                                typeof (Profile) !== 'undefined' && Profile.length && Profile[0].resource.address !== undefined ? Profile[0].resource.address[0].line.map((item, index) => {
                                                    return (

                                                        <ProfileItemB title={addressNodes[index]} address={item} />

                                                    )
                                                }) : <span>No address found.</span>
                                            }



                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row">
                    <div className="col-lg-12">
                        <div className="grid">
                            <p className="grid-header">Contact Information</p>
                            <div className="grid-body">
                                <div className="item-wrapper">
                                    <div className="row mb-3">
                                        <div className="col-md-8 mx-auto">
                                            {/* title goe here */}



                                            {
                                                typeof (Profile) !== 'undefined' && Profile.length && Profile[0].resource.telecom.map((item) => {
                                                    return (

                                                        <ProfileItemSingle contactTile={getByTitle(item)} contactValue={item.value} />

                                                    )
                                                })
                                            }



                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12">
                        <div className="grid">
                            <p className="grid-header">Centre Details</p>
                            <div className="grid-body">
                                <div className="item-wrapper">
                                    <div className="row">
                                        <div className="col-md-12 mx-auto">
                                            {/* {
                                                            isFetching && <ActivityIndicator size="small" />
                                                        } */}


                                            {/* {
                                                            typeof (Practise) !== 'undefined' && Practise.length ? (
                                                                Practise.map((p, i) => {

                                                                    return <ProfileItemB title="Center Name" address={p.resource.extension[4] !== undefined ? p.resource.extension[4].valueString : 'N/A'} />
                                                                })
                                                            ) : <ProfileItemB title="Center Name" address={'N/A'} />

                                                        } */}
                                            {

                                                typeof (Practise) !== 'undefined' && Practise.length ? (
                                                    Practise[0].resource.extension.map((person, i) => {
                                                        if (person.url === 'Practice Location') {
                                                            return <ProfileItemB title="Centre Name" address={person.url === 'Practice Location' ? person.valueString : 'N/A'} />
                                                            // return <ProfileInfoList key={i} title="Center Name" profileData={person.url === 'Practice Location' ? person.valueString : 'N/A'} />//{person.resource.extension[4] !== undefined ? person.resource.extension[4].valueString : 'N/A'} />
                                                        }
                                                    })
                                                ) : <ProfileItemB title="Centre Name" address={'N/A'} />

                                            }

                                            <div className='justify-content-center align-item-center'>

                                                <div className='userprofile-inner-container' >
                                                    <div className='d-flex flex-column' >
                                                        <div className='text-bold margin-top-ten'>National Coagulation Centre:</div>
                                                        <div className='text-bold margin-top-ten'>Children’s Health Ireland:</div>
                                                        <div className='text-bold margin-top-ten'>Cork University Hospital:</div>
                                                        <div >University Hospital Galway:</div>
                                                    </div>
                                                    <div className='d-flex flex-column'>
                                                        <div className='text-bold margin-top-ten'><a target="_blank" href="http://www.stjames.ie/Departments/DepartmentsA-Z/N/NationalCoagulationCentre">Visit</a></div>
                                                        <div className='text-bold margin-top-ten'><a href="https://www.olchc.ie/" target="_blank">Visit</a></div>
                                                        <div className='text-bold margin-top-ten'><a target="_blank" href="https://haemophilia.ie/about-haemophilia/treatment-centres-in-ireland/cork-university-hospital-treatment-centre/">Visit</a></div>
                                                        <div className='text-bold margin-top-ten'><a target="_blank" href="https://www.saolta.ie/hospital/university-hospital-galway">Visit</a></div>
                                                    </div>
                                                </div>

                                            </div>





                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row">
                    <div className="col-lg-12">
                        <div className="grid">
                            <p id="noti" className="grid-header">Notification Settings</p>
                            <div className="grid-body">
                                <div className="item-wrapper">
                                    <div className="row">
                                        <div className="col-md-12 mx-auto">
                                            {/* {
                                                            isFetching && <ActivityIndicator size="small" />
                                                        } */}
                                            <div className='userprofile-notfications'>
                                                <div className='notification-enable-text' >Enable Email Notifications: </div>


                                                {Profile.map((person, i) => {
                                                    return (
                                                        <>
                                                            {
                                                                isloading && <ActivityIndicator className='padding-right-ten' size="small" />
                                                            }
                                                            <Switch
                                                                trackColor={{ false: "#767577", true: "#81b0ff" }}
                                                                thumbColor={true ? "#fff" : "#f4f3f4"}
                                                                ios_backgroundColor="#3e3e3e"
                                                                onValueChange={() =>


                                                                    person.resource.extension.length > 6 ? (
                                                                        toggleSwitch(
                                                                            person.resource.extension[5] !== undefined
                                                                                ? person.resource.extension[5].valueBoolean
                                                                                : false,
                                                                            person.resource.extension[6] !== undefined
                                                                                ? person.resource.extension[6].valueBoolean
                                                                                : false,
                                                                            person.resource.extension[4] !== undefined
                                                                                ? person.resource.extension[4].valueString
                                                                                : 'N/A'
                                                                        )
                                                                    ) : toggleSwitch(
                                                                        person.resource.extension[4] !== undefined
                                                                            ? person.resource.extension[4].valueBoolean
                                                                            : false,
                                                                        person.resource.extension[5] !== undefined
                                                                            ? person.resource.extension[5].valueBoolean
                                                                            : false,
                                                                        person.resource.extension[3] !== undefined
                                                                            ? person.resource.extension[3].valueString
                                                                            : 'N/A'
                                                                    )

                                                                }
                                                                value={
                                                                    person.resource.extension.length > 6 ? (
                                                                        person.resource.extension[5] !== undefined
                                                                            ? person.resource.extension[5].valueBoolean
                                                                            : false
                                                                    ) : (
                                                                            person.resource.extension[4] !== undefined
                                                                                ? person.resource.extension[4].valueBoolean
                                                                                : false
                                                                        )


                                                                }
                                                                disabled={isloading}
                                                            />
                                                        </>
                                                    );
                                                })}
                                            </div>


                                            <div className='userprofile-notfications'>
                                                <div className='notification-enable-text' >Enable In-App Notifications: </div>


                                                {Profile.map((person, i) => {
                                                    return (
                                                        <>
                                                            {
                                                                isloadingNot && <ActivityIndicator className='padding-right-ten' size="small" />
                                                            }
                                                            <Switch
                                                                trackColor={{ false: "#767577", true: "#81b0ff" }}
                                                                thumbColor={true ? "#fff" : "#f4f3f4"}
                                                                ios_backgroundColor="#3e3e3e"
                                                                onValueChange={() =>


                                                                    person.resource.extension.length > 6 ? (
                                                                        toggleSwitchPushNotes(
                                                                            person.resource.extension[5] !== undefined
                                                                                ? person.resource.extension[5].valueBoolean
                                                                                : false,
                                                                            person.resource.extension[6] !== undefined
                                                                                ? person.resource.extension[6].valueBoolean
                                                                                : false,
                                                                            person.resource.extension[4] !== undefined
                                                                                ? person.resource.extension[4].valueString
                                                                                : 'N/A'
                                                                        )
                                                                    ) : toggleSwitchPushNotes(
                                                                        person.resource.extension[4] !== undefined
                                                                            ? person.resource.extension[4].valueBoolean
                                                                            : false,
                                                                        person.resource.extension[5] !== undefined
                                                                            ? person.resource.extension[5].valueBoolean
                                                                            : false,
                                                                        person.resource.extension[3] !== undefined
                                                                            ? person.resource.extension[3].valueString
                                                                            : 'N/A'
                                                                    )

                                                                }
                                                                value={
                                                                    person.resource.extension.length > 6 ? (
                                                                        person.resource.extension[6] !== undefined
                                                                            ? person.resource.extension[6].valueBoolean
                                                                            : false
                                                                    ) : (
                                                                            person.resource.extension[5] !== undefined
                                                                                ? person.resource.extension[5].valueBoolean
                                                                                : false
                                                                        )


                                                                }
                                                                disabled={isloadingNot}
                                                            />
                                                        </>
                                                    );
                                                })}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12">
                        <div className="grid">
                            <p className="grid-header">Update Password</p>
                            <div className="grid-body">
                                <div className="item-wrapper">
                                    <div className="row">
                                        <div className="col-md-12 mx-auto">
                                            <div className='update-password-container' >

                                                <div className='update-password-inner-container'  >
                                                    <div className='d-flex flex-column' >
                                                        <TouchableOpacity onPress={() => { setShowReset(true) }}>
                                                            <button className="btn btn-primary btn-block"> Update My Password </button>
                                                        </TouchableOpacity>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="grid">
                            <p className="grid-header">Term and condition</p>
                            <div className="grid-body">
                                <div className="item-wrapper">
                                    <div className="row">
                                        <div className="col-md-12 mx-auto">
                                            <div className='d-flex justify-content-center align-item-center'>

                                                <div className='term-condition-div'>
                                                    <TouchableOpacity onPress={() => {
                                                        const win = window.open("https://www.google.com/", '_blank');
                                                        if (win != null) {
                                                            win.focus();
                                                        }
                                                    }}>
                                                        <button className="btn btn-primary btn-block">View Provicy</button>
                                                    </TouchableOpacity>
                                                    <TouchableOpacity onPress={() => {
                                                        const win = window.open("https://www.google.com/", '_blank');
                                                        if (win != null) {
                                                            win.focus();
                                                        }
                                                    }}>
                                                        <button className="btn btn-primary btn-block"> View Conditon</button>
                                                    </TouchableOpacity>

                                                    {/* </View> */}
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={showReset} onHide={() => handleCloseReset()} backdrop="static"
                keyboard={false}>
                <Modal.Header className="modal-header header-primary-bg" closeButton>
                    <Modal.Title className="modal-title text-white">Update your password to continue.</Modal.Title>
                </Modal.Header>
                <Modal.Body>


                    <div>
                        <div className='password-rule-div  mb-4' >
                            <Text > <strong> Password Rules </strong> </Text>
                            <br />
                            <Text>Password must be at least 8 characters.</Text>
                            <br />
                            <Text>Password must contain at least one alphabet and two digits.</Text>
                            <br />
                            <Text>Password must contain at least one upper case letter.</Text>
                            <br />
                            <Text>Password must not contain first, middle or family name.</Text>
                            <br />
                            <Text>Example: Abc@1234</Text>
                        </div>

                        <div className="form-group">
                            <div className="input-group">
                                <input autoComplete="off" id="password" type="password" className="form-control" placeholder="Password" />
                                <div className="input-group-append">
                                    <div onClick={() => showPassword()} className="input-group-text"><i className={showincon}></i></div>
                                </div>
                            </div>
                        </div>

                        <div className="form-group">
                            <div className="input-group">
                                <input autoComplete="off" id="confirmPassword" type="password" className="form-control" placeholder="Password" />
                                <div className="input-group-append">
                                    <div onClick={() => showPasswordConfirm()} className="input-group-text"><i className={showincon}></i></div>
                                </div>
                            </div>
                        </div>

                        <div className="form-group input-rounded">
                            <p id="errorMessageReset" className='text-red'></p>
                        </div>
                        {
                            isResetPassword && <div className='text-align-center d-flex margin-bottom-five'  ><ActivityIndicator size="small" color="#00A1DE" /></div>
                        }
                        {
                            passwordStartMessage.error && <div className='text-align-center d-flex margin-bottom-five'><Text className='text-red'>{passwordStartMessage.message}</Text></div>
                        }
                        <button onClick={() => resetPasswordFunc()} className="btn btn-primary btn-block">Set Password </button>

                    </div>
                </Modal.Body>

            </Modal>
        </div>





    )
}

function mapStateToProps(state) {
    return {
        Profile: state.Profile,
        Practise: state.Practise
    }
}

function mapDispatchToProps(dispatch) {
    return {
        // getProfile:
        getProfile: () => dispatch(fetchProfileFromApi()),
        getPracticeCenterDetail: () => dispatch(fetchPractisefromAPI()),
        tokenVal: (history) => dispatch(checkToken(history))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
