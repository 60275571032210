// import { AsyncStorage } from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';


const getUserInfo = async () => {
    let userId = '';
    try {
        userId = await AsyncStorage.getItem('@userToken') || 'none';

    } catch (error) {
        // Error retrieving data
      
    }
 
    return userId;
}

export default getUserInfo