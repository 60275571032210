import AsyncStorage from "@react-native-async-storage/async-storage";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Settings from "../../../config/Settings";
import getUserId from "../../../core/GetToken";

export const fetchAllergyFromApi = createAsyncThunk(
    "fetchAllergyFromApi/Allergy",
    async (values, { dispatch, getState }) => {

        dispatch(getAlg())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);


            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = {
                PatientID: item.userid,
                PageSize: values.pageSize,
                PageNumber: values.pageNumber,
            }
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(raw)
            };

            // token = data

            fetch(Settings.apiUrl + "Patient/AllergyIntolerance", requestOptions)
                .then(data => data.json())
                .then(json => {
                    // dispatch(getAlgSuccess(json[0].entry))
                    json[0].hasOwnProperty('entry') ? dispatch(getAlgSuccess(json[0].entry)) : dispatch(getAlgFailed())
                })
                .catch(err => dispatch(getAlgFailed()))


        })


    }

);

const AllergyIntolerenceSlice = createSlice({
    name: "fetchAllergyFromApi",
    initialState: {
        Allergies: [],
        isGetting: false,
        error: false,
    },
    reducers: {
        getAlg: (state, action) => {
            state.Allergies = []
            state.isGetting = true
            state.error = false

        },
        getAlgSuccess: (state, action) => {
            state.Allergies = action.payload
            state.isGetting = false
            state.error = false

        },
        getAlgFailed: (state, action) => {
            state.Allergies = []
            state.isGetting = false
            state.error = true

        },

    },
});




export const {
    getAlg, getAlgFailed, getAlgSuccess
} = AllergyIntolerenceSlice.actions;
export default AllergyIntolerenceSlice.reducer;
