import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { fetchUserInformation } from "../../shared/actions/AuthActions";
import { View, TouchableOpacity, FlatList, ActivityIndicator } from "react-native-web";
import { emailValidator, getInitials } from '../../shared/core/utils';
import forGotPasswordApi from '../../shared/services/ForgotPasswordServices';
import Settings from '../../shared/config/Settings';
import indicilogo from "../assets/indicilogo.png";
import ReCAPTCHA from "react-google-recaptcha";
const ForgetPassword = props => {
    const dispatch = useDispatch();

    const [isForgetPassword, setIsForgetPassword] = useState(false)
    const [isValidated, setIsValidated] = useState(true)
    const navigate = useNavigate()

    function onChange(value) {
        setIsValidated(false)
    }


    function onExpired(value) {
        setIsValidated(true)
    }

    const resetPassword = (e) => {

        document.getElementById("errorMessage").innerHTML = "";

        var email = document.getElementById("useName").value;

        const emailError = emailValidator(email);
        if (emailError) {
            document.getElementById("errorMessage").innerHTML = "You have entered an invalid email address.";
            return;
        }
        getApiURL(email)


    }

    const getApiURL = (email) => {
        setIsForgetPassword(true)

        var myHeaders = new Headers();

        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify(
            {
                "Email": email
            }
        );

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_API_AUTH_IDENTITY_URL.replace("Authentication2", "")}GetApiUrl`, requestOptions)
            .then(res => res.json())
            .then(data => {
                if (data?.APIURL !== "") {
                    passAuthServer(email, data?.APIURL)
                } else {
                    document.getElementById("errorMessage").innerHTML = "Invalid email address"
                    setIsForgetPassword(false)
                }
            })
            .catch(err => {
                document.getElementById("errorMessage").innerHTML = "Some Error Occured"
                setIsForgetPassword(false)

            })
    }
    //***************************************************************** api call server auth***********************************
    const passAuthServer = (email, apiUrl) => {
        setIsForgetPassword(true)


        var myHeaders = new Headers();

        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify(
            {
                "Username": email
            }
        );

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };





        fetch(apiUrl + "Patient/ForgotPassword", requestOptions)
            .then(res => res.json())
            .then(data => {
                if (data.StatusCode == "1") {
                    document.getElementById("errorMessage").innerHTML = data.ResponseMessage;
                    setIsForgetPassword(false)

                } else if (data.StatusCode == "0") {

                    document.getElementById("errorMessage").innerHTML = data.ResponseMessage;
                    setIsForgetPassword(false)
                } else {
                    document.getElementById("errorMessage").innerHTML = "Something went wrong. Please try again.";
                    setIsForgetPassword(false)
                }

            }).catch(err => {
                setIsForgetPassword(false)
            })

    }
    //***************************************************************** End of call server auth***********************************

    return (
        <div className="authentication-theme auth-style_1">

            <div className="row">
                <div className="col-lg-5 col-md-7 col-sm-9 col-11 mx-auto">
                    <div className="grid forget-pass-grid">
                        <img src="assets/images/back-arrow.svg" onClick={() => navigate("/")} className="forget-pass-back-button" alt="Back" />
                        <div className="grid-body">
                            <div className="row">
                                <div className="col-lg-7 col-md-8 col-sm-9 col-12 mx-auto form-wrapper">

                                    <div className="form-group">
                                        <div className="col-12 logo-section">
                                            <a className="logo cursor-default">
                                                {/* <img className='indici-logo-forget-pass' src={indicilogo} alt="logo" /> */}
                                                <img src="assets/images/indicilogo.svg" className="login-sideimg" alt="Login" />

                                            </a>
                                        </div>
                                    </div>


                                    <div className="form-group">
                                        <input autoComplete="off" id="useName" type="text" className="form-control" placeholder="Enter Email Address" />
                                    </div>
                                    {/* <div className="form-group input-rounded">
                                        <input type="password" className="form-control" placeholder="Password" />
                                    </div> */}
                                    {/* <div className="form-inline">
                                        <div className="checkbox">
                                            <label>
                                                <input type="checkbox" className="form-check-input" />Remember me <i className="input-frame" />
                                            </label>
                                        </div>
                                    </div> */}
                                    <div className="form-group input-rounded">
                                        <p id="errorMessage" className='text-danger'></p>
                                    </div>
                                    {
                                        isForgetPassword && <div className="activity-indicator-forget-pass"><ActivityIndicator size="small" color="#00A1DE" /></div>
                                    }
                                    <ReCAPTCHA
                                        className='d-inline-block'
                                        sitekey={`${process.env.REACT_APP_CAPTCHA_SITEKEY}`}
                                        onChange={onChange}
                                        onExpired={onExpired}
                                        badge={'bottomleft'}
                                    />

                                    <button disabled={isValidated} onClick={(e) => isForgetPassword ? {} : resetPassword(e)} type="submit" className="btn btn-primary btn-block"> Send Password </button>


                                    {/* <div className="signup-link">

                                        <Link to="/">
                                            Sign In
                                        </Link>


                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="auth_footer">
                <p className="text-muted text-center">© myindici 2022</p>
            </div>
        </div>
    );
}



function mapStateToProps(state) {
    return {
        AuthUser: state.AuthUser
    }
}

function mapDispatchToProps(disptach) {
    return {
        getUserToken: (email, password) => disptach(fetchUserInformation(email, password)),
    }
}


//export default Login;

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ForgetPassword)