import { Checkbox } from "antd";
import React, { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form';

const getRandomColor = () => {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}
const MsgCard = ({
  imgSrc = "",
  sender = "",
  subject = "",
  date = "",
  isRead = false,
  onSelect,
  isActive = false,
  profileColor = "#47b2f6",
  msgChecked = false,
  handleCheckChanged,
  DMSID = false,
  setIndeterminate
}) => {

  const [thisMessageRead, setThisMessageRead] = useState(isRead ? true : false);
    const [thisMessageChecked, setThisMessageChecked] = useState(msgChecked ? true : false);
    useEffect(()=>{
      setThisMessageChecked(msgChecked)
    },[msgChecked])
  useEffect(() => {
  }, [thisMessageRead])
  return (
    <>
      <div
        className={
          "msgCard d-flex align-items-center px-2 justify-content-center gap3 " +
          (isActive ? " active " : "") +
          (thisMessageRead ? " isRead " : " ")
        }
        
      >
        {/* <Checkbox className="mr-2" onClick={()=>handleCheckChanged()} checked={thisMessageChecked} onChange={()=>setThisMessageChecked(!thisMessageChecked)} /> */}
        <Form.Check autoComplete="off" className="message-list-main-checkbox" checked={thisMessageChecked} onChange={() => setThisMessageChecked(!thisMessageChecked)} onClick={() => {
          handleCheckChanged()
        }} />
        <div className='profileImg d-flex justify-content-center align-items-center p-3 card-mesg-bg'>
          {/* <img src={imgSrc} alt="" /> */}
          {sender.slice(0, 1)}
        </div>
        <div className="msgDetails w-100" onClick={() => {
        
          setThisMessageRead(true)
          onSelect()
        }}>
          <div className="detailsTop">
            <p className="senderName">{sender} 
              <span className="msgDate">{date}</span>
            </p>
           
          </div>
          <div className="w-100 d-flex justify-content-between">

          <p className="subjectContent">{subject}</p>
            <div className="d-flex">
            {!thisMessageRead && <span className="unread-msg-indicator"></span>}
              {DMSID && <div className="attachment-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={15}
                  height={15}
                  viewBox="0 0 16.953 19.375" >
                  <path
                    data-name="Icon metro-attachment"
                    d="M11.992 6.188L10.765 4.96l-6.147 6.144a2.607 2.607 0 003.687 3.687l7.375-7.374a4.345 4.345 0 10-6.145-6.145L1.792 9.015l-.017.016a6.06 6.06 0 008.571 8.569l.016-.017 5.285-5.284-1.229-1.227-5.285 5.284-.016.016a4.322 4.322 0 01-6.113-6.111l.017-.016 7.744-7.743a2.607 2.607 0 013.687 3.686l-7.375 7.373a.869.869 0 01-1.229-1.228l6.146-6.145z"
                    fill="#545454"
                  />
                </svg>
              </div>}
            </div>
          </div>
        </div>
      </div>
      {/* <hr className={"m-0 mx-2 " + (isActive ? "d-none" : "d-block")} /> */}
    </>
  );
};

export default MsgCard;
