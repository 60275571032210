import React, { useEffect, useRef, useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import UserInvoice from "../../components/UserInvoice";
import StatusMessages, { useMessages } from "../../components/StatusMessages";
import { connect, useDispatch, useSelector } from "react-redux";
import { fetchBalanceFromApi } from "../../../shared/actions/GetBalanceAction";
import { Input, Radio, Select, Spin, notification, Tooltip, Modal } from 'antd'

import {
  View,
  ActivityIndicator,
} from "react-native-web";

import {
  fetchPendingInvoiceFromApi
} from "../../../shared/actions/PendingInvoiceActions";
import getUserId from "../../../shared/core/GetToken";
import GetAppSettings from "../../../shared/core/GetAppSettings";
import { ProgressBar } from "react-bootstrap";
import Settings from "../../../shared/config/Settings";
import { flushSync } from "react-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import CreditCardInput from 'react-credit-card-input';
import { actionAPI } from "../../../shared/reduxToolkit/actionAPI";



let pageNumber = 1;
const PendingInvoice = (props) => {
  const { PendingInvc, isGetting } = useSelector(state => state.PendingInvoices);
  const { Practise } = useSelector(state => state.PracticeOrganization);

  const { Profile, isFetching } = useSelector(state => state.UserProfile);
  const stripe = useStripe();
  const elements = useElements();
  const [messages, addMessage] = useMessages();
  const [pageSize, setPageSize] = useState(100); //state for the page number
  const [isReady, setIsReady] = React.useState(false); //state for check loading time
  const { Balance, isLoadoing } = useSelector(state => state.UserAccountBalance);
  const [selectedOption, setSelectedOption] = useState(2);
  const [isPaying, setIsPaying] = useState(false);
  const [amount, setAmount] = useState(Balance ? Balance : "")
  const [lookupData, setLookupData] = useState(null)
  const [invoiceNote, setInvoiceNote] = useState("");
  const [cardtypename, setCardTypeName] = useState("");
  const [disablePayClick, setDisablePayClick] = useState(false);
  const [progressbarNumber, setProgressbarNumber] = useState(0);
  const [paymentMethodType, setPaymentMethodType] = useState("2");
  const [cardTypeErrMsg, setCardTypeErrMsg] = useState("");
  const [appSettings, setAppSettings] = useState({});
  const [cardkindName, setCardkindName] = useState("");
  const [deductedAmount, setDeductedAmount] = useState(0);
  const [paymentConfigurationError, setPaymentConfigurationError] = useState(false);
  const [practiceCode, setPracticeCode] = useState(0)
  const [nhiNumber, setNhiNumber] = useState("")
  const [userEmail, setUserEmail] = useState("")
  const [cardTypeNotMatchErrMsg, setCardTypeNotMatchErrMsg] = useState("")
  const [poliPaymentModalVisible, setPoliPaymentModalVisible] = useState(false)
  const [navigateUrlForPoli, setNavigateUrlForPoli] = useState("")
  const [windcaveCardDetails, setWindcaveCardDetails] = useState({
    cardNumber: "",
    cardCVC: "",
    cardExpiry: "",
    holderName: ""
  })
  const [windcaveCardDetailsErrState, setWindcaveCardDetailsErrState] = useState({
    cardNumber: "",
    cardCVC: "",
    cardExpiry: "",
    holderName: ""
  })


  const [validationErrorMsgs, setValidationErrorMsgs] = useState({
    amountErr: "Minimum amount of $1 is required",
    cardNumberCompleteError: "Please enter correct card number",
    cardTypeErrMsg: "Please select a Card Type",
    onceValidated: false
  })
  // const [checked, setChecked] = React.useState('first');
  // const [notes, setNotes] = React.useState('')
  // const [otheramount, setOtherAmount] = React.useState('')
  const [checkedInvoiceIDrows, setCheckedInvoiceIDrows] = useState([])

  const [totalRecords, setTotalRecords] = useState(10)
  const [invoicesList, setInvoicesList] = useState([])
  let limit = 25
  let offset = 1

  const apiDispatcher = useDispatch()
  const getPendingInvoicesAPI = (fromStart = false) => {
    limit = 15;
    offset = fromStart ? 1 : Math.ceil(invoicesList.length / limit) + 1;
    console.log("mergeData offset =>", offset)
    if (offset == 1) {
      flushSync(() => {
        setInvoicesList([])
        setTotalRecords(0)
      })
    }
    apiDispatcher(actionAPI.GetPendingInvoicesFromAPI({ pageSize: limit, pageNumber: offset }))

  };

  useEffect(() => {
    getPendingInvoicesAPI()
  }, [])
  useEffect(() => {
    if (typeof (Practise) !== 'undefined' && Practise.length) {
      // item.resource.extension.find(o => o.url === 'ObservationSummary')?.valueString
      setPracticeCode(Practise[0]?.resource?.extension?.find(item => item?.url === "Practice Code")?.valueString)

    }
  }, [Practise])
  useEffect(() => {
    if (typeof (Profile) !== 'undefined' && Profile.length) {
      setNhiNumber(Profile[0]?.resource?.extension[3]?.valueString ? Profile[0]?.resource?.extension[3]?.valueString : "")
      setUserEmail(Profile[0]?.resource?.telecom.find(x => x.system == "email")?.value)
    }
  }, [Profile])
  useEffect(() => {
    if (PendingInvc && PendingInvc.length) {
      setTotalRecords(PendingInvc[0].total)
      let tempPageNumber = Math.ceil(invoicesList.length / limit) + 1
      if (PendingInvc[0]?.entry) {
        if (tempPageNumber !== 1) {

          const mergeData = [...invoicesList, ...PendingInvc[0]?.entry];

          setInvoicesList(mergeData);
        } else {

          setInvoicesList(PendingInvc[0].entry);
        }


      }

    }
  }, [PendingInvc])
  const fetchMoreInvoicesFromAPI = (ignore = false) => {

    if (ignore || invoicesList.length < totalRecords) {
      getPendingInvoicesAPI();
    }
  }

  const openNotificationWithIcon = (type, value, msg = '') => {
    notification[type]({
      message: msg,
      description: value,
    });
  };

  const HandleCardChangeElement = (e) => {
    console.log("cardkind changed =>", e)
    if (e.brand && e.brand != "") {

      if (e.brand == "amex") {
        setCardkindName("americanexpress")

      }
      else {
        setCardkindName(e.brand)
      }
    }
    if (e.empty)
      setValidationErrorMsgs({ ...validationErrorMsgs, cardNumberCompleteError: "Card number is required" })
    if (!e.complete)
      setValidationErrorMsgs({ ...validationErrorMsgs, cardNumberCompleteError: "Your card number is incomplete." })

    if (!e.empty)
      setValidationErrorMsgs({ ...validationErrorMsgs, cardNumberCompleteError: e?.error?.message })

    if (!e.complete && e.error === undefined)
      setValidationErrorMsgs({ ...validationErrorMsgs, cardNumberCompleteError: "CVC is required." })
    if (!e.empty && e.complete) {
      setValidationErrorMsgs({ ...validationErrorMsgs, cardNumberCompleteError: "accomplished" })
      setCardTypeNotMatchErrMsg("")
      // if (e.brand == "amex") {
      //   setCardkindName("americanexpress")

      // }
      // else {
      //   setCardkindName(e.brand)
      // }
    }
  }

  const handleCheckedInvoices = (invoiceIDsList) => {
    let arr = invoiceIDsList.filter(item => {
      if (item.isChecked) {
        return item;
      }
    })//.map(item=> item.InvoiceID)
    setCheckedInvoiceIDrows(arr)
  }


  const handlePayNowValidations = () => {
    let errCaught = false
    if (paymentMethodType === '1' && windcaveCardDetails.holderName.trim() === "") {
      setWindcaveCardDetailsErrState({
        ...windcaveCardDetailsErrState,
        holderName: "Card holder name is mandatory"
      })
      errCaught = true
    }
    if (amount == "" || amount.length == 0) {
      errCaught = true
    }
    else if (parseFloat(amount) < 1) {
      errCaught = true
    }

    return errCaught
  }
  const handleCardNumberValidations = () => {
    let errCaught = false
    if (validationErrorMsgs.cardNumberCompleteError == "" ||
      validationErrorMsgs.cardNumberCompleteError == null ||
      validationErrorMsgs.cardNumberCompleteError == "Your card number is invalid." ||
      validationErrorMsgs.cardNumberCompleteError == "Your card number is incomplete." ||
      validationErrorMsgs.cardNumberCompleteError == "CVC is required." ||
      validationErrorMsgs.cardNumberCompleteError == 'Please enter correct card number') {
      openNotificationWithIcon("error", "Card Number is invalid")
      errCaught = true
    }
    if (validationErrorMsgs.cardNumberCompleteError == "accomplished") {
      errCaught = false;
    }
    return errCaught

  }
  const handleCardTypeValidations = () => {
    let errCaught = false

    if (cardtypename == "") {
      setCardTypeErrMsg("Please select a Card Type")
      errCaught = true
    }
    else {
      console.log("cardName", cardtypename)
    }
    return errCaught
  }

  const isCardTypeMatch = () => {
    let [cardType, cardCharges] = cardtypename?.replace('%', '').split('-');

    if (cardType && cardkindName !== cardType?.split(" ")?.join("")?.toLowerCase()) {
      openNotificationWithIcon("error", "Payment cannot be processed as selected card type does not match with card number. ")
      return false
    } else {
      return true
    }
  }
  const isWindcaveCardTypeMatch = () => {
    let [cardType, cardCharges] = cardtypename?.replace('%', '').split('-');

    if (cardType && cardkindName !== cardType?.split(" ")?.join("")?.toLowerCase()) {

      return false
    } else {
      return true
    }
  }
  ////////PAy Now Click//////////////////
  const handleSubmit = async (e) => {

    e.preventDefault();

    let isAmountValidated = handlePayNowValidations();
    let isCardValidated = handleCardNumberValidations()
    let isCardTypeValidated = appSettings?.IsCardTypeConfifiguration && handleCardTypeValidations()
    setValidationErrorMsgs({ ...validationErrorMsgs, onceValidated: true });
    let isCardTypeNumMatch = appSettings?.IsCardTypeConfifiguration && isCardTypeMatch()


    if (isAmountValidated || isCardValidated || isCardTypeValidated || (appSettings?.IsCardTypeConfifiguration && !isCardTypeNumMatch)) {
      return
    }

    setProgressbarNumber(25)
    // setIsPaying(true);
    setDisablePayClick(true)

    const itemUserInfo = await getUserId().then((userInfo) => {
      return JSON.parse(userInfo);
    });
    let cardel = CardElement;
    let [cardType, cardCharges] = cardtypename?.replace('%', '').split('-');
    //////////////////////////////////////temp payment call start//////////////////////////////////////////////////////
    let calculatedAmount = amount

    if (cardCharges && isCardTypeNumMatch) {
      calculatedAmount = (((parseFloat(amount) * parseFloat(cardCharges)) / 100) + parseFloat(amount)).toFixed(2)
    }
    const temppaymentResult = Settings.apiUrl.indexOf("fpnz") < 0 ? await saveTempPaymentCall() : await saveTempPaymentCallSecurity();

    if (temppaymentResult == "-1") {
      setDisablePayClick(false)
      setProgressbarNumber(100)
      /////failed temp payment
      openNotificationWithIcon('error', 'Payment unsuccessful')
      return;
    }

    //////////////////////////////////////////////////send stripe call////////////////////////////////////////////////////////////////////////////
    if (temppaymentResult > 0 || temppaymentResult !== "") {

      // if (!stripe || !elements) {
      //   addMessage("Stripe.js has not yet loaded.");
      //   return;
      // }

      var payUrl = `${process.env.REACT_APP_PAYMENTURL}`;
      const { error: backendError, clientSecret } =
        await fetch(payUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "patienttoken": itemUserInfo.token
          },
          body: JSON.stringify({
            paymentMethodType: "card",
            currency: "nzd",
            amount: parseInt(calculatedAmount * 100),
            patientId: itemUserInfo.parentid,
            ApiUrl: Settings?.apiUrl?.replace("/api/", ""),
            Description: practiceCode && practiceCode !== 0 ? `ST-${practiceCode}-${temppaymentResult} (${nhiNumber}) - (${userEmail})` : `ST-${temppaymentResult} (${nhiNumber}) - (${userEmail})`,
            UserEmail: userEmail
          }),
        }
        ).then((r) => r.json())
          .finally(() => {
            //   setDisablePayClick(false)
            // setProgressbarNumber(100)
          })

      if (backendError) {
        console.log("backendError", backendError)
        //addMessage(backendError.message);
        setIsPaying(false)
        setDisablePayClick(false)
        setProgressbarNumber(100)

        return;
      }
      setProgressbarNumber(45)

      const { error: stripeError, paymentIntent } =
        await stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: elements.getElement(CardElement),
            billing_details: {
              name: localStorage.getItem("@userToken").parentname, // send actual name here
              email: localStorage.getItem("@userToken").email,
            },
          },
        });

      if (stripeError) {
        setIsPaying(false)
        setDisablePayClick(false)
        setProgressbarNumber(100)

        openNotificationWithIcon('error', stripeError.message)
        //addMessage(stripeError.message);
        return;
      }

      // Show a success message to your customer, There's a risk of the customer closing the window before callback
      // execution. Set up a webhook or plugin to listen for the payment_intent.succeeded event that handles any business critical post-payment actions.
      //addMessage(`Payment succeed : ${paymentIntent.status}: ${paymentIntent.id}`);
      //////////////////////////////////////////////////final stripe call end////////////////////////////////////////////////////////////////////////////
      if (paymentIntent?.status == "succeeded") {
        ///////////////////////////final payment call//////////////////////////////////////////////////////
        //openNotificationWithIcon('success') // alert('payment succeeeded')
        setDisablePayClick(true)
        setProgressbarNumber(90)

        const payloadqueryStringFinal = `?PatientID=${itemUserInfo.parentid}&electronicPaymentID=${temppaymentResult}`
        const responsePaymentFinal = await fetch(`${Settings.apiUrl}patient/SavePayment${payloadqueryStringFinal}`, {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
            "Token": itemUserInfo.token
          },
          redirect: 'follow'
        });

        const finalpaymentResult = await responsePaymentFinal.json();

        if (finalpaymentResult) {
          getPendingInvoicesAPI(true)
          setAmount("");
          setDeductedAmount(0)
          setValidationErrorMsgs({ ...validationErrorMsgs, amountErr: "Please enter amount", onceValidated: false })
          openNotificationWithIcon('success', 'Payment succeeded successfully')
          // setDisablePayClick(false)

          //setSelectedOption(2)
          //setInvoiceNote("")
          //elements.getElement(CardElement).clear();
        }
        else {
          //final payment not saved successfully
          openNotificationWithIcon('error', 'Payment unsuccessful')
        }
        ///////////////////////////final payment call END//////////////////////////////////////////////////////
      }
      else {
        openNotificationWithIcon('error', 'Payment unsuccessful')
      }
    };
    setProgressbarNumber(100)

    apiDispatcher(actionAPI.GetAccountBalanceAPI())
    setDisablePayClick(false)
    setIsPaying(false)
  }

  const cardRef = useRef()

  const xmlToJson = (element) => {
    const data = {};

    if (element.nodeType === Node.ELEMENT_NODE) {
      if (element.attributes.length > 0) {
        data['attributes'] = {};
        for (let i = 0; i < element.attributes.length; i++) {
          const attribute = element.attributes[i];
          data['attributes'][attribute.nodeName] = attribute.nodeValue;
        }
      }
    } else if (element.nodeType === Node.TEXT_NODE) {
      data['text'] = element.nodeValue.trim();
    }

    if (element.hasChildNodes()) {
      for (let i = 0; i < element.childNodes.length; i++) {
        const child = element.childNodes[i];
        const childData = xmlToJson(child);
        if (childData !== null) {
          if (data[child.nodeName]) {
            if (!Array.isArray(data[child.nodeName])) {
              data[child.nodeName] = [data[child.nodeName]];
            }
            data[child.nodeName].push(childData);
          } else {
            data[child.nodeName] = childData;
          }
        }
      }
    }

    return data;
  }

  // const  TempPaymentSubmissionCallForPoli= async ()=>{
  //   const itemUserInfo = await getUserId().then((userInfo) => {
  //     return JSON.parse(userInfo);
  //   });

  //   //////////////////////////////////////temp payment call start//////////////////////////////////////////////////////
  //   let invoiceGridDetail = checkedInvoiceIDrows;
  //   var requestOptions = {
  //     method: 'POST',
  //     headers: {
  //       "Content-Type": "application/json",
  //       "Token": itemUserInfo.token
  //     },
  //     body: JSON.stringify(invoiceGridDetail),
  //     redirect: 'follow'
  //   };

  //   let payloadqueryString = `?PatientID=${itemUserInfo.parentid}&CardType=''&cardCharges=0&amount=${amount}&notes=${invoiceNote}`;


  //   const responseTempPayment = await fetch(`${Settings.apiUrl}patient/SaveTempPayment${payloadqueryString}`, requestOptions);
  //   const temppaymentResult = await responseTempPayment.json();
  //     return temppaymentResult
  //   }
  const saveTempPaymentCall = async () => {

    const itemUserInfo = await getUserId().then((userInfo) => {
      return JSON.parse(userInfo);
    });
    let cardel = CardElement;
    let [cardType, cardCharges] = cardtypename?.replace('%', '').split('-');
    //////////////////////////////////////temp payment call start//////////////////////////////////////////////////////
    let invoiceGridDetail = checkedInvoiceIDrows;

    var requestOptions = {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Token": itemUserInfo.token
      },
      body: JSON.stringify(invoiceGridDetail),
      redirect: 'follow'
    };
    let payloadqueryString = "";

    let isCardTypeNumMatch = appSettings?.IsCardTypeConfifiguration && isCardTypeMatch()

    let calculatedAmount = amount

    if (cardCharges && isCardTypeNumMatch && paymentMethodType === "2") {
      calculatedAmount = (((parseFloat(amount) * parseFloat(cardCharges)) / 100) + parseFloat(amount)).toFixed(2)
    }
    let notesTemp = JSON.parse(localStorage.getItem("@portalSettings"))?.Face2FaceConfirmationMessage?.split(",")[1];
    payloadqueryString = `?PatientID=${itemUserInfo.parentid}&CardType=${cardkindName}&cardCharges=${cardCharges ? (parseFloat(calculatedAmount) - parseFloat(amount?.trim())).toFixed(2) : 0}&amount=${amount}&notes=${invoiceNote}&lognotes=${notesTemp}`;


    const responseTempPayment = await fetch(`${Settings.apiUrl}patient/SaveTempPayment${payloadqueryString}`, requestOptions);
    const temppaymentResult = await responseTempPayment.json();
    return temppaymentResult
  }
  const saveTempPaymentCallSecurity = async () => {
    let isCardTypeNumMatch = appSettings?.IsCardTypeConfifiguration && isCardTypeMatch()

    const itemUserInfo = await getUserId().then((userInfo) => {
      return JSON.parse(userInfo);
    });
    let cardel = CardElement;
    let [cardType, cardCharges] = cardtypename?.replace('%', '').split('-');
    //////////////////////////////////////temp payment call start//////////////////////////////////////////////////////
    let invoiceGridDetail = checkedInvoiceIDrows;
    let notesTemp = JSON.parse(localStorage.getItem("@portalSettings"))?.Face2FaceConfirmationMessage?.split(",")[1];

    let calculatedAmount = amount

    if (cardCharges && isCardTypeNumMatch && paymentMethodType === "2") {
      calculatedAmount = (((parseFloat(amount) * parseFloat(cardCharges)) / 100) + parseFloat(amount)).toFixed(2)
    }
    const raw = {
      PatientID: itemUserInfo.parentid,
      CardType: cardkindName,
      cardCharges: cardCharges ? (parseFloat(calculatedAmount) - parseFloat(amount?.trim())).toFixed(2) : 0,
      amount: calculatedAmount,
      notes: invoiceNote,
      lognotes: notesTemp,
      invoiceDetailGrids: invoiceGridDetail
    }
    var requestOptions = {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Token": itemUserInfo.token
      },
      body: JSON.stringify(raw),
      redirect: 'follow'
    };




    const responseTempPayment = await fetch(`${Settings.apiUrl}patient/SaveTempPayment`, requestOptions);
    const temppaymentResult = await responseTempPayment.json();
    return temppaymentResult
  }
  const windcaveCreditRef = useRef()
  const handleWindcaveCardNumberValidations = () => {
    // if (validCardDiv?.toString()?.indexOf("is-invalid")) {
    //   console.log("windcaveCreditRef.current,", windcaveCreditRef.current?.toString()?.indexOf("is-invalid"))

    // }
    if (document.getElementsByClassName("card-input-windcave")[0].classList.value.indexOf("is-invalid") >= 0) {
      return true
    } else {

      return false
    }
  }
  // ////// Paynow Windcave Click /////////////////////
  const handleWindcaveSubmit = async (e) => {

    e.preventDefault();
    let isAmountValidated = handlePayNowValidations();
    let isCardValidated = handleWindcaveCardNumberValidations()
    let isCardTypeValidated = appSettings?.IsCardTypeConfifiguration && handleCardTypeValidations()
    setValidationErrorMsgs({ ...validationErrorMsgs, onceValidated: true });



    if (isAmountValidated || isCardValidated) {
      return
    }


    let isCardTypeNumMatch = appSettings?.IsCardTypeConfifiguration && isWindcaveCardTypeMatch()

    setProgressbarNumber(25)
    // setIsPaying(true);
    setDisablePayClick(true)

    const itemUserInfo = await getUserId().then((userInfo) => {
      return JSON.parse(userInfo);
    });
    let cardel = CardElement;
    let [cardType, cardCharges] = cardtypename?.replace('%', '').split('-');
    //////////////////////////////////////temp payment call start//////////////////////////////////////////////////////

    const temppaymentResult = Settings.apiUrl.indexOf("fpnz") < 0 ? await saveTempPaymentCall() : await saveTempPaymentCallSecurity();

    if (temppaymentResult == "-1") {
      setDisablePayClick(false)
      setProgressbarNumber(100)
      /////failed temp payment
      openNotificationWithIcon('error', 'Payment unsuccessful')
      return;
    }
    let notesTemp = JSON.parse(localStorage.getItem("@portalSettings"))?.Face2FaceConfirmationMessage?.split(",")[1];

    //////////////////////////////////////////////////send stripe call////////////////////////////////////////////////////////////////////////////
    let calculatedAmount = amount

    if (amount.toString().indexOf(".") >= 0) {
      let decimalLength = amount.toString().split(".")[1].length
      if (decimalLength === 1) {
        calculatedAmount = calculatedAmount + "0"
      }
    }
    if (temppaymentResult > 0 || temppaymentResult !== "") {
      let raw = {
        patientid: itemUserInfo.parentid,
        CardFee: cardCharges ? cardCharges : 0,
        CardType: cardkindName,
        cardCharges: parseFloat(cardCharges ? cardCharges : 0),
        totalAmount: calculatedAmount,
        transactionNotes: notesTemp,
        pId: temppaymentResult,
        CardNumber: windcaveCardDetails.cardNumber?.replaceAll(" ", ""),
        ExpiryDate: windcaveCardDetails.cardExpiry?.replaceAll(" / ", ""),
        CvcNo: windcaveCardDetails.cardCVC,
        CardHolderName: windcaveCardDetails.holderName,
      }
      var requestOptions = {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "Token": itemUserInfo.token
        },
        body: JSON.stringify(raw),
        redirect: 'follow'
      };
      fetch(`${Settings.apiUrl}patient/WindCaveProcessing`, requestOptions)
        .then(res => res.json())
        .then(json => {

          setDisablePayClick(false);

          setProgressbarNumber(100);

          const xmlString = json.Response.Xml;

          // Create a new DOMParser
          const parser = new DOMParser();

          // Parse the XML string
          const xmlDoc = parser.parseFromString(xmlString, 'text/xml');

          // Helper function to convert XML element to JSON


          // Convert the XML document to JSON
          const jsonData = xmlToJson(xmlDoc.documentElement);

          console.log(jsonData);

          if (jsonData.Success["#text"].text == "1") {
            openNotificationWithIcon('success', 'Payment succeeded successfully');
            getPendingInvoicesAPI(true)
            setAmount("")
            setWindcaveCardDetails({
              ...windcaveCardDetails,
              cardCVC: "",
              cardNumber: "",
              cardExpiry: "",
              holderName: ""
            })
            setCardTypeName("")
            setDeductedAmount(0)
            apiDispatcher(actionAPI.GetAccountBalanceAPI())
          }
          else {
            if (jsonData.ResponseText['#text'].text === "INVALID TRANSACTION") {
              openNotificationWithIcon('error', 'Card Declined: The card used does not support this kind of transaction.');

            } else if (jsonData.ResponseText['#text'].text === "DO NOT HONOUR") {
              openNotificationWithIcon('error', 'Card does not allow this transaction.');

            }
            else if (jsonData.ResponseText['#text'].text !== "") {
              openNotificationWithIcon('error', jsonData.ResponseText['#text'].text);

            }
            else {

              openNotificationWithIcon('error', 'Payment unsuccessfull');
            }
          }

        })
        .catch(err => {
          setDisablePayClick(false)

          setProgressbarNumber(100)
        })
    }
  }




  // ////// Paynow Poli Click /////////////////////
  const handlePoliSubmit = async (e) => {
    e.preventDefault()

    if (amount == "" || amount.length == 0) {
      openNotificationWithIcon('error', 'Minimum amount of $1 is required')
      return;
    }
    else if (parseFloat(amount) < 1) {
      openNotificationWithIcon('error', 'Amount cannot be less than 1')
      return;
    }

    setProgressbarNumber(20)

    //saving in indici temp payments
    let temppaymentResult = Settings.apiUrl.indexOf("fpnz") < 0 ? await saveTempPaymentCall() : await saveTempPaymentCallSecurity();

    if (temppaymentResult == "-1") {
      setDisablePayClick(false)
      setProgressbarNumber(100)
      /////failed temp payment
      openNotificationWithIcon('error', 'Payment unsuccessful')
      return;
    }

    localStorage.setItem("temppaymentResult", temppaymentResult)
    getUserId().then((userInfo) => {
      const item = JSON.parse(userInfo);

      var myHeaders = new Headers();
      myHeaders.append("patienttoken", item.token);
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        "paymentMethodType": "card",
        "currency": "nzd",
        "amount": amount,
        "patientId": item.userid,
        "MerchantData": "patientId=" + item.userid + "&patientToken=" + item.token,
        "ApiUrl": Settings?.apiUrl?.replace("/api/", ""),
        Description: practiceCode && practiceCode !== 0 ? `PO-${practiceCode}-${temppaymentResult} (${nhiNumber}) - (${userEmail})` : `PO-${temppaymentResult} (${nhiNumber}) - (${userEmail})`,
        UserEmail: userEmail
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(`${process.env.REACT_APP_PAYMENTURL}`, requestOptions)
        .then(response => response.json())
        .then(result => {
          setProgressbarNumber(100)
          if (result.Success) {
            // window.open(result.NavigateURL, '_blank');
            setNavigateUrlForPoli(result.NavigateURL)
            setPoliPaymentModalVisible(true)
          }
          if (result.error) {
            openNotificationWithIcon("error", result.error.message ? result.error.message : "Payment was unsuccessful")
          }
        })
        .catch(error => console.log('error', error));
    })

  }
  const handleInvoiceReset = (e) => {
    e.preventDefault()
    setAmount("")
    setSelectedOption(2)
    setInvoiceNote("")
    elements.getElement(CardElement).clear();

  }
  const handlePoliInvoiceReset = () => {

    setAmount("")
    setSelectedOption(2)
    setInvoiceNote("")

  }

  useEffect(() => {
    apiDispatcher(actionAPI.GetAccountBalanceAPI())
  }, [])
  const radioChanged = (e) => {
    if (e.target.value === 1) {
      setAmount(Balance?.split(" ")[0]?.replace("$", ""))
    } else {
      setAmount("")
      setDeductedAmount(0)
    }
    setSelectedOption(e.target.value)
  }
  const getStripeConfiguration = () => {
    getUserId().then((userInfo) => {


      const item = JSON.parse(userInfo);

      var myHeaders = new Headers();

      myHeaders.append("Token", item.token)
      myHeaders.append("Content-Type", "application/json")

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
      };

      fetch(Settings.apiUrl + "Patient/LoadPaymentConfiguration?PatientID=" + item.userid, requestOptions)
        .then(data => data.json())
        .then(json => {
          const stripeConfiguration = json?.resource?.extension[4]?.valueString
          const paymentExpressConfiguration = json?.resource?.extension[3]?.valueString
          const poliConfiguration = json?.resource?.extension[5]?.valueString
          if (!stripeConfiguration && !poliConfiguration && !paymentExpressConfiguration) {
            setPaymentConfigurationError(true)

          }
        })
        .catch(error => {

        })
        .finally(() => {


        });
    });
  }
  useEffect(() => {

    GetAppSettings().then((info) => {

      const set = JSON.parse(info)
      setPaymentMethodType(set.PaymentMethodType ? set.PaymentMethodType : "2")
      setLookupData(set.PaymentCardTypes && JSON.parse(set.PaymentCardTypes))
      setAppSettings(set)
    })
    getStripeConfiguration()
  }, [])
  useEffect(() => { }, [amount])
  useEffect(() => {
    if (!isNaN(parseFloat(amount))) {

      let [cardType, cardCharges] = cardtypename?.replace('%', '').split('-');

      console.log("cardkindName", cardkindName, ",  cardType => ", cardType, " after trim => ", cardType.replace(" ", "").toLowerCase().trim())
      var stripeElementCardType = cardkindName
      var dropDownCardType = cardType?.split(" ")?.join("")?.toLowerCase()

      if (amount && parseFloat(amount) > 0 && cardtypename != "" && (stripeElementCardType == dropDownCardType)) {
        setDeductedAmount((((parseFloat(amount) * parseFloat(cardCharges)) / 100) + parseFloat(amount)).toFixed(2))
      } else {
        setDeductedAmount((parseFloat(amount) === NaN ? 0 : parseFloat(amount)).toFixed(2))
      }
    }

  }, [amount, cardkindName, cardtypename])
  return (
    <div className="accounts-info-container">
      <div className="payment-details-container mb-4">

      </div>
      {!paymentConfigurationError ? appSettings?.IsShowPayNow ? <div className={"payment-input-controls-container grid mb-4 " + (disablePayClick ? "opacitypoint5 pteventsnone" : "opacityone pteventsall")}>
        {<div className="grid-body">
          <form autoComplete="off" className={`payment-details-form ${"opacityone pteventsall"}`} >
            <div className="form-group d-inline d-lg-flex">
              <div className="form-control-container mb-2 mb-lg-0">
                <Radio.Group onChange={(e) => radioChanged(e)} value={selectedOption}>
                  <Radio value={1}>Pay total outstanding balance</Radio>
                  <Radio value={2}>Pay another amount</Radio>
                </Radio.Group>
              </div>
              <div className="form-control-container mb-2 mb-lg-0 ml-lg-auto">

                <div className="account-balance payment-card py-2 d-flex align-items-center">
                  <p className="payment-label m-0 mr-2">Account Balance:</p>
                  <h4 className="payment-amount m-0">{Balance}</h4>
                </div>
              </div>

            </div>
            <div className="form-group row">
              <div className="form-item col-md-6 col-sm-6">

                <label className="mb-0">Amount</label>
                <div className="form-control-container d-flex flex-column align-items-start">

                  <Input
                    autoComplete="off"
                    placeholder="Amount"
                    className='h-34px'
                    disabled={selectedOption != 2}
                    value={amount}
                    type="number"
                    onChange={(e) => {

                      let currentAmount = e.target.value

                      if (currentAmount.toString().indexOf(".") < 0 || currentAmount.toString().split(".")[1].length <= 2) {

                        setAmount(e.target.value);
                        if (e.target.value.length == 0)
                          setValidationErrorMsgs({ ...validationErrorMsgs, amountErr: "Please enter amount" })
                        else if (parseFloat(e.target.value) < 1) {

                          setValidationErrorMsgs({ ...validationErrorMsgs, amountErr: "Amount cannot be less than 1" })
                        }
                        else
                          setValidationErrorMsgs({ ...validationErrorMsgs, amountErr: "" })
                      }
                    }}
                    name="amountErr"
                  />
                  <p className="text-danger">{validationErrorMsgs.onceValidated && selectedOption != 1 && validationErrorMsgs.amountErr}</p>
                </div>
              </div>

              {appSettings?.IsCardTypeConfifiguration && paymentMethodType === "2" && <div className="form-item col-md-6 col-sm-6">

                <label className="mb-0">Card Type</label>
                <div className="form-control-container">

                  {lookupData ?

                    <Select
                      onChange={(e) => {
                        setCardTypeName(e);
                        setCardTypeErrMsg("");
                        setCardTypeNotMatchErrMsg("");
                      }}
                      value={cardtypename}
                      className='w-100'
                      // disabled
                      options={
                        [{
                          value: "",
                          label: "Select"
                        }].concat(

                          lookupData.map(item => {
                            return {
                              value: item.CardFee,
                              label: item.CardFee,
                            }
                          })
                        )
                      }
                    /> : <Spin />
                  }

                </div>
                <p className="text-danger">{cardTypeErrMsg}</p>

              </div>}


              {paymentMethodType === "1" ? <>
                <div className="form-item col-md-6 col-sm-6">
                  <label className="mb-0">Name on Card</label>

                  <div className="form-control-container card-fields">

                    <Input
                      autoComplete="off" value={windcaveCardDetails.holderName} placeholder="Name" className="h-34px" onChange={(e) => {
                      setWindcaveCardDetails({
                        ...windcaveCardDetails,
                        holderName: e.target.value.replace(/[0-9]/g, '')
                      });
                      setWindcaveCardDetailsErrState({
                        ...windcaveCardDetailsErrState,
                        holderName: ""
                      });
                    }
                    }

                    />
                  </div>

                  <p className="text-danger">{windcaveCardDetailsErrState.holderName}</p>

                </div>
                <div className="form-item col-md-6 col-sm-6">
                  <label className="mb-0">Card Number</label>

                  <div ref={windcaveCreditRef} className="form-control-container card-fields">

                    <CreditCardInput
                      containerClassName="windcave-main-card-input-container"
                      cardNumberInputProps={{
                        value: windcaveCardDetails.cardNumber,
                        className: "credit-card-input windcave-cardNumber-input-field",
                        onChange: (e) => {
                          setWindcaveCardDetails({
                            ...windcaveCardDetails,
                            cardNumber: e.target.value
                          })
                          const visaRegex = new RegExp("^4[0-9]{6,}$")
                          const masterRegex = new RegExp("^5[1-5][0-9]{5,}|222[1-9][0-9]{3,}|22[3-9][0-9]{4,}|2[3-6][0-9]{5,}|27[01][0-9]{4,}|2720[0-9]{3,}$")
                          const amexRegex = new RegExp("^3[47][0-9]{5,}|37[0-9]$")
                          if (visaRegex.test(e.target.value.replaceAll(" ", ""))) {
                            setCardkindName("visa")
                          }
                          else if (amexRegex.test(e.target.value.replaceAll(" ", ""))) {
                            setCardkindName("americanexpress")

                          }
                          else if (masterRegex.test(e.target.value.replaceAll(" ", ""))) {
                            setCardkindName("mastercard")

                          }
                        },
                        onError: (e) => setWindcaveCardDetailsErrState({
                          ...windcaveCardDetailsErrState,
                          cardNumber: e
                        }),
                      }}
                      cardExpiryInputProps={{
                        value: windcaveCardDetails.cardExpiry,
                        onChange: (e) => setWindcaveCardDetails({
                          ...windcaveCardDetails,
                          cardExpiry: e.target.value
                        }),
                        onError: (e) => setWindcaveCardDetailsErrState({
                          ...windcaveCardDetailsErrState,
                          cardExpiry: e
                        })

                      }}
                      cardCVCInputProps={{
                        value: windcaveCardDetails.cardCVC,
                        onChange: (e) => setWindcaveCardDetails({
                          ...windcaveCardDetails,
                          cardCVC: e.target.value
                        }),
                        onError: (e) => setWindcaveCardDetailsErrState({
                          ...windcaveCardDetailsErrState,
                          cardCVC: e
                        })
                      }}
                      fieldClassName="card-input-windcave"
                    />
                  </div>


                </div>

              </> : paymentMethodType != "3" && <div className="form-item col-md-6 col-sm-6">

                <div id="payment-form">
                  <label htmlFor="card" className='mb-0'>Card</label>
                    <div className="border border-secondary card-field-padding" ref={cardRef}>

                    <CardElement

                      name="cardNumberCompleteError"
                      id="card"
                      options={{
                        style: {
                          base: {
                            fontSize: "16px",
                            color: "#424770",
                            "::placeholder": {
                              color: "#aab7c4",
                            },
                            lineHeight: "32px",

                          },

                          invalid: {
                            color: "#9e2146",
                          },
                        },
                        hidePostalCode: true
                      }}
                      onChange={HandleCardChangeElement}
                    />

                  </div>
                </div>
                  <p className="text-danger">{validationErrorMsgs.onceValidated && validationErrorMsgs.cardNumberCompleteError?.replace("accomplished", "")}</p>
                <p className="text-danger">{cardTypeNotMatchErrMsg}</p>

                <StatusMessages messages={messages} />
              </div>}

              <div className="form-item col-md-6 col-sm-6">

                <label className="mb-0">Notes</label>
                <div className="form-control-container">
                  <textarea
                    autoComplete="off"
                    name
                    id
                    placeholder="Notes"
                    className="form-control"
                    rows={1}

                    defaultValue={""}
                    value={invoiceNote}
                    onChange={(e) => setInvoiceNote(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="form-group">
            </div>

            <div className="form-group">

              <div className="form-submit-btn d-flex justify-content-start">
                <span className="fw-bolder mr-2">Deducted Payment:</span> ${deductedAmount}
              </div>
              <div className="form-submit-btn d-flex justify-content-end">
                <button onClick={handleInvoiceReset} className="btn btn-lg amount-reset-btn ml-2 ml-lg-4">
                  Reset
                </button>

                <Tooltip title={typeof PendingInvc !== "undefined" && PendingInvc[0]?.entry?.length > 0 ? "Pay Now" : "No invoices to pay"}>

                  <button onClick={(e) => paymentMethodType == "1" ? handleWindcaveSubmit(e) : paymentMethodType == "2" ? handleSubmit(e) : handlePoliSubmit(e)} className={`btn btn-sm pay-with-stripe-btn btn-margin-left paynow-btn ${disablePayClick || typeof PendingInvc == "undefined" || PendingInvc[0]?.entry?.length == 0 ? " opacitypoint3" : "opacityone"}`} disabled={disablePayClick || typeof PendingInvc == "undefined" || PendingInvc[0]?.entry?.length == 0} >
                    {isPaying ? <ActivityIndicator size="small" color="#fff" /> :
                      <div className="paynow-btn-div">
                        <span className="upper">PAY WITH</span>
                        <span className="lower">{paymentMethodType == "1" ? "Windcave" : paymentMethodType == "2" ? "Stripe" : "Poli"}</span>
                      </div>
                    }
                  </button>
                </Tooltip>
                <Modal
                  title="Poli Payment"
                  open={poliPaymentModalVisible}
                  onCancel={() => setPoliPaymentModalVisible(false)}
                  maskClosable={false}
                  okButtonProps={{ style: { display: 'none' } }}
                  cancelText="Close"
                  afterClose={() => {
                    getPendingInvoicesAPI(true);
                    apiDispatcher(actionAPI.GetAccountBalanceAPI())
                    handlePoliInvoiceReset()
                    setDeductedAmount(0)

                  }}
                  width={700}>
                  <iframe title="Poli Payment" src={navigateUrlForPoli} width={"100%"} height="700px"></iframe>
                </Modal>
              </div>
            </div>
          </form>

          {/* {paymentMethodType == "1" ? <p className="text-danger text-center">{"Payment Express"} is not set up for this app</p> : null} */}
          {progressbarNumber != 100 && progressbarNumber != 0 ? <ProgressBar className='heigjt-helf-px' now={progressbarNumber} animated /> : null}
        </div>}
        <strong>
          <strong></strong>
        </strong>
      </div> : <div className="d-flex justify-content-end">
        <div className="form-control-container my-3">
          <div className="account-balance payment-card py-2 d-flex align-items-center">
            <p className="payment-label m-0 mr-2">Account Balance:</p>
            <h4 className="payment-amount m-0">{Balance}</h4>
          </div>
        </div>
      </div> : <>
        <div className="d-flex justify-content-end">
          <div className="form-control-container my-3 ">

            <div className="account-balance payment-card py-2 d-flex align-items-center">
              <p className="payment-label m-0 mr-2">Account Balance:</p>
              <h4 className="payment-amount m-0">{Balance}</h4>
            </div>
          </div>
        </div>
          {/* <p className="text-danger text-center">Payment method is set up but keys are not configured at practice level.</p> */}
      </>
      }
      {isGetting && (
        <div className='d-flex text-align-center margin-bottom-five' >
          <ActivityIndicator size="small" color="#00A1DE" />
        </div>
      )}
      <div id="pendingInvlicesScrollableDiv">
        {typeof PendingInvc !== "undefined" && PendingInvc[0]?.entry?.length && invoicesList.length ? (
          <InfiniteScroll
            dataLength={invoicesList.length}
            next={fetchMoreInvoicesFromAPI}
            hasMore={invoicesList.length < totalRecords}
            loader={<div className='d-flex justify-content-center my-2'><Spin /></div>}
            onScroll={() => { }}
            scrollableTarget="pendingInvlicesScrollableDiv"
          >
            <UserInvoice AllInvc={invoicesList} enteredAmount={amount} invoiceType="pending" handleCheckedInvoices={handleCheckedInvoices} />
          </InfiniteScroll>
        ) : !isGetting && <div className='overflow-x-auto' >
          <table className="invoice-table table table-striped table-hover w-100">
            <thead  >
              <tr className="invoice-table-row">
                {props.invoiceType == "pending" && <th className='w-50px' ></th>}
                <th className='w-100px'>Date</th>
                <th className='w-100px' >Patient</th>
                  <th className='w-100px' >Payee</th>
                <th className="notes-th w-250px" >Notes</th>
                <th className='w-150px'>Type</th>
                <th className='w-150px'>Ref No.</th>
                <th className='w-150px'>Amount</th>
                <th className='w-150px'>Balance</th>
                <th className='w-100px'>Pay Now</th>
                <th className='w-37px' >Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>

                <td colSpan={11} className="alert alert-warning text-align-center" >
                  No Record Found
                </td>
              </tr>
            </tbody>
          </table>
        </div>}

      </div>

    </div>
  );
};

function mapStateToProps(state) {
  return {
    PendingInvc: state.PendingInvc,
    Balance: state.Balance,

    Practise: state.Practise,

    Profile: state.Profile,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getPendingInvoice: (pageSize, pageNumber) =>
      dispatch(fetchPendingInvoiceFromApi(pageSize, pageNumber)),
    getBalance: () => dispatch(fetchBalanceFromApi()),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(PendingInvoice);
// export default PendingInvoice;
