import AsyncStorage from "@react-native-async-storage/async-storage";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Settings from "../../../config/Settings";
import getUserId from "../../../core/GetToken";

export const GetOverdueImmuneFromAPI = createAsyncThunk(
    "getOverdueImmune/Diagnosis",
    async (values, { dispatch, getState }) => {

        dispatch(getOverdueImmune())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = {
                PatientID: item.userid,
                PageSize: values.pageSize,
                PageNumber: values.pageNumber,
                TypeID: 2,
            }
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(raw)
            };

            // const queryString = '?Token=b25bbd77-4d40-4bb4-be11-d7a136033cd9&PatientID=969691&PageSize=10&PageNumber=1&TypeID=2';

            fetch(Settings.apiUrl + "Patient/Immunization", requestOptions)
                .then(data => data.json())
                .then(json => {
                    if (json) {

                        json[0].hasOwnProperty('entry') ? dispatch(getOverdueImmuneSuccess(json[0].entry)) : dispatch(getOverdueImmuneFinished())
                    } else {
                        dispatch(getOverdueImmuneFinished())
                    }

                })
                .catch(err => dispatch(getOverdueImmuneFailed(err)))
        })

    }

);

const GetOverdueImmuneSlice = createSlice({
    name: "getOverdueImmune",
    initialState: {
        OverdueImmune: [],
        isGettingImmuneOverdue: false,
        immuneOverdueError: false
    },
    reducers: {
        getOverdueImmune: (state, action) => {
            state.OverdueImmune = []
            state.isGettingImmuneOverdue = true
            state.immuneOverdueError = false
        },
        getOverdueImmuneSuccess: (state, action) => {
            state.OverdueImmune = action.payload
            state.isGettingImmuneOverdue = false
            state.immuneOverdueError = false

        },
        getOverdueImmuneFailed: (state, action) => {
            state.OverdueImmune = []
            state.isGettingImmuneOverdue = false
            state.immuneOverdueError = true
        },
        getOverdueImmuneFinished: (state, action) => {
            state.OverdueImmune = []
            state.isGettingImmuneOverdue = false
            state.immuneOverdueError = false
        },

    },
});




export const {
    getOverdueImmune, getOverdueImmuneFailed, getOverdueImmuneSuccess, getOverdueImmuneFinished
} = GetOverdueImmuneSlice.actions;
export default GetOverdueImmuneSlice.reducer;
