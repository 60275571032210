import AsyncStorage from "@react-native-async-storage/async-storage";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Settings from "../../../config/Settings";
import getUserId from "../../../core/GetToken";

export const GetUserProfileAPI = createAsyncThunk(
    "getUserProfile/User",
    async (values, { dispatch, getState }) => {

        dispatch(gettingProfile())

        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = {
                PatientID: item.userid,

            }
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(raw)
            };

            // when api is update then i will uncomment queryString
            // because currently some key is missing in the api response.


            // fetch(Settings.apiUrl + "Patient" + queryString, requestOptions)
            fetch(Settings.apiUrl + "Patient", requestOptions)
                .then(data => data.json())
                .then(json => {

                    dispatch(gettingProfileSuccess(json[0].entry))
                })
                .catch(err => dispatch(gettingProfileFailed(err)))

            // getPatientProfile(queryString)
            //     .then(data => data.json())
            //     .then(json => {

            //         dispatch(getProfileSuccess(json[0].entry))
            //     })
            //     .catch(err => dispatch(getProfileFailure(err)))
        }).catch((err) => {

        })
    })


const GetUserProfileSlice = createSlice({
    name: "getUserProfile",
    initialState: {
        Profile: [],
        isFetching: false,
        error: false
    },
    reducers: {
        gettingProfile: (state, action) => {
            state.Profile = []
            state.isFetching = true
            state.error = false

        },
        gettingProfileSuccess: (state, action) => {
            state.Profile = action.payload
            state.isFetching = false
            state.error = false

        },
        gettingProfileFailed: (state, action) => {
            state.Profile = []
            state.isFetching = false
            state.error = true

        },

    },
});




export const {
    gettingProfile, gettingProfileFailed, gettingProfileSuccess
} = GetUserProfileSlice.actions;
export default GetUserProfileSlice.reducer;
