import React, { useState, useEffect } from 'react';

import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import { Button, View, Text, SafeAreaView, Dimensions, StyleSheet, FlatList, ActivityIndicator } from "react-native-web";
import { Link } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { fetchAllergyFromApi } from "../../shared/actions/AllergiesActions";
import { capitalize } from "../../shared/core/datehelper";
import AllergiesHandler from "../components/AllergiesHandler";
import { checkToken } from '../../shared/actions/TokenValidActions';
import { actionAPI } from '../../shared/reduxToolkit/actionAPI';
const useNavigate = require("react-router-dom").useNavigate;


const AllergiesHome = props => {
    const history = useNavigate()

    const navigate = useNavigate()
    const { ProxyMenu, isLoadingMenu } = useSelector(state => state.ProxyMenu);
    const [isloaded, setIsload] = React.useState(false);
    const { Allergies, isGetting } = useSelector(state => state.AllergyIntolerance);
    const [pageSize, setPageSize] = useState(30);           //state for the page number
    const [pageNumber, setPageNumber] = useState(1);

    const apiDispatcher = useDispatch()
  
    useEffect(() => {
        if (ProxyMenu != null && ProxyMenu != undefined) {
            let itemExists = false
            ProxyMenu.map((item, index) => {
                if (item.resource.extension[0].valueString == 'Allergies') {
                    itemExists = true
                }

            })
            if (!itemExists) {
                navigate("/")
            }
        }
    }, [ProxyMenu])

    useEffect(() => {
        //alert('Called Future')
        apiDispatcher(actionAPI.fetchAllergyFromApi({ pageSize, pageNumber }))
    }, [])


    React.useEffect(() => {
        apiDispatcher(actionAPI.checkToken(history))
    }, [])

    //find the tag color
    function GetTagColor(allergyLevel) {
        if (allergyLevel === 'Severe') {
            return 'card-header text-white bg-danger';
            //return { backgroundColor: Colors.highAllergy, borderTopRightRadius: 10, borderTopLeftRadius: 10, }
        }
        if (allergyLevel === 'Moderate') {
            return 'card-header text-white bg-warning';
            // return { backgroundColor: Colors.midAllergy, borderTopRightRadius: 10, borderTopLeftRadius: 10, }
        }
        if (allergyLevel === 'Mild') {
            return 'card-header text-white bg-success';
            // return { backgroundColor: Colors.lowAllergy, borderTopRightRadius: 10, borderTopLeftRadius: 10, }
        }
    }


    //find the tag name
    function GetTagName(allergyLevel) {
        if (allergyLevel === 'Severe') {
            return 'High'
        }
        if (allergyLevel === 'Moderate') {
            return 'Moderate'
        }
        if (allergyLevel === 'mild' || allergyLevel === 'Mild') {
            return 'Low'
        }
    }
    return (
    
                    <div className="page-content-wrapper-inner">
                        <div className="viewport-header">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb has-arrow">
                                    <li className="breadcrumb-item">
                                        <Link to="/">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">Allergies</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="content-viewport">
                            <div className="row row-margin-zero h-100">
                                <div className="col-12 h-100">
                                    <div className="grid h-100">
                                        <div className="grid-body h-100">
                                          
                                            <div className="item-wrapper allergies-container">
                                                <div className="row row-margin-zero">
                                                    <div className="col-12">
                                                        <div className='card-body mb-0'>
                                                <div className='align-items-center'>
                                                    <span className="badge badge-success margin-left-ten " >Mild</span>
                                                    <span className="badge badge-warning m-top-15 ml-3 " >Moderate</span>
                                                    <span className="badge badge-danger m-top-15 ml-3 " >Severe</span>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {
                                        isGetting && <div className='activity-indicator-allergies mt-3'><ActivityIndicator size="small" color="#00A1DE" /></div>
                                                }
                                                {
                                                    typeof (Allergies) !== 'undefined' && Allergies.length ? (
                                                        <FlatList
                                                className="m-top-15"
                                                            data={Allergies}
                                                            renderItem={({ item }) => (


                                                                <AllergiesHandler
                                                                    // algstyles={item.resource.hasOwnProperty('extension') ? item.resource.extension[1].valueString == "" ? 'Mild' : item.resource.extension[1].valueString : 'Mild'}

                                                                    // styles={GetTagColor(item.resource.hasOwnProperty('reaction') ? item.resource.reaction[0].severity : 'low')}
                                                                    styles={GetTagColor(item.resource.hasOwnProperty('extension') ? item.resource.extension[1].valueString == "" ? 'Mild' : item.resource.extension[1].valueString : 'Mild')}
                                                                    // algLevel={GetTagName(item.resource.hasOwnProperty('reaction') ? item.resource.reaction[0].severity : 'low')}
                                                                    // allergyLevel={item.resource.hasOwnProperty('extension') ? item.resource.extension[1].valueString : 'Low'}
                                                                    algLevel={GetTagName(item.resource.hasOwnProperty('extension') ? item.resource.extension[1].valueString : 'Low')}
                                                                    // allergyLevel={item.resource.hasOwnProperty('reaction') ? capitalize(item.resource.reaction[0].severity) : 'Low'}
                                                                    allergyLevel={item.resource.hasOwnProperty('extension') ? item.resource.extension[1].valueString == "" ? capitalize("n/a") : capitalize(item.resource.extension[1].valueString) : 'Low'}
                                                                    algTitle={capitalize(item.resource.code.text)}
                                                                    algType={item.resource.extension[0].valueString}
                                                                    symptoms={item.resource.hasOwnProperty('reaction') ? item.resource.reaction[0].manifestation[0].text : ''}
                                                                    comment={item.resource.hasOwnProperty('note') ? item.resource.note[0].text : ''}
                                                                />
                                                            )}
                                                            keyExtractor={item => item.resource.id}
                                                            initialNumToRender={10}
                                                            maxToRenderPerBatch={10}
                                                            windowSize={7}

                                                        />
                                                    ) : !isGetting && 
                                            <div className='alert alert-danger p-2 text-center my-3'>No Records Found</div>
                                                    

                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                
    )
}




function mapStateToProps(state) {
    return {
        Allergies: state.Allergies,
        ProxyMenu: state.ProxyMenu,
    }
}

function mapDispatchToProps(disptach) {
    return {
        getAlg: (pageNumber, pageSize) => disptach(fetchAllergyFromApi(pageNumber, pageSize)),
        tokenVal: (history) => disptach(checkToken(history))

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllergiesHome)
//export default AllergiesHome
