import { ADDING_APPOINT, ADDING_APPOINT_SUCCESS, ADDING_APPOINT_FAIL, ADDING_APPOINT_CLEANUP } from "../constants/Constant";
import addPatientAppointment from "../services/AddApointmentService";
import getUserId from "../core/GetToken";
import Settings from "../config/Settings";
import addPatientAppointmentV2 from "../services/AddApointmentServiceV2";


export const addPaitentAppoinmenttoAPI = (slotid, reason, PaitentID, typeid, reasonid = 25, startTime, endTime, duration) => {
    return async (disptach) => {
        // alert(reasonid)
        disptach(addAppoint())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            // token = data
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = {
                PatientID: PaitentID != '' ? PaitentID : item.userid,
                AppointmentTypeID: typeid != '' ? typeid : null,
                SlotID: slotid,
                Reason: reason,
                ReasonForVisitID: reasonid,
                PatientID2: item.userid,
                StartTime: startTime,
                EndTime: endTime,
                Duration: duration

            }
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(raw)
            };

                addPatientAppointmentV2("", requestOptions)
                    .then(data => data.json())
                    .then(json => {
                        if (json == "Appointment has been booked successfully")
                            disptach(addAppointSuccess(json))
                        else {
                            disptach(addAppointFail(json))
                        }
                    })
                    .catch(err => disptach(addAppointFail(err)))


        })


    }
}

//for state reset
export const clenuplatData = () => {
    return (disptach) => {
        disptach(addAppointClean())
    }
}


const addAppoint = () => {
    return {
        type: ADDING_APPOINT
    }
}

//data success
const addAppointSuccess = (data) => {
    return {
        type: ADDING_APPOINT_SUCCESS,
        data,
    }
}

//data failed
const addAppointFail = (data) => {
    return {
        type: ADDING_APPOINT_FAIL,
        data
    }
}

const addAppointClean = () => {
    return {
        type: ADDING_APPOINT_CLEANUP
    }
}