import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

const PublicRoute = (props) => {
    const location = useLocation();

    console.log("Public Routeeeee, Authed =>", props.authed)
    return (
        props.authed ? (
            <Navigate to="/" state={{ from: location.pathname }} replace />
        ) : (
            <Outlet />
        )
        // <Route
        //     {...rest}
        //     render={(props) => authed === false
        //         ? <Component {...props} />
        //         :
        //         authed ? <Redirect to={{ pathname: props.location.state !== undefined ? props.location.state.from.pathname : '/', state: { from: props.location } }} />
        //             :
        //             <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        //     }
        // />
    )
}

export default PublicRoute
