import { GETTING_ALLERGIES, GETTING_ALLERGIES_SUCCESS, GETTING_ALLERGIES_FAILED, GETTING_ALLERGIES_MORE_SUCCESS, GETTING_ALLERGIES_FINISHED } from "../constants/Constant";
import getAllergies from "../services/AllergiesServices";
import getUserId from "../core/GetToken";
import Settings from "../config/Settings";

//function will return data
export const fetchAllergyFromApi = (pageNumber, pageSize) => {
    return (disptach) => {
        disptach(getAlg())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);


            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = {
                PatientID: item.userid,
                PageSize: pageSize,
                PageNumber: pageNumber,
            }
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(raw)
            };

            // token = data

            fetch(Settings.apiUrl + "Patient/AllergyIntolerance", requestOptions)
                .then(data => data.json())
                .then(json => {
                    // disptach(getAlgSuccess(json[0].entry))
                    json[0].hasOwnProperty('entry') ? disptach(getAlgSuccess(json[0].entry)) : disptach(getAlgFailed())
                })
                .catch(err => disptach(getAlgFailed()))

          
        })

    }
}





//start getting data
const getAlg = () => {
    return {
        type: GETTING_ALLERGIES
    }
}

//date success
const getAlgSuccess = (data) => {
    return {
        type: GETTING_ALLERGIES_SUCCESS,
        data,
    }
}
// //date success
// const getAlgMoreSuccess = (data) => {
//     return {
//         type: GETTING_ALLERGIES_MORE_SUCCESS,
//         data,
//     }
// }

//data faied
const getAlgFailed = () => {
    return {
        type: GETTING_ALLERGIES_FAILED
    }
}
//data faied
const getAlgFinished = () => {
    return {
        type: GETTING_ALLERGIES_FINISHED
    }
}