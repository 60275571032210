import { GET_COUNTER, GET_COUNTER_SUCCESS, GET_COUNTER_FAILED, } from "../constants/Constant";


import getPatientProfile from "../services/ProfileService";
import getUserId from "../core/GetToken";
import Settings from "../config/Settings";
//import getUserInfo from "../core/GetTokenized";



export const fetchCounterFromApi = () => {
    return async (dispatch) => {


        dispatch(getCounter())

        getUserId().then((userInfo) => {

            const item = JSON.parse(userInfo);

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = {
                PatientID: item.userid,
            }
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(raw)
            };

            fetch(Settings.apiUrl + "Patient/RefreshCounter", requestOptions)
                .then(response => response.json())
                .then(result => {

                    dispatch(getCounterSuccess(result[0]))
                })
                .catch(error => {});
           
        }).catch((err) => {
        })




    }
}




//state getting 
const getCounter = () => {
    return {
        type: GET_COUNTER
    }
}


//data success
const getCounterSuccess = (data) => {
    return {
        type: GET_COUNTER_SUCCESS,
        data,
    }
}


//data failed
const getCounterFailure = () => {
    return {
        type: GET_COUNTER_FAILED
    }

}