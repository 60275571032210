import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import { View, Text, StyleSheet } from "react-native-web";
import { Tabs } from "antd";
import { connect, useDispatch, useSelector } from 'react-redux'

import "antd/dist/antd.css";
import AllMedications from "./MedicationsTabs/AllMedications";
import LongTermMed from "./MedicationsTabs/LongTermMed";
import RepeatedMedication from "./MedicationsTabs/RepeatedMedication";

import { checkToken } from "../../shared/actions/TokenValidActions";
import { actionAPI } from "../../shared/reduxToolkit/actionAPI";
import OrdersTab from "./MedicationsTabs/OrdersTab";
import GetAppSettings from "../../shared/core/GetAppSettings";
// import OrdersTab from "./MedicationsTabs/OrdersTab";
const useNavigate = require("react-router-dom").useNavigate;

const { TabPane } = Tabs;

const Med = (props) => {
  const history = useNavigate()

  const navigate = useNavigate()
  const { ProxyMenu, isLoadingMenu } = useSelector(state => state.ProxyMenu);
  const [isloaded, setIsload] = React.useState(false);
  const [activeTabKey, setActiveTabKey] = React.useState("1");
  const [longTermClickChange, setLongTermClickChange] = React.useState(false);
  const [allMedsClickChange, setAllMedsClickChange] = React.useState(false);
  const [portalSetting, setPortalSetting] = React.useState(null);

  const apiDispatcher = useDispatch()
  React.useEffect(() => {
    GetAppSettings().then((info) => {
      const set = JSON.parse(info);
      setPortalSetting(set)
    })
    apiDispatcher(actionAPI.checkToken(history))
  }, [])
  useEffect(() => {
    if (ProxyMenu != null && ProxyMenu != undefined) {
      let itemExists = false
      ProxyMenu.map((item, index) => {
        if (item.resource.extension[0].valueString == 'Medications') {
          itemExists = true
        }

      })
      if (!itemExists) {
        navigate("/")
      }
    }
  }, [ProxyMenu])
  return (
    
          <div className="page-content-wrapper-inner">
            <div className="viewport-header">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb has-arrow">
                  <li className="breadcrumb-item">
                    {/* <a href=>Dashboard</a> */}
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Medications
                  </li>
                </ol>
              </nav>
            </div>
            <div className="content-viewport">
              <div className="row row-margin-zero h-100">
                <div className="col-12 h-100">
            <div className="grid">
              <div className="grid-body">
                <div className="med-repeat-info-box">
                  <p className="info-heading fw-bolder">Medication Repetition Process:</p>
                  <ul>
                    <li>Select the medication you want to repeat by checking the corresponding box next to each medication.</li>
                    <li>Once you've selected the medication, click the <strong>"Repeat Medication"</strong> button to proceed.</li>
                  </ul>
                </div>
                      {/* <h2 className="grid-title">Appointments</h2> */}
                      <div className="item-wrapper medication-outer-container">
                  <Tabs  
                  tabBarExtraContent={
                      activeTabKey === "3" ?
                  <>
                          {/* <div className="d-flex gap20 align-items-center">
                    <div className="d-flex align-items-center gap5">
                      <div className="awaiting"> </div>
                      <div className="font-size-12px ms-2">Awaiting Approval</div>
                    </div>
                    <div className="d-flex align-items-center gap5">
                      <div className="pharmacy"> </div>
                      <div className="font-size-12px ms-2">Script Sent to Pharmacy</div>
                    </div>
                    <div className="d-flex align-items-center gap5">
                      <div className="pickup"> </div>
                      <div className="font-size-12px ms-2">Script Ready for Pickup</div>
                    </div>
                    <div className="d-flex align-items-center gap5">
                      <div className="dispence"> </div>
                              <div className="font-size-12px ms-2">Medication Dispensed</div>
                    </div>
                    <div className="d-flex align-items-center gap5">
                      <div className="cancel-rxrejected"> </div>
                              <div className="font-size-12px ms-2">Cancelled/Rx Rejected</div>
                    </div>
                  </div> */}
                        </> : null}
                  defaultActiveKey="1" activeKey={activeTabKey} onChange={(e) => setActiveTabKey(e)} centered items={[
                    {
                      label: (<span onClick={() => setLongTermClickChange(!longTermClickChange)}><img src='/assets/images/Longtermmedicaton1.svg' className=" me-2" alt='Long Term' />  Long Term</span>),
                      key: "1",
                      children: <>

                        <LongTermMed setActiveTabKey={setActiveTabKey} longTermClickChange={longTermClickChange} />
                      </>
                    },
                    {
                      label: (<span onClick={() => setAllMedsClickChange(!allMedsClickChange)}> <img src='/assets/images/Social-History-Icon.svg' className="me-2" alt='History' /> History </span>),
                      key: "2",
                      children: <>
                        <AllMedications allMedsClickChange={allMedsClickChange} />
                      </>
                    },
                    {
                      label: (<span> <img src='/assets/images/repeeatmedication.svg' className=" me-2" alt='Rx Request'></img> Rx Requests</span>),
                      key: "3",
                      children: <>
                        <OrdersTab />
                      </>
                    }
                  ]}>

                          {/* <TabPane tab="Repeated Medication" key="3">
                            <RepeatedMedication />
                          </TabPane> */}
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

       
    // <div>
    //     <Header />
    //     <div id="myphonemenu" className="page-body">
    //         <Sidebar />
    //         <div className="page-content-wrapper">
    //             <div className="page-content-wrapper-inner">

    //                 <div className="viewport-header">
    //                     <nav aria-label="breadcrumb">
    //                         <ol className="breadcrumb has-arrow">
    //                             <li className="breadcrumb-item">
    //                                 <a href="#">Dashboard</a>
    //                             </li>
    //                             <li className="breadcrumb-item active" aria-current="page">Medications</li>
    //                         </ol>
    //                     </nav>
    //                     <div className="item-wrapper">
    //                         <Tabs defaultActiveKey="1" centered >
    //                             <TabPane tab="Long Term" key="1" >
    //                                 <LongTermMed />

    //                             </TabPane>

    //                             <TabPane tab="Recent Medication" key="2">

    //                                 <AllMedications />
    //                             </TabPane>
    //                             <TabPane tab="Repeated Medication" key="3" >

    //                                 <RepeatedMedication />
    //                             </TabPane>

    //                         </Tabs>
    //                     </div>
    //                 </div>

    //             </div>

    //             <footer className="footer-ignore">

    //             </footer>
    //         </div>

    //     </div>

    // </div >
  );
};

const styles = StyleSheet.create({
  textDetails: {
    textAlign: "justify",
    color: "#95989A",
    fontSize: 15,
  },
  textDetailsInner: {
    textAlign: "justify",
    color: "#333333",
    fontSize: 14,
  },
});


function mapStateToProps(state) {
  return {
    ProxyMenu: state.ProxyMenu,
  }
}

function mapDispatchToProps(dispatch) {
  return {

    tokenVal: (history) => dispatch(checkToken(history))
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Med)


// export default Med;
