import AsyncStorage from "@react-native-async-storage/async-storage";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Settings from "../../../config/Settings";
import getUserId from "../../../core/GetToken";

export const GetProviderLocationsFromAPI = createAsyncThunk(
    "getProviderLocations/Appointment",
    async (values, { dispatch, getState }) => {

        dispatch(getProviderLocations())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = {
                PatientID: item.userid,
                TypeID: 11,
            }
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(raw)
            };
            fetch(Settings.apiUrl + "/IndiciLookup", requestOptions)
                .then(response => response.json())
                .then(json => {
                    let arrayofProviderLocation = []
                    let listofids = []
                    let namesLocation = []
                    if (json[0].hasOwnProperty('entry'))
                        json[0].entry.map((item, index) => {
                            let ob = {
                                id: item.resource.extension[0].valueString,
                                title: item.resource.extension[1].valueString
                            }
                            let obj = {
                                id: item.resource.extension[0].valueString,
                                colorcode: getRandomColor(index),
                                name: item.resource.extension[1].valueString
                            }
                            listofids.push(item.resource.extension[0].valueString)
                            arrayofProviderLocation.push(obj)
                            namesLocation.push(item.resource.extension[0].valueString)
                        })

                    dispatch(getProviderLocationsSuccess({ data: json[0].entry, locations: listofids, colorList: arrayofProviderLocation }))

                })
                .catch(error => getProviderLocationsFailed());

        })


    }

);

const getRandomColor = (index) => {
    // var colorList = ["#4A192C", "#8B8C7A", "#8A6642", "#4A192C", "#E63244", "#2F353B", "#FFA420", "#252850", "#F80000", "#721422"]
    // let colorInd = index
    // if (index > colorList.length - 1) {
    //     colorInd = colorInd % colorList.length
    // }
    // return colorList[colorInd];

    return index > 9 ? index % 10 : index
}

const GetProviderLocationsSlice = createSlice({
    name: "getProviderLocations",
    initialState: {
        ProviderLocation: [],
        defaultLocation: [],
        Colorlist: [],
        isFetchingProvider: false,
        error: false
    },
    reducers: {
        getProviderLocations: (state, action) => {
            state.isFetchingProvider = false
            state.ProviderLocation = []
            state.defaultLocation = []
            state.Colorlist = []

        },
        getProviderLocationsSuccess: (state, action) => {

            state.ProviderLocation = action.payload.data
            state.defaultLocation = action.payload.locations
            state.Colorlist = action.payload.colorList
            state.isFetchingProvider = false
            state.error = false

        },
        getProviderLocationsFailed: (state, action) => {
            state.ProviderLocation = []
            state.defaultLocation = []
            state.Colorlist = []
            state.isFetchingProvider = false
            state.error = true
        },
        getProviderLocationsFinished: (state, action) => {
            state.ProviderLocation = []
            state.defaultLocation = []
            state.Colorlist = []
            state.isFetchingProvider = false
            state.error = false
        },

    },
});




export const {
    getProviderLocations, getProviderLocationsFailed, getProviderLocationsSuccess, getProviderLocationsFinished
} = GetProviderLocationsSlice.actions;
export default GetProviderLocationsSlice.reducer;
