import React, { useEffect, useState } from 'react';

import { connect, useDispatch, useSelector } from "react-redux";
import { getVitalfromAPI } from '../../shared/actions/VitalsActions';
import { View, ActivityIndicator, FlatList, StyleSheet, Text, StatusBar } from "react-native-web";
import VitalHandler from '../components/VitalHandler';
import { checkToken } from '../../shared/actions/TokenValidActions';
import { Button, DatePicker } from 'antd';
import moment from 'moment';
import { actionAPI } from '../../shared/reduxToolkit/actionAPI';

const useNavigate = require("react-router-dom").useNavigate;
const { RangePicker } = DatePicker;

const Vitals = props => {
    const { isLoading, wholeArray } = useSelector(state => state.PatientVitals)

    const navigate = useNavigate()
    const { ProxyMenu, isLoadingMenu } = useSelector(state => state.ProxyMenu);
    const history = useNavigate()

    const apiDispatcher = useDispatch()
    React.useEffect(() => {
        apiDispatcher(actionAPI.checkToken(history))
    }, [])
    useEffect(() => {
        if (ProxyMenu != null && ProxyMenu != undefined) {
            let itemExists = false
            ProxyMenu.map((item, index) => {
                if (item.resource.extension[0].valueString == 'Vitals') {
                    itemExists = true
                }

            })
            if (!itemExists) {
                navigate("/")
            }
        }
    }, [ProxyMenu])
    useEffect(() => {
        apiDispatcher(actionAPI.GetPatientVitalsFromAPI({
            pageSize: 30,
            pageNumber: 1,
            ChartType: 0,
        }))
        console.log("object")
    }, [])
    const [filterStartDate, setFilterStartDate] = useState(null)
    const [filterEndDate, setFilterEndDate] = useState(null)
    const [dates, setDates] = useState(null)
    const handleDateChange = (date, dateString) => {
        console.log(moment(dateString[0]).format("YYYY-MM-DD"))
        if (dateString && dateString.length > 0) {

            setFilterStartDate(moment(dateString[0]).format("YYYY-MM-DD"))
            setFilterEndDate(moment(dateString[1]).format("YYYY-MM-DD"))
        }
    }
    const handleFilterClick = () => {
        const pageSize = 30
        const ChartType = 0
        const pageNumber = 1
        apiDispatcher(actionAPI.GetPatientVitalsFromAPI({
            pageSize: pageSize,
            pageNumber: pageNumber,
            ChartType: ChartType,
            startDate: filterStartDate,
            endDate: filterEndDate
        }))
    }
    const handleResetClick = () => {
        setFilterStartDate(null)
        setFilterEndDate(null)
        apiDispatcher(actionAPI.GetPatientVitalsFromAPI({
            pageSize: 30,
            pageNumber: 1,
            ChartType: 0,
            startDate: null,
            endDate: null
        }))
    }
    return (

        <div className="page-content-wrapper-inner">
            <div className="viewport-header">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb has-arrow">
                        <li className="breadcrumb-item">
                            <a href="#">Dashboard</a>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">Vitals</li>
                    </ol>
                </nav>
            </div>
            <div className="content-viewport">
                <div className="med-repeat-info-box">
                    <p className="info-heading fw-bolder">Vitals are initially visible for 4 years</p>
                    <ul>
                        <li>Old data can be viewed by using the Date filter below.</li>
                        <li>Maximum 4 years of data at a time will be displayed by the date filter.</li>
                    </ul>
                </div>
                <div className='row my-3 p-4 w-100'>
                    <div className='col-12 fw-bolder' >Filter Vitals by: </div>

                    <RangePicker

                        className="appointment-history-datepicker col-12 col-sm-8 col-md-5 m-2"
                        format={'DD-MMM-YYYY'}
                        disabledDate={(current) => {
                            const tooLate = dates && dates[0] && current.diff(dates[0], 'days') >= 1460;
                            const tooEarly = dates && dates[1] && dates[1].diff(current, 'days') >= 1460;
                            return current && (current < (moment().subtract(100, "year")) || current > (moment(new Date())) || tooLate || tooEarly)
                        }}
                        getPopupContainer={(trigger) =>
                            trigger.parentNode
                        }
                        onCalendarChange={(e) => setDates(e)}
                        onChange={handleDateChange}
                        inputReadOnly
                    />
                    <Button className='btn btn-primary m-2 col-auto' onClick={handleFilterClick}>Filter</Button>
                    <Button className='btn btn-primary m-2 col-auto' onClick={handleResetClick}>Reset</Button>
                </div>
                <div className="row row-margin-zero">
                    {
                        isLoading && <div className='w-100 d-flex justify-content-center align-items-center mb-2'><ActivityIndicator size="small" color="#00A1DE" /></div>
                    }


                    {
                        typeof (wholeArray) !== 'undefined' && wholeArray.length ? (wholeArray.map((item, key) => {
                            return (
                                <VitalHandler
                                    key={key}
                                    keyitem={key}
                                    vitalName={item.name}
                                    vitalLabelsArray={item.labels}
                                    vitalDataArray={item.data}
                                    vitalDataArrayB={item.datab} />

                            )
                        })) : !isLoading &&
                        <div className='alert alert-danger p-2 text-center w-100'>No Records Found</div>

                    }

                </div>

            </div>
        </div>

    )
}
const styles = StyleSheet.create({
    container: {
        flex: 1,
        marginTop: StatusBar.currentHeight || 0,
    },
    item: {
        backgroundColor: '#f9c2ff',
        padding: 20,
        marginVertical: 8,
        marginHorizontal: 16,
    },
    title: {
        fontSize: 32,
    },
});

function mapStateToProps(state) {
    return {
        VitalReducer: state.VitalReducer,
        ProxyMenu: state.ProxyMenu,
    }
}


function mapDispatchToProps(disptach) {
    return {
        getVital: (pageSize, ChartType, pageNumber, startDate = null, endDate = null) => disptach(getVitalfromAPI(pageSize, ChartType, pageNumber, startDate, endDate)),
        tokenVal: (history) => disptach(checkToken(history))
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Vitals)
