import { Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { FormatAMPM } from '../../shared/core/datehelper'
import GetAppSettings from '../../shared/core/GetAppSettings'
const Item = ({ titleName, titleValue = "-" }) => {
    return (
        <div className="detail-container">
            <label className="detail-label">{titleName}</label>
            <Tooltip title={titleValue}>

            <span className="detail-value">{titleValue}</span>
            </Tooltip>
        </div>
    )
}
const ProfilePracticeDetail = ({ Practise }) => {
    const [appSettings, setAppSettings] = useState({});

    useEffect(() => {

        GetAppSettings().then((info) => {

            const set = JSON.parse(info)

            setAppSettings(set)
        })
    }, [Practise])
    return (
        <div>
            <div className="letter-detail-card-body">
                <div className="row row-margin-zero">
                    <div className="col-12 col-lg-5 p-0">
                        <Item
                            titleName={"Name"}
                            titleValue={Practise[0].resource.name} />
                        <Item
                            titleName={"Start Time"}
                            titleValue={!Practise[0]?.resource?.extension[6]?.valueBoolean ? FormatAMPM(Practise[0].resource.extension[0].valueDateTime.split('+')[0]) : "-"} />

                        <Item
                            titleName={"End Time"}
                            titleValue={!Practise[0]?.resource?.extension[6]?.valueBoolean ? FormatAMPM(Practise[0].resource.extension[1].valueDateTime.split('+')[0]) : "-"} />
                        {/*                      
                        <Item
                            titleName={"Ethnicity"}
                            titleValue={"Miss"} />
                        */}

                    </div>
                    <div className="col-12 col-lg-6 offset-lg-1 p-0">

                        <Item
                            titleName={"Phone Number"}
                            titleValue={Practise[0].resource.telecom[0].value} />
                        <Item
                            titleName={"My GP"}
                            titleValue={Practise[0].resource.extension.find(o => o.url === 'My GP')?.valueString} />
                        <Item
                            titleName={"Address"}
                            titleValue={Practise[0].resource.address[0].text} />
                        {/* <Item
                            titleName={"Blood Group"}
                            titleValue={"Miss"} /> */}


                    </div>
                </div>
            </div>

        </div>
    )
}
export default ProfilePracticeDetail;