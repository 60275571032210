import AsyncStorage from "@react-native-async-storage/async-storage";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Settings from "../../../config/Settings";
import getUserId from "../../../core/GetToken";

export const GetResourcesFromAPI = createAsyncThunk(
    "getResources/Resources",
    async (values, { dispatch, getState }) => {
        dispatch(getResources())

        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);


            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = {
                PatientID: item.userid,
                PageSize: values.pageSize,
                PageNumber: values.pageNumber,
            }
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(raw)
            };

            fetch(Settings.apiUrl + "Patient/Media", requestOptions)
                .then(data => data.json())
                .then(json => {
                    if (json && json.length) {
                        dispatch(getResourcesSuccess(json[0].entry))

                    } else {
                        dispatch(getResourcesFailed())
                    }
                })
                .catch(err => dispatch(getResourcesFailed(err)))



        })
    }

);

const GetResourcesSlice = createSlice({
    name: "getResources",
    initialState: {
        Resources: [],
        isGetting: false,
        error: false
    },
    reducers: {
        getResources: (state, action) => {
            state.Resources = []
            state.isGetting = true
            state.error = false
        },
        getResourcesSuccess: (state, action) => {
            state.Resources = action.payload
            state.isGetting = false
            state.error = false

        },
        getResourcesFailed: (state, action) => {
            state.Resources = []
            state.isGetting = false
            state.error = true
        },
        getResourcesFinished: (state, action) => {
            state.Resources = []
            state.isGetting = false
            state.error = false
        },

    },
});




export const {
    getResources, getResourcesFailed, getResourcesSuccess, getResourcesFinished
} = GetResourcesSlice.actions;
export default GetResourcesSlice.reducer;
