import React, { useState, useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import {
    StyleSheet,
    Text,
    View,
    Image,
    Dimensions,
    FlatList,
    ActivityIndicator,
} from "react-native-web";

import { connect, useDispatch, useSelector } from "react-redux";
import {
    FomraatedDate,
} from "../../shared/core/datehelper";

import ReminderWebHandler from "../components/ReminderHandler";
import { fetchReminderFromApi, fetchMoreReminderFromApi } from "../../shared/actions/ReminderActions";
import ReminderHandler from "../components/ReminderHandler";
import { checkToken } from '../../shared/actions/TokenValidActions';
import { actionAPI } from "../../shared/reduxToolkit/actionAPI";

const useNavigate = require("react-router-dom").useNavigate;
const RemidersHome = props => {

    const navigate = useNavigate()
    const { ProxyMenu, isLoadingMenu } = useSelector(state => state.ProxyMenu);
    const history = useNavigate()

    const [isloaded, setIsload] = React.useState(false);

    const { Reminders, isGettingReminders } = useSelector(state => state.PatientReminders);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(100);

    const apiDispatcher = useDispatch()

    React.useEffect(() => {
        apiDispatcher(actionAPI.checkToken(history))
    }, [])

    useEffect(() => {
        //alert('Called Future')
        apiDispatcher(actionAPI.GetPatientRemindersFromAPI({ pageNumber: pageNumber, pageSize: pageSize }))
    }, [])
    useEffect(() => {
        if (ProxyMenu != null && ProxyMenu != undefined) {
            let itemExists = false
            ProxyMenu.map((item, index) => {
                if (item.resource.extension[0].valueString == 'Reminders') {
                    itemExists = true
                }

            })
            if (!itemExists) {
                navigate("/")
            }
        }
    }, [ProxyMenu])


    const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));
    React.useEffect(() => {
        const onDimensionsChange = ({ window }) => {
            setDimensions(window);
        };
        Dimensions.addEventListener('change', onDimensionsChange);

        return () => Dimensions.removeEventListener('change', onDimensionsChange);
    }, []);

    const isLargeScreen = dimensions.width >= 1024;
    let columnCount = isLargeScreen ? 2 : 1;

    return (

        <div className="page-content-wrapper-inner">
            <div className="viewport-header">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb has-arrow">
                        <li className="breadcrumb-item">
                            <Link to="/">Dashboard</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">Reminders</li>
                    </ol>
                </nav>
            </div>
            <div className="content-viewport">
                <div className="row row-margin-zero h-100">
                    <div className="col-12 h-100">
                        <div className="grid full-width h-100">
                            <div className="grid-body full-width h-100">

                                <div className="item-wrapper full-width d-flex flex-column h-100">
                                    <div className='reminder-legends-container'>
                                        <div className="reminder-legends card-body">
                                            <div className='reminder-low-legend'>
                                                <div className='custom-legends low-legend-box'> Low </div>
                                            </div>
                                            <div className='reminder-medium-legend'>
                                                <div className='custom-legends medium-legend-box'> Medium </div>
                                            </div>

                                            <div className='reminder-high-legend'>
                                                <div className='custom-legends high-legend-box'> High </div>
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        isGettingReminders && (
                                            <div className="mb-5 text-center">
                                                <ActivityIndicator size="small" color="#00A1DE" />
                                            </div>
                                        )
                                    }
                                    {
                                        typeof (Reminders) !== 'undefined' && Reminders.length ? (
                                            <FlatList
                                                className="reminders-home-flatlist"
                                                data={Reminders}
                                                renderItem={({ item }) => (
                                                    <ReminderHandler
                                                        title={item.resource.extension[2].valueString}
                                                        reminderProvider={item.resource.sender.display}
                                                        color={item.resource.extension[5].valueString}
                                                        reminderNote={item.resource.note == undefined ? "(Not Available)" : item.resource.note[0].text}
                                                        reminderDate={FomraatedDate(item.resource.sent)} //item.resource.extension[5].valueString
                                                        reminderDescription={item.resource.extension[0].valueString}
                                                    />
                                                )}
                                                keyExtractor={(item) => item.resource.id}
                                                initialNumToRender={10}
                                                maxToRenderPerBatch={10}
                                                numColumns={columnCount}
                                                key={columnCount}
                                                windowSize={7}

                                            />
                                        ) : !isGettingReminders &&
                                            <div className='alert alert-danger p-2 text-center mb-0 full-width'>No Records Found</div>

                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}





function mapStateToProps(state) {
    return {
        Reminders: state.Reminder,
        ProxyMenu: state.ProxyMenu,
    };
}

function mapDispatchToProps(disptach) {
    return {
        getReminder: (pageSize, pageNumber) => disptach(fetchReminderFromApi(pageSize, pageNumber)),
        tokenVal: (history) => disptach(checkToken(history))

        //getMoreReminder: (pageNumber, pageSize) => disptach(fetchMoreReminderFromApi(pageNumber, pageSize)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(RemidersHome);

// export default RemidersHome
