import AsyncStorage from "@react-native-async-storage/async-storage";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Settings from "../../../config/Settings";
import getUserId from "../../../core/GetToken";

export const GetRolesFromAPI = createAsyncThunk(
    "getRoles/Diagnosis",
    async (values, { dispatch, getState }) => {
        dispatch(getRoles())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")

            var raw = {
                PatientID: item.userid,
                TypeID: 2,
                PracticeLocation: values.PracticeLocation?.toString(),
                IsLockDownProviderEnabled :true
            }
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(raw)
            };

            fetch(Settings.apiUrl + "Patient/LoadRoles", requestOptions)
                .then(data => data.json())
                .then(json => {
                    json[0].hasOwnProperty('entry') ? dispatch(getRolesSuccess(json[0].entry)) : dispatch(getRolesFailed())
                })
                .catch((err) => {
                    dispatch(getRolesFailed(err))
                })
        })
    }

);

const GetRolesSlice = createSlice({
    name: "getRoles",
    initialState: {
        Roles: [],
        isFetching: false,
        error: false
    },
    reducers: {
        getRoles: (state, action) => {
            state.Roles = []
            state.isFetching = true
            state.error = false
        },
        getRolesSuccess: (state, action) => {
            state.Roles = action.payload
            state.isFetching = false
            state.error = false

        },
        getRolesFailed: (state, action) => {
            state.Roles = []
            state.isFetching = false
            state.error = true
        },
        getRolesFinished: (state, action) => {
            state.Roles = []
            state.isFetching = false
            state.error = false
        },

    },
});




export const {
    getRoles, getRolesFailed, getRolesSuccess, getRolesFinished
} = GetRolesSlice.actions;
export default GetRolesSlice.reducer;
