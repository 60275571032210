import AsyncStorage from "@react-native-async-storage/async-storage";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Settings from "../../../config/Settings";
import getUserId from "../../../core/GetToken";

export const GetDueImmuneFromAPI = createAsyncThunk(
    "getDueImmune/Immune",
    async (values, { dispatch, getState }) => {

        dispatch(getDueImmune())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = {
                PatientID: item.userid,
                PageSize: values.pageSize,
                PageNumber: values.pageNumber,
                TypeID: 3,
            }
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(raw)
            };


            fetch(Settings.apiUrl + "Patient/Immunization", requestOptions)
                .then(data => data.json())
                .then(json => {
                    if (json) {

                        json[0].hasOwnProperty('entry') ? dispatch(getDueImmuneSuccess(json[0].entry)) : dispatch(getDueImmuneFinished())
                    } else {
                        dispatch(getDueImmuneFinished())
                    }

                })
                .catch(err => dispatch(getDueImmuneFailed(err)))


        })

    }

);

const GetDueImmuneSlice = createSlice({
    name: "getDueImmune",
    initialState: {
        DueImmune: [],
        isGettingImmune: false,
        DueimmuneError: false
    },
    reducers: {
        getDueImmune: (state, action) => {
            state.DueImmune = []
            state.isGettingImmune = true
            state.DueimmuneError = false
        },
        getDueImmuneSuccess: (state, action) => {
            state.DueImmune = action.payload
            state.isGettingImmune = false
            state.DueimmuneError = false

        },
        getDueImmuneFailed: (state, action) => {
            state.DueImmune = []
            state.isGettingImmune = false
            state.DueimmuneError = true
        },
        getDueImmuneFinished: (state, action) => {
            state.DueImmune = []
            state.isGettingImmune = false
            state.DueimmuneError = false
        },

    },
});




export const {
    getDueImmune, getDueImmuneFailed, getDueImmuneSuccess, getDueImmuneFinished
} = GetDueImmuneSlice.actions;
export default GetDueImmuneSlice.reducer;
