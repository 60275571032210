
import Main from "./app/index";
// import { Worker } from '@react-pdf-viewer/core';
import * as All from './intercept'
import * as AA from './intercept2.js'
import "./App.css"
// Import the stylesEindex.html
// import '@react-pdf-viewer/core/lib/styles/index.css';
import { useCallback, useEffect, useRef, useState } from "react";
import { Input, Modal,Button } from "antd";
import { fetchUserInformation } from "./shared/actions/AuthActions";
import { connect, useDispatch, useSelector } from "react-redux";
import { AuthenticateUserAPI } from "./shared/reduxToolkit/actions/AuthenticateActions/AuthenticateUser";
import { actionAPI } from "./shared/reduxToolkit/actionAPI";
const BrowserRouter = require("react-router-dom").BrowserRouter



function App(props) {
  const [signAgainModalVisible, setSignAgainModalVisible] = useState(false)
  const { isLoggedIn, isLoggin, AuthUser, error, passworderror, loginFailed, userPasswordStatus } = useSelector(state => state.AuthenticateUser);

  const [reloginUserName, setReloginUserName] = useState(JSON.parse(localStorage.getItem("@userToken"))?.email)
  const [reloginPassword, setReloginPassword] = useState("")
  const [reloginLoading, setReloginLoading] = useState(false)
  const apiDispatcher = useDispatch()

  const getNewUserToken = async () => {
  
    setSignAgainModalVisible(true)

  };
  const intervalRef = useRef();
  const getToken = useCallback(() => {
    // Get new token if and only if existing token is available
    let tokenData = JSON.parse(localStorage.getItem("@userToken"))
    if (tokenData != null && tokenData.token != null && !signAgainModalVisible && isLoggedIn && window.location.pathname.indexOf("login") == -1) {
      getNewUserToken();
    }
  }, []);
    // Trigger API to get a new token before token gets expired.
  useEffect(() => {
    //time out set to 10min
    const interval = setInterval(() => getToken(),10 * 60 * 1000000000000);
    intervalRef.current = interval;
    return () => clearInterval(interval);
  }, [getToken]);
  const handleRelogin = () => {
    setReloginLoading(true)
    // props.getUserToken(reloginUserName, reloginPassword)
    apiDispatcher(AuthenticateUserAPI({ username: reloginUserName, password: reloginPassword }))
  }

  useEffect(() => {
    const handleStorageChange = (event) => {
      console.log("storage data changed", event.key)
      if (event.key === "@userToken") {
        if (localStorage.getItem("@userToken")) {
          apiDispatcher(actionAPI.GetUserProfileAPI())
          apiDispatcher(actionAPI.GetPracticeOrganizationDataAPI())
        }
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [])
  useEffect(() => {
    if (isLoggedIn) {
      setReloginLoading(false)
      setSignAgainModalVisible(false)

    }
  }, [isLoggin])

 

  return (
    <>
      <BrowserRouter>
      
     
        <Main />
        

      </BrowserRouter>
      <Modal
        title="Session TimeOut"
        open={signAgainModalVisible}
        onOk={() => handleRelogin()}
        okText="Login"
        confirmLoading={reloginLoading}
        closable={false}
        cancelButtonProps={{ style: { display: 'none' } }}
        zIndex={10000}
        footer={[
         
          <Button
            className="btn btn-sm btn-primary"
            onClick={() => handleRelogin()}
            loading={reloginLoading}
          >
            Login
          </Button>,
        ]}

      >
        <p>It appears that your session has expired due to inactivity, please login again to continue working
        </p>
        <div className="mt-3 d-flex">

          <span className="mr-4"> <b> Username:</b> </span>
          <label>{reloginUserName}</label>
        </div>
        <div className="mt-3 d-flex justify-content-center align-items-center">

          <span className="mr-4"> <b> Password: </b> </span>
          <Input autoComplete="off" className='form-control' type="password" placeholder="Password" value={reloginPassword} onChange={(e) => setReloginPassword(e.target.value)} />
        </div>
      </Modal>
    </>
  );
}
function mapStateToProps(state) {
  return {
    AuthUser: state.AuthUser,

  }
}

function mapDispatchToProps(disptach) {
  return {
    getUserToken: (email, password, q = "") => disptach(fetchUserInformation(email, password, q)),
  }
}


//export default Login;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App)