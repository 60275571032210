import AsyncStorage from "@react-native-async-storage/async-storage";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Settings from "../../../config/Settings";
import getUserId from "../../../core/GetToken";

export const ListInboxMessagesAPI = createAsyncThunk(
    "listInboxMessages/Messaging",
    async (values, { dispatch, getState }) => {

        dispatch(listingInboxMessages())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = {
                PatientID: item.userid,
                PageSize: values.pageSize,
                PageNumber: values.pageNumber,
                Type: values.type,
                MessagingType: values.messagingType,
                ReadFilter: values.readFilter
            }
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(raw)
            };


            fetch(Settings.apiUrl + "Patient/Messaging", requestOptions)

                .then(data => data.json())
                .then(json => {
                    if (json == null) {
                        dispatch(listingInboxMessagesSuccess([{ total: 0 }]))

                    } else {
                        dispatch(listingInboxMessagesSuccess(json))

                    }
                })
                .catch(err => dispatch(listingInboxMessagesFailed(err)))

        })

    }

);

const ListingInboxMessagesSlice = createSlice({
    name: "listInboxMessages",
    initialState: {
        inboxMessageListing: null,
        inboxMessageListingSuccess: false,
        inboxMessageListingFailed: false,
        inboxMessageListingLoading: false,
        inboxMessageListingError: null,
        inboxMessageListingStatus: "idle",
    },
    reducers: {
        listingInboxMessages: (state, action) => {
            state.inboxMessageListingSuccess = false
            state.inboxMessageListingFailed = false
            state.inboxMessageListingLoading = true
            state.inboxMessageListingError = null
            state.inboxMessageListingStatus = "loading"

        },
        listingInboxMessagesSuccess: (state, action) => {
            state.inboxMessageListing = action.payload
            state.inboxMessageListingSuccess = true
            state.inboxMessageListingFailed = false
            state.inboxMessageListingLoading = false
            state.inboxMessageListingError = null
            state.inboxMessageListingStatus = "success"


        },
        listingInboxMessagesFailed: (state, action) => {
            state.inboxMessageListingSuccess = false
            state.inboxMessageListingFailed = true
            state.inboxMessageListingLoading = false
            state.inboxMessageListingError = action.payload
            state.inboxMessageListingStatus = "error"

        },

    },
});




export const {
    listingInboxMessages, listingInboxMessagesFailed, listingInboxMessagesSuccess
} = ListingInboxMessagesSlice.actions;
export default ListingInboxMessagesSlice.reducer;
