import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { fetchUserInformation } from "../../shared/actions/AuthActions";
import { View, TouchableOpacity, FlatList, ActivityIndicator } from "react-native-web";
import { emailValidator, getInitials, passwordValidator } from '../../shared/core/utils';
import AsyncStorage from "@react-native-async-storage/async-storage";
import changePasswrodService from '../../shared/services/ChangePasswrod';
import { Redirect, useNavigate } from "react-router-dom";

const ResetPassword = props => {
    const dispatch = useDispatch();
    const linkHistory = useNavigate();
    const [isResetPassword, setIsResetPassword] = useState(false)

    const resetPasswordFunc = async () => {
        linkHistory("/", { replace: true })
       
    }

    const loadReset = () => {
        linkHistory("/", { replace: true })
    }
    const clearCache = async () => {
        try {
            await AsyncStorage.clear()
        } catch (e) {
            // clear error
        }

    }
    function value(passForm) {

       



    };

    return (
        <div className="authentication-theme auth-style_1">
            <div className="row">
                <div className="col-12 logo-section">
                    <a href="#" className="logo">
                        <img src="https://devnhlapp.indici.ie/Images/mainLogo-indici-140X40.svg" alt="logo" />
                    </a>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-5 col-md-7 col-sm-9 col-11 mx-auto">
                    <div className="grid">
                        <div className="grid-body">
                           
                            <div className="row">
                                <div className="col-lg-7 col-md-8 col-sm-9 col-12 mx-auto form-wrapper">

                                    <div className="form-group input-rounded">
                                        <input autoComplete="off" id="password" type="text" className="form-control" placeholder="Enter password." />
                                    </div>
                                    <div className="form-group input-rounded">
                                        <input autoComplete="off" id="confirmPassword" type="text" className="form-control" placeholder="Confirm password." />
                                    </div>
                                    {/* <div className="form-group input-rounded">
                                        <input autoComplete="off" type="password" className="form-control" placeholder="Password" />
                                    </div> */}
                                    {/* <div className="form-inline">
                                        <div className="checkbox">
                                            <label>
                                                <input autoComplete="off" type="checkbox" className="form-check-input" />Remember me <i className="input-frame" />
                                            </label>
                                        </div>
                                    </div> */}
                                    <div className="form-group input-rounded">
                                        <p id="errorMessage" className='text-danger'></p>
                                    </div>
                                    {/* {
                                        reloadApplication && reloadApp()
                                    } */}
                                    {
                                        isResetPassword && <div className='text-center mb-5' ><ActivityIndicator size="small" color="#00A1DE" /></div>
                                    }

                                    <button onClick={() => loadReset()} className="btn btn-primary btn-block">Set Password </button>
                                    <div className="signup-link">

                                        <Link to="/">
                                            Sign In
                                        </Link>


                                    </div>
                                </div>
                            </div>
                       
                       
                       
                       
                        </div>
                    </div>
                </div>
            </div>
            <div className="auth_footer">
                <p className="text-muted text-center">© My indici 2021</p>
            </div>
        </div>

    );
}



function mapStateToProps(state) {
    return {
        AuthUser: state.AuthUser
    }
}

function mapDispatchToProps(disptach) {
    return {
        getUserToken: (email, password) => disptach(fetchUserInformation(email, password)),
    }
}


//export default Login;

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResetPassword)