import { Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { capitalize } from '../../shared/core/datehelper'



const getByTitle = item => {

    if (item.system === "email" && item.use === "home") {
        return "Primary Email"
    }
    else if (item.system === "email" && item.use === "temp") {
        return "Secondary Email"
    }
    else {
        return capitalize(item.use)
    }
    //  item.system === "email" && item.use ==="home" ?   capitalize(item.use)
}

const Item = ({ titleName, titleValue }) => {
    return (
        <Tooltip title={titleValue}>

            <div className="detail-container">
                <label className="detail-label">{titleName}</label>
                <span className="detail-value">{titleValue}</span>
            </div>
        </Tooltip>
    )
}
const ProfileContactDetails = ({ Profile }) => {
    const [contactMethodStr, setContactMethodStr] = useState("")
    useEffect(() => {
        let str = "";
        if (typeof (Profile) !== 'undefined' && Profile.length && Profile[0].resource.extension) {

            let arr = JSON.parse(Profile[0].resource.extension[6].valueString)
            if (arr.length >= 1) {

                arr.map((item, index) => {
                    str = str + item.ContactMethod
                    if (index != arr.length - 1) {
                        str = str + ", "
                    }
                }

                    // <ProfileItemSingle contactTile={getByTitle(item)} contactValue={item.value} />
                )
            }
            setContactMethodStr(str)
        }
    }, [Profile])
    return (
        <div>
            <div className="letter-detail-card-body">
                <div className="row row-margin-zero">
                    <div className="col-12 p-0">
                        {
                            typeof (Profile) !== 'undefined' && Profile.length && Profile[0].resource.telecom ? Profile[0].resource.telecom.map((item) => {
                                return (
                                    <Item
                                        titleName={getByTitle(item)}
                                        titleValue={item.value} />
                                    // <ProfileItemSingle contactTile={getByTitle(item)} contactValue={item.value} />

                                )
                            }) : <span>Contact detail not available.</span>
                        }
                        <Item
                            titleName={"Contact Methods"}
                            titleValue={contactMethodStr} />
                        {/* <Item
                    titleName={"Work Phone Number"}
                    titleValue={"Miss"} />
                <Item
                    titleName={"Home Phone Number"}
                    titleValue={"Miss"} />
                <Item
                    titleName={"Cell Phone Number"}
                    titleValue={"Miss"} />

                <Item
                    titleName={"Email"}
                    titleValue={"Miss"} />
                <Item
                    titleName={"Contact Method"}
                    titleValue={"Miss"} /> */}
                    </div>
                </div>
            </div>

        </div>
    )
}
export default ProfileContactDetails;