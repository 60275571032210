import React, { useEffect, useState } from 'react';
import { View, Text, ActivityIndicator, FlatList } from "react-native-web";
import { Button, DatePicker, Spin } from "antd";
import 'antd/dist/antd.css';
import { connect, useDispatch, useSelector } from 'react-redux'

import AppointmentHandler from '../../components/AppointmentHandler';
import { searchAppointmentFromApi, searchFilteredAppointmentFromApi, showALlAppointmentFromApi } from "../../../shared/actions/SearchAppointmentActions";
import { GetDayName, GetAppointmentDate, GetMonthName, ReturnTypeIcon, GetYear, FormateUTCTime, GetMonthNumber } from '../../../shared/core/datehelper'

import moment from "moment";
import InfiniteScroll from 'react-infinite-scroll-component';
import { flushSync } from 'react-dom';
import { actionAPI } from '../../../shared/reduxToolkit/actionAPI';

const { RangePicker } = DatePicker;
const SearchAppointmentView = props => {
    // const [filteredData, setFilteredData] = useState(null)
    const [activeStatus, setActiveStatus] = useState("All");
    const [appointsDisplayed, setAppointsDisplayed] = useState([])
    const [filterFromDate, setFilterFromDate] = useState(null)
    const [filterToDate, setFilterToDate] = useState(null)
    const { Profile } = useSelector(state => state.UserProfile)
    const { Practise } = useSelector(state => state.PracticeOrganization)
    const { SearchAppointment, isGettingSearch } = useSelector(state => state.SearchAppointments);
    const apiDispatcher = useDispatch()
    const [appointmentsList, setAppointmentsList] = useState([]);
    const [totalRecords, setTotalRecords] = useState(10)
    let limit = 15
    let offset = 1

    const getAppointmentsAPI = (fromSearchButton = false, status = activeStatus) => {
        limit = 15;
        offset = fromSearchButton ? 1 : Math.ceil(appointmentsList.length / limit) + 1;
        if (offset == 1) {
            flushSync(() => {
                setAppointmentsList([])
                setTotalRecords(0)
            })
        }

        apiDispatcher(actionAPI.GetPatientAppointmentsFromAPI({
            pageSize: limit,
            pageNumber: offset,
            fromDate: filterFromDate,
            toDate: filterToDate,
            appointmentType: status
        }))
    };
    const getAppointmentsFromStartAPI = () => {

        limit = 15;
        offset = 1;
        flushSync(() => {
            setAppointmentsList([])
        })


        apiDispatcher(actionAPI.GetPatientAppointmentsFromAPI({
            pageSize: limit,
            pageNumber: offset,
            fromDate: filterFromDate,
            toDate: filterToDate,
            appointmentType: activeStatus
        }))

    };
    const fetchmoreAppointmentsData = (ignore = false) => {
        if (ignore || appointmentsList.length < totalRecords) {
            getAppointmentsAPI();
        }
    }

    useEffect(() => {
        getAppointmentsAPI()
    }, [])
    useEffect(() => {
        if (SearchAppointment !== "undefined" && SearchAppointment.length) {
            setTotalRecords(SearchAppointment[0].total)
            if (SearchAppointment[0]?.entry) {
                const mergeData = [...appointmentsList, ...SearchAppointment[0]?.entry];
                setAppointmentsList(mergeData);


            }

        }
    }, [SearchAppointment])
    useEffect(() => {
        setAppointsDisplayed(appointmentsList)
    }, [appointmentsList])
    const dateFormat = 'DD-MM-YYYY';
    //const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];
    const dateChange = (date, dateString) => {

            // // alert(dateString)
            // var str = dateString.toString();
            // //alert(str)
            // str = str.split(",");

            // let sDate = GetMonthNumber(str[0])// GetYear(str[0]) + '-' + GetMonthNumber(str[0]) + '-' + GetAppointmentDate(str[0])
        flushSync(() => {
            setFilterFromDate(date ? JSON.stringify(date[0]).replace(/['"]+/g, '') : null)
            setFilterToDate(date ? JSON.stringify(date[1]).replace(/['"]+/g, '') : null)

            setActiveStatus("All")
        })

           


    }

    const getTagStyles = (type) => {
        if (type === "Cancelled") {
            return 'card-header text-white bg-warning'
        }
        if (type === "Missed") {
            return 'card-header text-white bg-danger'
        }
        if (type === "Future") {
            return 'card-header text-white bg-primary'
        }
        if (type === "Completed") {
            return 'card-header text-white bg-success'
        }
        if (type === "Other") {
            return 'card-header text-white bg-success'
        }
    }
  
    const filterMissedAppoints = () => {
        setActiveStatus("Missed")

        // if (SearchAppointment !== "undefined" && SearchAppointment.length && SearchAppointment[0].entry) {
        //     setAppointsDisplayed(appointmentsList.filter(item => item.resource.extension[6].valueString === "Missed"))
        // }
        getAppointmentsAPI(true, "Missed")

    }
    const filterCompletedAppoints = () => {

        setActiveStatus("Completed")

        // if (SearchAppointment !== "undefined" && SearchAppointment.length && SearchAppointment[0].entry) {
        //     setAppointsDisplayed(appointmentsList.filter(item => item.resource.extension[6].valueString === "Completed"))
        // }
        getAppointmentsAPI(true, "Completed")
    }
    const filterCancelledAppoints = () => {
        setActiveStatus("Cancelled")
        // if (SearchAppointment !== "undefined" && SearchAppointment.length && SearchAppointment[0].entry) {
        //     setAppointsDisplayed(appointmentsList.filter(item => item.resource.extension[6].valueString === "Cancelled"))
        // }
        getAppointmentsAPI(true, "Cancelled")

    }
    const filterAllAppoints = () => {
        setActiveStatus("All")
        // if (SearchAppointment !== "undefined" && SearchAppointment.length && SearchAppointment[0].entry) {
        //     setAppointsDisplayed(appointmentsList)
        // }
        getAppointmentsAPI(true, "All")

    }

    return (
        <div className='appointment-history-inner-container'>
            <div className='padding-left-twenty padding-right-twenty' >
                <div className='card-body p-0'>
                    <div className="row row-margin-zero mb-3">
                        <div className="col-12">
                            <div className='text-algin-center' >
                                
                                <span className={`appoint-status-badge badge badge-success margin-top-ten ${activeStatus === "Completed" ? "CompletedSearchAppointment": ""}`}  onClick={filterCompletedAppoints}>Completed</span>
                                <span className={`appoint-status-badge badge badge-danger margin-top-ten margin-left-fifteen ${activeStatus === "Missed" ? "MissedSearchAppointment": ""}`}  onClick={filterMissedAppoints}>Missed</span>
                                <span className={`appoint-status-badge badge badge-warning margin-top-ten margin-left-fifteen ${activeStatus === "Cancelled" ? "CancelledSearchAppointment": ""}`}  onClick={filterCancelledAppoints}>Cancelled </span>
                                <span className={`appoint-status-badge badge badge-primary margin-top-ten margin-left-fifteen ${activeStatus === "All" ? "AllearchAppointment": ""}`}  onClick={filterAllAppoints}>All </span>
                            </div>

                        </div>
                    </div>
                    <div className="row row-margin-zero">
                        
                    <div className="appt-histoy-padding-container" >
                        <div className='appt-history-filter-text' >Filter Appointments History by: </div>
                        <RangePicker

                            className="appointment-history-datepicker"
                            format={dateFormat}
                            disabledDate={(current) => {
                                return current && (current < (moment().subtract(100, "year")) || current > (moment(new Date())))
                            }}
                            getPopupContainer={(trigger) =>
                                trigger.parentNode
                            }
                            onChange={dateChange}
                            inputReadOnly
                        />
                        <Button className='btn btn-primary appt-history-search-btn' onClick={() => getAppointmentsAPI(true)}>Search</Button>
                    </div>
                    </div>
                </div>
            </div>

            
            {
                typeof (SearchAppointment) !== 'undefined' && SearchAppointment.length ? (
                    <div id="appointmentScrollableDiv" className='appointment-history-internal-scroll h-100'>
                        <InfiniteScroll
                            dataLength={appointmentsList.length}
                            next={fetchmoreAppointmentsData}
                            hasMore={appointmentsList.length < totalRecords}
                            loader={<div className='d-flex justify-content-center my-2'><Spin /></div>}

                            scrollableTarget="appointmentScrollableDiv"
                        >
                    <FlatList
                       className="SearchAppointmentFlatList"
                          
                                data={appointsDisplayed}
                        renderItem={({ item }) => (
                            item.resource.extension[6].valueString != "Future" &&
                            <AppointmentHandler
                                providerName={item.resource.extension[2].valueString ? item.resource.extension[2].valueString : 'N/A'}
                                styleName={getTagStyles(item.resource.extension[6].valueString)}
                                appType={item.resource.extension[0].valueString ? item.resource.extension[0].valueString : 'N/A'}
                                nhi={Profile.length && Profile[0]?.resource?.extension[3]?.valueString}
                                appointmentSatus={item.resource.extension[6].valueString ? item.resource.extension[6].valueString : 'N/A'}
                                // appointmentDate={GetAppointmentDate(item.resource.period.start) + ' ' + GetMonthName(item.resource.period.start) + ' ' + GetYear(item.resource.period.start)}
                                appointmentDate={GetAppointmentDate(item.resource.period.start.split('T')[0]) + ' ' + GetMonthName(item.resource.period.start.split('T')[0]) + ' ' + GetYear(item.resource.period.start.split('T')[0])}
                                praticeAddress={item.resource.extension[3].hasOwnProperty('valueString') ? item.resource.extension[3].valueString : '(N/A)'}
                                firstitem={item.resource.extension[5].valueString ? item.resource.extension[5].valueString : 'N/A'}
                                secondItem={GetDayName(item.resource.period.start.split('T')[0]) + ', ' + FormateUTCTime(item.resource.period.start)}
                                patientDetails={item.resource.extension[1].valueString} //+ ', ' + item.resource.extension[0].valueString}}
                                location={item.resource.extension[4].hasOwnProperty('valueString') ? item.resource.extension[4].valueString : '(N/A)'}
                                icTpye={'mdi mdi-18px mdi-alert-circle'} //this icons for cancel reason
                                // reason={"Appointment Status: " + item.resource.extension[6].valueString}
                                status={item.resource.extension[6].valueString}
                                type={item.resource.extension[6].valueString}
                                icTpyeCancel={'mdi mdi-18px mdi-close-circle'}
                                reasonForCancel={item.resource.extension[6].valueString === "Cancelled" ? item.resource.extension[9].valueString !== undefined || item.resource.extension[9].valueString !== " " ? 'Reason: ' + item.resource.extension[9].valueString : 'Reason: N/A' : null}
                                patientProfile={Profile}
                                organizationData={Practise}
                                birthdate={moment(Profile.length && Profile[0]?.resource?.birthDate).format('DD MMM YYYY')}
                                address={Profile.length && Profile[0]?.resource?.address && Profile[0]?.resource?.address.length ? Profile[0]?.resource?.address[0]?.text : ""}

                                videoCall={false}
                                duration={item?.resource?.extension?.find(o => o.url === "Duration")?.valueDecimal}

                            />
                        )}
                        horizontal={false}
                        keyExtractor={item => item.resource.id}
                        initialNumToRender={30}

                    />
                        </InfiniteScroll>
                    </div>
                ) : !isGettingSearch &&
                    <div className='alert alert-danger p-2 text-center'>No Records Found</div>

            }

            {/* <AppointmentHandler styleName={'card-header text-white bg-warning'} />
            <AppointmentHandler styleName={'card-header text-white bg-danger'} />
            <AppointmentHandler styleName={'card-header text-white bg-warning'} />
            <AppointmentHandler styleName={'card-header text-white bg-primary'} />
            <AppointmentHandler styleName={'card-header text-white bg-primary'} />
            <AppointmentHandler styleName={'card-header text-white bg-success'} /> */}
        </div>
    );
}


function mapStateToProps(state) {
    return {
        SearchAppointment: state.SearchAppointment,
        Profile: state.Profile,
        Practise: state.Practise,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        searchAppointment: (fromDate, toDate) => dispatch(searchAppointmentFromApi(fromDate, toDate)),
        searchFilteredAppointment: (appointType) => dispatch(searchFilteredAppointmentFromApi(appointType)),
        getAllAppointments: (pageSize, pageNumber, fromDate = null, toDate = null, appointmentType) => dispatch(showALlAppointmentFromApi(pageSize, pageNumber, fromDate, toDate, appointmentType)),
        //getMoreMissedAppoint: (pageSize, pageNumber) => dispatch(fetchMoreAppointmentsFromApi(pageSize, pageNumber))
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(SearchAppointmentView)

//export default SearchAppointmentView
