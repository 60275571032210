import React, { useState, useEffect } from 'react'
import { connect, useDispatch, useSelector } from "react-redux";

import { View, Text, ActivityIndicator, FlatList } from "react-native-web";
import { fetchLongTermFromApi, fetchPeopleFromApi } from '../../../shared/actions/DiagnosisActions';
import { GetAppointmentDate, GetMonthName, GetYear } from '../../../shared/core/datehelper';
import DiagnosisHandler from '../../components/DiagnosisHandler'
import moment from 'moment';
import { actionAPI } from '../../../shared/reduxToolkit/actionAPI';

const Recent = props => {
    const [isloaded, setIsload] = React.useState(false);

    const { RecentDiag, isFetching } = useSelector(state => state.RecentDiagnosis);
    const [pageSize, setPageSize] = useState(100);           //state for the page number
    const [pageNumber, setPageNumber] = useState(1);

    const apiDispatcher = useDispatch()





    useEffect(() => {
        //alert('Called Future')
        apiDispatcher(actionAPI.GetRecentDiagnosisFromAPI({ pageSize, pageNumber }))
    }, [])
    return (
        <div>
            <div className="content-viewport">
                <div className="row row-margin-zero">
                    <div className="col-12">
                        {/* <div className="grid"> */}
                        {/* <div className="grid-body">
                                <h2 className="grid-title">Diagnosis</h2> */}
                        <div className="item-wrapper">
                            {
                                isFetching && <div className='text-center mb-5'><ActivityIndicator size="small" color="#00A1DE" /></div>
                            }
                            {
                                typeof (RecentDiag) !== 'undefined' && RecentDiag.length ? (
                                    <FlatList
                                        className="complete-appointment-view-flatlist"
                                        showsVerticalScrollIndicator={true}
                                        data={RecentDiag}
                                        renderItem={({ item }) => (

                                            <DiagnosisHandler
                                                mystyles={'card-header text-white bg-primary'}
                                                date={moment(item.resource.onsetDateTime).format("DD MMM YYYY")}
                                                comments={item.resource.note[0].text}
                                            // displayName={item.resource.code.coding[0].display}
                                            />
                                            // <DiagnosisWebHandler
                                            //     styles={GetTagColor('Long')}
                                            //     date={GetAppointmentDate(item.resource.onsetDateTime) + ' ' + GetMonthName(item.resource.onsetDateTime)}
                                            //     month={GetYear(item.resource.onsetDateTime)}
                                            //     //type_diagnoes={results.resource.resourceType}
                                            //     comments={item.resource.note[0].text}
                                            // />
                                        )}
                                        horizontal={false}
                                        keyExtractor={item => item.resource.id}
                                        initialNumToRender={10}

                                    />
                                ) : !isFetching &&
                                <div className='alert alert-danger p-2 text-center'>No Records Found</div>

                            }
                        </div>
                        {/* </div> */}
                        {/* </div> */}
                    </div>
                </div>
            </div>

        </div>
    )
}
function mapStateToProps(state) {
    return {
        RecentDiag: state.RecentDiag
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getRecentDiag: (pageSize, pageNumber) => dispatch(fetchPeopleFromApi(pageSize, pageNumber, 'Not')),
        // getPeopleLongMoreSuccess: (pageSize, pageNumber) => dispatch(fetchMoreLongTermFromApi(pageSize, pageNumber, 'Not'))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Recent)