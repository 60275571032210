import React, { useEffect, useState } from 'react';
import UserInvoice from '../../components/UserInvoice';
import { connect, useDispatch, useSelector } from 'react-redux'
import { GetDayName, GetAppointmentDate, GetMonthName, ReturnTypeIcon, GetYear } from '../../../shared/core/datehelper'
import { TouchableOpacity, View, FlatList, Text, ActivityIndicator } from "react-native-web";
import { fetchAllInvoiceFromApi } from "../../../shared/actions/AllInvoicesActions";
import { Divider, Radio, Spin, Table } from 'antd'
import { flushSync } from 'react-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { actionAPI } from '../../../shared/reduxToolkit/actionAPI';
let pageNumber = 1;
const AllInvoice = props => {
    const { AllInvc, isGetting } = useSelector(state => state.AllInvoices);

    const [totalRecords, setTotalRecords] = useState(10)
    const [invoicesList, setInvoicesList] = useState([])
    let limit = 25
    let offset = 1

    const apiDispatcher = useDispatch()
    const getAllInvoicesFromAPI = (fromStart = false) => {
        limit = 15;
        offset = fromStart ? 1 : Math.ceil(invoicesList.length / limit) + 1;

        if (offset == 1) {
            flushSync(() => {
                setInvoicesList([])
                setTotalRecords(0)
            })
        }
        apiDispatcher(actionAPI.GetAllInvoicesFromAPI({ pageSize: limit, pageNumber: offset }))
    };

    useEffect(() => {
        getAllInvoicesFromAPI()
    }, [])
    useEffect(() => {
        if (AllInvc && AllInvc.length) {
            setTotalRecords(AllInvc[0].total)
            debugger
            if (AllInvc[0]?.entry) {
                if (invoicesList.length > 0 && AllInvc[0].entry[0].resource.id == invoicesList[0].resource.id) {
                    setInvoicesList(AllInvc[0].entry);

                } else {

                    const mergeData = [...invoicesList, ...AllInvc[0]?.entry];

                    setInvoicesList(mergeData);
                }
            }
        }
    }, [AllInvc])
    const fetchMoreInvoicesFromAPI = (ignore = false) => {
        console.log("totalRecords", totalRecords)
        if (ignore || invoicesList.length < totalRecords) {
            getAllInvoicesFromAPI();
        }
    }

    const handleCheckedInvoices=()=>{

  }
    return (
        <div id='allInvlicesScrollableDiv' className='all-invoices-ineer-container'>
            {
                isGetting && <div className='text-center mb-5' ><ActivityIndicator size="small" color="#00A1DE" /></div>
            }
            {
                typeof (AllInvc) !== 'undefined' && AllInvc.length ? (
                    // <FlatList
                    //     data={AllInvc}
                    //     renderItem={({ item }) => (
                    <InfiniteScroll
                        dataLength={invoicesList.length}
                        next={fetchMoreInvoicesFromAPI}
                        hasMore={invoicesList.length < totalRecords}
                        loader={<div className='d-flex justify-content-center my-2'><Spin /></div>}
                        onScroll={() => {}}
                        scrollableTarget="allInvlicesScrollableDiv"
                    >
                        <UserInvoice AllInvc={invoicesList} invoiceType="All" handleCheckedInvoices={handleCheckedInvoices} />
                    </InfiniteScroll>
                    //         <UserInvoice
                    //             invoice_date={GetAppointmentDate(item.resource.extension[0].valueDateTime) + ' ' + GetMonthName(item.resource.extension[0].valueDateTime)}
                    //             invoice_month={GetYear(item.resource.extension[0].valueDateTime)}
                    //             payename={item.resource.extension[3].valueString}
                    //             type={item.resource.extension[2].valueString}
                    //             paitent_name={item.resource.extension[4].valueString}
                    //             ammount={item.resource.extension[6].valueString}
                    //             balance={item.resource.extension[7].valueString}
                    //             notes={item.resource.extension[1].valueString}
                    //             reference={item.resource.extension[5].valueString}
                    //             paynow={item.resource.extension[8].valueDecimal}
                    //             transactionid={item.resource.id}
                    //         />
                    //         // <AccountsHandler
                    //         //     styles={GetTagColor(item.resource.extension[10].valueString)}
                    //         //     invoice_date={GetAppointmentDate(item.resource.extension[0].valueDateTime) + ' ' + GetMonthName(item.resource.extension[0].valueDateTime)}
                    //         //     invoice_month={GetYear(item.resource.extension[0].valueDateTime)}
                    //         //     payename={item.resource.extension[4].valueString}
                    //         //     paitent_name={'Type: ' + item.resource.extension[2].valueString}
                    //         //     ammount={'Ammount: $' + item.resource.extension[6].valueString}
                    //         //     balance={'Balance: $' + item.resource.extension[7].valueString}

                    //         // />
                    //     )}
                    //     keyExtractor={(item, index) => index + ""}
                    //     // keyExtractor={item => item.resource.id}
                    //     initialNumToRender={10}
                    //     maxToRenderPerBatch={10}
                    //     windowSize={7}
                    //     onEndReachedThreshold={0.5}
                    // // onEndReached={() => {
                    // //     pageNumber = pageNumber + 1;
                    // //     props.getAllInvoice(pageSize, pageNumber)
                    // //     //props.getPeople(pageSize, pageNumber, diagType);
                    // // }}
                    // />
                    
                ) : !isGetting &&
                <div className='alert alert-danger p-2 text-center'>No Records Found</div>
                
            }
            {/* <UserInvoice />
            <UserInvoice />
            <UserInvoice /> */}
        </div>
    );
};

function mapStateToProps(state) {
    return {
        AllInvc: state.AllInvc
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getAllInvoice: (pageSize, pageNumber) => dispatch(fetchAllInvoiceFromApi(pageSize, pageNumber))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllInvoice)
// export default AllInvoice;