import AsyncStorage from "@react-native-async-storage/async-storage";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Settings from "../../../config/Settings";
import getUserId from "../../../core/GetToken";

export const GetRecentDiagnosisFromAPI = createAsyncThunk(
    "getRecentDiagnosis/Diagnosis",
    async (values, { dispatch, getState }) => {

        dispatch(getRecentDiagnosis())

        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = {
                PatientID: item.userid,
                PageSize: values.pageSize,
                PageNumber: values.pageNumber,
                TypeID: 1,
            }
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(raw)
            };

            // getAllDiagnosis(queryString)
            fetch(Settings.apiUrl + "Patient/Condition", requestOptions)
                .then(data => data.json())
                .then(json => {

                    json[0].hasOwnProperty('entry') ? dispatch(getRecentDiagnosisSuccess(json[0].entry)) : dispatch(getRecentDiagnosisFailed())
                })
                .catch(err => {

                    dispatch(getRecentDiagnosisFailed(err))
                })
        })

    }

);

const GetRecentDiagnosisSlice = createSlice({
    name: "getRecentDiagnosis",
    initialState: {
        RecentDiag: [],
        isFetching: false,
        error: false
    },
    reducers: {
        getRecentDiagnosis: (state, action) => {
            state.RecentDiag = []
            state.isFetching = true
            state.error = false
        },
        getRecentDiagnosisSuccess: (state, action) => {
            state.RecentDiag = action.payload
            state.isFetching = false
            state.error = false

        },
        getRecentDiagnosisFailed: (state, action) => {
            state.RecentDiag = []
            state.isFetching = false
            state.error = true
        },
        getRecentDiagnosisFinished: (state, action) => {
            state.RecentDiag = []
            state.isFetching = false
            state.error = false
        },

    },
});




export const {
    getRecentDiagnosis, getRecentDiagnosisFailed, getRecentDiagnosisSuccess, getRecentDiagnosisFinished
} = GetRecentDiagnosisSlice.actions;
export default GetRecentDiagnosisSlice.reducer;
