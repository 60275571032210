import { INBOX_MESSAGE_LISTING, INBOX_MESSAGE_LISTING_SUCCESS, INBOX_MESSAGE_LISTING_LOADING, INBOX_MESSAGE_LISTING_ERROR, INBOX_MESSAGE_LISTING_FAILED } from '../../constants/Constant'
import getUserId from "../../core/GetToken";
import Settings from '../../config/Settings';




/******************************** LT *******************************************/

//return long terms diagnosis
export const listInboxMessageAPI = (values) => {
    return (dispatch) => {
        dispatch(listingInboxMessages())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = {
                PatientID: item.userid,
                PageSize: values.pageSize,
                PageNumber: values.pageNumber,
                Type: values.type,
                MessagingType: values.messagingType,
                ReadFilter: values.readFilter
            }
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(raw)
            };


            fetch(Settings.apiUrl + "Patient/Messaging", requestOptions)

                .then(data => data.json())
                .then(json => {
                    if (json == null) {
                        dispatch(listingInboxMessagesSuccess([{ total: 0 }]))

                    } else {
                        dispatch(listingInboxMessagesSuccess(json))

                    }
                })
                .catch(err => dispatch(listingInboxMessagesFailed(err)))

        })
    }
}





//ALL DIAGNOSIS LOADS HERE
const listingInboxMessages = () => {
    return {
        type: INBOX_MESSAGE_LISTING
    }
}

const listingInboxMessagesSuccess = (data) => {
    return {
        type: INBOX_MESSAGE_LISTING_SUCCESS,
        data,
    }
}

const listingInboxMessagesFailed = () => {
    return {
        type: INBOX_MESSAGE_LISTING_FAILED
    }
}
