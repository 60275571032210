import { GETTING_RESOURCES, GETTING_RESOURCES_SUCCESS, GETTING_RESOURCES_FAILED, GETTING_RESOURCES_FINISHED, GETTING_RESOURCES_MOR_SUCCESS } from "../constants/Constant";
import getPaitentResources from "../services/ResourcesService";
import getUserId from "../core/GetToken";
import Settings from "../config/Settings";
//function will return data
export const fetchResourcesFromApi = (pageSize, pageNumber) => {
    return (dispatch) => {
        dispatch(getResources())

        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            
            // token = data

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = {
                PatientID: item.userid,
                PageSize: pageSize,
                PageNumber: pageNumber,
            }
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(raw)
            };

            fetch(Settings.apiUrl + "Patient/Media", requestOptions)
                .then(data => data.json())
                .then(json => {
                    if (json && json.length) {
                        dispatch(getResourcesSuccess(json[0].entry))

                    } else {
                        dispatch(getResourcesFailed())
                    }
                })
                .catch(err => dispatch(getResourcesFailed(err)))



            // const queryString = '?Token=' + item.token + '&PatientID=' + item.userid + '&PageSize=' + pageSize + '&PageNumber=' + pageNumber;
            // getPaitentResources(queryString)
            //     .then(data => data.json())
            //     .then(json => {
          
            //         dispatch(getResourcesSuccess(json[0].entry))
            //     })
            //     .catch(err => dispatch(getResourcesFailed(err)))
        })


    }
}



//function will return more data
export const fetchMoreResourcesFromApi = (token, pageSize, pageNumber) => {
    return (dispatch) => {
        dispatch(getResources())

        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
           
            // token = data
            const queryString = '?Token=' + item.token + '&PatientID=' + item.userid + '&PageSize=' + pageSize + '&PageNumber=' + pageNumber;
            getPaitentResources(queryString)
                .then(data => data.json())
                .then(json => {
                    if (json != null) {
                        json[0].hasOwnProperty('entry') ? dispatch(getMoreResourcesSuccess(json[0].entry)) : dispatch(getResourcesFinished())
                    } else {
                        dispatch(getResourcesFinished())
                    }
                })
                .catch(err => dispatch(getResourcesFailed(err)))
        })


    }
}
const getResources = () => {
    return {
        type: GETTING_RESOURCES
    }
}

const getResourcesSuccess = (data) => {
    return {
        type: GETTING_RESOURCES_SUCCESS,
        data,
    }
}
const getMoreResourcesSuccess = (data) => {
    return {
        type: GETTING_RESOURCES_MOR_SUCCESS,
        data,
    }
}

const getResourcesFailed = () => {
    return {
        type: GETTING_RESOURCES_FAILED
    }
}

const getResourcesFinished = () => {
    return {
        type: GETTING_RESOURCES_FINISHED
    }
}