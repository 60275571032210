import React, { useState, useEffect } from "react";
import { Select, Modal, Button, message, Upload } from "antd";
import { Input, notification, Spin } from "antd";
import { RadiusBottomleftOutlined } from "@ant-design/icons";
import { UploadOutlined } from "@ant-design/icons";
import { getMessageRecipientsAPI } from "../../../../shared/actions/MessagingActions/ListMessagesRecipients";
import { listMessageRolesAPI } from "../../../../shared/actions/MessagingActions/LoadMessagingRoles";
import { sendMessageAPI } from "../../../../shared/actions/MessagingActions/SendingMessages";
import { connect, useDispatch, useSelector } from "react-redux";
import AttachmentsHandler from "../MessageAttachments/AttachmentsHandler";
import renderHTML from 'react-render-html';
import sanitize from "sanitize-html";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import getUserId from "../../../../shared/core/GetToken";
import Settings from "../../../../shared/config/Settings";
import { actionAPI } from "../../../../shared/reduxToolkit/actionAPI";

const { Option } = Select;
const { TextArea } = Input;

const ReplyMsgModal = (props) => {
    const { recipientsData, recipientsDataSuccess } = useSelector(state => state.ListMessageRecipients)
    const { rolesData, rolesDataSuccess } = useSelector(state => state.MessageRolesList)
    const { sendMessage, sendMessageLoading, sendMessageSuccess, sendMessageFailed } = useSelector(state => state.SendingMessage)

    const [msgProviderLocaionId, setMsgProviderLocationId] = useState(rolesDataSuccess ? rolesData[0].entry[0].resource.id : 0);
    const [msgProviderLocationName, setMsgProviderLocationName] = useState(rolesDataSuccess ? rolesData[0].entry[0].resource.title : null);
    const [msgRecipientId, setMsgRecipientId] = useState(recipientsDataSuccess && recipientsData !== null && recipientsData[0]?.entry
        ? recipientsData[0]?.entry[0]?.resource?.extension[0]?.valueString
        : null);
    const [msgRecipientName, setMsgRecipientName] = useState(recipientsDataSuccess && recipientsData !== null && recipientsData[0]?.entry && recipientsData[0]?.entry[0]?.resource?.name[0]?.text
        ? recipientsData[0]?.entry[0]?.resource?.name[0]?.text
        : null);
    const [msgSubject, setMsgSubject] = useState("Re:" + props.replySubject);
    const [msgBody, setMsgBody] = useState("");
    const [msgBodyErrMsg, setMsgBodyErrMsg] = useState("");
    const [msgSubErrMsg, setsetMsgSubErrMsg] = useState("");
    const [selectedFiles, setSelectedFiles] = useState([])
    const [filesState, setFilesState] = useState([])
    const [imageFiles, setImageFiles] = useState([])
    const [isSendingReplyMessageState, setIsSendingReplyMessageState] = useState(false)


    const apiDispatcher = useDispatch()

    const onRolesChange = (value, option) => {
        setMsgProviderLocationId(value);
        setMsgProviderLocationName(option.children);
        setMsgRecipientId(null);
        const values = {

            roleID: value,
        };
        apiDispatcher(actionAPI.ListMessageRecipientsAPI(values))


    };
    const onProviderChange = (value, option) => {
        setMsgRecipientId(option.key);
        setMsgRecipientName(option.children);

    };
    const PostTempFileData = async () => {
        const itemUserInfo = await getUserId().then((userInfo) => {
            return JSON.parse(userInfo);
        });

        const apiUrl = Settings.apiUrl + "Patient/PostTempMessageFiles";


        const formData = new FormData();


        for (let i = 0; i < filesState.length; i++) {
            formData.append('files', filesState[i]);
        }
        formData.append('patientid', itemUserInfo.userid.toString());
        formData.append('messageid', props.msgID);

        fetch(apiUrl, {
            method: 'POST',
            body: formData,
            processData: false,  // Set processData to false to prevent jQuery from serializing the data
            //contentType: false,  // Set contentType to false to let the browser set the Content-Type

            headers: {
                // Add this line to specify the content type

                'token': itemUserInfo.token
            },
        })
            .then((response) => {
                if (response.ok) {
                    return response.text(); // Assuming the server responds with a text message
                } else {
                    //throw new Error('Error uploading files');
                }
            })
            .then((data) => {
                console.log(data); // Response from the server
            })
            .catch((error) => {
                console.error(error);
            });

    }
    const checkValidations = () => {
        let caughtErr = false

        if (msgBody?.trim() == "" || msgBody == null) {
            setMsgBodyErrMsg("Please type something in the body of the message")
            caughtErr = true
        }
        if (msgSubject?.trim() == "" || msgSubject == null) {
            setsetMsgSubErrMsg("Please type something in the subject of the message")
            caughtErr = true
        }
        return caughtErr
    }
    const handleSendClick = () => {
        let validateErr = checkValidations()
        if (validateErr) {
            return
        }
        setIsSendingReplyMessageState(true)

        // if (filesState.length > 0) {

        //     try {
        //         PostTempFileData()
        //     } catch (error) {

        //     }
        // }
        let providerID = props.providerRef
        let providerName = props.provider

        if (props.providerAvailable && props.providerAvailable?.length > 0) {
            if (props.providerAvailable[0]?.IsLeave === false && props.providerAvailable[0]?.IsDefaultGp === true) {
                if (props.providerAvailable[0]?.ProviderID !== null && props.providerAvailable[0]?.ProviderName !== null) {
                    providerID = props.providerAvailable[0]?.ProviderID
                    providerName = props.providerAvailable[0]?.ProviderName
                }
            }
        }
        const values = {
            centre: {
                id: "Location/" + msgProviderLocaionId,
                name: msgProviderLocationName,
            },
            practitioner: {
                id: providerID,
                name: providerName
            },

            message: {
                subject: msgSubject,
                body: msgBody + "<br><hr><br><br>" + props.oldMessageBody,
            },
            selectedFiles: selectedFiles,
            messageType: "Send Mail",
            roleID: props.roleID,
        };
        apiDispatcher(actionAPI.SendingMessagesAPI(values))

    };
    const handleDraftClick = () => {
        let validateErr = checkValidations()
        if (validateErr) {
            return
        }
        setIsSendingReplyMessageState(true)

        // if (filesState.length > 0) {

        //     try {
        //         PostTempFileData()
        //     } catch (error) {

        //     }
        // }
        let providerID = props.providerRef
        let providerName = props.provider

        if (props.providerAvailable && props.providerAvailable?.length > 0) {
            if (props.providerAvailable[0]?.IsLeave === false && props.providerAvailable[0]?.IsDefaultGp === true) {
                if (props.providerAvailable[0]?.ProviderID !== null && props.providerAvailable[0]?.ProviderName !== null) {
                    providerID = props.providerAvailable[0]?.ProviderID
                    providerName = props.providerAvailable[0]?.ProviderName
                }
            }
        }
        const values = {
            centre: {
                id: "Location/" + msgProviderLocaionId,
                name: msgProviderLocationName,
            },
            practitioner: {
                id: providerID,
                name: providerName
            },
            message: {
                subject: msgSubject,
                body: msgBody + "<br /><hr /><br /><br />" + props.oldMessageBody,
            },
            selectedFiles: selectedFiles,
            messageType: "Draft",
            roleID: props.roleID,

        };
        apiDispatcher(actionAPI.SendingMessagesAPI(values))

    };
    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: 'Notification',
            description:
                message,
        });
    };


    useEffect(() => {
        if (sendMessageSuccess) {
            props.handleCancel()
        }
    }, [sendMessageLoading])
    const handleRemoveFile = (index) => {
        if (selectedFiles.length > 0) {
            setSelectedFiles(selectedFiles.filter((file, fileIndex) => fileIndex != index))
            setFilesState(filesState.filter((file, fileIndex) => fileIndex != index))
        }
    }
    const imageTypeRegex = /image\/(png|jpg|jpeg|heif|heic)/gm;
    const pdfTypeRegex = /application\/(pdf)/gm;
    const selectFilesHandler = (e) => {
        const { files } = e.target;


        const validImageFiles = [];
        if (selectedFiles.length + files.length > 4) {
            openNotificationWithIcon("error", "Only four files are allowed to be uploaded")
            return
        }
        let sizeLimitExceed = false
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (file.size > 6000000) {
                sizeLimitExceed = true
            }
            if (file.type.match(imageTypeRegex) || file.type.match(pdfTypeRegex) || (file.type === "" && (file.name.includes("heif") || file.name.includes("heic")))) {
                validImageFiles.push(file);
            }
        }
        if (sizeLimitExceed) {
            openNotificationWithIcon("error", "File size cannot be greater than 6MB")
            return
        }
        if (filesState.length + files.length < 5) {
            setFilesState([...filesState, ...files])
        }
        if (validImageFiles.length) {
            setImageFiles(validImageFiles);
            return;
        }
        openNotificationWithIcon("error", "Selected images are not of valid type!");
    };
    useEffect(() => {
        const images = [], fileReaders = [];
        let isCancel = false;
        if (imageFiles.length) {
            imageFiles.forEach((file) => {
                let data = {}
                const fileReader = new FileReader();
                fileReaders.push(fileReader);
                fileReader.onload = (e) => {
                    const { result } = e.target;
                    if (result) {
                        data = {
                            contentAttachment: {
                                contentType: result.split("/")[1].split(";base64")[0],
                                data: result.split("base64,")[1]

                            }
                        }
                        images.push(data)
                    }
                    if (images.length === imageFiles.length && !isCancel) {
                        setSelectedFiles((prev) => prev.concat(images));
                    }
                }
                fileReader.readAsDataURL(file);
            })
        };
        return () => {
            isCancel = true;
            fileReaders.forEach(fileReader => {
                if (fileReader.readyState === 1) {
                    fileReader.abort()
                }
            })
        }
    }, [imageFiles]);
    const [oldMsgData, setOldMsgData] = useState("")
    useEffect(() => {
        const parser = new DOMParser();
        const parsedHtml = parser.parseFromString(props.oldMessageBody, 'text/html');
        const plainText = parsedHtml.body.textContent || '';
        setOldMsgData(plainText)
    }, [])
    return (
        <>
            <Modal
                title="Reply Message"
                width={800}
                className="compose-msg-modal"
                open={props.isReplyModalOpen}
                onCancel={props.handleCancel}
                afterClose={() => {
                    setMsgBody("");
                    setIsSendingReplyMessageState(false)
                }}
                footer={props.providerAvailable && props.providerAvailable.length > 0 ? [
                    <Button
                        key="cancel"
                        onClick={props.handleCancel}
                        className="cancel-msg-btn"
                        disabled={sendMessageLoading || isSendingReplyMessageState}
                    >
                        Cancel
                    </Button>,
                    <Button
                        key="draft"
                        onClick={handleDraftClick}
                        className="draft-msg-btn"

                        disabled={sendMessageLoading || isSendingReplyMessageState}
                    >
                        Draft
                    </Button>,
                    <Button key="send" onClick={handleSendClick} className="send-msg-btn" disabled={sendMessageLoading || isSendingReplyMessageState}>
                        Send
                    </Button>,
                ] : [
                    <Button
                        key="cancel"
                        onClick={props.handleCancel}
                        className="cancel-msg-btn"
                        disabled={sendMessageLoading || isSendingReplyMessageState}
                    >
                        Cancel
                    </Button>
                ]}
            >
                {props.disclaimerMsg && props.disclaimerMsg != "" &&
                    <div className="row alert alert-warning compose-msg-diclaimer">
                        <h6 className="alert-heading">Disclaimer:</h6>
                        {
                            renderHTML(props.disclaimerMsg)
                        }

                    </div>}
                <div className="row">
                    <label className="col-6 col-sm-3 fw-bolder">Practitioner</label>
                    {props.providerAvailable && props.providerAvailable.length > 0 ? <Input autoComplete="off" className="col-8" placeholder="Select Provider" value={props.provider} /> : <span className="text-danger">As per practice policy, this provider is not available in messaging list.</span>}
                </div>
                <div className="row">

                    <label className="col-3 fw-bolder m-top-15 ">Subject</label>

                    < Input
                        className="col-8 m-top-15"
                        placeholder="Subject"
                        value={msgSubject}
                        disabled={!(props.providerAvailable && props.providerAvailable.length > 0)}
                    />
                    <p className="text-danger">{msgSubErrMsg}</p>
                </div>
                {/* <TextArea

                    placeholder="Write your message here"
                    autoSize={{ minRows: 5, maxRows: 8 }}
                    className="w-100 m-top-15"
                    value={msgBody}
                    onChange={(e) => { setMsgBody(e.target.value); setMsgBodyErrMsg("") }}
                    maxLength={8000}
                /> */}
                <div className="mt-4 ">
                    <CKEditor

                        editor={ClassicEditor}
                        data={msgBody}
                        config={{ toolbar: [], placeholder: "Write your reply here." }}
                        disabled={!(props.providerAvailable && props.providerAvailable.length > 0)}
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            setMsgBody(data);
                            setMsgBodyErrMsg("")
                        }}

                    />
                </div>
                <p className="text-danger">{msgBodyErrMsg}</p>
                <div className="m-top-15 half-opacity oldMessageBodyDiv" dangerouslySetInnerHTML={{ __html: sanitize(props.oldMessageBody) }}></div>
                {/* <TextArea
                    className="mt-2 w-100 m-top-15 half-opacity"
                    // value={value}
                    // onChange={(e) => setValue(e.target.value)}
                    placeholder="Original Message"
                    autoSize={{ minRows: 5, maxRows: 10 }}
                    contentEditable={false}

                    value={oldMsgData}
                    maxLength={8000}
                /> */}
                <label htmlFor="file" className="p-2 text-primary fw-bold mt-3 px-3 choose-file">Choose Files
                </label>
                <input
                    autoComplete="off"
                    type="file"
                    id="file"
                    onChange={selectFilesHandler}
                    accept="image/png, image/jpg, image/jpeg, image/heif, image/heic,application/pdf"
                    multiple
                />
                {
                    selectedFiles.length > 0 ?
                        <AttachmentsHandler selectedFiles={selectedFiles} type="composing" handleRemoveFile={handleRemoveFile} /> : null
                }

            </Modal>
        </>
    );
};

function mapStateToProps(state) {
    return {

        MessageRecipients: state.MessagesRecipientsReducer,
        SendingMessage: state.SendingMessagesReducer,
        MessagingRoles: state.LoadMessagingRolesReducer
    }
}
function mapDispatchToProps(disptach) {
    return {

        getMsgRecipients: (values) => disptach(getMessageRecipientsAPI(values)),
        sendMessage: (values) => disptach(sendMessageAPI(values)),
        loadMsgRoles: () => disptach(listMessageRolesAPI()),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ReplyMsgModal)
// export default
