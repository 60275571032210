
import React from 'react';
import { StyleSheet, Text, View, Image, Dimensions } from 'react-native';


const ProfileItemSingle = props => {
    return (
        <div className="form-group row showcase_row_area">
            <div className="col-md-3 showcase_text_area">
                <label htmlFor="inputType1">{props.contactTile}</label>
            </div>
            <div className="col-md-9 showcase_content_area">
                <input autoComplete="off" type="text" className="form-control" id="inputType1" defaultValue={props.contactValue} readOnly />
            </div>
        </div>
    );
}

export default ProfileItemSingle
