
import { GET_ROLES, GET_ROLES_SUCCESS, GET_ROLES_FAILED } from "../constants/Constant";

import getUserId from "../core/GetToken";
import getAllRoles from "../services/RolesService";

export const fetchRolesFromApi = (PracticeLocation = null) => {
    return async (dispatch) => {
        // const networkCheck = await isNetworkAvilable();
        // if (networkCheck) {
        dispatch(getRoles())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")

            var raw = {
                PatientID: item.userid,
                TypeID: 2,
                PracticeLocation: PracticeLocation?.toString(),
            }
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(raw)
            };

            getAllRoles("", requestOptions)
                .then(data => data.json())
                .then(json => {
                    json[0].hasOwnProperty('entry') ? dispatch(getRolesSuccess(json[0].entry)) : dispatch(getRolesFailed())
                })
                .catch((err) => {
                    dispatch(getRolesFailed(err))
                })
        })
      

    }
}


const getRoles = () => {
    return {
        type: GET_ROLES
    }
}

//load data
const getRolesSuccess = (data) => {
    return {
        type: GET_ROLES_SUCCESS,
        data,
    }
}


//data failure
const getRolesFailed = () => {
    return {
        type: GET_ROLES_FAILED,
    }
}




