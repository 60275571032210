import AsyncStorage from "@react-native-async-storage/async-storage";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Settings from "../../../config/Settings";
import getUserId from "../../../core/GetToken";

export const GetTimelineDataFromAPI = createAsyncThunk(
    "getTimelineData/Timeline",
    async (values, { dispatch, getState }) => {

        dispatch(getTimelineData())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = {
                PatientID: item.userid,
                PageSize: values.pageSize,
                PageNumber: values.pageNumber,
                FromDate: values.fromDate,
                toDate: values.toDate,
                SearchText: values.textSearch,
                ProviderID: (values.filterProviderId === '' ? null : values.filterProviderId)

            }
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(raw)
            };


            let data = [];

            fetch(Settings.apiUrl + "Patient/Timeline", requestOptions)
                .then(data => data.json())
                .then(json => {

                    if (!json[0].hasOwnProperty("entry")) {
                        dispatch(getTimelineDataSuccess(0), 0)
                        return
                    }
                    json[0].entry.map((item, index) => {
                        let ob = {
                            "id": item.resource.id,
                            "date": new Date(item.resource.created.slice(0, 16)).toISOString(),
                            "display": item.resource.code.coding[0].display,
                            "text": item.resource.code.text,
                            "providerName": item.resource.extension[0].valueString,
                        }
                        data.push(ob)
                    })


                    dispatch(getTimelineDataSuccess({ data: getGroupedList(data), total: json[0].total }))
                })
                .catch(err => dispatch(getTimelineDataFailed(err)))

        })


    }

);
export const TimelineDataRefresher = createAsyncThunk(
    "timlineDataRefresher/Timeline",
    async (values, { dispatch, getState }) => {
        dispatch(getTimelineDataFinished())
    }
)
const getGroupedList = (data) => {



    return data.reduce((acc, val) => {

        const date = val.date.match(/\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/g).toString();
        //var date_regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
        // const item = acc.find((item) => item.date.match(new RegExp(date, 'g')));
        const item = false;

        if (!item) acc.push({ date: val.date, alerts: [val], heading: val.providerName });

        else item.alerts.push(val);



        return acc;

    }, []);


}
const GetTimelineDataSlice = createSlice({
    name: "getTimelineData",
    initialState: {
        PatientTimeline: [],
        PatientTimelineTotalNum: 0,

        isGetting: false,
        error: false
    },
    reducers: {
        getTimelineData: (state, action) => {

            state.PatientTimelineTotalNum = 0
            state.isGetting = true
            state.error = false
        },
        getTimelineDataSuccess: (state, action) => {
            state.PatientTimeline = action.payload.data
            state.PatientTimelineTotalNum = action.payload.total
            state.isGetting = false
            state.error = false

        },
        getTimelineDataFailed: (state, action) => {

            state.PatientTimelineTotalNum = 0
            state.isGetting = false
            state.error = true
        },
        getTimelineDataFinished: (state, action) => {
            state.PatientTimeline = []
            state.PatientTimelineTotalNum = 0
            state.isGetting = false
            state.error = false
        },

    },
});




export const {
    getTimelineData, getTimelineDataFailed, getTimelineDataSuccess, getTimelineDataFinished
} = GetTimelineDataSlice.actions;
export default GetTimelineDataSlice.reducer;
