import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";
import Settings from "../../../config/Settings";
import getUserId from "../../../core/GetToken";

export const ValidateTokenAPI = createAsyncThunk(
    "validateToken/token",
    async (value, { dispatch, getState }) => {

        dispatch(validatingToken())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token);
            //  myHeaders.append("Cookie", ".Stackify.Rum=462da2cc-f704-4f17-a355-2b2660c6762f");

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            fetch(Settings.apiUrl + "ValidateToken", requestOptions)
                .then(data => data.json())
                .then(json => {
                    if (!json) {
                        // resetIdentityServerData()
                        // clearUserState()
                        // refreshProxyListData()
                        // RefreshApplicationSettings()
                        dispatch(validateTokenReset())
                        // value('/login', { replace: true })
                        return
                    }
                    // history.replace('/')

                    dispatch(validatingTokenSuccess(json))
                })
                .catch(err => {


                    dispatch(validatingTokenFailed(err))
                    // value('/login', { replace: true })
                })


            // checkPatientToken(queryString)
            // .then(data => data.json())
            // .then(json => {

            //     dispatch(validateTokenSuccess(json))
            // })
            //     .catch(err => dispatch(validateTokenFailed(err)))
        })
    }

);
export const checkToken = createAsyncThunk(
    "validateToken2/token",
    async (history, { dispatch, getState }) => {
        // const navigate = useNavigate()
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            // const queryString = '?Token=' + item.token;


            var myHeaders = new Headers();
            myHeaders.append("Token", item.token);
            //  myHeaders.append("Cookie", ".Stackify.Rum=462da2cc-f704-4f17-a355-2b2660c6762f");

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            fetch(Settings.apiUrl + "ValidateToken", requestOptions)
                .then(data => data.json())
                .then(json => {

                    if (!json) {
                        // alert("out")
                        history('/', { replace: true })
                    }
                    // history.replace('/')
                    // dispatch(validateTokenFailed(json))
                })
                .catch(err => {

                    history('/login')
                    dispatch(validatingTokenFailed(false))
                })



        }).catch(error => {

            // Modal.warning({
            //     title: 'This is a warning message',
            //     content: 'some messages...some messages...',
            // });
            // alert("hello")
            window.location.reload();
            // dispatch(validateTokenFailed(false))
            // dispatch(showModal(false))

            history('/login')

        })
    }

);

export const refreshTokenValidatorData = createAsyncThunk(
    "resetValidateToken/token",
    async (values, { dispatch, getState }) => {
        dispatch(validateTokenReset())
    }
)

const ValidateTokenSlice = createSlice({
    name: "validateToken",
    initialState: {
        TokenValidator: false,
        isChecking: false,
        error: false,
        showModal: false,

    },
    reducers: {
        validatingToken: (state, action) => {
            state.isChecking = true
            state.TokenValidator = false
            state.showModal = false
            state.error = false
        },
        validatingTokenSuccess: (state, action) => {
            state.isChecking = false
            state.TokenValidator = action.payload

        },
        validatingTokenFailed: (state, action) => {

            state.isChecking = false
            state.showModal = false
            state.error = true
        },
        validateTokenReset: (state, action) => {
            state.isChecking = false
            state.TokenValidator = false
            state.showModal = false
            state.error = false
        },
        validateTokenShowModal: (state, action) => {
            state.showModal = true
        }
    },
});


export const {
    validatingToken, validatingTokenFailed, validatingTokenSuccess, validateTokenReset
} = ValidateTokenSlice.actions;
export default ValidateTokenSlice.reducer;
