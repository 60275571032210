import { Modal } from 'antd'
import React, { useState } from 'react'

const JpgFileComp = (props) => {
    const [isFileModalVisible, setIsFileModalVisible] = useState(false)
  return (
      <>
          <div onClick={() => setIsFileModalVisible(true)} className="jpg-file-display-box d-flex align-items-center justify-content-around m-2">
            <div className={'remove-btn '+ (props.type != "composing" ? "d-none":"")} onClick={()=>props.handleRemove(props.attachNumber-1)}>
                <svg fill="blue" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"/></svg>
            </div>
              <div className="type-icon d-flex align-items-center justify-content-center ">
                  <svg className='jpg-svg' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM128 256c0 17.7-14.3 32-32 32s-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32zm88 32c5.3 0 10.2 2.6 13.2 6.9l88 128c3.4 4.9 3.7 11.3 1 16.5s-8.2 8.6-14.2 8.6H216 176 128 80c-5.8 0-11.1-3.1-13.9-8.1s-2.8-11.2 .2-16.1l48-80c2.9-4.8 8.1-7.8 13.7-7.8s10.8 2.9 13.7 7.8l12.8 21.4 48.3-70.2c3-4.3 7.9-6.9 13.2-6.9z"/></svg>
              </div>
              <div className="type-details">

                  <div className="type-name">Image</div>
                  <div className="type-number">Attachment {props.attachNumber}</div>
              </div>

          </div>
          <Modal
              title="JPG File"
              width={1000}
              className="message-jpg-file-comp-modal"
              open={isFileModalVisible}
              onCancel={() => setIsFileModalVisible(false)}
              footer={[
                  <button className="btn btn-primary" onClick={() => setIsFileModalVisible(false)}>Cancel</button>
              ]}
          >
              <div className="d-flex justify-content-center align-items-center">

              {props.jpgData ?
              
              <img className="jpg-file-msg" src={"data:image/jpeg;base64," + props.jpgData} />
              : null
            }
            </div>
          </Modal>
      </>
  )
}

export default JpgFileComp
