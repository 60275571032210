import AsyncStorage from "@react-native-async-storage/async-storage";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Settings from "../../../config/Settings";
import getUserId from "../../../core/GetToken";

export const GetFutureAppointFromAPI = createAsyncThunk(
    "getFutureAppoint/Appointment",
    async (values, { dispatch, getState }) => {

        dispatch(getFutureAppoint())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = {
                PatientID: item.userid,
                PageSize: values.pageSize,
                PageNumber: values.pageNumber,
                AppointmentType: 1,
            }
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(raw)
            };



            fetch(Settings.apiUrl + "Patient/Appointments", requestOptions)
                .then(data => data.json())
                .then(json => {
                    json[0].hasOwnProperty('entry') ? dispatch(getFutureAppointSuccess(json[0].entry)) : dispatch(getFutureAppointSuccess([]))
                })
                .catch(err => dispatch(getFutureAppointFailed(err)))
        })

    }

);

const GetFutureAppointSlice = createSlice({
    name: "getFutureAppoint",
    initialState: {
        FutureAppointments: [],
        isGettingFut: false,
        error: false
    },
    reducers: {
        getFutureAppoint: (state, action) => {
            state.FutureAppointments = []
            state.isGettingFut = true
            state.error = false
        },
        getFutureAppointSuccess: (state, action) => {
            state.FutureAppointments = action.payload
            state.isGettingFut = false
            state.error = false

        },
        getFutureAppointFailed: (state, action) => {
            state.FutureAppointments = []
            state.isGettingFut = false
            state.error = true
        },
        getFutureAppointFinished: (state, action) => {
            state.FutureAppointments = []
            state.isGettingFut = false
            state.error = false
        },

    },
});




export const {
    getFutureAppoint, getFutureAppointFailed, getFutureAppointSuccess, getFutureAppointFinished
} = GetFutureAppointSlice.actions;
export default GetFutureAppointSlice.reducer;
