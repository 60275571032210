import { SENDING_MESSAGE, SENDING_MESSAGE_SUCCESS, SENDING_MESSAGE_REFRESH_DATA, SENDING_MESSAGE_LOADING, SENDING_MESSAGE_ERROR, SENDING_MESSAGE_FAILED } from '../../constants/Constant'
import getUserId from "../../core/GetToken";
import Settings from '../../config/Settings';




/******************************** LT *******************************************/

//return long terms diagnosis
export const sendMessageAPI = (values) => {
    return (dispatch) => {
        dispatch(sendingMessage())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);


          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");

          myHeaders.append("Token", item.token);
            var raw = JSON.stringify({
                resourceType: "Communication",
                extension: [
                  {
                    url: "MessageBody",
                    valueString: encodeURIComponent(values.message.body),
                  },
                  {
                    url: "MessageType",
                    valueString: values.messageType,
                  },

                ],
                status: "completed",
                recipient: [
                  {
                    reference: `${values.practitioner.id}`,
                    display: values.practitioner.name,
                  },
                ],
                sender: {
                  reference: `Patient/${item.userid}`,
                  display: `${item.username}`,
                },
                payload: values.selectedFiles,
                note: [
                  {
                    text: values.message.subject,
                  },
                ],
              });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };
          const queryString = `?RoleID=${values.roleID}&CallType=3&IsWeb=true`
            fetch(Settings.apiUrl + "Patient/Message" + queryString, requestOptions)

                .then(data => data.json())
                .then(json => {
                    dispatch(sendingMessageSuccess(json))
                })
                .catch(err => dispatch(sendingMessageFailed(err)))

        })
    }
}

export const sendMessageAPIRefresher = ()=>{
  return (dispatch) =>{
    dispatch(sendingMessageRefreshData())
  }
}



//ALL DIAGNOSIS LOADS HERE
const sendingMessage = () => {
    return {
        type: SENDING_MESSAGE
    }
}
const sendingMessageRefreshData = () => {
    return {
        type: SENDING_MESSAGE_REFRESH_DATA
    }
}

const sendingMessageSuccess = (data) => {
    return {
        type: SENDING_MESSAGE_SUCCESS,
        data,
    }
}

const sendingMessageFailed = () => {
    return {
        type: SENDING_MESSAGE_FAILED
    }
}
