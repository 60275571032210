import { GET_AVAILABLE_PRACTITIONER, GET_AVAILABLE_PRACTITIONER_SUCCESS, GET_AVAILABLE_PRACTITIONER_FAILED, GET_AVAILABLE_PRACTITIONER_CLEAN } from "../constants/Constant";
import getAvailablePractitioner from "../services/GetAvailablePractitioner";
import getUserId from "../core/GetToken";



export const fetchAvailablePractitionerFromApi = (roleID, appType, roletype, locations,selectedReasonForVisit=25) => {
    return async (dispatch) => {

        dispatch(getPractitioner())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = {
                PatientID: item.userid,
                AppointmentTypeID: appType,
                PracticeLocationID: locations?.toString(),
                RoleID: roleID,
                BookWithID: roletype,
                ReasonForVisitID: selectedReasonForVisit
            }
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(raw)
            };

            // alert('Path is alright---->>>>>: ' + JSON.stringify(queryString))
            getAvailablePractitioner("", requestOptions)
                .then(data => data.json())
                .then(json => {
                    json[0].hasOwnProperty('entry') ? dispatch(getPractitionerSuccess(json[0].entry)) : dispatch(getPractitionerFailed())
                })
                .catch(err => dispatch(getPractitionerFailed(err)))
        })


    }
}

// //featch more diagnosis pagination call
// export const fetchMoreLongTermFromApi = (pageSize, pageNumber, diagType) => {
//     return (dispatch) => {
//         dispatch(getPeopleLong())
//         getUserId().then((userInfo) => {
//             const item = JSON.parse(userInfo);
//             const queryString = '?Token=' + item.token + '&PatientID=' + item.userid + '&PageSize=' + pageSize + '&PageNumber=' + pageNumber + '&TypeID=2'
//             getAllDiagnosis(queryString)
//                 .then(data => data.json())
//                 .then(json => {
//                     if (json != null) {
//                         json[0].hasOwnProperty('entry') ? dispatch(getPeopleLongMoreSuccess(json[0].entry)) : dispatch(getPeopleLongFinshed())
//                     } else {
//                         dispatch(getPeopleLongFinshed())
//                     }
//                 })
//                 .catch(err => dispatch(getPeopleLongFailed(err)))
//         })
//     }
// }






//ALL LOADS HERE
const getPractitioner = () => {
    return {
        type: GET_AVAILABLE_PRACTITIONER
    }
}

const getPractitionerSuccess = (data) => {
    return {
        type: GET_AVAILABLE_PRACTITIONER_SUCCESS,
        data,
    }
}

const getPractitionerFailed = (data) => {
    return {
        type: GET_AVAILABLE_PRACTITIONER_FAILED,
        data,
    }
}

export const getPractitionerClean = () => {
    return {
        type: GET_AVAILABLE_PRACTITIONER_CLEAN,
    }
}

