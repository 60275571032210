import AsyncStorage from "@react-native-async-storage/async-storage";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import Settings from "../../../config/Settings";
import getUserId from "../../../core/GetToken";

export const GetPatientVitalsFromAPI = createAsyncThunk(
    "getPatientVitals/Vitals",
    async (values, { dispatch, getState }) => {
        dispatch(getPatientVitals())
        let pgSize = values.pageSize == undefined ? 30 : values.pageSize
        let pgNumber = values.pageNumber == undefined ? 1 : values.pageNumber
        let chartType = values.ChartType == undefined ? 0 : values.ChartType
        getUserId().then((userInfo) => {

            const item = JSON.parse(userInfo);
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = {
                PatientID: item.userid,
                PageSize: pgSize,
                PageNumber: pgNumber,
                ChartType: chartType,
                StartDate: values.startDate,
                EndDate: values.endDate
            }
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(raw)
            };


            fetch(Settings.apiUrl + "Patient/Observation", requestOptions)
                .then(data => data.json())
                .then(json => {
                    if (json) {

                        gettingFormat(dispatch, json)
                        dispatch(getPatientVitalsSuccess(json))
                    } else {
                        gettingFormat(dispatch, [])
                        dispatch(getPatientVitalsSuccess([]))

                    }
                })
                .catch(err => dispatch(getPatientVitalsFailed(err)))
        })
    }

);

function gettingFormat(dispatch, json) {

    var dataArray = json[0].entry

    let copyOfDataArray = json[0].entry
    let sortedArray = copyOfDataArray.sort(function (a, b) {
        // descemding order base on date and time
        // if need ascending order need to change the a with b and vice versa in the return not in the function arg
        return (new Date(b.resource.extension[0].valueDateTime) - new Date(a.resource.extension[0].valueDateTime));
        // return new Date(b.dateCreated) - new Date(a.dateCreated);
    });



    // ************************************************Custome solution *************************************************************
    // var grad = [];
    // var totalLeth = dataArray.length;

    // for (let index of dataArray){
    //     grad.push({
    //         data: [],
    //         labels: [],
    //         text: [],
    //         name: ''
    //     })
    //     // if (grad[0].name === "") {
    //     //     graphdata[0].name = "Blood Pressure"
    //     // }
    //     // graphdata[0].data.push(ob.value);
    //     // graphdata[0].text.push(ob.text)
    //     // graphdata[0].labels.push(moment(ob.inserteddate).format("DD MMM YYYY"));

    // }
    // dataArray.map((item,key)=>{

    //     // grad.push({
    //     //     data: [],
    //     //     labels: [],
    //     //     text: [],
    //     //     name: ''
    //     // })
    //     var ob = {
    //         text: item.resource.code.text,
    //         value: item.resource.component[0].valueQuantity.value,
    //         inserteddate: item.resource.extension[0].valueDateTime
    //     };
    //     if (grad[key].name === "") {
    //         grad[key].name = item.resource.code.text
    //     }
    //     grad[key].data.push(ob.value);
    //     grad[key].text.push(ob.text)
    //     grad[key].labels.push(moment(ob.inserteddate).format("DD MMM YYYY"));

    // })



    // ************************************************End of Custome solution *************************************************************


    var graphdataArray = [{
        data: [],
        datab: [],
        labels: [],
        text: [],
        name: '',
        id: 1
    },
    {
        data: [],
        datab: [],
        labels: [],
        text: [],
        name: '',
        id: 2
    },
    {
        data: [],
        datab: [],
        labels: [],
        text: [],
        name: '',
        id: 3
    },
    {
        data: [],
        datab: [],
        labels: [],
        text: [],
        name: '',
        id: 4
    },
    {
        data: [],
        datab: [],
        labels: [],
        text: [],
        name: '',
        id: 5
    },
    {
        data: [],
        datab: [],
        labels: [],
        text: [],
        name: '',
        id: 6
    },
    {
        data: [],
        datab: [],
        labels: [],
        text: [],
        name: '',
        id: 7
    }];




    // sortedArray.map((item, key) => {
    //     var obDia = {
    //         text: item.resource.component[1].code.text,    ///item.resource.code.coding[0].display,
    //         value: item.resource.component[1].valueQuantity.value,
    //         inserteddate: item.resource.extension[0].valueDateTime
    //     };

    //     if (graphdataArray[7].name === "") {
    //         graphdataArray[7].name = "Low"
    //     }
    //     graphdataArray[7].data.push(obDia.value);
    //     graphdataArray[7].text.push(obDia.text)
    //     graphdataArray[7].labels.push(GetAppointmentDate(obDia.inserteddate) + " " + GetMonthName(obDia.inserteddate) + " " + GetYear(obDia.inserteddate));
    // })

    sortedArray.map((item, key) => {

        var ob = {
            text: item.resource.code.coding[0].display,    ///item.resource.code.coding[0].display,
            value: item.resource?.component != undefined ? item.resource?.component[0]?.valueQuantity.value : "",
            valueB: item.resource?.component != undefined ? item.resource?.component[1]?.valueQuantity.value : "",
            inserteddate: item.resource.effectiveDateTime?.split('T')[0]
        };
        switch (ob.text) {
            case "Blood Pressure":
                if (graphdataArray[0].name === "") {
                    graphdataArray[0].name = "Blood Pressure"
                }
                graphdataArray[0].data.push(ob.value);
                graphdataArray[0].datab.push(ob.valueB);
                graphdataArray[0].text.push(ob.text)
                graphdataArray[0].labels.push(moment(ob.inserteddate).format("DD MMM YYYY"));
                break;


            case "Weight":
            case "Body Weight": {

                if (graphdataArray[1].name === "") {
                    graphdataArray[1].name = "Weight"
                }

                graphdataArray[1].data.push(ob.value);
                graphdataArray[1].text.push(ob.text)
                graphdataArray[1].labels.push(moment(ob.inserteddate).format("DD MMM YYYY"));
                break;
            }
            case "BMI":
                if (graphdataArray[2].name === "") {
                    graphdataArray[2].name = "BMI"
                }
                graphdataArray[2].data.push(ob.value);
                graphdataArray[2].text.push(ob.text)
                graphdataArray[2].labels.push(moment(ob.inserteddate).format("DD MMM YYYY"));
                break;
            case "Height":
                if (graphdataArray[3].name === "") {
                    graphdataArray[3].name = "Height"
                }
                graphdataArray[3].data.push(ob.value);
                graphdataArray[3].text.push(ob.text)
                graphdataArray[3].labels.push(moment(ob.inserteddate).format("DD MMM YYYY"));
                break;
            case "Heart Rate":
                if (graphdataArray[4].name === "") {
                    graphdataArray[4].name = "Heart Rate"
                }
                graphdataArray[4].data.push(ob.value);
                graphdataArray[4].text.push(ob.text)
                graphdataArray[4].labels.push(moment(ob.inserteddate).format("DD MMM YYYY"));
                break;
            case "Body Height":
                if (graphdataArray[5].name === "") {
                    graphdataArray[5].name = "Body Height"
                }
                graphdataArray[5].data.push(ob.value);
                graphdataArray[5].text.push(ob.text)
                graphdataArray[5].labels.push(moment(ob.inserteddate).format("DD MMM YYYY"));
                break;
            case "Body Mass Index":
                if (graphdataArray[6].name === "") {
                    graphdataArray[6].name = "Body Mass Index"
                }
                graphdataArray[6].data.push(ob.value);
                graphdataArray[6].text.push(ob.text)
                graphdataArray[6].labels.push(moment(ob.inserteddate).format("DD MMM YYYY"));
                break;
            default:
                return;
        }
    })

    let graphdata = [];
    graphdataArray.map((value, index) => {
        // if some indix has no data then leve it and push the data contain index into the graphdata array
        if (value.name) {
            graphdata.push(value)
        } else {
            // 
        }
    })


    let wholeArrays = {
        // gradphData: grad,
        gradphData: graphdata,

    }
    dispatch(setVitalsWholeArray(wholeArrays.gradphData))


}

const GetPatientVitalsSlice = createSlice({
    name: "getPatientVitals",
    initialState: {
        isLoading: false,
        GetVital: [],
        wholeArray: {},
        error: false,
        errorMessage: '',
        isWholeSuccess: false
    },
    reducers: {
        getPatientVitals: (state, action) => {
            state.isLoading = true
            state.GetVital = []
            state.wholeArray = {}
            state.error = false
            state.errorMessage = ""
            state.isWholeSuccess = false
        },
        getPatientVitalsSuccess: (state, action) => {
            state.GetVital = action.payload
            state.isLoading = false
            state.isWholeSuccess = false

        },
        setVitalsWholeArray: (state, action) => {
            state.wholeArray = action.payload
            state.isWholeSuccess = true
        },
        getPatientVitalsFailed: (state, action) => {
            state.isLoading = false
            state.errorMessage = action.payload
            // state.isAdding= false
            state.error = true
            state.isWholeSuccess = false
            state.GetVital = []
            state.wholeArray = {}
        },
        getPatientVitalsFinished: (state, action) => {
            state.isLoading = false
            state.GetVital = []
            state.wholeArray = {}
            state.error = false
            state.errorMessage = ""
            state.isWholeSuccess = false
        },

    },
});




export const {
    getPatientVitals, getPatientVitalsFailed, getPatientVitalsSuccess, getPatientVitalsFinished, setVitalsWholeArray
} = GetPatientVitalsSlice.actions;
export default GetPatientVitalsSlice.reducer;
