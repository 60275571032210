import { GETTING_TIMELINE, GETTING_TIMELINE_SUCCESS, GETTING_TIMELINE_SEARCH_SUCCESS, GETTING_TIMELINE_FAILED, GETTING_TIMELINE_MORE_SUCCESS, GETTING_TIMELINE_FINISHED, GETTING_TIMELINE_RESET_DATA } from "../constants/Constant";
import getPaitentTimeline from "../services/TimelineService";
import getUserId from "../core/GetToken";
import Settings from "../config/Settings";


export const fetchTimelineFromApi = (pageSize, pageNumber, fromDate, toDate) => {
    return (dispatch) => {
        dispatch(getTimeline())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            //fetch('https://stagingindiciconnect.itsmyhealth.nz/api/?Tozken=c72bf947-4549-4d0d-b7de-141617e52b87&PatientID=2255013&PageSize=10&PageNumber=1', requestOptions)

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = {
                PatientID: item.userid,
                PageSize: pageSize,
                PageNumber: pageNumber,
                FromDate: fromDate,
                toDate: toDate,
            }
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(raw)
            };

            let data = [];

            fetch(Settings.apiUrl + "Patient/Timeline", requestOptions)
                .then(data => data.json())
                .then(json => {
                   
                    json[0].entry.map((item, index) => {
                        let ob = {
                            "id": item.resource.id,
                            "date": new Date(item.resource.created.slice(0, 16)).toISOString(), //.split('T')[0],
                            "display": item.resource.code.coding[0].display,
                            "text": item.resource.code.text,
                            "providerName": item.resource.extension[0].valueString
                        }
                        data.push(ob)
                    })

                   
                    dispatch(getTimelineSuccess(getGroupedList(data), json[0].total))
                })
                .catch(err => dispatch(getTimelineFailed(err)))


            // getPaitentTimeline(queryString)
            //     .then(data => data.json())
            //     .then(json => {
          
            //         dispatch(getTimelineSuccess(json[0].entry))
            //     })
            //     .catch(err => dispatch(getTimelineFailed(err)))
        })


    }
}
export const fetchTimelineDatesTextFromApi = (pageSize, pageNumber, fromDate, toDate, textSearch, filterProviderId) => {
   
    return (dispatch) => {
        dispatch(getTimeline())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = {
                PatientID: item.userid,
                PageSize: pageSize,
                PageNumber: pageNumber,
                FromDate: fromDate,
                toDate: toDate,
                SearchText: textSearch,
                ProviderID: (filterProviderId === '' ? null : filterProviderId)

            }
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(raw)
            };


            let data = [];

            fetch(Settings.apiUrl + "Patient/Timeline", requestOptions)
                .then(data => data.json())
                .then(json => {
                    
                    if (!json[0].hasOwnProperty("entry")) {
                        dispatch(getTimelineSuccess(0), 0)
                        return
                    }
                    json[0].entry.map((item, index) => {
                        let ob = {
                            "id": item.resource.id,
                            "date": new Date(item.resource.created.slice(0, 16)).toISOString(),
                            "display": item.resource.code.coding[0].display,
                            "text": item.resource.code.text,
                            "providerName": item.resource.extension[0].valueString,
                        }
                        data.push(ob)
                    })

                
                    dispatch(getTimelineSuccess(getGroupedList(data), json[0].total))
                })
                .catch(err => dispatch(getTimelineFailed(err)))

        })


    }
}
export const fetchMoreTimelineFromApi = (pageSize, pageNumber) => {
    return (dispatch) => {
        dispatch(getTimeline())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            //fetch('https://stagingindiciconnect.itsmyhealth.nz/api/?Tozken=c72bf947-4549-4d0d-b7de-141617e52b87&PatientID=2255013&PageSize=10&PageNumber=1', requestOptions)
            const queryString = '?Token=' + item.token + '&PatientID=' + item.userid + '&PageSize=' + pageSize + '&PageNumber=' + pageNumber
            getPaitentTimeline(queryString)
                .then(data => data.json())
                .then(json => {
                 
                    // dispatch(getTimelineSuccess(json[0].entry))
                    if (json != null) {
                        json[0].hasOwnProperty('entry') ? dispatch(getMoreTimelineSuccess(json[0].entry)) : dispatch(getTimelineFinished())
                    } else {
                        dispatch(getTimelineFinished())
                    }
                })
                .catch(err => dispatch(getTimelineFailed(err)))
        })


    }
}

export const fetchTimelineSearchFromApi = (fromdate, todate) => {
    return (dispatch) => {
        dispatch(getTimeline())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = {
                PatientID: item.userid,
                PageSize: 100,
                PageNumber: 1,
                FromDate: fromdate != "" ? fromdate : null,
                ToDate: todate != "" ? todate : null

            }
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(raw)
            };


            let data = []
            fetch(Settings.apiUrl + "Patient/Timeline", requestOptions)
                .then(data => data.json())
                .then(json => {
                
                    json[0].entry.map((item, index) => {
                        let ob = {
                            "id": item.resource.id,
                            "date": new Date(item.resource.created.slice(0, 16)).toISOString(),
                            "display": item.resource.code.coding[0].display,
                            "text": item.resource.code.text,
                            "providerName": item.resource.extension[0].valueString
                        }
                        data.push(ob)
                    })

                   
                    dispatch(getTimelineSuccess(getGroupedList(data), json[0].total))
                })
                .catch(err => dispatch(getTimelineFailed(err)))
        
            // getPaitentTimeline(queryString)
            //     .then(data => data.json())
            //     .then(json => {
      
            //         dispatch(getTimelineSearchSuccess(json[0].entry))
            //     })
            //     .catch(err => dispatch(getTimelineFailed(err)))
        })
    }
}
export const timelineResetDataFromRedux = () => {
    return (dispatch) => {
        dispatch(getTimelineRefresh())
    }
}
const getGroupedList = (data) => {
  


    return data.reduce((acc, val) => {

        const date = val.date.match(/\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/g).toString();
        //var date_regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
        // const item = acc.find((item) => item.date.match(new RegExp(date, 'g')));
        const item = false;

        if (!item) acc.push({ date: val.date, alerts: [val], heading: val.providerName });

        else item.alerts.push(val);


 
        return acc;

    }, []);


}

const getTimeline = () => {
    return {
        type: GETTING_TIMELINE
    }
}

const getTimelineSuccess = (data, total) => {
    return {
        type: GETTING_TIMELINE_SUCCESS,
        data,
        total,
    }
}
const getTimelineSearchSuccess = (data) => {
    return {
        type: GETTING_TIMELINE_SEARCH_SUCCESS,
        data
    }
}
const getMoreTimelineSuccess = (data, total = 0) => {
    return {
        type: GETTING_TIMELINE_MORE_SUCCESS,
        data,
        total
    }
}

const getTimelineFailed = () => {
    return {
        type: GETTING_TIMELINE_FAILED
    }
}

const getTimelineFinished = () => {
    return {
        type: GETTING_TIMELINE_FINISHED
    }
}
const getTimelineRefresh = () => {
    return {
        type: GETTING_TIMELINE_RESET_DATA
    }
}