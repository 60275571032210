import React, { useEffect, useState } from "react";
import {
	StyleSheet,
	Text,
	View,
	TouchableOpacity,
} from "react-native";
import { Modal, Tooltip, Divider } from "antd";
import { useReactToPrint } from 'react-to-print';
import { useRef } from "react";
import PrintAppointmentHeader from "./PrintAppointmentHeader";
import getUserId from "../../shared/core/GetToken";


//export default function AppointmentHandler() {
const AppointmentHandler = (props) => {

	const [isPrintActive, setIsPrintActive] = useState(false)
	const [userData, setUserData] = useState(null)
	//const [zoomMeetModalVisible, setZoomMeetModalVisible] = useState(false)
	const componentRef = useRef();
	const pageStyleCss = `@page {
		
        size: A4 landscape;
	  }
	  
	  table.patient-information-table-print{
		width: 100%;
		margin-top:15px;
		border-collapse: collapse;
		border-spacing: 0px;
	}
	
	.table-heading{
		margin-top:25px
	}

    table.patient-information-table-print tbody tr{
        border-bottom: 1px solid #f2f4f9;
    }

    table.patient-information-table-print tbody tr td{
		padding: 10px;
		border: 1px solid #f2f4f9;
		font-size: 13px !important;
	}

	table.patient-information-table-print tbody tr th{
		text-align:left !important;
		font-size: 13px !important;
		font-weight: 600 !important;
	}
	
      *{
          font-family: sans-serif;
        }
        .print-header{
            color: lightgrey;
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
      }
        .print-header-left{
            display: flex;
        }
        h3,h4,h5,p{
            margin:0px;
        }
        p span{
            font-weight: 600;
		}
		.appointment-print-container{
			display: flex;
            flex-direction: row;
            flex: 1 0 0;
		}
		.appointment-print-coumns{
			display: flex;
            flex-direction: column;
            flex: 1 0 0;
		}
		.padding-left-right-ten{
            padding-left: 10px;
            padding-right: 10px;
        }
		`;
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		copyStyles: false,
		pageStyle: pageStyleCss,
		onAfterPrint: () => setIsPrintActive(false)
	})

	const [meetingJoinUrl, setMeetingJoinUrl] = useState()
	const [printModalVissible, setPrintModalVissible] = useState(false)

	useEffect(() => {
		if (props.videoCounsutlConfig) {
			//setMeetingId(json.MeetingID)
			//setPass(json.PassCode)
			setMeetingJoinUrl(props.videoCounsutlConfig)
			// alert(json.MeetingID +"----"+json.PassCode)
		}
	}, [])
	useEffect(() => {
		if (isPrintActive) {
			handlePrint()
			setIsPrintActive(false)
		}
	}, [isPrintActive])
	const [isLocationModalOpen, setIsLocationModalOpen] = useState(false)
	var MapLocation = "https://maps.google.com/maps?q=" + props.location + "&t=&z=13&ie=UTF8&iwloc=&output=embed";
	const handleZoomCall = (url) => {
		//window.location = url
		window.open(url, '_blank');

	}
	useEffect(() => {
		getUserId().then(item => {
			let user = item = JSON.parse(item)
			console.log("local => ", user.parentname, " , prop => ", props.patientDetails, ", equal => ", user.parentname?.trim() === props.patientDetails?.trim())
			setUserData(user)
		})
	}, [])
	return (
		<div className="col-lg-12 ">
			<div
				className="appointment-card card shadow p-0 mb-3 rounded"
			>
				<div className={props.styleName}>
					<div className='future-appointment-heading-text'>
						<div className="future-appointment-provider-name">
							<b>{props.providerName !== null ? props.providerName : " "}</b>
						</div>

						{/* <Link to="/zoom">
							Start Call
						</Link> */}

						<div className="future-appointment-dateTime">
							<div className="future-appointment-dateTime-time">
								<b>{props.remianingtime ? parseInt(props?.remianingtime?.asDays()) == 0 ? props.totalHours + " Hours " + props.totalMinutes + " Minutes" : parseInt(props?.remianingtime?.asDays()) + " Day(s)" : ""}</b>
							</div>
							<div className="future-appointment-dateTime-date">
								<b>{props.appointmentDate !== null
									? props.appointmentDate
									: " "}</b>
							</div>

						</div>
					</div>
					{/* <ReactToPrint
						trigger={() => <Button>Print this out!</Button>}
						content={() => componentRef.current}
					/> */}
				</div>
				<div className="card-body mb-0">
					{/* <h4 className="card-title"><i className="mdi mdi-18px mdi-home-map-marker" /> {props.praticeAddress}</h4> */}

					<div className="row">
						<div className="col-lg-4 col-md-12 col-sm-12">
							<h4 className="card-title mb-2">

								<img src="assets/images/Location.svg" className="future-appointment-locationSvg mr-2" />
								{" "}
								{props.firstitem}{"  "}
							</h4>
						</div>

						<div className="col-lg-4 col-md-12 col-sm-12">
							<p className="card-text mb-2">
								<img src='assets/images/appointmnts.svg' className='future-appointment-appointSvg mr-2' />
								{props.secondItem}
							</p>
						</div>
						<div className="col-lg-4 col-md-12 col-sm-12">
							<p className="card-text mb-2">
								Appointment Type:
								<strong>{props.appType} {
									props.appType == 'Virtual Consult - Video' && props.videoCall && meetingJoinUrl &&

									<div className="text-primary c-pointer" onClick={() => {
										handleZoomCall(meetingJoinUrl)
									}}>
										(Start Call)
										</div>
								}</strong>
							</p>
						</div>

					</div>

					<div className="row">
						<div className="col-lg-4 col-md-12 col-sm-12 mb-2">

							<p className="card-text" onClick={() => setIsLocationModalOpen(true)}>

								<img src="assets/images/marker.svg" className="mr-2 future-appointment-locationSvg" />
								{props.location}
							</p>


						</div>
						<div className="col-lg-4 col-md-12 col-sm-12">
							<p className="card-text mb-2">

								{userData && userData.parentname?.trim() === props.patientDetails?.trim() ? <strong>Self
								</strong> : <span> Other: <strong>{props.patientDetails}</strong></span>}

							</p>
						</div>
							{props.reason !== undefined && (
							<div className="col-lg-4 col-md-12 col-sm-12">
								<div>
									<div className="future-appointment-reason"
									></div>
									<p className="card-text">
										<i className={props.icTpye + " future-appointment-reason-icon"} />
										{props.reason}
									</p>
									{props.reasonForCancel !== null && (
										<p className="card-text">
											<i
												className={props.icTpyeCancel + " future-appointment-reason-icon"}
											/>
											{props.reasonForCancel}
										</p>
									)}
								</div>
						</div>
						)}
					</div>
					{/* <button className="" >Print Appointment</button> */}
					<div className="row">
						{/* <div className="col-6"> */}
						<div className="col-md-6 col-sm-12 mb-2">
							<p className="card-text">
								Appointment Status:
								<strong>{props.appointmentSatus}</strong>
							</p>
						</div>
						<div className="col-md-6 col-sm-12 position-relative mb-2">
							<div className="d-flex justify-content-end">

								<div className="print-icon d-flex justify-content-end align-items-center mr-3" onClick={() => { setPrintModalVissible(true) }}>
									<img src='assets/images/print.svg' alt='print' className='mr-2 future-appointment-print' />

									<p className="m-0 print-text">Print</p>
								</div>
								{props.type == "Future" ? (
									<div className="print-icon d-flex justify-content-end align-items-center" onClick={() => {
										props.canelHandler();
									}}>

										<>{
											props.appointmentSatusID == 1 || props.appointmentSatusID == 2 || props.appointmentSatusID == 3 ||
												props.appointmentSatusID == 11 ? <div className="future-appointment-cancel-button"
												>


													<Tooltip placement="topLeft" title={"Cancel Appointment"}>
														<img src='assets/images/Delete.svg' className="future-appointment-locationSvg" />
														{/* <svg className="mx-2" fill="white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width={20} height={20}><path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z" /></svg> */}
													</Tooltip>
											</div> : null
										}</>


										<p className="m-0 print-text">Cancel</p>
									</div>)
									: null}
							</div>

						</div>

						{/* </div> */}
					</div>
				</div>
			</div>

			<Modal
				open={isLocationModalOpen}
				onOk={() => setIsLocationModalOpen(false)}
				onCancel={() => setIsLocationModalOpen(false)}
			>
				<span>{props.location}</span>
				<iframe src={MapLocation} className="appointment-location-modal-iframe"></iframe>
			</Modal>
			<Modal
			className='modal-large'
				// ref={componentRef}
				open={printModalVissible}
				title="Appointment Slip"
				onOk={() =>
					setIsPrintActive(true)} //setPrintModalVissible(false)
				onCancel={() => setPrintModalVissible(false)}
				okText="Print"
			>
				<div id="printModal" ref={componentRef} >
					<PrintAppointmentHeader
						organizationData={props.organizationData}
						patientProfile={props.patientProfile}
						isPrintActive={isPrintActive}
					/>
					<div className='mb-2 table-heading'>
						<b>Patient Information</b>
					</div>
					<table className='table table-bordered table-striped patient-information-table-print'>
						<tbody>
							<tr>
								<th className="appointment-slip-table-column">Name</th>
								<td >{props?.patientDetails}</td>
							</tr>
							<tr>
								<th className="appointment-slip-table-column">NHI Number</th>
								<td >{props?.nhi}</td>
							</tr>
							<tr>
								<th className="appointment-slip-table-column">Date of Birth</th>
								<td >{props?.birthdate}</td>
							</tr>
							<tr>
								<th className="appointment-slip-table-column">Address</th>
								<td >
									<span className="appoitment-slip-address"
									>
										{props?.address}</span>
								</td>
							</tr>
						</tbody>
					</table>

					<div className='mt-4'>

						<div className='mb-2 table-heading'><b>Appointment Information</b> </div>
						<table className='table table-bordered table-striped patient-information-table-print'>

							<tbody>
								<tr>
									<th className="appointment-slip-table-column">Appointment Date/Time</th>
									<td >{props?.appointmentDate + ' ' + props?.secondItem}</td>
								</tr>
								<tr>
									<th className="appointment-slip-table-column">Provider Name</th>
									<td >{props?.providerName}</td>
								</tr>
								<tr>
									<th className="appointment-slip-table-column">Appointment Duration</th>
									<td >{props?.duration}</td>
								</tr>
								<tr>
									<th className="appointment-slip-table-column">Appointment Service </th>
									<td >N/A</td>
								</tr>
								<tr>
									<th className="appointment-slip-table-column">Practice Location </th>
									<td >{props.firstitem}  {props.location}</td>
								</tr>
								{/* <tr>
									<td className="appointment-slip-table-column"></td>
									<td > </td>
								</tr> */}
							</tbody>
						</table>
					</div>
				</div>
			</Modal>
		</div>
	);
};

const styles = StyleSheet.create({
	listItems: {
		flex: 0.5,
		//    alignSelf:'baseline',
		//s  alignItems: 'space-between',
	},
	listRow: {
		flexDirection: "row",
		justifyContent: "flex-start",
		padding: 5,
		marginTop: 10,
		marginLeft: 5,
		marginBottom: 3,
		marginRight: 5,
		borderRadius: 10,
		borderWidth: 0,
		borderColor: "#D1D1D6",
		backgroundColor: "white",
	},
	doctorTilteFont: {
		color: "#212121",
		fontSize: 17,
	},
	patientFont: {
		marginTop: 3,
		fontSize: 16,
		color: "#455A64",
	},
	dateSectionMainStyle: {
		opacity: 1,
		position: "relative",
		backgroundColor: "transparent",
		width: 50,
		height: 75,
		marginTop: 10,
		marginBottom: 10,
		justifyContent: "center",
		alignItems: "center",
	},
	dateSectionBackground: {
		opacity: 1,
		backgroundColor: "#1c4490",
		borderRadius: 5,
		width: 55,
		height: 65,
	},
	dateSectionTextPlacement: {
		opacity: 1,
		position: "absolute",
		height: "100%",
		width: "100%",
		borderRadius: 10,
		flex: 1,
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
	},
	appointmentTypeStyle: {
		marginTop: 5,
		marginBottom: 5,
		marginRight: 35,
		flex: 1,
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
	},

	appointmentTypeBox: {
		backgroundColor: "#C5C5C5",
		borderRadius: 5,
		marginBottom: 5,
		flex: 1,
		alignItems: "center",
		justifyContent: "center",
	},
	appointmentTypeStyleText: {
		textAlign: "center",
		width: 80,
		marginBottom: 5,
		color: "#00A500",
		fontWeight: "400",
		fontSize: 16,
	},
	toptextDate: {
		fontSize: 14,
		fontWeight: "400",
		color: "#fff",
	},
});


export default AppointmentHandler;
