import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Settings from "../../../config/Settings";
import getUserId from "../../../core/GetToken";
import { refreshTokenValidatorData } from "../TokenValidateActions/ValidateToken";
import { clearUserState } from "./AuthenticateUser";
import { refreshProxyListData } from "./GetProxyUsers";


export const LogoutUserFunction = createAsyncThunk(
    "logoutUser/user",
    async (values, { dispatch, getState }) => {
        getUserId().then((userInfo) => {
            if (userInfo == "none") {
                return
            }
            const item = JSON.parse(userInfo);
            const queryString = '?Token=' + item.token



            var myHeaders = new Headers();
            myHeaders.append("token", item.token);
            myHeaders.append("Content-Type", "application/json");
            var requestOptions = {

                method: 'POST',
                headers: myHeaders,

                redirect: 'follow'
            };

            fetch(Settings.apiUrl + "Patient/Logout", requestOptions)
                .then(response => response.json())
                .then(result => {

                    alert(JSON.stringify(result))
                    if (result.StatusCode == 1) {
                        // aslert(result.ResponseMessage)
                    }
                })
                .catch(error => { });

        })


        refreshTokenValidatorData()
        clearUserState()
        refreshProxyListData()
        localStorage.clear()
    }
)