import Settings from "../config/Settings";
import { GET_LOOKUP_LIST, GET_LOOKUP_LIST_SUCCESS, GET_LOOKUP_LIST_FAILED } from "../constants/Constant";
import getUserId from "../core/GetToken";



export const fetchLookupListAPI = () => {
    return async (dispatch) => {
        // const networkcheck = await isNetworkAvilable();
        // if (networkcheck) {
        dispatch(getLookupList())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = {
                PatientID: item.userid,
                TypeID: 13,
            }
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(raw)
            };

            fetch(`${Settings.apiUrl}IndiciLookup`, requestOptions)
                .then(response => response.json())
                .then(result => {
                   
                    dispatch(getLookupListSuccess(result[0].entry))
                })
                .catch(error => dispatch(getLookupListFail()));
            // const queryString = '?PatientID=' + item.userid + '&PageSize=' + pageSize + '&PageNumber=' + pageNumber + '&TransactionTypeID=0'
            // getUserInvoice(queryString, myHeaders)
            //     .then(data => data.json())
            //     .then(json => {
           
            //         json[0].hasOwnProperty('entry') ? dispatch(getAllInvoiceSuccess(json[0].entry)) : dispatch(getAllInvoiceFinsihed())
            //     })
            //     .catch(err => dispatch(getAllInvoiceFail(err)))
        })
        // } else {
        //     showAlert(MessageConstant.networkMessage)

        //     dispatch(getAllInvoiceFail())
        // }

    }
}





const getLookupList = () => {
    return {
        type: GET_LOOKUP_LIST
    }
}

//data success
const getLookupListSuccess = (data) => {
    return {
        type: GET_LOOKUP_LIST_SUCCESS,
        data,
    }
}


//data failed
const getLookupListFail = () => {
    return {
        type: GET_LOOKUP_LIST_FAILED
    }
}

const getLookupListFinsihed = () => {
    return null;
}


