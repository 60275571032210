import { DELETE_MESSAGE, DELETE_MESSAGE_SUCCESS, DELETE_MESSAGE_REFRESH_DATA, DELETE_MESSAGE_LOADING, DELETE_MESSAGE_ERROR, DELETE_MESSAGE_FAILED } from '../../constants/Constant'
import getUserId from "../../core/GetToken";
import Settings from '../../config/Settings';




/******************************** LT *******************************************/

//return long terms diagnosis
export const deleteMessageAPI = (values) => {
    return (dispatch) => {
        dispatch(deleteMessage())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token);
            myHeaders.append("Content-Type", "application/json");
            var requestOptions = {
                method: 'DELETE',
                headers: myHeaders,
                redirect: 'follow',
                body:JSON.stringify({PatientID:item.userid,MessageID:values.messageID})
            };
           
            fetch(Settings.apiUrl + "Patient/DeleteMessage", requestOptions)

                .then(data => data.json())
                .then(json => {
                    dispatch(deleteMessageSuccess(json))
                })
                .catch(err => dispatch(deleteMessageFailed(err)))

        })
    }
}

export const deleteMessageAPIRefresher = () =>{
    return (dispatch)=>{
        dispatch(deleteMessageRefreshData())
    }
}



//ALL DIAGNOSIS LOADS HERE
const deleteMessage = () => {
    return {
        type: DELETE_MESSAGE
    }
}
const deleteMessageRefreshData = () => {
    return {
        type: DELETE_MESSAGE_REFRESH_DATA
    }
}

const deleteMessageSuccess = (data) => {
    return {
        type: DELETE_MESSAGE_SUCCESS,
        data,
    }
}

const deleteMessageFailed = () => {
    return {
        type: DELETE_MESSAGE_FAILED
    }
}
