import AsyncStorage from "@react-native-async-storage/async-storage";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Settings from "../../../config/Settings";
import getUserInfo from "../../../core/GetTokenized";

export const GetPracticeOrganizationDataAPI = createAsyncThunk(
    "getPracticeOrganization/Organization",
    async (values, { dispatch, getState }) => {

        dispatch(gettingPracticeData())
        getUserInfo().then((information) => {
            const item = JSON.parse(information);
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = {
                PatientID: item.userid,
            }
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(raw)
            };
            fetch(Settings.apiUrl + 'Patient/Organization', requestOptions)
                .then(data => data.json())
                .then(json => {

                    dispatch(gettingPracticeDataSuccess(json[0].entry))
                })
                .catch(err => dispatch(gettingPracticeDataFailed(err)))

        }).catch((err) => {

        })

    }

);

const GetPracticeOrganizationDataSlice = createSlice({
    name: "getPracticeOrganization",
    initialState: {

        Practise: [],
        isFetching: false,
        error: false,
        errorMsg: null
    },
    reducers: {
        gettingPracticeData: (state, action) => {
            state.Practise = []
            state.isFetching = true
            state.error = false
            state.errorMsg = null
        },
        gettingPracticeDataSuccess: (state, action) => {
            state.Practise = action.payload
            state.isFetching = false
            state.error = false
            state.errorMsg = null

        },
        gettingPracticeDataFailed: (state, action) => {
            state.Practise = []
            state.isFetching = false
            state.error = true
            state.errorMsg = action.payload

        },

    },
});




export const {
    gettingPracticeData, gettingPracticeDataFailed, gettingPracticeDataSuccess
} = GetPracticeOrganizationDataSlice.actions;
export default GetPracticeOrganizationDataSlice.reducer;
