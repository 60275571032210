

import React from 'react';
import { StyleSheet, Text, View, Image, Dimensions } from 'react-native';


const ProfileItemB = props => {
    return (
        <div className="form-group row showcase_row_area">
            <div className="col-md-3 showcase_text_area">
                <label className="col-form-label">{props.title}</label>
            </div>
            <div className="col-md-9 showcase_content_area mb-1">
                <input autoComplete="off" type="text" id="validate-phone-field" className="form-control enable-mask phone-mask" value={props.address} readOnly />
            </div>
        </div>
    );
}

export default ProfileItemB
