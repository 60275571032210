import { GETTING_MEDS_RECENT_SUCESSS, GETTING_RECENT_MEDS, GETTING_RECENT_MEDS_FAILED } from "../constants/Constant";

//inital state
const initalState = {
    AllRecentMedeications: [],
    isGettingRecent: false,
    errorRecent: false
}

export default function allRecentMedication(state = initalState, action) {
    switch (action.type) {
        case GETTING_RECENT_MEDS:
            return {
                ...state,
                AllRecentMedeications: [],
                isGettingRecent: true
            }

        case GETTING_MEDS_RECENT_SUCESSS:
            return {
                ...state,
                isGettingRecent: false,
                AllRecentMedeications: action.data  // [...state.AllRecentMedeications, ...action.data]

            }

        case GETTING_RECENT_MEDS_FAILED:
            return {
                ...state,
                isGettingRecent: false,
                AllRecentMedeications: [],
                errorRecent: true
            }

        default:
            return state
    }
}

