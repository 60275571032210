import AsyncStorage from "@react-native-async-storage/async-storage";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Settings from "../../../config/Settings";
import getUserId from "../../../core/GetToken";

export const GetAccountBalanceAPI = createAsyncThunk(
    "getAccountBalance/User",
    async (values, { dispatch, getState }) => {

        dispatch(gettingBalance())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = {
                PatientID: item.userid,

            }
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(raw)
            };

            fetch(Settings.apiUrl + "Patient/AccountBalance", requestOptions)
                .then(response => response.json())
                .then(result => {
                    //  alert(result)
                    dispatch(gettingBalanceSuccess(result))
                })
                .catch(error => {
                    dispatch(gettingBalanceFailed())
                    //{}
                });
            // getAllRoles(queryString, myHeaders)
            //     .then(data => data.json())
            //     .then(json => {
            //         json[0].hasOwnProperty('entry') ? dispatch(getRolesSuccess(json[0].entry)) : dispatch(getRolesFailed())
            //     })
            //     .catch((err) => {
            //         dispatch(getRolesFailed(err))
            //     })
        })

    }

);

const GetAccountBalanceSlice = createSlice({
    name: "getAccountBalance",
    initialState: {
        isLoadoing: false,
        Balance: '',
        error: false,
        errorMsg: null
    },
    reducers: {
        gettingBalance: (state, action) => {
            state.isLoadoing = true
            state.Balance = ''
            state.error = false
            state.errorMsg = null

        },
        gettingBalanceSuccess: (state, action) => {
            state.isLoadoing = false
            state.Balance = action.payload
            state.error = false
            state.errorMsg = null

        },
        gettingBalanceFailed: (state, action) => {
            state.isLoadoing = false
            state.Balance = ''
            state.error = true
            state.errorMsg = action.payload
        },

    },
});




export const {
    gettingBalance, gettingBalanceFailed, gettingBalanceSuccess
} = GetAccountBalanceSlice.actions;
export default GetAccountBalanceSlice.reducer;
