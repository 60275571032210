import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import { View, Text, StyleSheet } from "react-native-web";
import { Tabs } from "antd";
import "antd/dist/antd.css";
import AllMedications from "./MedicationsTabs/AllMedications";
import LongTermMed from "./MedicationsTabs/LongTermMed";
import RepeatedMedication from "./MedicationsTabs/RepeatedMedication";
import { connect, useDispatch, useSelector } from "react-redux";

import Long from "./DiagnosisTabs/Long";
import Recent from "./DiagnosisTabs/Recent";
import { checkToken } from "../../shared/actions/TokenValidActions";
import All from "./DiagnosisTabs/All";
import { actionAPI } from "../../shared/reduxToolkit/actionAPI";
const { TabPane } = Tabs;
const useNavigate = require("react-router-dom").useNavigate;
const Diagnosis = (props) => {

  const navigate = useNavigate()
  const { ProxyMenu, isLoadingMenu } = useSelector(state => state.ProxyMenu);
  const history = useNavigate()

  const [isloaded, setIsload] = React.useState(false);
  

  const apiDispatcher = useDispatch()

  // React.useEffect(() => {
  //   apiDispatcher(actionAPI.ValidateTokenAPI(history))
  // }, [])
  useEffect(() => {
    debugger
    if (ProxyMenu != null && ProxyMenu != undefined) {
      let itemExists = false
      console.log("diagnosis Menu =>", ProxyMenu)
      ProxyMenu.map((item, index) => {
        if (item.resource.extension[0].valueString == 'Diagnosis') {
          itemExists = true
        }

      })
      if (!itemExists) {
        navigate("/")
      }
    }
  }, [ProxyMenu])

  return (
    
          <div className="page-content-wrapper-inner">
            <div className="viewport-header">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb has-arrow">
                  <li className="breadcrumb-item">
                    {/* <a href=>Dashboard</a> */}
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Diagnosis
                  </li>
                </ol>
              </nav>
            </div>
            <div className="content-viewport">
              <div className="row row-margin-zero h-100">
                <div className="col-12 h-100">
                  <div className="grid h-100">
                    <div className="grid-body h-100">
                      {/* <h2 className="grid-title">Appointments</h2> */}
                      <div className="item-wrapper diagnosis-container">
                  <Tabs defaultActiveKey="1" centered items={[
                    {
                      label: "Long Term",
                      key: "1",
                      children: <>
                            <Long />
                      </>
                    },
                    {
                      label: "Recent Diagnosis",
                      key: "2",
                      children: <>

                        <Recent />
                      </>
                    },
                    {
                      label: "All Diagnosis",
                      key: "3",
                      children: <>

                        <All />
                      </>
                    }
                  ]}>

                        </Tabs>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

      

  );
};

const styles = StyleSheet.create({
  textDetails: {
    textAlign: "justify",
    color: "#95989A",
    fontSize: 15,
  },
  textDetailsInner: {
    textAlign: "justify",
    color: "#333333",
    fontSize: 14,
  },
});
function mapStateToProps(state) {
  return {

    ProxyMenu: state.ProxyMenu,
  }
}

function mapDispatchToProps(disptach) {
  return {
    tokenVal: (history) => disptach(checkToken(history))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Diagnosis)
// export default Diagnosis;

