import { Tooltip } from 'antd'
import React from 'react'
import { capitalize, GetAppointmentDate, GetMonthName, GetYear } from '../../shared/core/datehelper'
const Item = ({ titleName, titleValue }) => {
    return (
        <div className="detail-container">
            <label className="detail-label">{titleName}</label>
            <Tooltip title={titleValue}>

            <span className="detail-value">{titleValue}</span>
            </Tooltip>
        </div>
    )
}
const PersonalDetail = ({ nameArray }) => {
    return (
        <div>
            <div className="letter-detail-card-body">
                <div className="row row-margin-zero">
                    <div className="col-12 p-0">

                        <Item
                            titleName={"Title"}
                            // titleValue={"Miss"}
                            titleValue={typeof (nameArray[0].resource.name[0].prefix) !== 'undefined' ? nameArray[0].resource.name[0].prefix[0] : ""}
                        />

                        <Item
                            titleName={"First Name"}
                            titleValue={capitalize(nameArray[0].resource.name[0].given[0])} />

                        <Item
                            titleName={"Family Name"}
                            titleValue={nameArray[0].resource.name[0].family} />

                        <Item
                            titleName={"Preferred Name"}
                            titleValue={nameArray[0]?.resource?.name[0]?.text ? nameArray[0]?.resource?.name[0]?.text : ""} />

                        <Item
                            titleName={"Gender"}
                            titleValue={capitalize(nameArray[0]?.resource?.extension?.find(o => o.url === "GenderName").valueString)} />


                        <Item
                            titleName={"Date of Birth"}
                            titleValue={GetAppointmentDate(nameArray[0].resource.birthDate) + "-" + GetMonthName(nameArray[0].resource.birthDate) + "-" + GetYear(nameArray[0].resource.birthDate)} />


                        <Item
                            titleName={"Ethnicity"}
                            titleValue={nameArray[0].resource.extension[1].valueString ? nameArray[0].resource.extension[1].valueString : "N/A"} />


                        <Item
                            titleName={"Blood Group"}
                            titleValue={nameArray[0].resource.extension[4].valueString ? nameArray[0].resource.extension[4].valueString : "N/A"} />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default PersonalDetail;
