import React, { useCallback, useState, useEffect } from 'react';
import { Button, View, Text, SafeAreaView, Dimensions, StyleSheet, FlatList, ActivityIndicator, TouchableOpacity } from 'react-native-web';
import { Link } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";

import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import { fetchResourcesFromApi, fetchMoreResourcesFromApi } from "../../shared/actions/ResourcesActions";
import { GetAppointmentDate, GetMonthName, GetYear } from '../../shared/core/datehelper'

import { Card, Col, Row } from 'antd';
import 'antd/dist/antd.css';
import ResourcesHandler from "../components/ResourcesHandler";
import "../components/css/drive.css";


import { checkToken } from '../../shared/actions/TokenValidActions';
import CalendarComponent from '../components/CalenderControl/CalenderComponent';
import { actionAPI } from '../../shared/reduxToolkit/actionAPI';

const useNavigate = require("react-router-dom").useNavigate;
const gridStyle = {
    width: '50%',
    textAlign: 'center',
};
const ResourcesHome = props => {
    const { Resources, isGetting } = useSelector(state => state.Resources);

    const navigate = useNavigate()
    const { ProxyMenu, isLoadingMenu } = useSelector(state => state.ProxyMenu);
    const history = useNavigate()
    const [pageSize, setPageSize] = useState(100);           //state for the page number
    const [pageNumber, setPageNumber] = useState(1);
    const [isloaded, setIsload] = React.useState(false);
    const apiDispatcher = useDispatch()
    useEffect(() => {
        if (ProxyMenu != null && ProxyMenu != undefined) {
            let itemExists = false
            ProxyMenu.map((item, index) => {
                if (item.resource.extension[0].valueString == 'Resources') {
                    itemExists = true
                }

            })
            if (!itemExists) {
                navigate("/")
            }
        }
    }, [ProxyMenu])


    useEffect(() => {
        apiDispatcher(actionAPI.GetResourcesFromAPI({ pageNumber: pageNumber, pageSize: pageSize }))
    }, [])
    useEffect(() => {
    }, [isGetting])
    React.useEffect(() => {
        apiDispatcher(actionAPI.checkToken(history))
    }, [])

    const checkItems = resourceInfo => {
        alert('yooyoyoyoy')
    }
    return (

        <div className="page-content-wrapper-inner">
            <div className="viewport-header">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb has-arrow">
                        <li className="breadcrumb-item">
                            <Link to="/">Dashboard</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">Resources</li>
                    </ol>
                </nav>
            </div>
            <div className="content-viewport">
                <div className="row row-margin-zero">
                    <div className="col-12">
                        <div className="grid">
                            <div className="grid-body">

                                <div className="item-wrapper">

                                    <div className="drive-wrapper drive-grid-view">
                                        <div className="grid-items-wrapper">
                                            {
                                                isGetting && <div className="text-center mb-5 "><ActivityIndicator size="small" color="#00A1DE" /></div>
                                            }
                                            {
                                                // Resources != null && Resources != undefined && Resources.map((item) => {
                                                //     return (
                                                //         <ResourcesHandler />
                                                //     )
                                                // })

                                                typeof (Resources) !== 'undefined' && Resources.length ? (
                                                    Resources.map((item) => {
                                                        return (
                                                            <ResourcesHandler
                                                                name={item.resource.content.title}
                                                                responseType={item.resource.content.hasOwnProperty('contentType') ? item.resource.content.contentType === 'PDF' ? 'fa fa-file-pdf-o text-primary-blue' : 'fa fa-link text-primary-blue' : 'fa fa-link text-primary-blue'}
                                                                contentType={item.resource.content.hasOwnProperty('contentType') ? item.resource.content.contentType === 'PDF' ? 'PDF' : 'URL' : 'URL'}
                                                                contentFlow={item.resource.content.hasOwnProperty('contentType') ? item.resource.content.contentType === 'PDF' ? item.resource.id : item.resource.content.url : item.resource.content.url}
                                                            />
                                                        )
                                                    })
                                                ) :
                                                    !isGetting &&
                                                    <div className='alert alert-danger p-2 text-center'>No Records Found</div>
                                            }
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div> 
                       
                    </div>
                    {/* <CalendarComponent startingMonth={3} endingMonth={5} /> */}
                </div>
           


           
        </div>
        </div>
    )
}


function mapStateToProps(state) {
    return {
        Resources: state.Resources,
        ProxyMenu: state.ProxyMenu,
    }
}

function mapDispatchToProps(disptach) {
    return {
        getResources: (pageSize, pageNumber) => disptach(fetchResourcesFromApi(pageSize, pageNumber)),
        tokenVal: (history) => disptach(checkToken(history))

        //getMoreResources: (token, pageSize, pageNumber) => disptach(fetchMoreResourcesFromApi(token, pageSize, pageNumber))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResourcesHome)
//export default ResourcesHome
