import AsyncStorage from "@react-native-async-storage/async-storage";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Settings from "../../../config/Settings";
import getUserId from "../../../core/GetToken";

export const GetRecentMedsFromAPI = createAsyncThunk(
    "getRecentMeds/Medication",
    async (values, { dispatch, getState }) => {

        dispatch(getRecentMeds())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify(
                {
                    "PatientID": item.userid,
                    "PageSize": values.pageSize,
                    "PageNumber": values.pageNumber,
                    "TypeID": 2,
                }
            );

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };


            fetch(Settings.apiUrl + "Patient/Medication", requestOptions)
                .then(data => data.json())
                .then(json => {

                    json[0].hasOwnProperty('entry') ? dispatch(getRecentMedsSuccess(json)) : dispatch(getRecentMedsFinished())
                })
                .catch(err => dispatch(getRecentMedsFailed(err)))
        })

    }

);

const GetRecentMedsSlice = createSlice({
    name: "getRecentMeds",
    initialState: {
        AllRecentMedeications: [],
        isGettingRecent: false,
        errorRecent: false
    },
    reducers: {
        getRecentMeds: (state, action) => {
            state.isGettingRecent = true
            state.errorRecent = false
        },
        getRecentMedsSuccess: (state, action) => {
            state.AllRecentMedeications = action.payload
            state.isGettingRecent = false
            state.errorRecent = false

        },
        getRecentMedsFailed: (state, action) => {
            state.isGettingRecent = false
            state.errorRecent = true
        },
        getRecentMedsFinished: (state, action) => {
            state.AllRecentMedeications = []
            state.isGettingRecent = false
            state.errorRecent = false
        },

    },
});




export const {
    getRecentMeds, getRecentMedsFailed, getRecentMedsSuccess, getRecentMedsFinished
} = GetRecentMedsSlice.actions;
export default GetRecentMedsSlice.reducer;
