import { GET_PROXY_ACCOUNTS, GET_PROXY_ACCOUNTS_SUCCESS, GET_PROXY_ACCOUNTS_FAILED, GET_PROXY_ACCOUNTS_FINISHED, GET_PROXY_ACCOUNTS_REFRESH_DATA } from "../constants/Constant";
import getProxyAccess from "../services/ProxyService";
import getUserId from "../core/GetToken";
import Settings from "../config/Settings";


export const getProxyListApi = (pageNumber, pageSize) => {
    return (disptach) => {
        disptach(getProxyList())



        getUserId().then((userInfo) => {
           
            //  item = null;
            // try {
            const item = userInfo !== "none" && JSON.parse(userInfo);
            // }catch(e){
            //     item = JSON.parse(userInfo);
            // }
            // token = data

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = {
                PatientID: item.parentid,

            }
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(raw)
            };
            fetch(Settings.apiUrl + "Patient/ProxyUsers?", requestOptions)
                .then(response => response.json())
                .then(json => {

                    json[0].hasOwnProperty('entry') ? disptach(getProxyListSuccess(json[0].entry)) : disptach(proxyListEnd())
                })
                .catch(error => {});






            // getProxyAccess(queryString)
            //     .then(data => data.json())
            //     .then(json => {
           
            //         // disptach(getAlgSuccess(json[0].entry))
            //         json[0].hasOwnProperty('entry') ? disptach(getProxyListSuccess(json[0].entry)) : disptach(proxyListEnd())
            //     })
            //     .catch(err => disptach(getProxyListFailed(err)))

        })

    }
}

export const refreshProxyListData = () => {
    return (dispatch) => {
        dispatch(getProxyListRefreshData())
    }
}

const getProxyList = () => {
    return {
        type: GET_PROXY_ACCOUNTS
    }
}
const getProxyListRefreshData = () => {
    return {
        type: GET_PROXY_ACCOUNTS_REFRESH_DATA
    }
}


const getProxyListSuccess = (data) => {
    return {
        type: GET_PROXY_ACCOUNTS_SUCCESS,
        data
    }
}

const getProxyListFailed = () => {
    return {
        type: GET_PROXY_ACCOUNTS_FAILED
    }
}

const proxyListEnd = () => {
    return {
        type: GET_PROXY_ACCOUNTS_FINISHED
    }
}