import Settings from '../config/Settings';
import { GET_BALANCE_INFO, GET_BALANCE_INFO_SUCCESS, GET_BALANCE_INFO_FAILED, } from "../constants/Constant";

import getUserId from "../core/GetToken";
export const fetchBalanceFromApi = (typeid) => {
    return async (dispatch) => {
      
            dispatch(getBalance())
            getUserId().then((userInfo) => {
                const item = JSON.parse(userInfo);
                var myHeaders = new Headers();
                myHeaders.append("Token", item.token)
                myHeaders.append("Content-Type", "application/json")
                var raw = {
                    PatientID: item.userid,

                }
                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    redirect: 'follow',
                    body: JSON.stringify(raw)
                };

                fetch(Settings.apiUrl + "Patient/AccountBalance", requestOptions)
                    .then(response => response.json())
                    .then(result => {
                     //  alert(result)
                        dispatch(getBalanceSuccess(result))
                    })
                    .catch(error => {
                        dispatch(getBalanceFailed())
                        //{}
                    });
                // getAllRoles(queryString, myHeaders)
                //     .then(data => data.json())
                //     .then(json => {
                //         json[0].hasOwnProperty('entry') ? dispatch(getRolesSuccess(json[0].entry)) : dispatch(getRolesFailed())
                //     })
                //     .catch((err) => {
                //         dispatch(getRolesFailed(err))
                //     })
            })
        
    }
}


const getBalance = () => {
    return {
        type: GET_BALANCE_INFO
    }
}

//load data
const getBalanceSuccess = (data) => {
    return {
        type: GET_BALANCE_INFO_SUCCESS,
        data,
    }
}


//data failure
const getBalanceFailed = () => {
    return {
        type: GET_BALANCE_INFO_FAILED,
    }
}




