import AsyncStorage from "@react-native-async-storage/async-storage";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Settings from "../../../config/Settings";
import getUserId from "../../../core/GetToken";

export const GetMessageByIdAPI = createAsyncThunk(
    "gettingMessageById/Messaging",
    async (values, { dispatch, getState }) => {

        dispatch(gettingMessageById())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = {
                PatientID: item.userid,
                MessageID: values.messageId,
                isWeb: true

            }
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(raw)
            };

            fetch(Settings.apiUrl + "Patient/MessageByID", requestOptions)

                .then(data => data.json())
                .then(json => {
                    json[0].total = values.messageNum ? values.messageNum : 1
                    dispatch(gettingMessageByIdSuccess(json))
                })
                .catch(err => dispatch(gettingMessageByIdFailed(err)))

        })

    }

);
export const RefreshMessageByIdData = createAsyncThunk(
    "refreshMessageByIdData/Messaging",
    async (values, { dispatch, getState }) => {
        dispatch(gettingMessageByIdRefresh())
    })
const GettingMessageByIdSlice = createSlice({
    name: "gettingMessageById",
    initialState: {
        messageData: null,
        messageDataLoading: false,
        messageDataSuccess: false,
        messageDataFailed: false,
        messageDataError: null,
        messageDataStatus: "idle",
    },
    reducers: {
        gettingMessageById: (state, action) => {
            state.messageData = null
            state.messageDataSuccess = false
            state.messageDataFailed = false
            state.messageDataLoading = true
            state.messageDataError = null
            state.messageDataStatus = "loading"

        },
        gettingMessageByIdSuccess: (state, action) => {
            state.messageData = action.payload
            state.messageDataSuccess = true
            state.messageDataFailed = false
            state.messageDataLoading = false
            state.messageDataError = null
            state.messageDataStatus = "success"


        },
        gettingMessageByIdFailed: (state, action) => {
            state.messageData = null
            state.messageDataSuccess = false
            state.messageDataFailed = true
            state.messageDataLoading = false
            state.messageDataError = action.payload
            state.messageDataStatus = "error"

        },
        gettingMessageByIdRefresh: (state, action) => {
            state.messageData = null
            state.messageDataSuccess = false
            state.messageDataFailed = false
            state.messageDataLoading = false
            state.messageDataError = null
            state.messageDataStatus = "idle"

        },

    },
});




export const {
    gettingMessageById, gettingMessageByIdFailed, gettingMessageByIdSuccess, gettingMessageByIdRefresh
} = GettingMessageByIdSlice.actions;
export default GettingMessageByIdSlice.reducer;
