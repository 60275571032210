import React, { memo, useCallback, useEffect, useState } from 'react';
import { View, Text, StyleSheet, StatusBar, Dimensions, ActivityIndicator, FlatList } from 'react-native-web';
import { connect, useDispatch, useSelector } from "react-redux";


import { fetchAllMedsFromApi } from "../../../shared/actions/AllMedicationActions";
import MedicationHandler from "../../components/MedicationHandler";
import { GetAppointmentDate, GetMonthName, GetYear } from '../../../shared/core/datehelper'
import { fetchRecentMedsFromApi } from '../../../shared/actions/RecentMedAction';
import { flushSync } from 'react-dom';
import { DatePicker, Spin, Table, Button, Modal } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { actionAPI } from '../../../shared/reduxToolkit/actionAPI';
import getUserId from '../../../shared/core/GetToken';
import Settings from '../../../shared/config/Settings';
import moment from 'moment';

const OrdersTab = props => {
    const { RangePicker } = DatePicker;

    const { AllRecentMedeications, isGettingRecent } = useSelector(state => state.RecentMedication);
    //state for the page number
    const [orderMedsDataList, setOrderMedsDataList] = useState([]);
    const [totalRecords, setTotalRecords] = useState(10)
    const [activeRow, setActiveRow] = useState(10)
    const [loadingGridData, setLoadingGridData] = useState(false)
    const [searchRangeValue, setSearchRangeValue] = useState("");
    const [searchFromDate, setSearchFromDate] = useState(null);
    const [searchToDate, setSearchToDate] = useState(null);
    const [isAuditModalVisible, setIsAuditModalVisible] = useState(false);
    const [auditDataLoading, setAuditDataLoading] = useState(false);
    const [repeatAuditLogData, setRepeatAuditLogData] = useState(null);
    const [portalSetting, setPortalSetting] = useState(null);
    const apiDispatcher = useDispatch()
    let limit = 15
    let offset = 1

    const getGridData = (pageNumber, pageSize, isFromReset) => {
        setLoadingGridData(true)
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = {
                PatientID: item.userid,
                PageSize: pageSize,
                PageNumber: pageNumber,
                StartDate: isFromReset ? null : searchFromDate,
                EndDate: isFromReset ? null : searchToDate
            }
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(raw)
            };
            fetch(Settings.apiUrl + "Patient/RepeatRequestOrders", requestOptions)
                .then(response => response.json())
                .then(result => {

                    if (result !== "undefined" && result?.length > 0) {
                        setTotalRecords(result[0].total)
                        if (pageNumber === 1) {
                            setOrderMedsDataList(result[0].entry)
                        }
                        else if (result && orderMedsDataList != result[0].entry) {
                            const mergeData = [...orderMedsDataList, ...result[0].entry];
                            setOrderMedsDataList(mergeData);


                        }

                    }
                    setLoadingGridData(false)
                })
                .catch(error => {
                    setLoadingGridData(false)

                });

        })
    }
    const getAllOrderMedsDataFromApi = (fromStart = false, isFromReset = false) => {
        limit = 15;
        offset = fromStart ? 1 : Math.ceil(orderMedsDataList.length / limit) + 1;
        if (offset == 1) {
            flushSync(() => {
                setOrderMedsDataList([])
                setTotalRecords(0)
            })
        }
        // getTimelineDateTextSearch: (pageSize, pageNumber, fromdate, todate, textSearch, filterProviderId)
        getGridData(offset, limit, isFromReset)


    };
    const fetchMoreOrderMedsDataFromAPI = (ignore = false) => {
        if (ignore || orderMedsDataList.length < totalRecords) {
            getAllOrderMedsDataFromApi();
        }
    }

    useEffect(() => {
        getAllOrderMedsDataFromApi(true)

        setPortalSetting(JSON.parse(localStorage.getItem("@portalSettings")))

    }, [])
    // useEffect(() => {
    //     if (AllRecentMedeications !== "undefined" && AllRecentMedeications?.length > 0) {
    //         setTotalRecords(AllRecentMedeications[0].total)
    //         if (AllRecentMedeications && orderMedsDataList != AllRecentMedeications[0].entry) {
    //             const mergeData = [...orderMedsDataList, ...AllRecentMedeications[0].entry];
    //             setOrderMedsDataList(mergeData);


    //         }

    //     }
    // }, [AllRecentMedeications])
    function capitalize(word) {
        return word[0].toUpperCase() + word.slice(1).toLowerCase();
    }
    let gridColumns = [
        {
            title: 'DATE',
            dataIndex: "resource",
            // width: "50%",
            render: (item) => <p className='font-weight-bold'>{item?.extension?.find(o => o.url === "Date")?.valueString}</p>
        },
        {
            title: 'FEE',
            dataIndex: "resource",
            render: (item) => <p>{item?.extension?.find(o => o.url === "Amount")?.valueString}</p>
        },
        {
            title: 'REQUEST TO',
            dataIndex: "resource",
            render: (item) => <p>{item?.extension?.find(o => o.url === "RequestTo")?.valueString}</p>

        },
        {
            title: 'STATUS',
            dataIndex: "resource",
            className: 'statusgrid ',
            render: (item) => {
                let statusStr = item?.extension?.find(o => o.url === "Status")?.valueString;
                return <p className={
                    statusStr !== null && statusStr !== "" ? statusStr === 'Awaiting Approval' ? "awaiting-bg" :
                        statusStr === "Script Sent to Pharmacy" ? "pharmacy-bg" :
                            statusStr === "Script Ready for Pickup" ? "pickup-bg" :
                                statusStr === "Cancelled" ? "cancelled-bg" :
                                    statusStr && statusStr?.toLowerCase()?.indexOf("rejected") >= 0 ? "cancelled-bg" :
                                    "dispence-bg" : ""}>{statusStr}</p>
            }

        },


    ];
    if (portalSetting && portalSetting?.EnableRepeatRxInvoicing) {

        gridColumns = [...gridColumns, 
            {
                title: 'ACTION',
                dataIndex: "resource",
                render: (item) => <img src="assets/images/audit-grid-icon.svg" className='cursor-pointer' onClick={() => handleAuditLogClicked(item?.id)}></img>
                // <Button onClick={() => setIsAuditModalVisible(true)}>Audit</Button>

            }]
    }
    const resetFilterData = () => {
        setSearchFromDate(null)
        setSearchToDate(null)
        setSearchRangeValue("")
        getAllOrderMedsDataFromApi(true, true)
    }
    const handleAuditLogClicked = (requestID) => {
        setIsAuditModalVisible(true)
        setAuditDataLoading(true)

        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = {
                RequestId: requestID,
            }
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
            };
            fetch(Settings.apiUrl + "Patient/GetRepeatAuditLog?RequestId=" + requestID, requestOptions)
                .then(response => response.json())
                .then(result => {

                    setAuditDataLoading(false)
                    if (result.length > 0) {

                        setRepeatAuditLogData(result)
                    } else {
                        setRepeatAuditLogData(null)
                    }
                })
                .catch(error => {

                    setAuditDataLoading(false)
                });
        })

    }
    return (
        <div className='d-flex flex-column h-100'>
            <div className=''>
                <div className="reminder-legends card-body bg-grey">
                    {/* <div className="medication-shortterm-legend">
                        <div className="custom-legends shortterm-legend-box color-white"> History </div>
                    </div>
                    <div className="medication-longterm-legend">
                        <div className="custom-legends lognterm-legend-box color-white"> Long Term</div>
                    </div> */}


                    <div className='row row-margin-zero position-relative result-btuns-filter'>

                        <div className='col-md-6 col-sm-6'>
                            <div className='form-group'>
                                <RangePicker
                                    className="reports-filter-datepicker w-100"
                                    format={"DD-MM-YYYY"}

                                    getPopupContainer={(trigger) =>
                                        trigger.parentNode
                                    }
                                    value={searchRangeValue}
                                    disabledDate={(current) => {

                                        return current && (current < (moment().subtract(100, "year")) || current > (moment(new Date())))
                                    }}
                                    onChange={(e) => {
                                        setSearchRangeValue(e)
                                        setSearchFromDate(e ? moment(e[0]._d).format("YYYY-MM-DD") : null)
                                        setSearchToDate(e ? moment(e[1]._d).format("YYYY-MM-DD") : null)
                                    }}
                                    inputReadOnly
                                />
                            </div>
                        </div>
                        <div className=''>
                            <div className='form-group'>
                                <Button className='btn btn-primary mr-2' onClick={() => { getAllOrderMedsDataFromApi(true) }} type='primary'>Search</Button>
                                <Button className='btn btn-primary ' onClick={() => { resetFilterData() }} type='primary'>Reset</Button>

                            </div>
                        </div>

                    </div>
                    <div className='row row-margin-zero position-relative mb-2'>
                        <div className='col-12'>
                            <div className="d-flex align-items-center flex-wrap flex-md-nowrap">
                                <div className="d-flex align-items-center gap5 mr-2">
                                    <div className="awaiting"> </div>
                                    <div className="font-size-12px ms-2">Awaiting Approval</div>
                                </div>
                                <div className="d-flex align-items-center gap5 mr-2">
                                    <div className="pharmacy"> </div>
                                    <div className="font-size-12px ms-2">Script Sent to Pharmacy</div>
                                </div>
                                <div className="d-flex align-items-center gap5 mr-2">
                                    <div className="pickup"> </div>
                                    <div className="font-size-12px ms-2">Script Ready for Pickup</div>
                                </div>
                                <div className="d-flex align-items-center gap5 mr-2">
                                    <div className="dispence"> </div>
                                    <div className="font-size-12px ms-2">Medication Dispensed</div>
                                </div>
                                <div className="d-flex align-items-center gap5 mr-2">
                                    <div className="cancel-rxrejected"> </div>
                                    <div className="font-size-12px ms-2">Cancelled/Rx Rejected</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                loadingGridData && <div className='d-flex text-center justify-content-center mb-1 mt-2'><ActivityIndicator size="small" color="#00A1DE" /></div>
            }
            {
                typeof (AllRecentMedeications) !== 'undefined' && orderMedsDataList && orderMedsDataList?.length > 0 ? (
                    <div id="orderMedsScrollableDiv" className='long-term-medication-inner-div'>
                        <InfiniteScroll
                            dataLength={orderMedsDataList.length}
                            next={fetchMoreOrderMedsDataFromAPI}
                            hasMore={orderMedsDataList.length < totalRecords}
                            loader={<div className='d-flex justify-content-center my-2'><Spin /></div>}
                            scrollableTarget="orderMedsScrollableDiv"
                        >

                            <Table
                                columns={gridColumns}

                                rowKey={(record) => record.resource.id}

                                expandable={{
                                    expandedRowRender: (record) => (
                                        <ul>

                                            {record?.resource?.extension?.find(o => o.url === "Medications")?.valueString?.split(",").map((medicineData, index, medArr) => index !== (medArr.length - 1) && <li>{medicineData}</li>)}
                                        </ul>

                                    ),
                                    rowExpandable: (record) => record?.resource?.extension?.find(o => o.url === "Medications")?.valueString,
                                    expandIcon: ({ expanded, onExpand, record }) =>
                                        expanded ? (
                                            <img src='assets/images/arrowdown.svg' onClick={e => onExpand(record, e)} />
                                        ) : (
                                            <img src='assets/images/arrowup.svg' onClick={e => onExpand(record, e)} />
                                        )
                                }}
                                dataSource={orderMedsDataList}
                                pagination={false}
                                className='order-grid'
                            />

                            <Modal
                                open={isAuditModalVisible}
                                title="Audit Log"
                                width={800}
                                onOk={() => setIsAuditModalVisible(false)}
                                onCancel={() => setIsAuditModalVisible(false)}
                                cancelButtonProps={{
                                    style: {
                                        display: "none"
                                    }
                                }}
                                maskClosable={false}
                            >
                                <div className="d-flex flex-column gap-5">
                                    <div className="d-flex flex-column gap-4">
                                        {/* <span className='font-weight-bold font18 mb-2'>Today</span> */}
                                        {auditDataLoading ? <Spin /> : repeatAuditLogData && repeatAuditLogData.length > 0 ?

                                            repeatAuditLogData.map((log, logIndex) => {
                                                return <div className="d-flex timeline-box align-items-center p-2 gap-3 mb-2">
                                                    <div className="d-flex jus audit-log-date-area">
                                                        <img src="assets/images/clock-audit.svg" className='mrgnlftrgt10' alt="" />
                                                        <span className="timeline-time ms-3 mr-3"> {logIndex === 0 ? moment(log?.InsertedAt?.replace("PM", " PM")?.replace("AM", " AM")).format("h:mm a") : moment(log?.UpdatedAt?.replace("PM", " PM")?.replace("AM", " AM")).format("h:mm a")}</span>
                                                    </div>
                                                    <div className="d-flex flex-column timline-content ps-3 justify-content-center pl-3">
                                                        <div className="">
                                                            {/* <span className="font-weight-bold">
                                                                Request Submitted:
                                                            </span> */}
                                                            <span className=''>{log?.Status === "Prescribed" ? `Request was prescribed by ${log?.UPddtt} at ${logIndex === 0 ?
                                                                moment(log?.InsertedAt?.replace("PM", " PM")?.replace("AM", " AM")).format("DD MMM YYYY h:mm:ss a") :
                                                                moment(log?.UpdatedAt?.replace("PM", " PM")?.replace("AM", " AM")).format("DD MMM YYYY h:mm:ss a")}` :

                                                                `Repeat request status was changed to ${log?.Status} by ${log?.UPddtt} at ${logIndex === 0 ?
                                                                    moment(log?.InsertedAt?.replace("PM", " PM")?.replace("AM", " AM")).format("DD MMM YYYY h:mm:ss a") :
                                                                    moment(log?.UpdatedAt?.replace("PM", " PM")?.replace("AM", " AM")).format("DD MMM YYYY h:mm:ss a")}`
                                                            }</span>
                                                        </div>
                                                        <div>
                                                            <span className="font-weight-bold">
                                                                Status:
                                                            </span>
                                                            <span className="status-color pl-1">{log?.Status}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            })
                                            :
                                            <div className='alert alert-danger p-2 text-center'>No Log Data</div>

                                        }


                
                
            </div>
        </div>
                            </Modal>

                        </InfiniteScroll>
                    </div>
                ) : !loadingGridData &&
                <div className='alert alert-danger p-2 text-center'>No Records Found</div>


            }
        </div>
    );
}


function mapStateToProps(state) {
    return {
        RecentMed: state.RecentMed
    }
}

function mapDispatchToProps(disptach) {
    return {
        getMed: (pageSize, pageNumber) => disptach(fetchRecentMedsFromApi(pageSize, pageNumber)),

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OrdersTab);

// export default OrdersTab
