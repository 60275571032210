import React, { useState } from 'react';
import { StyleSheet, Text, View, Image, Dimensions, ActivityIndicator } from 'react-native';
import { Modal, Button } from 'antd';
import 'antd/dist/antd.css';


import getUserId from "../../shared/core/GetToken"
import getRandomColor from '../../shared/core/generateRandomColor';
import Settings from '../../shared/config/Settings';
import { capitalize } from '../../shared/core/datehelper';
const ResourcesHandler = props => {

    const [visible, setVisible] = useState(false);
    const [isLoadingPdf, setIsLoadingPdf] = useState(false);
    const [show, setShow] = React.useState(false)
    const [checkFile, setCheckFile] = useState(false);
    const [pdfData, setPdfData] = useState(null);

    const [file, setFile] = React.useState()
    const checkItems = resourceInfo => {

        setVisible(true);
        getUserId().then((userInfo) => {
            setIsLoadingPdf(true)

            const item = JSON.parse(userInfo);


            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")



            var raw = JSON.stringify({
                PatientID: item.userid,
                ResourceID: resourceInfo
            })
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: raw
            };


            // fetch(Settings.apiUrl + "Patient/MediaByID?Token=" + item.token + "&PatientID=" + item.userid + "&ResourceID=" + resourceInfo, requestOptions)
            fetch(Settings.apiUrl + "Patient/MediaByID", requestOptions)
                .then(data => data.json())
                .then(json => {

                    setShow(visible => !visible);
                    setShow((state) => {
                        return state;
                    });

                    setFile('data:application/pdf;base64,' + json[0].entry[0].resource.content.data)
                    setFile((state) => {
                        return state;
                    });
                    setPdfData(json[0].entry[0].resource.content.data)
                  

                })
                .catch(error => setIsLoadingPdf(false))
                .finally(() => {
                    setCheckFile(false)
                    setIsLoadingPdf(false)

                });
        });

    }
    const getRandomTag = () => {
        var textArray = [
            'card-header text-white bg-danger',
            'card-header text-white bg-success',
            'card-header text-white bg-primary',
            'card-header text-white bg-info',
            'card-header text-white bg-warning',
        ];
        var randomNumber = Math.floor(Math.random() * textArray.length);
        return textArray[randomNumber]
        // audioElement.setAttribute('src', textArray[randomNumber]);
    }
    //responseType  <a href="#"><i className="fa fa-file-pdf-o text-danger" /></a>
    return (
        <>
            <div onClick={() => props.contentType === 'URL' ? window.open(props.contentFlow, '_blank') : checkItems(props.contentFlow)}  className="drive-item module text-center cursor-pointer">
                <div className="drive-item-inner module-inner">
                    <div className="drive-item-title" ><a href="#" className='text-black'>{capitalize(props.name)}</a></div>

                    <div className="drive-item-thumb">
                        <a href="#"><i className={props.responseType} /></a>
                    </div>
                </div>
                <div className="drive-item-footer module-footer">
                    <ul className="utilities list-inline">
                        <li><a href="#" data-toggle="tooltip" data-placement="top" title data-original-title="Download">View </a>{isLoadingPdf && <ActivityIndicator size="small" color="#00A1DE" />}</li>

                    </ul>
                </div>


               


            </div >

            <Modal
                    title="PDF File"
                    centered
                    open={visible}
                    onOk={() => setVisible(false)}
                    onCancel={() => setVisible(false)}
                afterClose={() => setPdfData(null)}
                className='modal-position'
                    width={1000}
                >
                    <div className="PDF" id="showPDF">

                    </div>
                    <p id="hee">
                        {isLoadingPdf && <div className='loading-container-reports' ><ActivityIndicator size="small" color="#00A1DE" /><Text>Loading. Please wait...</Text></div>}
                    </p>
                {!isLoadingPdf && pdfData && 
                <iframe src={"data:application/pdf;base64," + pdfData} width="100%" height="600px" title="PDF Viewer"></iframe>
                
                
                // <object id="pdfObject" data={"data:application/pdf;base64," + pdfData}
                //  type="application/pdf"></object>
                 
                 
                 }
                </Modal>


            {/* {show && (
                <DocumentPreview
                    show={show}
                    file={file}
                    onClose={() => setShow(false)}
                    toolbar={
                        <div>
                            <button className="btn btn-primary">
                                Download
                            </button>
                        </div>
                    }
                    footer={<div>my footer</div>}
                />
            )} */}
        </>


    );
}

export default ResourcesHandler
