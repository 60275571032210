import { Spin } from 'antd';
import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux';
import { getMessageByIdAPI } from "../../../../shared/actions/MessagingActions/ListMessagesById";
import MsgCard from '../MessageListingComp/MsgCard';
import InfiniteScroll from "react-infinite-scroll-component";
import { listDraftMessageAPI } from '../../../../shared/actions/MessagingActions/ListDraftMesssages';
import { actionAPI } from '../../../../shared/reduxToolkit/actionAPI';

const DraftMessages = (props, { type, isLoading, data }) => {
    const [isComposeModalOpen, setIsComposeModalOpen] = useState(false);
    const [draftMessages, setDraftMessages] = useState([]);

    const { draftMessageListing, draftMessageListingSuccess, draftMessageListingLoading } = useSelector(state => state.DraftMessagesList)
    const { sendMessage, sendMessageSuccess } = useSelector(state => state.SendingMessage)

    const [totalRecords, setTotalRecords] = useState(10)
    let limit = 10
    let offset = 1
    const { deleteMessage, deleteMessageSuccess } = useSelector(state => state.DeleteMessage)
    const apiDispatcher = useDispatch()
    const showComposeModal = () => {
        setIsComposeModalOpen(true)
    }
    const getMsgProfileColor = (index) => {
        let colorList = ["#ffa358", "#f65747", "#47b2f6"]
        let colorIndex = index
        if (colorIndex > (colorList.length - 1)) {
            colorIndex = index % colorList.length
        }
        return colorList[colorIndex]
    }

    const getDraftMessagesAPI = () => {

        limit = 25;
        offset = Math.ceil(draftMessages.length / limit) + 1;
        if (offset == 1) {
            setDraftMessages([])
        }
        apiDispatcher(actionAPI.ListDraftMessagesAPI({
            type: 1, messagingType: 3, pageNumber: offset, pageSize: limit, readFilter: 0
        }))
    };
    const getDraftMessagesFromStartAPI = () => {
        limit = 25;
        offset = 1;

        apiDispatcher(actionAPI.ListDraftMessagesAPI({
            type: 1, messagingType: 3, pageNumber: offset, pageSize: limit, readFilter: 0
        }))
    };
    useEffect(() => {
        getDraftMessagesAPI()
    }, [])
    useEffect(() => {
        if (draftMessageListingSuccess && draftMessageListing) {
            setTotalRecords(draftMessageListing[0].total)
            if (draftMessageListing[0]?.entry) {
                const mergeData = [...draftMessages, ...draftMessageListing[0]?.entry];
                setDraftMessages(mergeData);

            }

        }
    }, [draftMessageListing])
    const fetchmoreDraftData = (ignore = false) => {

        if (ignore || draftMessages.length < totalRecords) {
            getDraftMessagesAPI();
        }
    }
    useEffect(() => {
        if (deleteMessageSuccess) {
            setDraftMessages([])
            getDraftMessagesFromStartAPI()
        }
    }, [deleteMessageSuccess])
    useEffect(() => {
        if (sendMessageSuccess) {
            setDraftMessages([])
            getDraftMessagesFromStartAPI()
        }
    }, [sendMessageSuccess])
    useEffect(() => {
        if (props.refreshTrigger > 0 && draftMessages.length > 0) {

            setDraftMessages([])
            getDraftMessagesFromStartAPI()
        }
    }, [props.refreshTrigger])
    return (
        <>
            <div className="msgList">

                <div id="scrollableDraftDiv" className='w-100 h-100 msgList-scrollable-div'>
                    {draftMessageListing != null && draftMessages.length == 0 && !draftMessageListingLoading && <div className="d-flex justify-content-center mt-3 text-secondary">Draft is empty</div>}
                    <InfiniteScroll
                        dataLength={draftMessages.length}
                        next={fetchmoreDraftData}
                        hasMore={draftMessages.length < totalRecords}
                        loader={<div className='d-flex justify-content-center my-2'><Spin /></div>}

                        scrollableTarget="scrollableDraftDiv"
                    >

                        {draftMessages.length > 0 ? draftMessages.map((message, index) => (
                            <MsgCard
                                handleCheckChanged={() => props.handleCheckChanged(message)}
                                msgChecked={props.selectAllMsgsChecked}
                                key={message.resource.id}
                                sender={message?.resource?.extension[3]?.valueString}
                                subject={message?.resource?.note ? message?.resource?.note[0]?.text : ""}
                                date={
                                    message?.resource?.sent?.slice(8, 10) +
                                    "-" +
                                    message?.resource?.sent?.slice(5, 7) +
                                    "-" +
                                    message?.resource?.sent?.slice(0, 4)
                                }
                                DMSID={
                                    message?.resource?.extension[9]?.valueDateTime || message?.resource?.extension[9]?.valueDateTime !== ""
                                }
                                imgSrc={null}
                                isRead={
                                    true
                                }
                                onSelect={() => {
                                    localStorage.setItem("activeMessage", JSON.stringify(message));
                                    props.setActiveMessage(message);
                                    apiDispatcher(actionAPI.GetMessageByIdAPI({
                                        messageId: message.resource.id, messageNum: (index + 1)
                                    }))
                                }}
                                isActive={
                                    localStorage.getItem("activeMessage") &&
                                        JSON.parse(localStorage.getItem("activeMessage")).resource.id ===
                                        message.resource.id
                                        ? true
                                        : false
                                }
                                profileColor={getMsgProfileColor(index)}

                            />
                        )) : ""}
                    </InfiniteScroll>
                </div>
            </div>
            {/* <div
                className="composeMsgIcon d-flex align-items-center justify-content-center"
                onClick={showComposeModal}
            >
                <svg
                    id="Plus_-_Free_shapes_and_symbols_icons-2_1_"
                    data-name="Plus - Free shapes and symbols icons-2 (1)"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 39.615 39.615"
                >
                    <g id="Group_2985" data-name="Group 2985">
                        <path
                            id="Path_3744"
                            data-name="Path 3744"
                            d="M36.133,16.326H23.289V3.482a3.482,3.482,0,1,0-6.964,0V16.326H3.482a3.482,3.482,0,1,0,0,6.964H16.326V36.133a3.482,3.482,0,1,0,6.964,0V23.289H36.133a3.482,3.482,0,1,0,0-6.964Z"
                            fill="#fff"
                        />
                    </g>
                </svg>
            </div>
            {isComposeModalOpen && (
                <ComposeMsgModal
                    handleCancel={() => { setIsComposeModalOpen(false) }}
                    handleOk={() => { setIsComposeModalOpen(false) }}
                    isComposeModalOpen={isComposeModalOpen}
                />)} */}
        </>
    )
}

function mapStateToProps(state) {
    return {

        messageByID: state.MessageByIdReducer,
        DraftMessages: state.DraftMessagesReducer,
        DeleteMessage: state.DeleteMessagesReducer,
        SendingMessage: state.SendingMessagesReducer,

    }
}
function mapDispatchToProps(disptach) {
    return {
        listDraftMessages: (values) => disptach(listDraftMessageAPI(values)),
        getMsgByID: (values) => disptach(getMessageByIdAPI(values)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DraftMessages)
// export default
