import { Button } from 'antd'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const PaymentCallback = () => {
    const navigate = useNavigate()
    const navigateBack = () => {
        function parseQuery(queryString) {
            const params = {};
            const queryStringWithoutQuestionMark = queryString.substring(1);
            const queryParams = queryStringWithoutQuestionMark.split("&");
            queryParams.forEach((param) => {
                const [key, value] = param.split("=");
                params[key] = decodeURIComponent(value);
            });
            return params;
        }


        const queryString = window.location.search;
        let { IsRxRequest } = parseQuery(queryString);

        if (IsRxRequest) {
            navigate("/medications")
        } else {
            navigate("/accounts")

        }


    }
    // useEffect(() => {
    //     setTimeout(() => {
    //         navigateBack()
    //     }, 2000)
    // }, [])
    return (
        <div className='w-100 d-flex flex-column align-items-center payment-returnpage-main-div'>
            <img className='mb-5' src="assets/images/failed.svg" />
            <h2 className=''>Callback</h2>
            <button className="payment-returnpage-btn mt-5" onClick={() => { navigateBack() }}>Continue</button>
        </div>
    )
}

export default PaymentCallback
