import AsyncStorage from "@react-native-async-storage/async-storage";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Settings from "../../../config/Settings";
import getUserId from "../../../core/GetToken";

export const GetProxyMenuAPI = createAsyncThunk(
    "proxyMenu/Portal",
    async (values, { dispatch, getState }) => {
        dispatch(gettingProxyMenu())
        getUserId().then((userInfo) => {

            const item = JSON.parse(userInfo);


            var myHeaders = new Headers();
            myHeaders.append("Token", item.token);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "UserLoggedID": item.parentid,
                "ProxyID": item.proxyid
            });


            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(Settings.apiUrl + "Patient/PatientMenu", requestOptions)
                .then(data => data.json())
                .then(json => {

                    json[0].hasOwnProperty('entry') ? dispatch(gettingProxyMenuSuccess(json[0].entry)) : dispatch(null)
                })
                .catch(err => dispatch(gettingProxyMenuFailed(err)))



        })
    }

);

const GetProxyMenuSlice = createSlice({
    name: "proxyMenu",
    initialState: {
        ProxyMenu: [],
        isLoadingMenu: false,
        errorMenu: false
    },
    reducers: {
        gettingProxyMenu: (state, action) => {
            state.isLoadingMenu = true
            state.errorMenu = false
        },
        gettingProxyMenuSuccess: (state, action) => {
            state.ProxyMenu = action.payload
            state.isLoadingMenu = false
            state.errorMenu = false
        },
        gettingProxyMenuFailed: (state, action) => {
            state.ProxyMenu = []
            state.isLoadingMenu = false
            state.errorMenu = true

        },

    },
});




export const {
    gettingProxyMenu, gettingProxyMenuFailed, gettingProxyMenuSuccess
} = GetProxyMenuSlice.actions;
export default GetProxyMenuSlice.reducer;
