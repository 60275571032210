import AsyncStorage from "@react-native-async-storage/async-storage";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Settings from "../../../config/Settings";
import getUserId from "../../../core/GetToken";

export const AddPatientAppoinmenttoAPI = createAsyncThunk(
    "addPaitentAppoinment/Appointment",
    async (values, { dispatch, getState }) => {

        dispatch(addAppoint())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            // token = data
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = {
                PatientID: values.PaitentID != '' ? values.PaitentID : item.userid,
                AppointmentTypeID: values.typeid != '' ? values.typeid : null,
                SlotID: values.slotid,
                Reason: values.reason,
                ReasonForVisitID: values.reasonId,
                PatientID2: item.userid,
                StartTime: values.startTime,
                EndTime: values.endTime,
                Duration: values.duration

            }
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(raw)
            };

            fetch(Settings.apiUrl + "Patient/AppointmentV2", requestOptions)
                .then(data => data.json())
                .then(json => {
                    if (json == "Appointment has been booked successfully")
                        dispatch(addAppointSuccess(json))
                    else {
                        dispatch(addAppointFailed(json))
                    }
                })
                .catch(err => dispatch(addAppointFailed(err)))


        })

    }

);
export const AddPatientAppointmentRefreshData = createAsyncThunk(
    "addPaitentAppoinment/Appointment",
    async (values, { dispatch, getState }) => {
        dispatch(addAppointRefreshData())
    }
)
const AddPatientAppointmentSlice = createSlice({
    name: "addPaitentAppoinment",
    initialState: {
        isAdding: false,
        AddAppointment: '',
        error: false,
        isAvailable: false,
        AddingFailed: false,
    },
    reducers: {
        addAppoint: (state, action) => {
            state.isAdding = true
            state.AddAppointment = ""
            state.error = false
            state.isAvailable = false
            state.AddingFailed = false

        },
        addAppointSuccess: (state, action) => {
            state.isAdding = false
            state.AddAppointment = action.payload
            state.error = false
            state.isAvailable = true
            state.AddingFailed = false

        },
        addAppointRefreshData: (state, action) => {
            state.isAdding = false
            state.AddAppointment = ''
            state.error = false
            state.isAvailable = false
            state.AddingFailed = false

        },
        addAppointFailed: (state, action) => {
            state.isAdding = false
            state.AddAppointment = action.payload
            state.error = true
            state.isAvailable = false
            state.AddingFailed = true

        },

    },
});




export const {
    addAppoint, addAppointFailed, addAppointSuccess, addAppointRefreshData
} = AddPatientAppointmentSlice.actions;
export default AddPatientAppointmentSlice.reducer;
