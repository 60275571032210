import AsyncStorage from "@react-native-async-storage/async-storage";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Settings from "../../../config/Settings";
import getUserId from "../../../core/GetToken";

export const GetReasonsForVisitFromAPI = createAsyncThunk(
    "getReasonsForVisit/Appointment",
    async (values, { dispatch, getState }) => {

        dispatch(getReasonsForVisit())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = {
                PatientID: item.userid,
                TypeID: 13,
            }
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(raw)
            };

            fetch(`${Settings.apiUrl}IndiciLookup`, requestOptions)
                .then(response => response.json())
                .then(result => {

                    dispatch(getReasonsForVisitSuccess(result[0].entry))
                })
                .catch(error => dispatch(getReasonsForVisitFailed()));
            // const queryString = '?PatientID=' + item.userid + '&PageSize=' + pageSize + '&PageNumber=' + pageNumber + '&TransactionTypeID=0'
            // getUserInvoice(queryString, myHeaders)
            //     .then(data => data.json())
            //     .then(json => {

            //         json[0].hasOwnProperty('entry') ? dispatch(getAllInvoiceSuccess(json[0].entry)) : dispatch(getAllInvoiceFinsihed())
            //     })
            //     .catch(err => dispatch(getAllInvoiceFail(err)))
        })

    }

);

const GetReasonsForVisitSlice = createSlice({
    name: "getReasonsForVisit",
    initialState: {
        LookUpList: [],
        isFetchingLookup: false,
        error: false
    },
    reducers: {
        getReasonsForVisit: (state, action) => {
            state.LookUpList = []
            state.isFetchingLookup = true
            state.error = false
        },
        getReasonsForVisitSuccess: (state, action) => {
            state.LookUpList = action.payload
            state.isFetchingLookup = false
            state.error = false

        },
        getReasonsForVisitFailed: (state, action) => {
            state.LookUpList = []
            state.isFetchingLookup = false
            state.error = true
        },
        getReasonsForVisitFinished: (state, action) => {
            state.LookUpList = []
            state.isFetchingLookup = false
            state.error = false
        },

    },
});




export const {
    getReasonsForVisit, getReasonsForVisitFailed, getReasonsForVisitSuccess, getReasonsForVisitFinished
} = GetReasonsForVisitSlice.actions;
export default GetReasonsForVisitSlice.reducer;
