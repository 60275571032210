import { connect } from "react-redux";
import { identityData } from "../src/shared/reducers/IdentityServer"
import store from "./configureStore"
import Settings from "./shared/config/Settings";

export const { fetch: originalFetch } = window;
window.fetch = async (...args) => {
  let [resource, config] = args;
  let response = await originalFetch(resource, config);

  if (!response.ok && (response.status === 401) && response.url.indexOf("Logout") == -1 && response.url.indexOf("Authentica") == -1) {
    window.location.replace("/login")
    return Promise.reject(response);
  }
  return response;
};
