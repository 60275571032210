import { GET_APPOINTMENT_TYPE, GET_APPOINTMENT_TYPE_SUCCESS, GET_APPOINTMENT_TYPE_FAILED } from "../constants/Constant";
import getPaitentAppointments from "../services/AppointmentServices";
import getUserId from "../core/GetToken";
import Settings from "../config/Settings";


export const fetchAppointmentsTypesFromApi = (pageSize, pageNumber) => {
    return (dispatch) => {
        dispatch(getAppointTypes())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = {
                PatientID: item.userid,
                TypeID: 12,
            }
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(raw)
            };


            fetch(Settings.apiUrl + "IndiciLookup", requestOptions)
                .then(response => response.json())
                .then(json => {
                    json[0].hasOwnProperty('entry') ? dispatch(getAppointTypesSucess(json[0].entry)) : dispatch(getAppointTypesFail())
                })
                .catch(err => dispatch(getAppointTypesFail(err)))
           
        })
    }
}



const getAppointTypes = () => {
    return {
        type: GET_APPOINTMENT_TYPE
    }
}

//data success
const getAppointTypesSucess = (data) => {
    return {
        type: GET_APPOINTMENT_TYPE_SUCCESS,
        data,
    }
}




//data failed
const getAppointTypesFail = () => {
    return {
        type: GET_APPOINTMENT_TYPE_FAILED
    }
}