import { Spin, notification } from "antd";
import Tooltip from "antd/es/tooltip";
import React, { useState, useEffect } from "react";
import messageImage from '../../assets/message_image.png';

import JpgFileComp from "./MessageAttachments/JpgFileComp";
import PdfFileComp from "./MessageAttachments/PdfFileComp";
import DeleteConfirmModal from "./Modals/DeleteConfirmModal";
import ReplyMsgModal from "./Modals/ReplyMsgModal";
import parse from 'html-react-parser';
import { deleteMessageAPI, deleteMessageAPIRefresher } from "./../../../shared/actions/MessagingActions/DeleteMessages";
import { connect, useDispatch, useSelector } from "react-redux";
import { listDraftMessageAPI } from "./../../../shared/actions/MessagingActions/ListDraftMesssages";
import { listInboxMessageAPI } from "./../../../shared/actions/MessagingActions/ListInboxMessages";
import { listSendMessageAPI } from "./../../../shared/actions/MessagingActions/ListSendMessages";
import { sendMessageAPI } from "./../../../shared/actions/MessagingActions/SendingMessages";
import SendConfirmModal from "./Modals/SendConfirmModal";
import ComposeMsgModal from "./Modals/ComposeMsgModal";
import DraftEditMsgModal from "./Modals/DraftEditMsgModal";
import getUserId from "../../../shared/core/GetToken";
import Settings from "../../../shared/config/Settings";
import moment from "moment";
import { clearMessageByIdData } from "../../../shared/actions/MessagingActions/ListMessagesById";
import { fetchCounterFromApi } from "../../../shared/actions/CounterActions";
import GetAppSettings from "../../../shared/core/GetAppSettings";
import DocxFileComp from "./MessageAttachments/DocxFileComp";
import HtmlFileComp from "./MessageAttachments/HtmlFileComp";
import sanitize from "sanitize-html";
import { actionAPI } from "../../../shared/reduxToolkit/actionAPI";


const MessageContent = (props) => {
  const { inboxMessageListing, inboxMessageListingSuccess, inboxMessageListingLoading } = useSelector(state => state.InboxMessagesList)
  const { sendMessageListing, sendMessageListingSuccess, sendMessageListingLoading } = useSelector(state => state.SendMessagesList)
  const { draftMessageListing, draftMessageListingSuccess, draftMessageListingLoading } = useSelector(state => state.DraftMessagesList)
  const { messageData, messageDataLoading, messageDataSuccess } = useSelector(state => state.GetMessageById);
  const [isDeleted, setIsDeleted] = useState(false)
  const [portalSetting, setPortalSetting] = useState(false)
  const [isReplyModalOpen, setIsReplyModalOpen] = useState(false)
  const [isSendModalOpen, setIsSendModalOpen] = useState(false)
  const [isEditDraftModalOpen, setIsEditDraftModalOpen] = useState(false)
  const [isDeleteMsgModalOpen, setIsDeleteMsgModalOpen] = useState(false)
  const [msgSubject, setMsgSubject] = useState("")
  const [isSendingMessage, setIsSendingMessage] = useState(false)
  const [messageNumber, setMessageNumber] = useState(0)
  const [totalMessage, setTotalMessage] = useState(0)
  const [isMsgFromSystem, setIsMsgFromSystem] = useState(false)
  const [loadOnlyAvailableProvidersData, setLoadOnlyAvailableProvidersData] = useState()
  const { deleteMessage, deleteMessageLoading, deleteMessageSuccess } = useSelector(state => state.DeleteMessage)
  const apiDispatcher = useDispatch()
  const handleDeleteClick = () => {
    if (messageData.length > 0) {
      apiDispatcher(actionAPI.DeleteMessageAPI({ messageID: messageData[0]?.entry[0]?.resource?.id }))
    }
  }
  const checkProviderAvailable = (ProviderId, fromCompose = 1) => {
    getUserId().then((userInfo) => {
      const item = JSON.parse(userInfo);
      var myHeaders = new Headers();
      myHeaders.append("Token", item.token);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        ProviderID: ProviderId?.replace("Practitioner/", "")?.replace("Person/", "")?.replace("Patient/", ""),
        PatientID: item.userid,
        FromCompose: fromCompose

      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
        body: raw,

      };
      fetch(Settings.apiUrl + "/Patient/LoadOnlyAvaliableProviders", requestOptions)
        .then(data => data.json())
        .then(json => {
          setLoadOnlyAvailableProvidersData(json)


        })
        .catch(err => console.log(err))
    })
  }


  useEffect(() => {
    setIsDeleted(false)

    if (messageDataSuccess) {
      setMsgSubject(messageData[0].entry[0].resource.note ? messageData[0].entry[0].resource.note[0].text : "")
      setMessageNumber(messageData[0].total)
      if (messageData[0].entry[0].resource.extension[1].valueString == "Inbox") {

        setTotalMessage(inboxMessageListing != null ? inboxMessageListing[0].total : 0)
      }
      else if (messageData[0].entry[0].resource.extension[1].valueString == "Drafts") {
        setTotalMessage(draftMessageListing ? draftMessageListing[0].total : 0)
      }
      else {
        setTotalMessage(sendMessageListing ? sendMessageListing[0].total : 0)
      }
      let msgSenderName = messageData[0].entry?.map(
        (value) => value.resource.sender.display)
      if (msgSenderName[0] && msgSenderName[0].includes("Super Admin")) {
        setIsMsgFromSystem(true)
      } else {

        setIsMsgFromSystem(false)
      }
      checkProviderAvailable(messageData[0].entry[0].resource.extension[1].valueString == "Drafts" ? messageData[0].entry[0].resource?.recipient[0]?.reference : messageData[0].entry[0].resource?.sender?.reference,
        messageData[0].entry[0].resource.extension[1].valueString == "Inbox" ? 0 : 1
      )
    }
  }, [messageDataLoading])
  useEffect(() => {

    apiDispatcher(actionAPI.RefreshCounterAPI())

  }, [messageData])
  useEffect(() => {
    if (deleteMessageSuccess) {

      setIsDeleted(true)
      openNotification(deleteMessage, "success")
      props.setMsgsRefreshTrigger(props.msgsRefreshTrigger + 1)
    }
  }, [deleteMessageLoading])
  const openNotification = (msg, type) => {

    notification[type]({
      message: msg,

    });
  };

  const reverseFromatMsgBody = (valueString) => {
    if (!valueString) {
      return ""
    }
    let newStr = valueString.replaceAll("<br>", " \r\n ")
    newStr = newStr.replaceAll("<p", " \r\n <p ")
    newStr = newStr.replaceAll("</p>", " </p> \r\n ")
    newStr = newStr.replaceAll("&nbsp;", " ")
    newStr = newStr.replace(/<[^>]+>/g, '')
    return newStr
  }

  const allowedTags = ['p', 'strong', 'em', 'a', 'ul', 'ol', 'li', 'br', 'hr', 'div']; // Add more tags as needed

  const sanitizeOptions = {
    allowedTags,
    allowedAttributes: {
      a: ['href'],
    },
  };
  const formatMsgBody = (valueString) => {
    if (!valueString) {
      return ""
    }
    let newStr = valueString.replaceAll("\r\n", " <br /> ")
    newStr = newStr.replaceAll("\n", " <br /> ")
    newStr = newStr.replaceAll("<br>", " <br> ")
    let strArr = []
    if (newStr.indexOf("http") >= 0) {
      strArr = newStr.split(" ")
      strArr.map((word, index) => {
        if (word.indexOf("http") === 0) {
          strArr[index] = `<a target="_blank" href="${word}">${word}</a>`
        }
      })
      newStr = strArr.join(" ")
    } else {
      newStr = valueString
    }
    newStr = newStr.replaceAll("\r\n", "<br/>")
    newStr = newStr.replaceAll("\n", "<br/>")

    return newStr
  }
  const sendMessage = (values) => {

    getUserId().then((userInfo) => {
      const item = JSON.parse(userInfo);


      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      myHeaders.append("Token", item.token);
      var raw = JSON.stringify({
        resourceType: "Communication",
        extension: [
          {
            url: "MessageBody",
            valueString: encodeURIComponent(values.message.body),
          },
          {
            url: "MessageType",
            valueString: values.messageType,
          },

        ],
        status: "completed",
        recipient: [
          {
            reference: `${values.practitioner.id}`,
            display: values.practitioner.name,
          },
        ],
        sender: {
          reference: `Patient/${item.userid}`,
          display: `${item.username}`,
        },
        payload: values.selectedFiles,
        note: [
          {
            text: values.message.subject,
          },
        ],
      });
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };
      fetch(Settings.apiUrl + `Patient/Message?RoleID=${values.roleID}&CallType=3&IsWeb=true`, requestOptions)

        .then(data => data.json())
        .then(json => {

          var myHeaders = new Headers();
          myHeaders.append("Token", item.token);
          myHeaders.append("Content-Type", "application/json");

          var requestOptions = {
            method: 'DELETE',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({
              PatientID: item.userid,
              MessageID: messageData[0].entry[0].resource.id,
            })
          };

          fetch(Settings.apiUrl + "Patient/DeleteMessage", requestOptions)

            .then(data => data.json())
            .then(json => {

              openNotification(values.messageType == "Draft" ? "Message Saved to Draft" : "Message Sent Successfully", "success")
              props.setMsgsRefreshTrigger(props.msgsRefreshTrigger + 1)
              setIsDeleted(true)
              setIsSendingMessage(false)
              setIsSendModalOpen(false)
              props.setActiveMessage(null)


            })
            .catch(err => { })


        })
        .catch(err => { })

    })
  }
  const handleSendClick = () => {

    const values = {
      practitioner: {
        id: messageData[0].entry[0].resource.recipient[0].reference,
        name: messageData[0].entry[0].resource.recipient[0].display,
      },
      message: {
        subject: messageData[0].entry[0].resource?.note ? messageData[0].entry[0].resource?.note[0]?.text : "",
        body: messageData[0].entry[0].resource?.extension[0]?.valueString,
      },
      selectedFiles: messageData[0].entry[0].resource.payload,
      messageType: "Send Mail",
      roleID: messageData[0].entry[0].resource.extension[8].valueDateTime,
    };
    sendMessage(values)
  };
  useEffect(() => {
    apiDispatcher(actionAPI.RefreshMessageByIdData())
    GetAppSettings().then((info) => {
      const set = JSON.parse(info)
      setPortalSetting(set)
    })
  }, [])
  return (
    <>
      {messageDataLoading ? <>
        <Spin />
        <p className="text-secondary">Loading Message</p>
      </> :
        messageData && !isDeleted ? (
          <div className="w-100 h-100 messages-inertal-content">
            <img src='assets/images/back-to-mail.svg' className='email-back-icon' onClick={() => props.setActiveMessage(null)} />
            <div className="msgHeader d-flex align-items-center row">
              <p className="col-9 pr-5">
                {messageData[0].entry?.map(
                  (value) => value?.resource && value?.resource?.note ? value?.resource?.note[0]?.text : ""
                )}
              </p>
              <p className="col-3 fw-normal font-size-13px text-align-end">
                selected <b>{messageNumber}</b> of {totalMessage}
              </p>
            </div>

            <div className="msgBody">
              <div className="msgSubHeader p-2 d-flex justify-content-between align-items-center flex-wrap whitesmokeheader">

                <div className="profile d-flex align-items-start">
                  <div className="profile-logo">
                    <span className="profile-initial">
                      {messageData && messageData[0].entry[0].resource.extension[1].valueString == "Inbox" ? messageData[0].entry?.map(
                        (value) => value.resource.sender.display[0]
                      ) : messageData[0].entry?.map(
                        (value) => value?.resource?.recipient[0]?.display[0]
                      )}
                    </span>
                  </div>
                  <div className="rest-details">

                    <span className="profileName">
                      {messageData[0].entry[0].resource.extension[1].valueString == "Inbox" ? isMsgFromSystem ? "System" : messageData[0].entry?.map(
                        (value) => value.resource.sender.display
                      ) : messageData[0].entry?.map(
                        (value) => value?.resource?.recipient[0]?.display
                      )}
                    </span>
                    {/* <p className="profile-subject text-primary">
      {messageData[0].entry?.map(
        (value) => value.resource.note[0].text
      )}
    </p> */}
                    <p className="dateTime mb-0">
                      <span className="date">
                        {moment(messageData[0].entry[0].resource.sent).format("llll")}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="timeDateReply d-flex justify-self-end">

                  <Tooltip title="Delete">

                    <div className="deleteBtn replyBtn mesg-reply-btn" onClick={() => setIsDeleteMsgModalOpen(true)} >
                      <img src="assets/images/Delete.svg" />
                      {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z" /></svg> */}
                    </div>
                  </Tooltip>

                  {!portalSetting.DisableMessaging && messageData && messageData[0]?.entry[0]?.resource?.extension[1]?.valueString === "Inbox" && !isMsgFromSystem ?
                    <Tooltip title="Reply">

                      <div className="replyBtn reply-button" onClick={() => {
                        if (loadOnlyAvailableProvidersData && loadOnlyAvailableProvidersData.length > 0) {

                          if ((loadOnlyAvailableProvidersData[0]?.ProfileTypeID === -99)) {

                            setIsReplyModalOpen(true)
                            return false
                          }
                          if (loadOnlyAvailableProvidersData[0]?.IsLeave) {
                            openNotification("As per practice policy, you cannot reply as provider is on leave", "error")
                            return false
                          }
                          if (loadOnlyAvailableProvidersData[0]?.IsDefaultGp && loadOnlyAvailableProvidersData[0].ProviderID === null) {
                            openNotification("As per practice policy, this provider is not configured", "error")
                            return false
                          }
                          else {
                            setIsReplyModalOpen(true)

                          }
                        } else {
                          openNotification("As per practice policy, this provider is not configured", "error")
                          return false

                        }
                      }
                      } >
                        <img src="assets/images/reply.svg" />
                        {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 14">
          <g
            id="Reply_-_Free_interface_icons-2"
            data-name="Reply - Free interface icons-2"
            transform="translate(-3 -5)"
          >
            <path
              id="Path_4047"
              data-name="Path 4047"
              d="M21,18a.961.961,0,0,1-.3.7.924.924,0,0,1-.7.3,1.019,1.019,0,0,1-1-1V14a2.225,2.225,0,0,0-.246-1.07,1.492,1.492,0,0,0-.684-.684A2.225,2.225,0,0,0,17,12H4a1.019,1.019,0,0,1-1-1,.924.924,0,0,1,.3-.7A.96.96,0,0,1,4,10H17a3.834,3.834,0,0,1,2,.544A3.786,3.786,0,0,1,20.456,12,3.834,3.834,0,0,1,21,14ZM8.3,5.3a.975.975,0,0,1,1.4,0,.975.975,0,0,1,0,1.4L4.807,11.6q-.053.07-.088-.263a4.306,4.306,0,0,1-.018-.667q.035-.333.105-.263L9.7,15.3a.89.89,0,0,1,.3.7.96.96,0,0,1-.3.7.975.975,0,0,1-1.4,0L3.4,11.807a1.144,1.144,0,0,1,0-1.614Z"
              fill="#4b4b4b"
            />
          </g>
        </svg> */}
                      </div>
                    </Tooltip>
                    : null}

                  {false && messageData && messageData[0]?.entry[0]?.resource?.extension[1]?.valueString === "Drafts" ?
                    <Tooltip title="Send Message">

                      <div className="replyBtn" onClick={() => {

                        if (loadOnlyAvailableProvidersData?.length > 0) {
                          if (!loadOnlyAvailableProvidersData[0].IsLeave) {

                            setIsSendModalOpen(true)
                          } else {
                            openNotification("As per practice policy, you cannot send this message as provider is on leave.", "error")

                          }
                        } else {
                          openNotification("As per practice policy, this provider is not available in messaging list.", "error")
                        }
                      }}>
                        <img src='assets/images/SendMessage.svg' alt='Send Message' />
                        {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M498.1 5.6c10.1 7 15.4 19.1 13.5 31.2l-64 416c-1.5 9.7-7.4 18.2-16 23s-18.9 5.4-28 1.6L284 427.7l-68.5 74.1c-8.9 9.7-22.9 12.9-35.2 8.1S160 493.2 160 480V396.4c0-4 1.5-7.8 4.2-10.7L331.8 202.8c5.8-6.3 5.6-16-.4-22s-15.7-6.4-22-.7L106 360.8 17.7 316.6C7.1 311.3 .3 300.7 0 288.9s5.9-22.8 16.1-28.7l448-256c10.7-6.1 23.9-5.5 34 1.4z" /></svg> */}
                      </div>
                    </Tooltip>

                    : null}
                  {messageData && messageData[0]?.entry[0]?.resource?.extension[1]?.valueString === "Drafts" ?
                    <Tooltip title="Edit Message">

                      <div className="editBtn" onClick={() => setIsEditDraftModalOpen(true)}>
                        <img src='assets/images/Edit.svg' alt='Edit' />
                        {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M373.1 24.97C401.2-3.147 446.8-3.147 474.9 24.97L487 37.09C515.1 65.21 515.1 110.8 487 138.9L289.8 336.2C281.1 344.8 270.4 351.1 258.6 354.5L158.6 383.1C150.2 385.5 141.2 383.1 135 376.1C128.9 370.8 126.5 361.8 128.9 353.4L157.5 253.4C160.9 241.6 167.2 230.9 175.8 222.2L373.1 24.97zM440.1 58.91C431.6 49.54 416.4 49.54 407 58.91L377.9 88L424 134.1L453.1 104.1C462.5 95.6 462.5 80.4 453.1 71.03L440.1 58.91zM203.7 266.6L186.9 325.1L245.4 308.3C249.4 307.2 252.9 305.1 255.8 302.2L390.1 168L344 121.9L209.8 256.2C206.9 259.1 204.8 262.6 203.7 266.6zM200 64C213.3 64 224 74.75 224 88C224 101.3 213.3 112 200 112H88C65.91 112 48 129.9 48 152V424C48 446.1 65.91 464 88 464H360C382.1 464 400 446.1 400 424V312C400 298.7 410.7 288 424 288C437.3 288 448 298.7 448 312V424C448 472.6 408.6 512 360 512H88C39.4 512 0 472.6 0 424V152C0 103.4 39.4 64 88 64H200z" /></svg> */}
                      </div>
                    </Tooltip>

                    : null}

                </div>
              </div>

              <div className="msgText h-100">
                {/* <div dangerouslySetInnerHTML={{ __html: 
              messageData[0].entry?.map(
                (value) => value.resource.extension[0].valueString
                )
              }} /> */}
                {parse(sanitize(formatMsgBody(messageData[0].entry[0]?.resource.extension[0].valueString), {

                  allowedSchemes: ['http', 'https', 'ftp', 'mailto', 'tel']
                })
                )}

              </div>
              <div className="msgAttachments">

                {messageData[0].entry[0]?.resource.payload && <div className="msgAttach">
                  <h6>Attachments</h6>
                  <div className="d-flex flex-wrap gap-10">

                    {messageData[0].entry[0]?.resource.payload ? messageData[0].entry[0]?.resource?.payload?.map((value, index) => {
                      return value.contentAttachment.contentType.toLowerCase() === "pdf" ?
                        <PdfFileComp pdfData={value?.contentAttachment?.data} attachNumber={index + 1} /> : <JpgFileComp jpgData={value?.contentAttachment?.data} attachNumber={index + 1} />
                    }) : "No Attachments Available"
                    }
                  </div>
                </div>}
              </div>

              {/* <div className="lastBtns">
              <div className="options">
                <button className="btn btn-delete" onClick={handleDeleteClick}>Delete</button>
                {messageData[0]?.entry[0].resource.extension[1].valueString === "Drafts" && <button className="btn btn-edit">Edit</button>}
              </div>
            </div> */}
            </div>
          </div>
        ) : <div className="text-center"><img className="w-50 opacity-zero-6" src={messageImage} alt="message-image" /></div>}
      {messageData ? <ReplyMsgModal
        isReplyModalOpen={isReplyModalOpen}
        handleCancel={() => setIsReplyModalOpen(false)}
        provider={messageData[0]?.entry[0]?.resource?.sender?.display}
        providerRef={messageData[0]?.entry[0]?.resource?.sender?.reference}
        replySubject={messageData[0]?.entry[0]?.resource?.note ? messageData[0]?.entry[0]?.resource?.note[0]?.text : ""}
        oldMessageBody={sanitize(messageData[0].entry[0]?.resource.extension[0].valueString, sanitizeOptions)
        }
        roleID={parseInt(messageData[0].entry[0].resource.extension[7].valueDateTime)}
        disclaimerMsg={props.disclaimerMsg}
        providerAvailable={loadOnlyAvailableProvidersData}
        msgID={messageData[0]?.entry[0]?.resource?.id}
      /> : null}
      {messageData ? <DeleteConfirmModal
        isModalOpen={isDeleteMsgModalOpen}
        handleCancel={() => setIsDeleteMsgModalOpen(false)}
        handleOk={() => {
          handleDeleteClick()
          setIsDeleteMsgModalOpen(false)
          props.setActiveMessage(null)
        }}
      /> : null}
      {messageData ? <SendConfirmModal
        isModalOpen={isSendModalOpen}
        handleCancel={() => setIsSendModalOpen(false)}
        handleOk={() => {
          setIsSendingMessage(true)
          handleSendClick()
        }}
        loading={isSendingMessage}
      /> : null}
      {messageData ? <DraftEditMsgModal
        setMsgsRefreshTrigger={props.setMsgsRefreshTrigger}
        msgsRefreshTrigger={props.msgsRefreshTrigger}
        isModalOpen={isEditDraftModalOpen}
        handleCancel={() => setIsEditDraftModalOpen(false)}
        messageID={messageData[0].entry[0].resource.id}
        msgContentSubject={messageData[0].entry?.map(
          (value) => value.resource.note ? value.resource.note[0].text : ""
        )}
        msgContentBody={((messageData[0].entry[0]?.resource.extension[0].valueString))}
        msgContentSenderName={messageData[0]?.entry[0]?.resource.sender.display}
        msgContentSenderID={messageData[0]?.entry[0]?.resource.sender.reference}
        msgContentRecipientName={messageData[0]?.entry[0]?.resource.recipient[0].display}
        msgContentRecipientID={messageData[0]?.entry[0]?.resource.recipient[0].reference}
        msgContentRecipientRoleID={parseInt(messageData[0]?.entry[0]?.resource.extension[8].valueDateTime)}
        contentPayload={messageData[0]?.entry[0]?.resource.payload}
        handleDeleteMessage={props.deleteMessage}
        setIsMsgDeleted={setIsDeleted}
        draftDisclaimerMsg={props.draftDisclaimerMsg}
        setActiveMessage={props.setActiveMessage}
        providerAvailable={loadOnlyAvailableProvidersData}
      /> : null}
    </>

  );
};



function mapStateToProps(state) {
  return {
    InboxMessages: state.InboxMessagesReducer,
    DraftMessages: state.DraftMessagesReducer,
    SendMessages: state.SendMessagesReducer,
    messageByID: state.MessageByIdReducer,
    DeleteMessage: state.DeleteMessagesReducer,

  }
}
function mapDispatchToProps(disptach) {
  return {
    listDraftMessages: (values) => disptach(listDraftMessageAPI(values)),
    listInboxMessages: (values) => disptach(listInboxMessageAPI(values)),
    listSendMessages: (values) => disptach(listSendMessageAPI(values)),
    messageClearData: () => disptach(clearMessageByIdData()),
    sendMessage: (values) => disptach(sendMessageAPI(values)),
    deleteMessage: (values) => disptach(deleteMessageAPI(values)),
    deleteMessageRefreshData: (values) => disptach(deleteMessageAPIRefresher(values)),
    getCounter: () => disptach(fetchCounterFromApi()),

  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MessageContent)
// export default
