import AsyncStorage from "@react-native-async-storage/async-storage";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Settings from "../../../config/Settings";
import getUserId from "../../../core/GetToken";

export const GetProfileImageFromAPI = createAsyncThunk(
    "getProfileImage/User",
    async (values, { dispatch, getState }) => {

        dispatch(getProfileImage())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = {
                PatientID: item.userid,
                DocumentKey: values.typeid
            }
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(raw)
            };
            fetch(Settings.apiUrl + "patient/LoadImage", requestOptions)
                .then(response => response.text())
                .then(result => {
                    dispatch(getProfileImageSuccess(result))
                })
                .catch(error => {
                    dispatch(getProfileImageFailed())
                    //{}
                });

        })

    }

);

const GetProfileImageSlice = createSlice({
    name: "getProfileImage",
    initialState: {
        ProfileImage: null,
        isFetching: false,
        error: false
    },
    reducers: {
        getProfileImage: (state, action) => {
            state.ProfileImage = null
            state.isFetching = true
            state.error = false
        },
        getProfileImageSuccess: (state, action) => {
            state.ProfileImage = action.payload
            state.isFetching = false
            state.error = false

        },
        getProfileImageFailed: (state, action) => {
            state.ProfileImage = null
            state.isFetching = false
            state.error = true
        },
        getProfileImageFinished: (state, action) => {
            state.ProfileImage = null
            state.isFetching = false
            state.error = false
        },

    },
});




export const {
    getProfileImage, getProfileImageFailed, getProfileImageSuccess, getProfileImageFinished
} = GetProfileImageSlice.actions;
export default GetProfileImageSlice.reducer;
