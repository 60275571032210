import { Button } from 'antd'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const PaymentFailRoute = () => {
    const [isCallFromRpeatRX, setIsCallFromRpeatRX] = useState(false)
    const [isCallFromWeb, setisCallFromWeb] = useState(false);
    const navigate = useNavigate()
    useEffect(() => {
            function parseQuery(queryString) {
                const params = {};
                const queryStringWithoutQuestionMark = queryString.substring(1);
                const queryParams = queryStringWithoutQuestionMark.split("&");
                queryParams.forEach((param) => {
                    const [key, value] = param.split("=");
                    params[key] = decodeURIComponent(value);
                });
                return params;
            }


            const queryString = window.location.search;
            let { IsRxRequest, callfrom } = parseQuery(queryString);
            setIsCallFromRpeatRX(IsRxRequest === "True" ? true : false)
            if (callfrom == "mobile") {
                setisCallFromWeb(false)
                // alert(queryString)
              } else {
                setisCallFromWeb(true)
              }



    }, [])
    return (
      <>
        <div className="page-content-wrapper-inner">
          <div className="content-viewport">
            <div className="row row-margin-zero h-100 text-center d-flex justify-content-center">
              {isCallFromWeb ? (
                <div className="d-flex flex-column align-items-center payment-returnpage-main-div w-75 max-width-400">
                  <img className="mb-5" src="assets/images/failed.svg" />
                  <h2 className="">Payment Declined</h2>
                  <p className="font-size-17">
                    We regret to inform you that your payment request has been declined. This could be due to your card being inactive, expired, or simply declined. Please ensure that your card is active and valid, or consider using an alternative payment method. We apologize for any inconvenience this may cause.
                  </p>
                  <button
                    className="payment-returnpage-btn mt-5"
                    onClick={() => {
                      isCallFromRpeatRX
                        ? navigate("/medications")
                        : navigate("/accounts");
                    }}
                  >
                    Go Back
                  </button>
                </div>
              ) : (
                <div className="d-flex flex-column align-items-center payment-returnpage-main-div w-75 max-width-400">
                  <img className="mb-5" src="assets/images/failed.svg" />
                    <h2 className="">Payment Declined</h2>
                  <p className="font-size-17">
                      We regret to inform you that your payment request has been declined. This could be due to your card being inactive, expired, or simply declined. Please ensure that your card is active and valid, or consider using an alternative payment method. We apologize for any inconvenience this may cause.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
}

export default PaymentFailRoute
