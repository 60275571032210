import AsyncStorage from "@react-native-async-storage/async-storage";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Settings from "../../../config/Settings";
import getUserId from "../../../core/GetToken";

export const GetAllImmuneFromAPI = createAsyncThunk(
    "getAllImmune/Immune",
    async (values, { dispatch, getState }) => {

        dispatch(getAllImmune())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = {
                PatientID: item.userid,
                PageSize: values.pageSize,
                PageNumber: values.pageNumber,
                TypeID: 0,
            }
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(raw)
            };

            fetch(Settings.apiUrl + "Patient/Immunization", requestOptions)
                .then(data => data.json())
                .then(json => {

                    if (json) {

                        json[0].hasOwnProperty('entry') ? dispatch(getAllImmuneSuccess(json[0].entry)) : dispatch(getAllImmuneFinished())
                    } else {
                        dispatch(getAllImmuneFinished())
                    }

                })
                .catch(err => dispatch(getAllImmuneFailed(err)))


        })

    }

);

const GetAllImmuneSlice = createSlice({
    name: "getAllImmune",
    initialState: {
        Immune: [],
        isGettingImmune: false,
        immuneError: false
    },
    reducers: {
        getAllImmune: (state, action) => {
            state.Immune = []
            state.isGettingImmune = true
            state.immuneError = false
        },
        getAllImmuneSuccess: (state, action) => {
            state.Immune = action.payload
            state.isGettingImmune = false
            state.immuneError = false

        },
        getAllImmuneFailed: (state, action) => {
            state.Immune = []
            state.isGettingImmune = false
            state.immuneError = true
        },
        getAllImmuneFinished: (state, action) => {
            state.Immune = []
            state.isGettingImmune = false
            state.immuneError = false
        },

    },
});




export const {
    getAllImmune, getAllImmuneFailed, getAllImmuneSuccess, getAllImmuneFinished
} = GetAllImmuneSlice.actions;
export default GetAllImmuneSlice.reducer;
