import React, { useCallback, useEffect, useState, useContext, useRef } from 'react';
import {
    StyleSheet,
    View,
    FlatList,
    Text,
    ActivityIndicator,
    Dimensions
} from 'react-native-web';
import { connect, useDispatch, useSelector } from 'react-redux'
import ImmuneHandler from "../../components/ImmuneHandler";
import { GetAppointmentDate, GetMonthName, GetYear } from '../../../shared/core/datehelper'
import { fetchDueImmunefromApi, fetchMoreDueImmunefromApi } from '../../../shared/actions/DueImmuneActions';
import { ImmuneHomeContext } from '../ImmuneHome';
import { useReactToPrint } from 'react-to-print';
import PrintAppointmentHeader from '../../components/PrintAppointmentHeader';
import { Tooltip } from 'antd';
import { actionAPI } from '../../../shared/reduxToolkit/actionAPI';


const DueTab = props => {
    const [pageSize, setPageSize] = useState(30);
    const [pageNumber, setPageNumber] = useState(1);
    const { DueImmune, isGettingImmune } = useSelector(state => state.DueImmune);
    const { Profile } = useSelector(state => state.UserProfile);
    const { Practise } = useSelector(state => state.PracticeOrganization);
    const [isPrintActive, setIsPrintActive] = useState(false);
    const { dispatch, state } = useContext(ImmuneHomeContext)

    const apiDispatcher = useDispatch()
    useEffect(() => {
        //alert('Called Future')
        apiDispatcher(actionAPI.GetDueImmuneFromAPI({ pageSize, pageNumber }))
    }, [])
    useEffect(() => {
        if (isGettingImmune) {
            dispatch({
                type: "ISIMMUNELOADED",

            })

        } else {
            typeof (DueImmune) !== 'undefined' && DueImmune.length &&
                dispatch({
                    type: "IMMUNELOAD",
                    immuneMg: DueImmune[0].resource.extension[13].valueString

                })
        }

    }, [isGettingImmune])
    const componentRef = useRef();
    const pageStyleCss = `@page {
        size: A4 landscape;
      }
      *{
          font-family: sans-serif;
        }
         .print-header{
            color: lightgrey;
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
      }
        .print-header-left{
            display: flex;
        }
        h3,h4,h5,p{
            margin:0px;
        }
        .card-header{
            background-color:#f2f4f9 !important;
            background:#f2f4f9 !important;
            padding:10px;
            border:1px solid #f2f4f9;
            -webkit-print-color-adjust:exact !important;
            print-color-adjust:exact !important; 
        }

        .card-body{
            border:1px solid #f2f4f9;
            padding:10px;
            padding-bottom:0
        }
        .card-body .card-title {
            padding:15px 0;
 border-bottom: 1px solid #f2f4f9;
        }
 
        .card-body .card-title:last-child {
            border-bottom:0px solid #f2f4f9;
        }

        .ind-med{
            
            padding:5px 10px; 
        }
        .card-text, .card-title, .med-date{
            color: black !important;
            font-weight: 300 !important;
        }
        .med-heading{
            color: black !important;
            font-weight: 600 !important;
        }
        .dont-print{
            display: none;
        }

        .appointment-print-container{
			display: flex;
            flex-direction: row;
            flex: 1 0 0;
		}
		.appointment-print-coumns{
			display: flex;
            flex-direction: column;
            flex: 1 0 0;
		}

        .padding-left-right-ten{
            padding-left: 10px;
            padding-right: 10px;
        }
     `;
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        copyStyles: false,
        pageStyle: pageStyleCss,
    })
    useEffect(() => {
        if (isPrintActive) {
            handlePrint()
            setIsPrintActive(false)
        }
    }, [isPrintActive])
    return (
        <div className="d-flex flex-column">
            {
                isGettingImmune && <div className='d-flex mt-2 mb-2 text-center justify-content-center'><ActivityIndicator size="small" color="#00A1DE" /></div>
            }
            {
                typeof (DueImmune) !== 'undefined' && DueImmune.length ? (
                    <>
                        <Tooltip title="Print" className="print-icon d-flex align-self-end justify-content-end align-items-center" onClick={() => { setIsPrintActive(true) }}>
                            <div className='print-immune-btn btn btn-primary'>
                            Print
                           </div>
                            {/* <p className="m-0 print-text">Print</p> */}
                        </Tooltip>

                        <div id="printModal" ref={componentRef} >

                            <PrintAppointmentHeader organizationData={Practise} patientProfile={Profile} isPrintActive={isPrintActive} />
                    <FlatList

                        data={DueImmune}
                        renderItem={({ item }) => (
                            <ImmuneHandler
                                styles={'card-header text-white bg-warning'}
                                date={GetAppointmentDate(item.resource.extension[7].valueDateTime) + ' ' + GetMonthName(item.resource.extension[7].valueDateTime) + ' ' + GetYear(item.resource.extension[7].valueDateTime)}//date={GetAppointmentDate(item.resource.extension[12].valueDateTime) + ' ' + GetMonthName(item.resource.extension[12].valueDateTime) + ' ' + GetYear(item.resource.extension[7].valueDateTime)}
                                month={GetYear(item.resource.extension[12].valueDateTime)}
                                // type_diagnoes={item.resource.extension[0].valueString}
                                heading={item.resource.extension[1].valueString}
                                vaccineName={item.resource.extension[0].valueString}
                                indication={(item.resource.extension[5].valueString != undefined ? item.resource.extension[5].valueString : "N/A")}
                                vacc={(item.resource.extension[6].valueString != undefined ? item.resource.extension[6].valueString : "N/A")}
                                datedue={  GetAppointmentDate(item.resource.extension[7].valueDateTime) + ' ' + GetMonthName(item.resource.extension[7].valueDateTime) + ' ' + GetYear(item.resource.extension[7].valueDateTime)}
                                vaccOutcome={""}

                            />
                        )}
                        keyExtractor={item => item.resource.id}
                        initialNumToRender={10}
                        maxToRenderPerBatch={10}
                        windowSize={7}
                    />
                        </div>
                    </>
                ) : !isGettingImmune && 
                <div className='alert alert-danger p-2 text-center'>No Records Found</div>
                

            }

        </div>
    );
}


function mapStateToProps(state) {
    return {
        DueImmune: state.DueImmune,
        Profile: state.Profile,
        Practise: state.Practise,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getDueImmune: (pageSize, pageNumber) => dispatch(fetchDueImmunefromApi(pageSize, pageNumber)),
        //getDueImmuneMoreSuccess: (pageSize, pageNumber) => dispatch(fetchMoreDueImmunefromApi(pageSize, pageNumber)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DueTab)
// export default DueTab
