import Modal from 'antd/lib/modal/Modal'
import React from 'react'

const DeleteConfirmModal = ({isModalOpen, handleOk, handleCancel}) => {
  return (
    <Modal
        open={isModalOpen}
        onOk={()=>handleOk()}
        onCancel={()=>handleCancel()}
        okText={"Yes"}
        cancelText={"No"}
      maskClosable={false}
    >
      Are you sure, You want to delete this message?
    </Modal>
  )
}

export default DeleteConfirmModal
