import { GETTING_MEDS, GETTING_MEDS_SUCCESS, GETTING_MEDS_MORE_SUCCESS, GETTING_MEDS_FAILED } from "../constants/Constant";

//inital state
const initalState = {
    AllMedeications: [],
    isGetting: false,
    error: false
}

export default function allMedReducer(state = initalState, action) {
    switch (action.type) {
        case GETTING_MEDS:
            return {
                ...state,
                isGetting: true,
                AllMedeications: []

            }

        case GETTING_MEDS_SUCCESS:
            return {
                ...state,
                isGetting: false,
                AllMedeications: action.data  // [...state.AllMedeications, ...action.data]

            }

        case GETTING_MEDS_FAILED:
            return {
                ...state,
                isGetting: false,
                AllMedeications: [],
                error: true
            }

        default:
            return state
    }
}

