import { Checkbox, Modal, Spin } from "antd";
import React, { useEffect, useState } from "react";
import getUserId from "../../shared/core/GetToken";
import moment from "moment";
import Settings from "../../shared/config/Settings";
import { isMobile } from "react-device-detect";
const UserInvoice = (props) => {
  const [invoiceBase64, setInvoiceBase64] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedPatientData, setSelectedPatientData] = useState({
    patientName: "",
    refNo: "",
  })
  const [selectAllBtn, setSelectAllBtn] = useState(false)
  const [payNowArr, setPayNowArr] = useState(props.AllInvc.map(invoice => {
    return { balance: invoice.resource.extension[7].valueString, paynow: invoice?.resource?.extension[8]?.valueDecimal }
  }))
  // "Amount": 10,
  // "Balance": 110,
  // "Notes": ""
  const [invoicesCheckState, setInvoicesCheckState] = useState(props.AllInvc.map(invoice => {
    return { InvoiceID: invoice.resource.id, isChecked: (selectAllBtn ? true : false), Amount: invoice.resource.extension[6].valueString, Balance: invoice.resource.extension[7].valueString, Notes: invoice.resource.extension[2].valueString, PayNow: invoice?.resource?.extension[8]?.valueDecimal }
  }))

  useEffect(() => {
    if (invoicesCheckState[0]) {
      props.handleCheckedInvoices(invoicesCheckState)
    }
  }, [invoicesCheckState])
  // const handleSelectAll=(e)=>{
  //   setSelectAllBtn(!selectAllBtn)
  //   setInvoicesCheckState(invoicesCheckState.map(data=>{
  //     return {id: data.id, isChecked: e.target.checked}
  //   }))
  // }
  //   const handleInvoiceCheck=(e)=>{
  //     setInvoicesCheckState(invoicesCheckState.map(data=> {
  //       if(data.id == e.target.id){
  //         return {id: data.id, isChecked: e.target.checked}
  //       }else{
  //         return data
  //       }
  //     })
  //     );
  //     // props.handleCheckedInvoices(invoicesCheckState)

  // }

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleDownloadPDFClick = (transactionID, patientName, refNo, patientUUID) => {
    setSelectedPatientData({
      ...selectedPatientData,
      patientName: patientName,
      refNo: refNo
    })
    showModal()
    getUserId().then((userInfo) => {
      const item = JSON.parse(userInfo)
      var myHeaders = new Headers();
      myHeaders.append("Token", item.token);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        PatientID: patientUUID,
        InvoiceTransactionID: transactionID
      })
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
        body: raw
      };
      let patientID = JSON.parse(localStorage.getItem("@userToken")).parentid;
      fetch(`${Settings.apiUrl}Patient/LoadInvoicePDF`, requestOptions)
        .then(response => response.json())
        .then(result => {

          setInvoiceBase64(result.PDFFileName)
        }
        ).catch(error => { })
    })
  }

  const handleAmountChange = () => {
    let enteredAmount = parseFloat(props.enteredAmount)
    if (enteredAmount > 0) {
      let remainingAmount = enteredAmount
      let invoiceCheckState = invoicesCheckState
      setPayNowArr(payNowArr.map((item, index) => {
        let obj = { ...item, paynow: (remainingAmount < parseFloat(item.balance) ? remainingAmount : parseFloat(item.balance)).toFixed(2) }
        if (obj.paynow > 0) {
          invoiceCheckState = invoiceCheckState.map((invoice, invInd) => {
            if (index == invInd) {
              return { ...invoice, isChecked: true, PayNow: obj.paynow }
            } else {
              return invoice
            }
          })
        } else {
          invoiceCheckState = invoiceCheckState.map((invoice, invInd) => {
            if (index == invInd) {
              return { ...invoice, isChecked: false }
            } else {
              return invoice
            }
          })
        }
        remainingAmount = remainingAmount - obj.paynow
        return obj
      }))
      setInvoicesCheckState(invoiceCheckState)
    } else {
      setPayNowArr(props.AllInvc.map(invoice => {
        return { balance: invoice.resource.extension[7].valueString, paynow: invoice?.resource?.extension[8]?.valueDecimal }
      }))

      setInvoicesCheckState(props.AllInvc.map(invoice => {
        return { InvoiceID: invoice.resource.id, isChecked: (selectAllBtn ? true : false), Amount: invoice.resource.extension[6].valueString, Balance: invoice.resource.extension[7].valueString, Notes: invoice.resource.extension[2].valueString, PayNow: invoice?.resource?.extension[8]?.valueDecimal }
      }))
    }
  }
  useEffect(() => {
    handleAmountChange()
  }, [props.enteredAmount])

  const base64ToBlob = (base64Data = "") => {
    const byteString = atob(base64Data);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }
    return new Blob([uint8Array], { type: 'application/pdf' });
  }

  // Function to handle the download event
  const handleDownload = () => {
    const blob = base64ToBlob(invoiceBase64);
    const url = URL.createObjectURL(blob);

    // Create a temporary anchor element to trigger the download
    const a = document.createElement('a');
    a.href = url;
    a.download = `${selectedPatientData.patientName} (${selectedPatientData.refNo})`; // Replace 'filename.pdf' with your desired filename for the downloaded PDF

    // Append the anchor element to the DOM, click it, and then remove it
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  return (
    <>
      <div className="table-responsive user-invoice-grid-container" >
        <table className="invoice-table table table-striped table-hover w-100">
          <thead className='user-invoice-table-head' >
            <tr className="invoice-table-row">
              {props.invoiceType == "pending" && <th className='w-50px'></th>}
              <th className='w-100px' >Patient</th>
              <th className='w-100px'>Payee</th>
              <th className='w-100px'>Date</th>
              <th className="notes-th w-250px">Notes</th>
              <th className='w-150px'>Type</th>
              <th className='w-150px'>Ref No.</th>
              <th className='w-150px'>Amount</th>
              <th className='w-150px'>Balance</th>
              <th className='w-100px'>Pay Now</th>
              <th className='w-37px'>Action</th>
            </tr>
          </thead>
          <tbody className="invoice-table-body">
            {
              props.AllInvc ?
                props.AllInvc.map((invoice, index) => {
                  let check = selectAllBtn ? true : false
                  return <tr className="invoice-table-body-row">
                    {props.invoiceType == "pending" && <td><Checkbox disabled id={invoice.resource.id} checked={invoicesCheckState[index]?.isChecked} /></td>}
                    <td> {invoice.resource.extension[4].valueString} </td>
                    <td> {invoice.resource.extension[3].valueString} </td>
                    <td>{moment(invoice.resource.extension[0].valueDateTime).format("ll")}</td>
                    <td>{invoice.resource.extension[1].valueString}</td>
                    <td>{invoice.resource.extension[2].valueString}</td>
                    <td>{invoice.resource.extension[5].valueString}</td>
                    <td><b className="text-success">{invoice.resource.extension[6].valueString}</b></td>
                    <td>{invoice.resource.extension[7].valueString}</td>
                    <td>{payNowArr[index]?.paynow}</td>

                    <td><span className="cursor-pointer" onClick={() => handleDownloadPDFClick(invoice.resource.id, invoice.resource.extension[4].valueString, invoice.resource.extension[5].valueString, invoice.resource.extension.find(o => o.url === "PatientUUID").valueString)}>
                     
                      <img src='assets/images/account-download.svg' className='mr-2' />
                    </span></td>

                  </tr>
                })
                : null
            }
          </tbody>
        </table>

      </div>
      <Modal title="Invoice" className="w-75" open={isModalOpen} onOk={handleOk} okText="Close" onCancel={handleCancel} cancelButtonProps={{ style: { display: 'none' } }} afterClose={() => { setInvoiceBase64(null) }}>
        {invoiceBase64 ?
          isMobile ? <div className="">
            <p>Invoice has been generated. Please click <span className="text-primary fw-bolder" onClick={handleDownload}> here </span> to download </p>
          </div> : <div className="PDF" id="showPDF">
            {invoiceBase64 ? <iframe id="pdfObject" src={"data:application/pdf;base64," + invoiceBase64} type="application/pdf"></iframe> : "Not Got it"}

          </div> : <div className="d-flex justify-content-center">
            <Spin />
          </div>  
        }
      </Modal>
    </>
  );
};

export default UserInvoice;
