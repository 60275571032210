import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import getUserId from '../../../shared/core/GetToken'
import Settings from '../../../shared/config/Settings'
import { notification } from 'antd'


const FailRoute = () => {
    const openNotificationWithIcon = (type, value, msg = '') => {
        notification[type]({
            message: msg,
            description: value,
        });
    };
    const navigate = useNavigate()
    const [paymentData, setPaymentData] = useState(null)
    const search = useLocation().search

    useEffect(() => {

        async function ftPaymentCall() {
            const pid = new URLSearchParams(search).get('pid');
            const token = new URLSearchParams(search).get('token');
            console.log("pid => ", pid, "token =>", token)


            const item = await getUserId().then((userInfo) => {
                return JSON.parse(userInfo);
            });

            var myHeaders = new Headers();
            myHeaders.append("patienttoken", item.token);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "transactionId": token,
                "patientId": pid,
                ApiUrl: Settings?.apiUrl?.replace("/api/", "")
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            const resultPoliTransaction = await fetch(`${process.env.REACT_APP_POLI_TRANURL}`, requestOptions);
            const finalresultPoliTransaction = await resultPoliTransaction.json();
            setPaymentData(finalresultPoliTransaction);

            if (finalresultPoliTransaction.TransactionStatus == "Completed") {

                ///////////////////////////final payment call//////////////////////////////////////////////////////
                let temppayResult = localStorage.getItem("temppaymentResult");
                if (temppayResult && parseInt(temppayResult) > 0) {

                    ///////////////neeed to get temppaymentResult id from lcalstorage
                    const payloadqueryStringFinal = `?PatientID=${pid}&electronicPaymentID=${temppayResult}`
                    const responsePaymentFinal = await fetch(`${Settings.apiUrl}patient/SavePayment${payloadqueryStringFinal}`, {
                        method: 'POST',
                        headers: {
                            "Content-Type": "application/json",
                            "Token": item.token
                        },
                        redirect: 'follow'
                    });

                    const finalpaymentResult = await responsePaymentFinal.json();

                    if (finalpaymentResult) {
                        // openNotificationWithIcon('success', 'Payment succeeded successfully')
                    }
                    else {
                        // openNotificationWithIcon('error', 'Payment unsuccessful')
                    }
                }
                else {
                    // openNotificationWithIcon('error', 'Payment unsuccessful')
                }
                ///////////////////////////final payment call END//////////////////////////////////////////////////////
            }
        }

        try {
            ftPaymentCall();
        }
        catch (error) {
            console.log('error', error)
        }

    }, [])

    return (
        <div className="page-content-wrapper-inner">

            <div className="content-viewport">
                <div className="row row-margin-zero h-100 text-center d-flex justify-content-center">
                    <h1 className='mt-5'>{!paymentData ? "Processing Payment........." : paymentData?.TransactionStatus?.toLowerCase() == "completed" ? <div className='d-flex flex-column align-items-center mt-5'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="154.313" height="156.045" viewBox="0 0 154.313 156.045">
                            <g id="checkexact" transform="translate(-17371.427 -18803.621)">
                                <path id="Path_33868" data-name="Path 33868" d="M17080.219,18800.408l-11.062,12.85s-41.58-21.949-74.236,19.273c.357.178-26.945,34.441,1.785,74.592.178,0,43.008,50.146,94.58,0,0-.357,17.844-20.521,12.49-49.43.355.178,14.1-11.242,14.1-11.242s12.314,40.152-14.1,69.773c0,.357-46.039,57.105-107.07,12.67-.359,0-66.742-54.785-1.785-120.633C16995.1,18808.262,17029.182,18775.426,17080.219,18800.408Z" transform="translate(405 13.786)" fill="#49c701" />
                                <path id="Path_33867" data-name="Path 33867" d="M16991.383,18861l33.906-23.336s1.783-1.371,4.393,2.746,15.1,22.648,15.1,22.648,3.295,4.395,5.078,0,34.867-62.045,61.5-73.439c.961,14.689-1.373,25.121,6.59,47.221.137-.137-30.613,14-67.4,75.637-.137,0-2.191,5.264-4.836,0S17017.689,18871.092,16991.383,18861Z" transform="translate(401.203 14)" fill="#49c701" />
                            </g>
                        </svg>
                        <div className='pos-tick-shadow'>

                        </div>
                        <p className='text-bolder mt-2'>Payment Successful</p>
                    </div> : <div className='d-flex flex-column align-items-center mt-5'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="156.188" height="134.348" viewBox="0 0 156.188 134.348">
                            <g id="error" transform="translate(-9.5 -43.969)">
                                <g id="Group_6849" data-name="Group 6849" transform="translate(9.5 43.969)">
                                    <path id="Path_33882" data-name="Path 33882" d="M92.282,46.619l72.36,120.752a7.218,7.218,0,0,1-6.2,10.945H16.746a7.218,7.218,0,0,1-6.2-10.945L82.9,46.619a5.473,5.473,0,0,1,9.378,0Z" transform="translate(-9.5 -43.969)" fill="#e03" fill-rule="evenodd" />
                                    <path id="Path_33883" data-name="Path 33883" d="M115.027,91.076,49.654,200.169H180.4Z" transform="translate(-36.933 -76.152)" fill="#eff4f7" fill-rule="evenodd" />
                                    <g id="Group_6848" data-name="Group 6848" transform="translate(70.533 40.64)">
                                        <path id="Path_33884" data-name="Path 33884" d="M244.045,222.1c0,5.988-9.114,5.987-9.114,0l-2.8-42.607c0-9.664,14.708-9.665,14.708,0Zm-4.556,22.425a5.864,5.864,0,1,0-5.864-5.864A5.876,5.876,0,0,0,239.488,244.527Z" transform="translate(-232.134 -172.246)" fill="#e03" fill-rule="evenodd" />
                                    </g>
                                </g>
                            </g>
                        </svg>

                        <div className='pos-tick-shadow'>

                        </div>
                        <p className='text-bolder mt-2'>Payment Failed</p>
                    </div>}</h1>
                </div>
            </div>
        </div>
    )
}

export default FailRoute
