

import React from 'react';
import { StyleSheet, Text, View, Image, Dimensions } from 'react-native';


const ReminderHandler = props => {
    const getRandomTag = (value) => {
        // var textArray = [
        //     'card-header text-white bg-danger',
        //     'card-header text-white bg-success',
        //     'card-header text-white bg-primary',
        //     'card-header text-white bg-info',
        //     'card-header text-white bg-warning',
        // ];

        if (value === 'High') {
            return 'card-header text-white high-card-header-bg'
        }

        if (value === 'Normal') {
            return 'card-header text-white low-card-header-bg'
        }

        if (value === 'Medium') {
            return 'card-header text-white medium-card-header-bg'
        }
        else
        {
            return 'card-header text-white low-card-header-bg'
        }
        // var randomNumber = Math.floor(Math.random() * textArray.length);
        // return textArray[randomNumber]
        // audioElement.setAttribute('src', textArray[randomNumber]);
    }
    return (
        <div className="col-lg-6 ">
            <div className="card shadow p-0 mb-3 rounded margin-bottom-ten">
                <div className={getRandomTag(props.color)}>
                    <div className='d-flex flew-row justify-content-space-between'>
                        <div className='primary-black-color font-weight-500'><b>{props.title}</b></div>
                        <div className='primary-black-color font-weight-500'><b>{props.reminderDate}</b></div>
                    </div>

                </div>
                <div className="card-body mb-0">
                    <h4 className="card-title mb-3">
                        <img src='assets/images/providername.svg' className='mr-2 w-20px' />
                        <b>Provider: </b>{props.reminderProvider}</h4>
                    <p className="card-text">
                        <div className="mr-2 description-icon-container">
                            <img src='assets/images/reminderdescription.svg' className='h-20px'  />
                        </div>
                        <b>Description: </b>{props.reminderDescription}</p>
                    <p className="card-text">
                        <div className="mr-2 description-icon-container">
                            <img src='assets/images/remindernotes.svg' className='h-20px' />
                        </div>
                        <b>Notes: </b>{props.reminderNote}</p>
                </div>
            </div>
        </div>
    );
}

export default ReminderHandler