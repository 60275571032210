import React from 'react';
import ReactDOM from 'react-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import './index.css';
import App from './Entry';

import { Provider } from "react-redux";
import store, { Persister } from "./shared/reduxToolkit/store";
import { PersistGate } from "redux-persist/integration/react"







// const store = configureStore();
// document.addEventListener('DOMContentLoaded', async () => {
 // const { publishableKey } = await fetch('http://localhost:4242/config').then((r) => r.json());

  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
          <PersistGate loading={null} persistor={Persister}>
            <App />
        </PersistGate>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  );
