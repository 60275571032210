import { Button, Modal } from "antd";
import { async } from "q";
import React, { useEffect, useState } from "react";
import { View, Text, Alert } from "react-native";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Settings from "../../shared/config/Settings";
import getUserId from "../../shared/core/GetToken";
import { actionAPI } from "../../shared/reduxToolkit/actionAPI";
// react-scripts --openssl-legacy-provider start
const PaymentSuccessRoute = () => {
  const [paymentData, setPaymentData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [repeatRequestSuccessful, setRepeatRequestSuccessful] = useState(false);
  const [isCallFromWeb, setisCallFromWeb] = useState(false);
  const [isCallFromRpeatRX, setIsCallFromRpeatRX] = useState(false);
  const [paymentMethod, setpaymentMethod] = useState(null);
  const [paymentChecking, setPaymentChecking] = useState("loading");
  const [sendingMedicationRepeatRequest, setSendingMedicationRepeatRequest] = useState(false);

  const navigate = useNavigate()
  let repeatPayload
  const search = useLocation().search
  const apiDispatcher = useDispatch()

  const repeatMedicationRequest = (payload, temppaymentid = "") => {
    getUserId().then(userInfo => {
      const item = JSON.parse(userInfo);
      var myHeaders = new Headers();
      myHeaders.append("Token", item.token);
      myHeaders.append("Content-Type", "application/json");

      let raw = {
        ...payload,
        "ElectronicPaymentID": temppaymentid
      }
      setSendingMedicationRepeatRequest(true)
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(raw),
        redirect: 'follow'
      };
      console.log("requestOptionsssss => ", requestOptions)

      let statusMed
      fetch(Settings.apiUrl + "Patient/SendRepeatMedicationRequest", requestOptions)
        .then(response => {
          writeToFileLog(`Step repeat: SendRepeatMedicationRequest call end`)

          statusMed = response.status
          return response.json()
        })
        .then((result) => {
          setSendingMedicationRepeatRequest(false)
          if (result.indexOf("successfully") >= 0) {
            setRepeatRequestSuccessful(true)
            apiDispatcher(actionAPI.GetAppSettingAPI())
            window.history.pushState('', '', '/paymentsuccess?checked=True');
          }
          // setIsModalOpen(true)
        })
    })
  }  
  const repeatMedicationRequest_Mobile = async (payload, patienttoken, apiUrl, temppaymentid) => {
    var myHeaders2 = new Headers();
    myHeaders2.append("Token", patienttoken);
    myHeaders2.append("Content-Type", "application/json");
    let raw = {
      ...payload,
      "ElectronicPaymentID": temppaymentid
    }
      const requestOptions = {
        method: "POST",
        headers: myHeaders2,
        body: JSON.stringify(raw),
        redirect: "follow",
      };
      setSendingMedicationRepeatRequest(true)
      let statusMed;
      fetch(
        apiUrl + '/api/Patient/SendRepeatMedicationRequest',
        requestOptions
      )
        .then((response) => {
          writeToFileLog(`Step repeat: SendRepeatMedicationRequest call end`)

          statusMed = response.status;
          // alert(response.status);
          return response.json();
        })
        .then((result) => {
          // alert("repeat-----"+JSON.stringify(result))
          setSendingMedicationRepeatRequest(false);

          if (result.indexOf("successfully") >= 0) {
            setRepeatRequestSuccessful(true)
            setPaymentChecking("success")
          }
       
        });
    
  }
  const writeToFileLog = (payload11) => {
    try {

      fetch(Settings.apiUrl + '/Patient/WriteToFile?data=' + decodeURI(payload11 + `${new Date()}`) + '', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(response => {
          return response.json();
        })
        .then(data => {
          console.log(data);
          // Do something with the retrieved data
        })
        .catch(error => {
          console.error('There was a problem with the fetch operation:', error);
        });
    }
    catch (e) {
      console.log(e)
    }
  }
  useEffect(async () => {
    function parseQuery(queryString) {
      const params = {};
      const queryStringWithoutQuestionMark = queryString.substring(1);
      const queryParams = queryStringWithoutQuestionMark.split("&");
      queryParams.forEach((param) => {
        const [key, value] = param.split("=");
        params[key] = decodeURIComponent(value);
      });
      return params;
    }
    try {



    const queryString = window.location.search;
    let { apiUrl, pid, patienttoken, paymenttype, callfrom, IsRxRequest, temppaymentid, token, patientid, abctick, sessionId, checked } = parseQuery(queryString);
    writeToFileLog(`Step 1: PID:${patientid}`)

    setIsCallFromRpeatRX(IsRxRequest === "True" ? true : false)

    if (checked === "True") {
      setPaymentChecking("success")
      setRepeatRequestSuccessful(true)
    }
    if (callfrom == "mobile") {
      setisCallFromWeb(false)
    } else {
      setisCallFromWeb(true)
    }
    setpaymentMethod(paymenttype)
    // alert(window.location.search);
    debugger
    apiUrl = abctick;
    // alert(apiUrl)
    setTimeout(async () => {

    if(callfrom == "mobile" && paymenttype =="stripe")
    {
      writeToFileLog(`Step 2: PID:${patientid} GetTempPaymentSessionID call start`)

      var myHeaders = new Headers();
      myHeaders.append("Token", token);
      myHeaders.append("Content-Type", "application/json");
      let IsAutoAdjustment

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
        body: JSON.stringify({
          TempPaymentID: temppaymentid
        }) 
      };

      fetch(apiUrl + "/api/Patient/GetTempPaymentSessionID", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          writeToFileLog(`Step 2: PID:${patientid} GetTempPaymentSessionID call end`)

         console.log("GetTempPaymentSessionID response", JSON.stringify(result))


         if (JSON.parse(result?.RepeatPayload)?.split("&repeat=")[1]) {
          IsAutoAdjustment = JSON.parse(result?.RepeatPayload)?.split("&IsAutoAdjustment=")[1]?.indexOf("True") === 0
           repeatPayload = JSON.parse(result?.RepeatPayload)?.split("&repeat=")[1]
           repeatPayload = JSON.parse(repeatPayload)
         }

          var raw2 = JSON.stringify({
            "SessionID": result?.PaymentSessionID,
            "PatientID": patientid,

          });
// alert('slss' + repeatPayload)       
          writeToFileLog(`Step 3: PID:${patientid} GetStripeTransactionBySession call start`)

          var requestOptions2 = {
            method: 'POST',
            headers: myHeaders,
            body: raw2,
            redirect: 'follow'
          };
          fetch(apiUrl + "/api/Patient/GetStripeTransactionBySession", requestOptions2)
            .then(response => {
              return response.json()
            })
            .then(json => {     
              writeToFileLog(`Step 3: PID:${patientid} GetStripeTransactionBySession call end`)

              console.log('axaxa',JSON.stringify(json))

              if (true) {
               

                const payloadqueryStringFinal = `?PatientID=${patientid}&electronicPaymentID=${temppaymentid}&${IsRxRequest === "True" ? "IsAutoAdjustment=" + IsAutoAdjustment : "IsAutoAdjustment=" + false}`;

                writeToFileLog(`Step 4: PID:${patientid} SavePayment2 call start`)

                fetch(apiUrl + "/api/Patient/SavePayment2" + payloadqueryStringFinal, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    "Token": token,
                  },
                  redirect: 'follow',
                })
                  .then((response) => response.json())
                  .then((result) => {
                    writeToFileLog(`Step 4: PID:${patientid} SavePayment2 call end`)

                    setPaymentChecking("success")
                  //  alert(JSON.stringify(result))


                    if (result && repeatPayload) {
                      // openNotificationWithIcon('success', 'Payment succeeded successfully')
                      // alert("inside resullt and repelapayloadddd")
                      setPaymentChecking("success")
                      writeToFileLog(`Step repeat: PID:${patientid} SendRepeatMedicationRequest call start`)

                      repeatMedicationRequest_Mobile(repeatPayload, token, apiUrl, temppaymentid)
                    }
                    else if (result) {
                      setPaymentChecking("success")
                      setRepeatRequestSuccessful(true)
                      // setIsModalOpen(true)
                    } else {
                      // openNotificationWithIcon('error', 'Payment unsuccessful')

                    }

                  });
              }

            })
        // } else {
        //   setPaymentChecking("failed")
        // }
        })
        .catch(err => console.log(err))
      
    }
    else if (callfrom == "mobile" && paymenttype == "poli") {

      setPaymentChecking("loading")
      let IsAutoInvoice = false

      const myHeaders = new Headers();
      myHeaders.append("Token", patienttoken);
      myHeaders.append("Content-Type", "application/json");
  
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
        body: JSON.stringify({
          TempPaymentID: temppaymentid
        })
      };
      // alert(apiUrl)
      // alert(temppaymentid)
      writeToFileLog(`Step 5: PID:${patientid} GetTempPaymentSessionID call start`)

      fetch(apiUrl + "/api/Patient/GetTempPaymentSessionID", requestOptions)
        .then(response => {
          return response.json()
        })
        .then(json => {
          writeToFileLog(`Step 5: PID:${patientid} GetTempPaymentSessionID call end`)

          // alert(JSON.parse(JSON.parse(json?.RepeatPayload)?.key2))
          // alert(token)
          // alert(apiUrl)
          // alert(JSON.stringify(json)   )
          if (JSON.parse(json?.RepeatPayload)?.split("&repeat=")[1]) {
            IsAutoInvoice = JSON.parse(json?.RepeatPayload)?.split("&IsAutoAdjustment=")[1]?.indexOf("True") === 0

            repeatPayload = JSON.parse(json?.RepeatPayload)?.split("&repeat=")[1]
            repeatPayload = JSON.parse(repeatPayload)
          }

          setPaymentData(json);




            // if (repeatJsonTID && parseInt(repeatJsonTID) > 0) {
            if (temppaymentid ) {
              const payloadqueryStringFinal = `?PatientID=${patientid}&electronicPaymentID=${temppaymentid}&${IsRxRequest === "True" ? "IsAutoAdjustment=" + true : "IsAutoAdjustment=" + false}`;
              // alert(payloadqueryStringFinal)
              writeToFileLog(`Step 6: PID:${patientid} SavePayment2 call start`)

              fetch(
                apiUrl + '/api/patient/SavePayment2' + payloadqueryStringFinal,
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Token: patienttoken,
                  },
                  redirect: "follow",
                }
              ).then(res => res.json())
                .then(json => {
                  if (json && repeatPayload) {
                    // openNotificationWithIcon('success', 'Payment succeeded successfully')
                    setPaymentChecking("success")
                    writeToFileLog(`Step repeat: PID:${patientid} SendRepeatMedicationRequest call start`)

                    repeatMedicationRequest_Mobile(repeatPayload, patienttoken, apiUrl, temppaymentid)

                  }
                  else if (json) {
                    setPaymentChecking("success")
                    // window.history.pushState('', '', '/paymentsuccess?checked=True');
                    setRepeatRequestSuccessful(true)

                  }
                })
          }

          setPaymentChecking("success")
          // alert("Accountssss")
          return;


 
    // alert("repeattttttt"+ JSON.stringify(repeatPayload));

    // const startIndexTID =
    //   finalresultPoliTransaction?.MerchantData?.indexOf("temppaymentid=") + 14;
    // const endIndexTID =
    //   finalresultPoliTransaction?.MerchantData?.lastIndexOf("*");
    // const repeatJsonTID = finalresultPoliTransaction?.MerchantData?.substring(
    //   startIndexTID,
    //   endIndexTID
    // );
    // alert("Repeatttttt"+ JSON.stringify(repeatJsonTID))
      // alert("inside1")



        })
    }
    else if(callfrom == "mobile" && paymenttype =="windcave"){
      
      setPaymentChecking("loading")
      var myHeaders = new Headers();
      myHeaders.append("Token", token);
      myHeaders.append("Content-Type", "application/json");
      writeToFileLog(`Step 9: PID:${patientid} GetWindCaveSessionById call start`)

      let IsAutoAdjustment = false

      let raw = {
        "PatientID": patientid,
        "SessionID": sessionId
      }
      console.log(raw)
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
        body: JSON.stringify(raw)
      };
      console.log(apiUrl + "/api/Patient/GetWindCaveSessionById")

      fetch(apiUrl + "/api/Patient/GetWindCaveSessionById", requestOptions)
        .then(response => {
          return response.json()
        })
        .then(json => {
          writeToFileLog(`Step 9: PID:${patientid} GetWindCaveSessionById call end`)

          if (true) {
 

                  var myHeaders = new Headers();
                  myHeaders.append("Token", token);
                  myHeaders.append("Content-Type", "application/json");
 
                  var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    redirect: 'follow',
                    body: JSON.stringify({
                      TempPaymentID: temppaymentid
                    }) 
                  };
                  // alert(apiUrl)
                  // alert(temppaymentid)
            writeToFileLog(`Step 10: PID:${patientid} GetTempPaymentSessionID call start`)

            fetch(apiUrl + "/api/Patient/GetTempPaymentSessionID", requestOptions)
                    .then(response => {
                      return response.json()
                    })
                    .then(json => {
                      writeToFileLog(`Step 10: PID:${patientid} GetTempPaymentSessionID call end`)

                      // alert(JSON.parse(JSON.parse(json?.RepeatPayload)?.key2))
                      // alert(token)
                      // alert(apiUrl)
                      // alert(JSON.stringify(json)   )
                      if (IsRxRequest === "True" && JSON.parse(json?.RepeatPayload)?.key1) {
                        IsAutoAdjustment = JSON.parse(json?.RepeatPayload)?.key1?.split("&IsAutoAdjustment=")[1]?.indexOf("True") >= 0
                      }
                      if (IsRxRequest === "True" && JSON.parse(JSON.parse(json?.RepeatPayload)?.key2)) {
                        // alert("inside repeatt")
                        repeatPayload = JSON.parse(JSON.parse(json?.RepeatPayload)?.key2)

                      }
                      const payloadqueryStringFinal = `?PatientID=${patientid}&electronicPaymentID=${temppaymentid}&${IsRxRequest === "True" ? "IsAutoAdjustment=" + IsAutoAdjustment : "IsAutoAdjustment=" + false}`;
                      writeToFileLog(`Step 11: PID:${patientid} SavePayment2 call start`)

                      fetch(apiUrl + "/api/Patient/SavePayment2" + payloadqueryStringFinal, {
                        method: 'POST',
                        headers: {
                          'Content-Type': 'application/json',
                          "Token": token,
                        },
                        redirect: 'follow',
                      })
                        .then((response) => response.json())
                        .then((result) => {
                          writeToFileLog(`Step 11: PID:${patientid} SavePayment2 call end`)

                          setPaymentChecking("success")

                          if (IsRxRequest === "true" || IsRxRequest === "True") {

                            repeatMedicationRequest_Mobile(repeatPayload, token, apiUrl, temppaymentid)
                          }
                        })
                      // alert("outside repeatt")
                    })
                // } else {
                //   // alert("else success")
                //   setPaymentChecking("success")
                // }
            
          }
        })
    }
    else if (callfrom == "web" && paymenttype == "poli") {
      async function ftPaymentCall() {
        setPaymentChecking("loading")
        const pid = new URLSearchParams(search).get('pid');
        // const token = new URLSearchParams(search).get('token');
        let repeatPayload
        let IsAutoInvoice = false
        const item = await getUserId().then((userInfo) => {
          return JSON.parse(userInfo);
        });
        writeToFileLog(`Step 12: PID:${patientid} GetTempPaymentSessionID call start`)

        var myHeaders = new Headers();
        myHeaders.append("Token", item.token);
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify({
            TempPaymentID: temppaymentid
          })
        };
        // alert(apiUrl)
        // alert(temppaymentid)
        writeToFileLog(`Step 10: PID:${patientid} GetTempPaymentSessionID call start`)

        fetch(apiUrl + "/api/Patient/GetTempPaymentSessionID", requestOptions)
          .then(response => {
            return response.json()
          })
          .then(json => {
            writeToFileLog(`Step 10: PID:${patientid} GetTempPaymentSessionID call end`)

            // alert(JSON.parse(JSON.parse(json?.RepeatPayload)?.key2))
            // alert(token)
            // alert(apiUrl)
            // alert(JSON.stringify(json)   )
            if (JSON.parse(json?.RepeatPayload)?.split("&repeat=")[1]) {
              IsAutoInvoice = JSON.parse(json?.RepeatPayload)?.split("&IsAutoAdjustment=")[1]?.indexOf("True") === 0

              repeatPayload = JSON.parse(json?.RepeatPayload)?.split("&repeat=")[1]
              repeatPayload = JSON.parse(repeatPayload)
            }

            setPaymentData(json);


            writeToFileLog(`Step 12: PID:${patientid} GetTempPaymentSessionID call end`)
            if (true) {

              ///////////////////////////final payment call//////////////////////////////////////////////////////

              if (temppaymentid) {
                writeToFileLog(`Step 12: PID:${patientid} SavePayment2 call start`)

                ///////////////neeed to get temppaymentResult id from lcalstorage
                const payloadqueryStringFinal = `?PatientID=${item.userid}&electronicPaymentID=${temppaymentid}&${IsRxRequest === "True" ? "IsAutoAdjustment=" + IsAutoInvoice : "IsAutoAdjustment=" + false}`
                fetch(`${Settings.apiUrl}patient/SavePayment2${payloadqueryStringFinal}`, {
                  method: 'POST',
                  headers: {
                    "Content-Type": "application/json",
                    "Token": item.token
                  },
                  redirect: 'follow'
                })
                  .then(response => {
                    return response.json()
                  })
                  .then(json => {


                    writeToFileLog(`Step 12: PID:${patientid} SavePayment2 call end`)
                    if (json && repeatPayload) {
                      // openNotificationWithIcon('success', 'Payment succeeded successfully')
                      setPaymentChecking("success")
                      writeToFileLog(`Step repeat: PID:${patientid} SendRepeatMedicationRequest call start`)

                      repeatMedicationRequest(repeatPayload, temppaymentid)
                    }
                    else if (json) {
                      setPaymentChecking("success")
                      window.history.pushState('', '', '/paymentsuccess?checked=True');
                      setRepeatRequestSuccessful(true)
                      // setTimeout(() => {
                      //   navigate("/accounts")
                      // }, 2000)

                      // setIsModalOpen(true)
                    } else {
                      // openNotificationWithIcon('error', 'Payment unsuccessful')

                    }
                  })
              }
              else {
                // openNotificationWithIcon('error', 'Payment unsuccessful')
              }
              ///////////////////////////final payment call END//////////////////////////////////////////////////////
            }
          })

      }
      try {
        ftPaymentCall();
      }
      catch (error) {
        console.log('error', error)
      }
    }
    else if (callfrom == "web" && paymenttype == "stripe") {
      const item = await getUserId().then((userInfo) => {
        return JSON.parse(userInfo);
      });
      writeToFileLog(`Step 13: PID:${patientid} GetTempPaymentSessionID call start`)

      setPaymentChecking("loading")
      let IsAutoAdjustment
      var myHeaders = new Headers();
      myHeaders.append("Token", item.token);
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
        body: JSON.stringify({
          TempPaymentID: temppaymentid
        }) 
      };
      fetch(Settings.apiUrl + "Patient/GetTempPaymentSessionID", requestOptions)
        .then(response => {
          return response.json()
        })
        .then(json => {
          writeToFileLog(`Step 13: PID:${patientid} GetTempPaymentSessionID call end`)

          console.log("GetTempPaymentSessionID response", json)
          if (JSON.parse(json?.RepeatPayload)?.split("&repeat=")[1]) {
            IsAutoAdjustment = JSON.parse(json?.RepeatPayload)?.split("&IsAutoAdjustment=")[1]?.indexOf("True") === 0

            repeatPayload = JSON.parse(json?.RepeatPayload)?.split("&repeat=")[1]
            repeatPayload = JSON.parse(repeatPayload)
          }

          if (typeof (json?.PaymentSessionID) === "string") {

            var raw2 = JSON.stringify({
              "SessionID": json?.PaymentSessionID,
              "PatientID": item.userid,

            });

            writeToFileLog(`Step 14: PID:${patientid} GetStripeTransactionBySession call start`)

            var requestOptions2 = {
              method: 'POST',
              headers: myHeaders,
              body: raw2,
              redirect: 'follow'
            };
            fetch(Settings.apiUrl + "Patient/GetStripeTransactionBySession", requestOptions2)
              .then(response => {
                return response.json()
              })
              .then(json => {
                writeToFileLog(`Step 14: PID:${patientid} GetStripeTransactionBySession call end`)

                console.log("GetStripeTransactionBySession response ", json)
                if (true) {
                  let merchantPayload = json?.response?.metadata?.MerchantPayload
                  if (merchantPayload?.indexOf('repeat=') >= 0) {

                    const startIndex = merchantPayload?.indexOf('repeat=') + 7;
                    const endIndex = merchantPayload?.lastIndexOf('}');
                    const repeatJson = merchantPayload?.substring(startIndex, endIndex + 1);
                    repeatPayload = JSON.parse(repeatJson);
                    console.log("lorem Ipsum")
                    console.log("repeattttttt", repeatPayload)
                  }
                  const payloadqueryStringFinal = `?PatientID=${patientid}&electronicPaymentID=${temppaymentid}&${IsRxRequest === "True" ? "IsAutoAdjustment=" + IsAutoAdjustment : "IsAutoAdjustment=" + false}`;
                  writeToFileLog(`Step 15: PID:${patientid} SavePayment2 call start`)

                  fetch(Settings.apiUrl + "Patient/SavePayment2" + payloadqueryStringFinal, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                      "Token": token,
                    },
                    redirect: 'follow',
                  })
                    .then((response) => response.json())
                    .then((result) => {
                      writeToFileLog(`Step 15: PID:${patientid} SavePayment2 call end`)

                      setPaymentChecking("success")
                      console.log(result)


                      if (result && repeatPayload) {
                        // openNotificationWithIcon('success', 'Payment succeeded successfully')
                        setPaymentChecking("success")
                        writeToFileLog(`Step repeat: PID:${patientid} SendRepeatMedicationRequest call start`)

                        repeatMedicationRequest(repeatPayload, temppaymentid)
                      }
                      else if (result) {
                        setPaymentChecking("success")
                        window.history.pushState('', '', '/paymentsuccess?checked=True');

                        setRepeatRequestSuccessful(true)
                        // setTimeout(() => {
                        //   navigate("/accounts")
                        // }, 2000)
                        // setIsModalOpen(true)
                      } else {
                        // openNotificationWithIcon('error', 'Payment unsuccessful')

                      }

                    });
                } 

              })
          } else {
            setPaymentChecking("failed")
          }
        }
        )
        .catch(err => console.log(err))
    }
    else if (callfrom == "web" && paymenttype == "windcave") {
      // {
      //   "SessionID": "000003000187955303d4b14c52e6bb4b",
      //     "PatientID": "8A006891-86E9-45DA-9170-865473AA5DC5"
      // }

      const item = await getUserId().then((userInfo) => {
        return JSON.parse(userInfo);
      });
      setPaymentChecking("loading")
      var myHeaders = new Headers();
      myHeaders.append("Token", item.token);
      myHeaders.append("Content-Type", "application/json");
      writeToFileLog(`Step 16: PID:${patientid} GetWindCaveSessionById call start`)

      let IsAutoAdjustment = false
      let raw = {
        "PatientID": item.userid,
        "SessionID": sessionId
      }
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
        body: JSON.stringify(raw)
      };
      fetch(Settings.apiUrl + "Patient/GetWindCaveSessionById", requestOptions)
        .then(response => {
          return response.json()
        })
        .then(json => {
          writeToFileLog(`Step 16: PID:${patientid} GetWindCaveSessionById call end`)

          console.log("GetWindCaveSessionById response ", json)
          if (true) {


                  var myHeaders = new Headers();
                  myHeaders.append("Token", item.token);
                  myHeaders.append("Content-Type", "application/json");

                  var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    redirect: 'follow',
                    body: JSON.stringify({
                      TempPaymentID: temppaymentid
                    }) 
                  };
            writeToFileLog(`Step 17: PID:${patientid} GetTempPaymentSessionID call start`)

            fetch(Settings.apiUrl + "Patient/GetTempPaymentSessionID", requestOptions)
                    .then(response => {
                      return response.json()
                    })
                    .then(json => {
                      writeToFileLog(`Step 17: PID:${patientid} GetTempPaymentSessionID call end`)

                      console.log("Temp payment ID", json)
                      if (IsRxRequest === "True" && JSON.parse(json?.RepeatPayload)?.key1) {
                        IsAutoAdjustment = JSON.parse(json?.RepeatPayload)?.key1?.split("&IsAutoAdjustment=")[1]?.indexOf("True") >= 0
                      }
                      if (IsRxRequest === "True" && JSON.parse(JSON.parse(json?.RepeatPayload)?.key2)) {
                        repeatPayload = JSON.parse(JSON.parse(json?.RepeatPayload)?.key2)
                      }
                      const payloadqueryStringFinal = `?PatientID=${patientid}&electronicPaymentID=${temppaymentid}&${IsRxRequest === "True" ? "IsAutoAdjustment=" + IsAutoAdjustment : "IsAutoAdjustment=" + false}`;
                      writeToFileLog(`Step 18: PID:${patientid} SavePayment2 call start`)

                      fetch(Settings.apiUrl + "Patient/SavePayment2" + payloadqueryStringFinal, {
                        method: 'POST',
                        headers: {
                          'Content-Type': 'application/json',
                          "Token": token,
                        },
                        redirect: 'follow',
                      })
                        .then((response) => response.json())
                        .then((result) => {
                          writeToFileLog(`Step 18: PID:${patientid} SavePayment2 call end`)

                          setPaymentChecking("success")
                          if (IsRxRequest === "True" && repeatPayload) {
                            writeToFileLog(`Step repeat: PID:${patientid} SendRepeatMedicationRequest call start`)

                            repeatMedicationRequest(repeatPayload, temppaymentid)
                          } else {
                            window.history.pushState('', '', '/paymentsuccess?checked=True');

                          }
                        })
                    })


                  // setTimeout(() => {
                  //   navigate("/accounts")
                  // }, 2000)



          }
        })
    }
    }, 5000)
    } catch (error) {
      writeToFileLog(`Payment error: ${error}`)

    }

  }, [])

  return (
    <div className="page-content-wrapper-inner">

      <div className="content-viewport">
        <div className="row row-margin-zero h-100 text-center d-flex justify-content-center">
          {isCallFromWeb ?
            <h1 className='mt-5 w-75 max-width-400'>{(paymentMethod === "poli" && !paymentData) || (paymentChecking === "loading") ?
              <>
                <img src="assets/images/processing.svg" />
                <h2>Payment Processing</h2>
              </>

              : sendingMedicationRepeatRequest ?
                <>
                  <img src="assets/images/repeating.svg" />
                  <h2>Repeating Prescription</h2>
                </>
                : (paymentMethod === "poli" && paymentData?.TransactionStatus?.toLowerCase() == "completed") || paymentChecking === "success" ?
                  <>
                    <img src="assets/images/Success.svg" />
                    <h2>Request Successful</h2>
                    {isCallFromRpeatRX ? repeatRequestSuccessful ? <p className="font-size-17">
                      Your payment is successful and your prescription has been sent to the practice. You can check the status of your prescription under Rx Request.</p> : <p>Your request failed. Please try again. If the problem persists, contact your practice for further help.</p> : null}
                    <button className="payment-returnpage-btn mt-5" onClick={() => {
                      if (isCallFromRpeatRX) {
                        localStorage.setItem("redirectedOnce", false)
                        navigate("/medications?RepeatSuccess=True")
                      } else {
                        navigate("/accounts")

                      }
                    }}>Continue</button>
                  </>
                  : paymentChecking === "pending" ?
                    <>
                      <img src="assets/images/failed.svg" />
                      <h2>Payment is still processing at stripe end.</h2>
                      <button className="payment-returnpage-btn mt-5" onClick={() => {
                        if (isCallFromRpeatRX) {
                          localStorage.setItem("redirectedOnce", false)
                          navigate("/medications?RepeatSuccess=True")
                        } else {
                          navigate("/accounts")

                        }
                      }}>Continue</button>
                    </>
                    : <>
                    <img src="assets/images/failed.svg" />
                    <h2>Request Failed</h2>
                    <button className="payment-returnpage-btn mt-5" onClick={() => {
                      if (isCallFromRpeatRX) {
                        localStorage.setItem("redirectedOnce", false)
                        navigate("/medications?RepeatSuccess=True")
                      } else {
                        navigate("/accounts")

                      }
                    }}>Continue</button>
                  </>}</h1>
            :

            <h1 className='mt-5 w-75 max-width-400'>{(paymentMethod === "poli" && !paymentData) || (paymentChecking === "loading") ? <>
              <img src="assets/images/processing.svg" />
              <h2>Payment Processing</h2>
            </> : sendingMedicationRepeatRequest ? <>
              <img src="assets/images/repeating.svg" />
              <h2>Repeating Prescription</h2>
            </>
              : (paymentMethod === "poli" && paymentData?.TransactionStatus?.toLowerCase() == "completed") || paymentChecking === "success" ?
                <>
                  <img src="assets/images/Success.svg" />
                  <h2>Request Successful</h2>
                  {isCallFromRpeatRX == false ? <p className="font-size-17">
                      Your payment is successful. Please tap on back arrow shaped button on top left corner to proceed further.</p> : 
                    isCallFromRpeatRX ? repeatRequestSuccessful ? <p className="font-size-17">
                        Your payment is successful and your prescription has been sent to the practice. You can check the status of your prescription under Rx Request. Please tap on back arrow shaped button on top left corner to proceed further.</p> : <p>Your request failed. Please try again. If the problem persists, contact your practice for further help.</p> : null
                    // {/* <button className="payment-returnpage-btn mt-5" onClick={() => { isCallFromRpeatRX ? navigate("/medications") : navigate("/accounts") }}>Continue</button> */}
                  }
                </>
                :
                  paymentChecking !== "idle" && paymentChecking === "pending" ?
                    <>
                      <img src="assets/images/failed.svg" />
                      <h2>Payment is still processing at stripe end.</h2>
                      <button className="payment-returnpage-btn mt-5" onClick={() => {
                        if (isCallFromRpeatRX) {
                          localStorage.setItem("redirectedOnce", false)
                          navigate("/medications?RepeatSuccess=True")
                        } else {
                          navigate("/accounts")

                        }
                      }}>Continue</button>
                    </>
                    : paymentChecking !== "idle" && <>
                  <img src="assets/images/failed.svg" />
                    <h2>Request Failed</h2>
                    {/* <button className="payment-returnpage-btn mt-5" onClick={() => { isCallFromRpeatRX ? navigate("/medications") : navigate("/accounts") }}>Continue</button> */}
                </>
            }</h1>}
        </div>
      </div>
      <Modal
        open={isModalOpen}
        title={repeatRequestSuccessful ? 'Payment Successful' : 'Payment Failed'}
        maskClosable={false}
        closable={false}
        cancelButtonProps={{ style: { display: "none" } }}
        afterClose={() => {
          navigate("/medications?RepeatSuccess=True");
          localStorage.setItem("redirectedOnce", false)
        }}
        onOk={() => { setIsModalOpen(false) }}
      >
        {repeatRequestSuccessful ? <p>
          Your payment is successful and your prescription has been sent to the practice. You can check the status of your prescription under Rx Request.</p> : <p>Your request failed. Please try again. If the problem persists, contact your practice for further help.</p>}
      </Modal>
    </div>
  );
};


export default PaymentSuccessRoute;
