import AsyncStorage from "@react-native-async-storage/async-storage";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Settings from "../../../config/Settings";
import getUserId from "../../../core/GetToken";

export const RefreshCounterAPI = createAsyncThunk(
    "refreshCounter/Counter",
    async (values, { dispatch, getState }) => {

        dispatch(gettingCounterData())


        getUserId().then((userInfo) => {

            const item = JSON.parse(userInfo);

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = {
                PatientID: item.userid,
            }
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(raw)
            };

            fetch(Settings.apiUrl + "Patient/RefreshCounter", requestOptions)
                .then(response => response.json())
                .then(result => {

                    dispatch(gettingCounterDataSuccess(result[0]))
                })
                .catch(error => { gettingCounterDataFailed() });

        }).catch((err) => {
        })
    }

);

const RefreshCounterSlice = createSlice({
    name: "proxyUsersData",
    initialState: {
        Counter: '',
        isFetching: false,
        error: false,
        errorMsg: null
    },
    reducers: {
        gettingCounterData: (state, action) => {
            state.Counter = ""
            state.isFetching = true
            state.error = false
            state.errorMsg = null

        },
        gettingCounterDataSuccess: (state, action) => {
            state.Counter = action.payload
            state.isFetching = false
            state.error = false
            state.errorMsg = null

        },
        gettingCounterDataFailed: (state, action) => {
            state.Counter = ""
            state.isFetching = false
            state.error = true
            state.errorMsg = action.payload

        },

    },
});




export const {
    gettingCounterData, gettingCounterDataFailed, gettingCounterDataSuccess
} = RefreshCounterSlice.actions;
export default RefreshCounterSlice.reducer;
